// IMPORTS
// --------------------------------------------------------
// Redux
import { put, takeLatest, call } from 'redux-saga/effects';

// Type
import { LIST_SCRIPTATTEMPTS_REQUEST } from './types';

// Actions
import { doListScriptAttemptsFailure, doListScriptAttemptsSuccess } from './actions';

// Requests
import { listScriptAttempts } from '../requests';

// MAIN SAGAS
// --------------------------------------------------------
/**
 * Worker for handling listScriptAttempts
 * @return {void}
 */
function* workerListScriptAttempts() {
  try {
    const response = yield call(listScriptAttempts);
    yield call;
    if (!response?.data?.data) {
      throw new Error('workerListScriptAttempts - Response Error');
    }

    const { data } = response.data;

    yield put(doListScriptAttemptsSuccess({ data }));
  } catch (error) {
    const errors = error?.message ?? 'Something went wrong';
    yield put(doListScriptAttemptsFailure({ errors }));
  }
}

/**
 * Watches for Dispatch LIST_SCRIPTATTEMPTS_REQUEST
 * @return {void}
 */
export function* watcherListScriptAttempts() {
  yield takeLatest(LIST_SCRIPTATTEMPTS_REQUEST, workerListScriptAttempts);
}
