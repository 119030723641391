// IMPORTS
// --------------------------------------------------------
// Redux
import { put, takeLatest, call } from 'redux-saga/effects';

// Type
import { SCRIPTATTEMPTS_LMSSCRIPTS_REQUEST } from './types';

// Actions
import { doScriptAttemptsLmsScriptsFailure, doScriptAttemptsLmsScriptsSuccess } from './actions';

// Requests
import { scriptAttemptsLmsScripts } from '../requests';

// MAIN SAGAS
// --------------------------------------------------------
/**
 * Worker for handling scriptAttemptsLmsScripts
 * @return {void}
 */
function* workerScriptAttemptsLmsScripts({ token, id, enrollmentId, include }) {
  try {
    const response = yield call(scriptAttemptsLmsScripts, { token, id, enrollmentId, include });

    if (!response?.data?.data) {
      throw new Error('workerScriptAttemptsLmsScripts - Response Error');
    }

    const { data } = response.data;

    yield put(doScriptAttemptsLmsScriptsSuccess({ data }));
  } catch (error) {
    const errors = error?.message ?? 'Something went wrong';
    yield put(doScriptAttemptsLmsScriptsFailure({ errors }));
  }
}

/**
 * Watches for Dispatch SCRIPTATTEMPTS_LMSSCRIPTS_REQUEST
 * @return {void}
 */
export function* watcherScriptAttemptsLmsScripts() {
  yield takeLatest(SCRIPTATTEMPTS_LMSSCRIPTS_REQUEST, workerScriptAttemptsLmsScripts);
}
