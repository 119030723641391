// IMPORTS
// --------------------------------------------------------
import styled from 'styled-components';

import { Layout, Button, Rate } from 'antd';
import Card from 'antd/es/card';
import Input from 'antd/es/input';
import Skeleton from 'antd/es/skeleton';
import Select from 'antd/es/select';
const { Content, Footer } = Layout;

// MAIN STYLED COMPONENTS
// --------------------------------------------------------
/**
 * Parent styles for component
 * @constant
 */

const GuestAccessStyles = styled.div`
  background: linear-gradient(339deg, #e0eff7, #0098e4) no-repeat center center fixed !important;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  min-height: 100vh;

  a {
    color: #f7f7f7;
  }

  @media only screen and (max-width: 600px) {
    .aypo-widget-container {
      position: relative;
      top: -15px;
    }
    .aypo-title-container {
      display: none;
    }
    .aypo-phone-conversion {
      text-align: center;
      margin-bottom: 0px;
    }
    .aypo-phone-conversion-padding {
      padding: 0px;
    }
    .aypo-phone-conversion-hr {
      float: none;
      width: 50%;
      margin-left: auto;
      margin-right: auto;
    }
    .aypo-logo-container {
      max-width: 600px;
      padding: 25px;
      padding-bottom: 5px;
    }
    .aypo-logo {
      width: 100%;
    }
    .aypo-content-container {
      background: aliceblue;
      border-radius: 12.5px;
      padding: 20px;
      box-shadow: 0 2.8px 2.2px rgba(0, 0, 0, 0.034), 0 6.7px 5.3px rgba(0, 0, 0, 0.048), 0 12.5px 10px rgba(0, 0, 0, 0.06), 0 22.3px 17.9px rgba(0, 0, 0, 0.072), 0 41.8px 33.4px rgba(0, 0, 0, 0.086),
        0 100px 80px rgba(0, 0, 0, 0.12);
      margin-top: 0px;
      margin: 15px;
      margin-bottom: 50px;
    }
    .aypo-header-container {
      width: 100%;
    }
    .aypo-header-goback-container {
      font-size: 28px;
      color: #fff;
      position: relative;
      top: 5px;
      text-align: center;
    }
    .aypo-layout-container {
      padding: 0px;
      padding-bottom: 50px;
      padding-left: 0px;
      padding-right: 0px;
    }
  }
  @media only screen and (min-width: 600px) {
    .aypo-title-container {
      display: none;
    }
    .aypo-phone-conversion {
      text-align: center;
      margin-bottom: 0px;
    }
    .aypo-phone-conversion-padding {
      padding: 0px;
    }
    .aypo-phone-conversion-hr {
      float: none;
      width: 50%;
      margin-left: auto;
      margin-right: auto;
    }
    .aypo-logo-container {
      // max-width: 600px;
      padding: 25px;
      padding-bottom: 5px;
    }
    .aypo-logo {
      width: 100%;
    }
    .aypo-content-container {
      background: aliceblue;
      border-radius: 12.5px;
      padding: 30px;
      box-shadow: 0 2.8px 2.2px rgba(0, 0, 0, 0.034), 0 6.7px 5.3px rgba(0, 0, 0, 0.048), 0 12.5px 10px rgba(0, 0, 0, 0.06), 0 22.3px 17.9px rgba(0, 0, 0, 0.072), 0 41.8px 33.4px rgba(0, 0, 0, 0.086),
        0 100px 80px rgba(0, 0, 0, 0.12);
      margin: 35px;
      margin-bottom: 50px;
    }

    .aypo-header-container {
      width: 100%;
    }
    .aypo-header-goback-container {
      font-size: 28px;
      color: #fff;
      position: relative;
      top: 5px;
      text-align: center;
    }
    .aypo-layout-container {
      padding: 0px;
      padding-bottom: 50px;
      padding-left: 0px;
      padding-right: 0px;
    }
  }
  @media only screen and (min-width: 768px) {
    .aypo-title-container {
      display: block;
    }
    .aypo-phone-conversion {
      text-align: center;
      margin-bottom: 0px;
    }
    .aypo-phone-conversion-padding {
      padding: 0px;
    }
    .aypo-phone-conversion-hr {
      float: none;
      width: 50%;
      margin-left: auto;
      margin-right: auto;
    }
    .aypo-logo-container {
      // max-width: 600px;
      padding: 25px;
      padding-bottom: 5px;
    }
    .aypo-logo {
      width: 100%;
    }
    .aypo-content-container {
      background: aliceblue;
      border-radius: 12.5px;
      padding: 30px;
      box-shadow: 0 2.8px 2.2px rgba(0, 0, 0, 0.034), 0 6.7px 5.3px rgba(0, 0, 0, 0.048), 0 12.5px 10px rgba(0, 0, 0, 0.06), 0 22.3px 17.9px rgba(0, 0, 0, 0.072), 0 41.8px 33.4px rgba(0, 0, 0, 0.086),
        0 100px 80px rgba(0, 0, 0, 0.12);
      margin: 35px;
      margin-bottom: 50px;
    }
    .aypo-header-container {
      width: 100%;
    }
    .aypo-header-goback-container {
      font-size: 28px;
      color: #fff;
      position: relative;
      top: 5px;
      text-align: center;
    }
    .aypo-layout-container {
      padding: 0px;
      padding-bottom: 50px;
      padding-left: 0px;
      padding-right: 0px;
    }
  }
  @media only screen and (min-width: 992px) {
    .aypo-phone-conversion {
      text-align: right;
      margin-bottom: 0px;
      margin-right: 25px;
    }
    .aypo-phone-conversion-padding {
      padding: 20px;
    }
    .aypo-phone-conversion-hr {
      float: right;
      width: 20%;
    }
    .aypo-logo-container {
      // max-width: 600px;
      padding: 25px;
      padding-bottom: 5px;
    }
    .aypo-logo {
      width: 100%;
    }
    .aypo-content-container {
      background: aliceblue;
      border-radius: 12.5px;
      padding: 30px;
      box-shadow: 0 2.8px 2.2px rgba(0, 0, 0, 0.034), 0 6.7px 5.3px rgba(0, 0, 0, 0.048), 0 12.5px 10px rgba(0, 0, 0, 0.06), 0 22.3px 17.9px rgba(0, 0, 0, 0.072), 0 41.8px 33.4px rgba(0, 0, 0, 0.086),
        0 100px 80px rgba(0, 0, 0, 0.12);
      margin: 35px;
      margin-bottom: 50px;
    }
    .aypo-header-container {
      width: 100%;
    }
    .aypo-header-goback-container {
      font-size: 28px;
      color: #fff;
      position: relative;
      top: 5px;
      text-align: left;
      margin-left: 15px;
    }
    .aypo-layout-container {
      padding: 0px;
      padding-bottom: 50px;
      padding-left: 0px !important;
      padding-right: 0px !important;
    }
  }
  @media only screen and (min-width: 1100px) {
    .aypo-layout-container {
      padding: 0px;
      padding-bottom: 50px;
      padding-left: 0px !important;
      padding-right: 0px !important;
    }
  }
  @media only screen and (min-width: 1200px) {
    .aypo-phone-conversion {
      text-align: right;
    }
    .aypo-phone-conversion-hr {
      float: right;
      width: 10%;
    }
    .aypo-logo-container {
      max-width: 600px;
      padding: 30px;
    }
    .aypo-logo {
      width: 100%;
    }
    .aypo-layout-container {
      padding: 0px;
      padding-top: 20px;
      padding-bottom: 50px;
      padding-left: 50px !important;
      padding-right: 50px !important;
    }
    .aypo-header-container {
      padding-left: 75px;
      width: 100%;
      padding-right: 75px;
    }
    .aypo-footer-container {
      padding-left: 0px;
      width: 100%;
      padding-right: 0px;
      background: #efefef;
    }
    .aypo-phone-conversion-padding {
      padding: 30px;
    }
    .aypo-content-container {
      background: aliceblue;
      border-radius: 12.5px;
      padding: 20px;
      box-shadow: 0 2.8px 2.2px rgba(0, 0, 0, 0.034), 0 6.7px 5.3px rgba(0, 0, 0, 0.048), 0 12.5px 10px rgba(0, 0, 0, 0.06), 0 22.3px 17.9px rgba(0, 0, 0, 0.072), 0 41.8px 33.4px rgba(0, 0, 0, 0.086),
        0 100px 80px rgba(0, 0, 0, 0.12);
    }
  }
  @media only screen and (min-width: 1700px) {
    .aypo-content-container {
      background: aliceblue;
      border-radius: 12.5px;
      padding: 30px;
    }
  }

  .ant-form-item-label > label {
    color: #fff;
    font-size: 18px;
    text-shadow: 1px 1px 0 black, 1px 1px 0 black;
  }
  .aypo-phone-number {
    color: #fff;
    font-size: 38px;
    text-shadow: 1px 1px 0 black, 1px 1px 0 black;
  }
  .aypo-content-widget-container {
    background: #001529;
    width: 100%;
    height: 100%;
    padding: 25px;
    border-radius: 5px;
  }
  .aypo-form-container {
    background: #001529;
    height: auto;
    width: 100%;
    border-radius: 5px;
  }
  .list-inline {
    padding-left: 0;
    margin-left: -5px;
    list-style: none;
  }
  .list-inline > li {
    display: inline-block;
    padding-right: 5px;
    padding-left: 5px;
  }
`;

GuestAccessStyles.Content = styled(Content)`
  // @media only screen and (max-width: 1200px) {
  //   background: linear-gradient(339deg, #e0eff7, #0098e4) !important;
  //
  //   top: 0;
  //   left: 0;
  //   min-width: 100%;
  //   min-height: 100%;
  // }
  // @media only screen and (min-width: 1200px) {
  //   background: linear-gradient(339deg, #e0eff7, #0098e4) !important;
  //   top: 0;
  //   left: 0;
  //   min-width: 100%;
  //   min-height: 100%;
  //   // position: fixed;
  // }
`;

GuestAccessStyles.Footer = styled(Footer)`
  padding: 0;
`;
GuestAccessStyles.Button = styled(Button)`
  margin: auto;
  width: 100%;
  max-width: 300px;
  height: 61px;
  background-color: #ff8a00;
  font-size: 24px;
  text-transform: uppercase;
  color: #ffffff;
  cursor: pointer;
  border: 0;
  :hover {
    background-color: #0098e4;
    color: #fff;
    box-shadow: 0px 0px 9px 0px rgb(47 143 205 / 25%);
  }
`;

GuestAccessStyles.Input = styled(Input)`
  height: 60px;
  font-size: 2em;
`;

GuestAccessStyles.Rate = styled(Rate)`
  .anticon {
    font-size: 40px;
  }
  .ant-rate-star > div:hover,
  .ant-rate-star > div:focus {
    transform: scale(1);
  }
`;

GuestAccessStyles.Card = styled(Card)`
  .ant-card-body {
    padding: 0px;
  }
  .ant-tabs-tab-prev {
    background: aliceblue;
  }
  .ant-tabs-tab-next {
    background: aliceblue;
  }
`;

GuestAccessStyles.Skeleton = styled(Skeleton)`
  .ant-skeleton-paragraph > li,
  .ant-skeleton-title {
    box-shadow: 0 2.8px 2.2px rgba(0, 0, 0, 0.034), 0 6.7px 5.3px rgba(0, 0, 0, 0.048), 0 12.5px 10px rgba(0, 0, 0, 0.06), 0 22.3px 17.9px rgba(0, 0, 0, 0.072), 0 41.8px 33.4px rgba(0, 0, 0, 0.086),
      0 100px 80px rgba(0, 0, 0, 0.12);
  }
`;

GuestAccessStyles.Select = styled(Select)`
  height: 60px;
  .ant-select-selection--single {
    height: 60px;
    font-size: 2em;
  }
  .ant-select-selection__placeholder,
  .ant-select-search__field__placeholder {
    height: 60px;
    top: 90%;
  }
  .ant-select-selection-selected-value {
    margin-top: 12px;
  }
`;
export default GuestAccessStyles;
