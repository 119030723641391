// MAIN TYPES
// --------------------------------------------------------
/**
 * @constant
 */
export const UPDATE_SCRIPTATTEMPTS_RESET = 'UPDATE_SCRIPTATTEMPTS_RESET';

/**
 * @constant
 */
export const UPDATE_SCRIPTATTEMPTS_REQUEST = 'UPDATE_SCRIPTATTEMPTS_REQUEST';

/**
 * @constant
 */
export const UPDATE_SCRIPTATTEMPTS_SUCCESS = 'UPDATE_SCRIPTATTEMPTS_SUCCESS';

/**
 * @constant
 */
export const UPDATE_SCRIPTATTEMPTS_FAILURE = 'UPDATE_SCRIPTATTEMPTS_FAILURE';
