// Imports
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Redirect } from 'react-router-dom';

// Presentations
import LmsScript from '../../components/LmsScript';

// Operations
import { doReadLmsScriptRequest, doReadLmsScriptReset } from '../../redux/modules/LmsScripts/read/actions';
import { doCreateScriptAttemptsRequest, doCreateScriptAttemptsReset } from '../../redux/modules/ScriptAttempts/create/actions';

// Main Component
/**
 * Main Container
 * @param {*} props any
 * @returns {JSX.Element} Main JSX Routes
 */
const LmsScriptContainer = props => {
  // State / Props
  const {
    token,
    history,
    match,
    readLmsScript,
    createScriptAttempt,
    doReadLmsScriptRequest: readLmsScriptRequest,
    doReadLmsScriptReset: readLmsScriptReset,
    doCreateScriptAttemptsRequest: createScriptAttemptsRequest,
    doCreateScriptAttemptsReset: createScriptAttemptsReset,
  } = props;
  const { enrollmentId, courseId, lmsScriptId } = match.params;

  // Functions
  /**
   * Start a new quiz attempt
   * @returns {void}
   */
  const handleBegin = () => {
    createScriptAttemptsRequest({ token, lmsScriptId, enrollmentId });
  };

  // Hooks
  /**
   * Onload get the lms quiz
   */
  useEffect(() => {
    readLmsScriptRequest({ token, id: lmsScriptId, enrollmentId });
  }, [readLmsScriptRequest, enrollmentId, lmsScriptId, token]);

  /**
   * Clean up
   */
  useEffect(() => {
    return () => {
      readLmsScriptReset();
      createScriptAttemptsReset();
    };
  }, [readLmsScriptReset, createScriptAttemptsReset]);

  // Render
  return createScriptAttempt?.data?.id ? (
    <Redirect to={`/dashboard/enrollments/${enrollmentId}/courses/${courseId}/lmslessons/${lmsScriptId}/attempts/${createScriptAttempt.data.id}`} />
  ) : (
    <LmsScript
      data={readLmsScript.data}
      errors={readLmsScript.errors}
      loading={readLmsScript.loading || createScriptAttempt.loading}
      params={match.params}
      history={history}
      handleBegin={handleBegin}
    />
  );
};

// Map State / Dispatch To Props
/**
 * Main redux state
 * @param {*} state object
 * @returns {Object} state objects
 */
const mapStateToProps = state => ({
  token: state.loginReducer.token,
  readLmsScript: state.ReadLmsScriptsReducer,
  createScriptAttempt: state.CreateScriptAttemptsReducer,
});

/**
 * Main redux dispatch functions
 * @param {*} dispatch redux dispatch
 * @returns {bindActionCreators} state objects
 */
const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      doReadLmsScriptRequest,
      doCreateScriptAttemptsRequest,
      doCreateScriptAttemptsReset,
      doReadLmsScriptReset,
    },
    dispatch,
  );

// Prop Types
LmsScriptContainer.propTypes = {
  match: PropTypes.object,
  token: PropTypes.string,
  data: PropTypes.any,
  errors: PropTypes.any,
  loading: PropTypes.bool,
  history: PropTypes.object,
  doReadLmsScriptRequest: PropTypes.func,
  readLmsScript: PropTypes.any,
  createScriptAttempt: PropTypes.any,
  doReadLmsScriptReset: PropTypes.func,
  doCreateScriptAttemptsRequest: PropTypes.func,
  doCreateScriptAttemptsReset: PropTypes.func,
};

// Exports
export default connect(mapStateToProps, mapDispatchToProps)(LmsScriptContainer);
