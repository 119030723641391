// IMPORTS
// --------------------------------------------------------
// Redux
import { put, takeLatest, call } from 'redux-saga/effects';

// Type
import { CREATE_SCRIPTATTEMPTS_REQUEST } from './types';

// Actions
import { doCreateScriptAttemptsFailure, doCreateScriptAttemptsSuccess } from './actions';
import { doSetCurrentPosition } from '../read/actions';

// Requests
import { createScriptAttempts } from '../requests';

// MAIN SAGAS
// --------------------------------------------------------
/**
 * Worker for handling createScriptAttempts
 * @param {Object} param0 { token, enrollmentId, lmsScriptId, sectionId }
 * @return {void}
 */
function* workerCreateScriptAttempts({ token, enrollmentId, lmsScriptId, sectionId }) {
  try {
    const response = yield call(createScriptAttempts, { token, enrollmentId, lmsScriptId, sectionId });
    if (!response?.data?.data) {
      throw new Error('workerCreateScriptAttempts - Response Error');
    }
    const { data } = response.data;
    yield put(doCreateScriptAttemptsSuccess({ data }));
    yield put(doSetCurrentPosition({ data }));
  } catch (error) {
    const errors = error?.message ?? 'Something went wrong';
    yield put(doCreateScriptAttemptsFailure({ errors }));
  }
}

/**
 * Watches for Dispatch CREATE_SCRIPTATTEMPTS_REQUEST
 * @return {void}
 */
export function* watcherCreateScriptAttempts() {
  yield takeLatest(CREATE_SCRIPTATTEMPTS_REQUEST, workerCreateScriptAttempts);
}
