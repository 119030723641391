// Imports
import axios from 'axios';

// Config
const apiUri = window.API_URL;

// Export actions
export const putSection = ({ token, sectionId, videoWatched }) => {
  return axios({
    method: 'put',
    url: `${apiUri}/sections/${sectionId}`,
    data: { video_watched: videoWatched },
    headers: { Authorization: `Bearer ${token}` },
  });
};
