// IMPORTS
// --------------------------------------------------------
import React from 'react';
import PropTypes from 'prop-types';
import { PageHeader, Button, Spin, Icon } from 'antd';

// Styled Components
import { LmsScriptQuestionStyles } from './styles.js';

// Presentation Components

// MAIN PRESENTATION COMPONENT
// --------------------------------------------------------
/**
 * LmsScriptQuestion
 * @param {Object} props component props
 * @returns {JSX} React compoennt
 */
const LmsScriptQuestion = props => {
  // State / Props
  const { data, loading, disableQuestion, complete, handleClick, answers, options } = props;

  // Functions
  const onClickButton = data => () => {
    if (handleClick) {
      handleClick(data);
    }
  };

  // Hooks
  /**
   * On load set the question options
   */

  // Render
  return (
    <LmsScriptQuestionStyles>
      {loading || !data?.data ? (
        <Spin spinning={true} />
      ) : (
        <>
          <PageHeader title={data?.data?.question ?? ''} />
          {options?.length > 0 && (
            <>
              <ul>
                {options.map((i, k) => {
                  return answers !== undefined && answers.length && answers[k]?.option?.replace(/\n/gi, '') !== '' ? (
                    <li key={`question-${data?.data?.id ?? ''}-option-${k}`}>
                      <Button disabled={disableQuestion || loading || answers[k]?.disabled || complete} type="secondary" onClick={onClickButton(i)} className={`${answers[k]?.status || ''}`}>
                        {i?.option ?? ''}
                        {answers[k]?.status && answers[k]?.status === 'correct' && <Icon type="check-circle" />}
                        {answers[k]?.status && answers[k]?.status === 'incorrect' && <Icon type="close-circle" />}
                        {!answers[k]?.status && <Icon type="right" />}
                      </Button>
                    </li>
                  ) : (
                    ``
                  );
                })}
              </ul>
            </>
          )}
        </>
      )}
    </LmsScriptQuestionStyles>
  );
};

// PROP TYPES
// --------------------------------------------------------
LmsScriptQuestion.propTypes = {
  data: PropTypes.object,
  loading: PropTypes.bool,
  disableQuestion: PropTypes.bool,
  attempts: PropTypes.any,
  allowRetry: PropTypes.bool,
  complete: PropTypes.bool,
  handleClick: PropTypes.func,
  handleDone: PropTypes.func,
  answers: PropTypes.any,
  options: PropTypes.any,
};

// EXPORTS
// --------------------------------------------------------
export default LmsScriptQuestion;
