// Imports
import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

// Operations
import { doForgotPassword } from './operations';

// Presentation Components
import ForgotPassword from '../../components/ForgotPassword';

// Main Component
/**
 * Main Container
 * @param {*} props any
 * @returns {JSX.Element} Main JSX Routes
 */
const ForgotPasswordContainer = props => {
  // Props
  const { doForgotPassword: forgotPassword, data } = props;
  /**
   * Main function that handle form submit
   *
   * @param {event} event browser event
   * @param {form} form antd form object
   *
   * @returns {dispatch} Dispatches forgotpassword function
   */
  const handleSubmit = ({ email }) => {
    forgotPassword({ email });
  };

  // /**
  //  * This will load the function once
  //  * equivalent to 'componentDidMount'
  //  */
  // useEffect(() => {
  //   resetAuth();
  // }, [resetAuth]);

  // Render
  return <ForgotPassword data={data} loading={props.fetching} handleSubmit={handleSubmit} errors={props.errors || []} />;
};

// Mapping State / Dispatch To Props
/**
 * Main redux state
 * @param {*} state object
 * @returns {Object} state objects
 */
const mapStateToProps = state => ({
  fetching: state.forgotPasswordReducer.fetching,
  errors: state.forgotPasswordReducer.errors,
  data: state.forgotPasswordReducer.data,
});

/**
 * Main redux dispatch functions
 * @param {*} dispatch redux dispatch
 * @returns {bindActionCreators} state objects
 */
const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      doForgotPassword,
    },
    dispatch,
  );

// Property Types
ForgotPasswordContainer.propTypes = {
  doForgotPassword: PropTypes.func,
  data: PropTypes.object,
  fetching: PropTypes.bool,
  token: PropTypes.string,
  errors: PropTypes.array,
};

// Export
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ForgotPasswordContainer));
