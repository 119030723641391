// Imports
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Form } from 'antd';

// Operations
import { doPostReview, doGetStudentReview } from './operations';

// Presentation Components
import Review from '../../components/Review';

// Main Component
const ReviewContainer = props => {
  /**
   *
   */
  const { token, fetching, doPostReview: postReview, doGetStudentReview: getStudentReview, match, data, history } = props;
  const { enrollmentId, courseId } = match.params;
  const [submitting, setSubmitting] = useState(false);

  const handleSubmit = ({ rating, review }) => {
    postReview({ token, enrollmentId, rating, review });
    setSubmitting(true);
  };

  // Get initial data
  useEffect(() => {
    if (token) {
      getStudentReview({ token, courseId, enrollmentId });
    }
  }, [getStudentReview, token, enrollmentId, courseId]);

  // Have an effect when successfully posted
  useEffect(() => {
    if (data?.id && submitting) {
      setSubmitting(false);
      history.push(`/dashboard/enrollments/${enrollmentId}/courses/${courseId}/outline`);
    }
  }, [data, history, enrollmentId, courseId, submitting]);

  return <Review {...props} loading={fetching} handleSubmit={handleSubmit} review={data} enrollmentId={enrollmentId} courseId={courseId} history={history} />;
};

// Mapping State / Dispatch To Props
const mapStateToProps = state => {
  return {
    token: state.loginReducer.token,
    fetching: state.reviewReducer.fetching,
    data: state.reviewReducer.data,
    errors: state.reviewReducer.errors,
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      doPostReview,
      doGetStudentReview,
    },
    dispatch,
  );

// Property Types
ReviewContainer.propTypes = {
  token: PropTypes.string,
  doPostReview: PropTypes.func,
  doGetStudentReview: PropTypes.func,
  match: PropTypes.object,
  fetching: PropTypes.bool,
  data: PropTypes.object,
  courseId: PropTypes.string,
  history: PropTypes.object,
};

// Export
export default connect(mapStateToProps, mapDispatchToProps)(Form.create()(ReviewContainer));
