// Imports
import storage from 'redux-persist/lib/storage';
import { persistReducer } from 'redux-persist';

// action types

// reducer with initial state
const initialState = {
  fetching: false,
  errors: null,
  data: null,
};

const persistConfig = {
  key: 'auth',
  storage,
  whitelist: ['token', 'refresh', 'user', 'redirect'],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const guestReducer = persistReducer(persistConfig, reducer);

export default guestReducer;
