// Imports
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';

// Presentation Components
import NotFound from '../../components/NotFound';

// Main Component
/**
 * Main Container
 * @param {*} props any
 * @returns {JSX.Element} Main JSX Routes
 */
const NotFoundContainer = props => {
  // State / Props

  // Functions

  // Hooks

  // Render
  return <NotFound />;
};

// Property Types
NotFoundContainer.propTypes = {};

// Mapping State / Dispatch To Props
/**
 * Main redux state
 * @param {*} state object
 * @returns {Object} state objects
 */
const mapStateToProps = state => ({});

/**
 * Main redux dispatch functions
 * @param {*} dispatch redux dispatch
 * @returns {bindActionCreators} state objects
 */
const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch);

// Export
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(NotFoundContainer));
