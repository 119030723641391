// IMPORTS
// --------------------------------------------------------
import styled from 'styled-components';

// MAIN STYLES
// --------------------------------------------------------
export const LessonStyles = styled.div`
  height: auto;
  overflow-y: auto;
`;
