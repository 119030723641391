// IMPORTS
// --------------------------------------------------------
// Types
import { QUESTION_SCRIPTATTEMPTS_RESET, QUESTION_SCRIPTATTEMPTS_REQUEST, QUESTION_SCRIPTATTEMPTS_FAILURE, QUESTION_SCRIPTATTEMPTS_SUCCESS } from './types';

// MAIN ACTIONS
// --------------------------------------------------------
/**
 * Resets main reducer to initialState
 * @returns {Dispatch} Redux Dispatch
 */
export const doQuestionScriptAttemptsReset = () => ({
  type: QUESTION_SCRIPTATTEMPTS_RESET,
});

/**
 * Main request
 * @returns {Dispatch} Redux Dispatch
 */
export const doQuestionScriptAttemptsRequest = ({ token, id, questionId }) => ({
  type: QUESTION_SCRIPTATTEMPTS_REQUEST,
  token,
  id,
  questionId,
});

/**
 * Handling request failure
 * @returns {Dispatch} Redux Dispatch
 */
export const doQuestionScriptAttemptsFailure = ({ errors }) => ({
  type: QUESTION_SCRIPTATTEMPTS_FAILURE,
  errors,
});

/**
 * Handling request success
 * @returns {Dispatch} Redux Dispatch
 */
export const doQuestionScriptAttemptsSuccess = ({ data }) => ({
  type: QUESTION_SCRIPTATTEMPTS_SUCCESS,
  data,
});
