// IMPORTS
// --------------------------------------------------------
// Types
import { UPDATE_SCRIPTATTEMPTS_RESET, UPDATE_SCRIPTATTEMPTS_REQUEST, UPDATE_SCRIPTATTEMPTS_SUCCESS, UPDATE_SCRIPTATTEMPTS_FAILURE } from './types';

// INITIAL STATE
// --------------------------------------------------------
/**
 * @constant
 */
const initialState = {
  loading: false,
  data: null,
  errors: null,
};

// MAIN REDUCER
// --------------------------------------------------------
/**
 * UpdateScriptAttempt Reducer
 * @param {object} state initialState
 * @param {object} action any
 * @returns {object} Redux state
 */
const UpdateScriptAttemptsReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_SCRIPTATTEMPTS_RESET:
      return initialState;
    case UPDATE_SCRIPTATTEMPTS_REQUEST:
      return {
        ...state,
        loading: true,
        errors: null,
      };
    case UPDATE_SCRIPTATTEMPTS_FAILURE:
      return {
        ...state,
        loading: false,
        errors: action.data.error,
      };
    case UPDATE_SCRIPTATTEMPTS_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.data,
      };
    default:
      return state;
  }
};

// EXPORTS
// --------------------------------------------------------
export default UpdateScriptAttemptsReducer;
