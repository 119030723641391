// IMPORTS
// --------------------------------------------------------
// Types
import { CREATE_SCRIPTATTEMPTS_RESET, CREATE_SCRIPTATTEMPTS_REQUEST, CREATE_SCRIPTATTEMPTS_FAILURE, CREATE_SCRIPTATTEMPTS_SUCCESS } from './types';

// MAIN ACTIONS
// --------------------------------------------------------
/**
 * Resets main reducer to initialState
 * @returns {Dispatch} Redux Dispatch
 */
export const doCreateScriptAttemptsReset = () => ({
  type: CREATE_SCRIPTATTEMPTS_RESET,
});

/**
 * Main request
 * @param {Object} param0 { token, enrollmentId, lmsScriptId, sectionId }
 * @returns {Dispatch} Redux Dispatch
 */
export const doCreateScriptAttemptsRequest = ({ token, enrollmentId, lmsScriptId, sectionId }) => ({
  type: CREATE_SCRIPTATTEMPTS_REQUEST,
  token,
  enrollmentId,
  lmsScriptId,
  sectionId,
});

/**
 * Handling request failure
 * @returns {Dispatch} Redux Dispatch
 */
export const doCreateScriptAttemptsFailure = ({ errors }) => ({
  type: CREATE_SCRIPTATTEMPTS_FAILURE,
  errors,
});

/**
 * Handling request success
 * @returns {Dispatch} Redux Dispatch
 */
export const doCreateScriptAttemptsSuccess = ({ data }) => ({
  type: CREATE_SCRIPTATTEMPTS_SUCCESS,
  data,
});
