// Imports
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

// Presentations
import LmsQuiz from '../../components/LmsQuiz';

// Operations
import { doGetLmsQuiz, doResetLmsCourse } from './operations';
import { doCreateQuizAttempt } from '../QuizAttempt/operations';

// Main Component
/**
 * Main Container
 * @param {*} props any
 * @returns {JSX.Element} Main JSX Routes
 */
const LmsQuizContainer = props => {
  // State / Props
  const { doGetLmsQuiz: getLmsQuiz, doCreateQuizAttempt: createQuizAttempt, doResetLmsCourse: resetLmsCourse, errors, history, token, data, loading, submitting, match, lmsCoursemember, user } = props;
  const { enrollmentId, lmsQuizId } = match.params;

  // Functions
  /**
   * Start a new quiz attempt
   * @returns {void}
   */
  const handleBegin = () => {
    createQuizAttempt({ token, lmsQuizId, enrollmentId });
  };

  /**
   * Reset lms course
   * @returns {void}
   */
  const handleReset = () => {
    let lmsCourseMemberId = data.lms_course_member_id;
    let courseId = data.lms_course.id;
    resetLmsCourse({ token, enrollmentId, lmsCourseMemberId });
    history.push(`/dashboard/enrollments/${enrollmentId}/courses/${courseId}/outline`);
  };

  // Hooks
  /**
   * Onload get the lms quiz
   */
  useEffect(() => {
    getLmsQuiz({ token, enrollmentId, lmsQuizId });
  }, [getLmsQuiz, enrollmentId, lmsQuizId, token]);

  // Render
  return (
    <LmsQuiz
      lmsCoursemember={lmsCoursemember}
      data={data}
      user={user}
      errors={errors}
      loading={loading}
      submitting={submitting}
      params={match.params}
      history={history}
      handleBegin={handleBegin}
      handleReset={handleReset}
    />
  );
};

// Map State / Dispatch To Props
/**
 * Main redux state
 * @param {*} state object
 * @returns {Object} state objects
 */
const mapStateToProps = state => ({
  token: state.loginReducer.token,
  loading: state.lmsQuizReducer.loading,
  data: state.lmsQuizReducer.data,
  errors: state.lmsQuizReducer.errors,
  submitting: state.quizAttemptReducer.submitting,
  dataQuizAttempt: state.quizAttemptReducer.data,
  user: state.loginReducer.user,
});

/**
 * Main redux dispatch functions
 * @param {*} dispatch redux dispatch
 * @returns {bindActionCreators} state objects
 */
const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      doGetLmsQuiz,
      doCreateQuizAttempt,
      doResetLmsCourse,
    },
    dispatch,
  );

// Prop Types
LmsQuizContainer.propTypes = {
  match: PropTypes.object,
  token: PropTypes.string,
  data: PropTypes.any,
  dataQuizAttempt: PropTypes.object,
  errors: PropTypes.any,
  loading: PropTypes.bool,
  submitting: PropTypes.bool,
  history: PropTypes.object,
  doGetLmsQuiz: PropTypes.func,
  doCreateQuizAttempt: PropTypes.func,
  doResetLmsCourse: PropTypes.func,
  lmsCoursemember: PropTypes.object,
  user: PropTypes.object,
};

// Exports
export default connect(mapStateToProps, mapDispatchToProps)(LmsQuizContainer);
