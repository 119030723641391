// Imports
import axios from 'axios';

// Config
const apiUri = window.API_URL;

// Export actions
export const postReview = ({ token, enrollmentId, rating, review }) => {
  return axios({
    method: 'post',
    url: `${apiUri}/reviews/`,
    data: { enrollment_id: +enrollmentId, rating, review },
    headers: { Authorization: `Bearer ${token}` },
  });
};

export const getStudentReview = ({ token, courseId, enrollmentId }) => {
  return axios({
    method: 'get',
    url: `${apiUri}/lmscourse/${courseId}/review?enrollment_id=${enrollmentId}`,
    headers: { Authorization: `Bearer ${token}` },
  });
};
