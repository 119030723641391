// IMPORTS
// --------------------------------------------------------
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

// Actions
import { doReadQuestionsRequest, doReadQuestionsReset } from '../../redux/modules/Questions/read/actions';

// Presentation Components
import LmsScriptQuestion from '../../components/LmsScriptQuestion';

// Container Components

// MAIN CONTAINER COMPONENT
// --------------------------------------------------------
/**
 * LmsScriptQuestionContainer
 * @param {Object} props component props
 * @returns {JSX} React compoennt
 */
const LmsScriptQuestionContainer = props => {
  // State / Props
  const {
    token,
    index,
    loading,
    questionId,
    attempts,
    allowRetry,
    complete,
    readQuestions,
    doReadQuestionsRequest: readQuestionsRequest,
    doReadQuestionsReset: readQuestionsReset,

    handleSubmitOption,
    handleQuestionDone,
    questionScriptAttempt,
  } = props;
  const [answers, setAnswers] = useState([]);
  const [options, setOptions] = useState([]);
  const [done, setDone] = useState(false);

  // Functions
  /**
   * Sends data to callback
   * @param {Object} data option object
   * @returns {void}
   */
  const handleClickOption = data => {
    if (handleSubmitOption) {
      handleSubmitOption(data);
    }
  };

  /**
   * Sends data to callback if question is done
   * @returns {void}
   */
  const handleDone = () => {
    if (handleQuestionDone) {
      handleQuestionDone();
    }
  };

  const randomizeArray = oldArray => {
    if (!oldArray || oldArray.length === 0) return [];
    const array = [...oldArray];
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  };

  const getOptionStatus = correct => {
    if (correct === 1) return 'correct';
    if (correct === 0) return 'incorrect';
    return false;
  };

  useEffect(() => {
    let data = readQuestions.data;
    if (data && data?.questionoptions) {
      if (!options || options.length === 0 || (options.length > 0 && data?.questionoptions?.length > 0 && options[0]?.question_id !== data?.questionoptions[0]?.question_id)) {
        // Randomize the redux data
        const newData = {
          ...data,
        };
        let randomizedOptions;
        if (questionScriptAttempt?.data?.length && questionScriptAttempt.data[0]?.question_id === data.id) {
          randomizedOptions =
            (newData?.questionoptions?.length > 0 &&
              randomizeArray(
                newData?.questionoptions.map(i => ({
                  ...{
                    status: getOptionStatus(questionScriptAttempt?.data.find(item => item.question_option_id === i.id)?.correct ? 1 : ''),
                    disabled: false,
                  },
                  ...i,
                })) ?? [],
              )) ||
            [];
        } else {
          randomizedOptions =
            (newData?.questionoptions?.length > 0 &&
              randomizeArray(
                newData?.questionoptions.map(i => ({
                  ...{
                    status: getOptionStatus(i?.correct),
                    disabled: false,
                  },
                  ...i,
                })) ?? [],
              )) ||
            [];
        }
        // Set you local state
        setOptions(randomizedOptions);
        setAnswers(randomizedOptions);
      } else if (!answers || answers.length === 0) {
        setAnswers(options, mapAnswers);
      }
    }
  }, [readQuestions, questionScriptAttempt]);

  // When new attempts are loaded, update the option statuses
  useEffect(() => {
    if (attempts?.length === 0) {
      setDone(false);
    }
    mapAnswers();
  }, [attempts, options, done]);

  const mapAnswers = () => {
    if (complete || (answers && answers?.length > 0 && attempts && attempts?.length > 0)) {
      // See if correct answer was found OR attempt limit has been met (4 length = 3 attemps)
      const maxAttempts = allowRetry ? 10 : 1;
      // const maxAttempts = allowRetry ? Math.min(answers.length - 1, 3) : 1;
      const disableAll = attempts?.length === 0 ? false : complete || (attempts.find(i => i.question_id === readQuestions?.data?.id && i.correct === 1) && true) || attempts.length >= maxAttempts;
      // Callback if question is done
      if (disableAll) {
        handleDone();
        setDone(true);
      }
      setAnswers(
        answers.map(i => {
          // If the attempt exists
          let answer = attempts.find(item => item.question_option_id === i.id);
          if (answer) {
            return { ...i, status: getOptionStatus(answer.correct), disabled: true };
          }
          return { ...i, disabled: disableAll };
        }),
      );
    } else if (attempts?.length === 0) {
      setAnswers(
        answers.map(i => {
          return { ...i, disabled: false };
        }),
      );
    }
  };

  // Hooks
  /**
   * On load retrieve question
   */
  useEffect(() => {
    // Added index to account for if the content needs to be reloaded
    readQuestionsReset();
    if (questionId) {
      readQuestionsRequest({ token, id: questionId });
    }
  }, [questionId]);

  // Render
  return (
    <LmsScriptQuestion
      attempts={attempts}
      complete={complete}
      data={readQuestions}
      answers={answers}
      options={options}
      allowRetry={allowRetry}
      disableQuestion={readQuestions.loading}
      loading={readQuestions.loading}
      handleClick={handleClickOption}
      handleDone={handleDone}
    />
  );
};

// REDUX
// --------------------------------------------------------
/**
 * Main redux state
 * @param {*} state object
 * @returns {Object} state objects
 */
const mapStateToProps = state => ({
  token: state.loginReducer.token,
  readQuestions: state.ReadQuestionsReducer,
  questionScriptAttempt: state.QuestionScriptAttemptsReducer,
});

/**
 * Main redux dispatch functions
 * @param {*} dispatch redux dispatch
 * @returns {bindActionCreators} state objects
 */
const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      doReadQuestionsRequest,
      doReadQuestionsReset,
    },
    dispatch,
  );

// PROP TYPES
// --------------------------------------------------------
LmsScriptQuestionContainer.propTypes = {
  token: PropTypes.string,
  index: PropTypes.number,
  questionId: PropTypes.number,
  attempts: PropTypes.array,
  allowRetry: PropTypes.bool,
  loading: PropTypes.bool,
  complete: PropTypes.bool,
  readQuestions: PropTypes.object,
  doReadQuestionsRequest: PropTypes.func,
  doReadQuestionsReset: PropTypes.func,
  handleSubmitOption: PropTypes.func,
  handleQuestionDone: PropTypes.func,
  questionScriptAttempt: PropTypes.any,
};

// EXPORTS
// --------------------------------------------------------
export default connect(mapStateToProps, mapDispatchToProps)(LmsScriptQuestionContainer);
