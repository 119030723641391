// IMPORTS",
// --------------------------------------------------------
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

// Actions
// import {} from '../../redux/modules';

// Presentation Components
import Lesson from '../../components/Lesson';

// Container Components

// MAIN CONTAINER COMPONENT
// --------------------------------------------------------
/**
 * LessonContainer
 * @param {Object} props component props
 * @returns {JSX} React compoennt
 */
const LessonContainer = props => {
  // State / Props
  const { data, index } = props;
  const [filteredData, setFilteredData] = useState([]);
  const [title, setTitle] = useState('');
  const [subTitle, setSubTitle] = useState('');
  const [sectionTitle, setSectionTitle] = useState('');
  const [sectionSubTitle, setSectionSubTitle] = useState('');
  const [subSectionTitle, setSubSectionTitle] = useState('');
  const [subSectionSubTitle, setSubSectionSubTitle] = useState('');
  const [loading, setLoading] = useState(true);

  // Functions
  const addTargetBlank = data => {
    return data?.replace(/<a/g, '<a target="_blank" rel="noreferrer" ');
  };

  const getTitles = slides => {
    let titles = [];
    let currentSlide = slides[0];
    titles[currentSlide?.level] = currentSlide?.title;
    for (let slide of slides) {
      if (slide.level === 1) {
        titles[1] = slide.title;
        break;
      }
      if (slide.level < currentSlide.level && !titles[slide.level]) {
        titles[slide.level] = slide.title;
      }
    }
    return titles;
  };

  // Hooks
  useEffect(() => {
    setLoading(true);
    // Only show content where present
    const filtered = data?.filter(i => i.content);

    // Get all slide data up to the current index and reverse it to find the last leve 1 and level 3 entries
    const filteredUpToIndex = [...data.slice(0, data.indexOf(filtered[index]) + 1)].reverse();

    const titles = getTitles(filteredUpToIndex);

    setTitle(titles[1] || '');
    setSubTitle(titles[2] || null);
    setSectionTitle(titles[3] || null);
    setSectionSubTitle(titles[4] || null);
    setSubSectionTitle(titles[5] || null);
    setSubSectionSubTitle(titles[6] || null);

    setFilteredData(filtered);

    setTimeout(() => {
      setLoading(false);
    }, 250);
  }, [data, index]);

  // Render
  return (
    <Lesson
      index={index}
      loading={loading}
      title={title}
      subTitle={subTitle}
      sectionTitle={sectionTitle}
      sectionSubTitle={sectionSubTitle}
      subSectionTitle={subSectionTitle}
      subSectionSubTitle={subSectionSubTitle}
      data={addTargetBlank(filteredData[index]?.content)}
    />
  );
};

// REDUX
// --------------------------------------------------------
/**
 * Main redux state
 * @param {*} state object
 * @returns {Object} state objects
 */
const mapStateToProps = state => ({});

/**
 * Main redux dispatch functions
 * @param {*} dispatch redux dispatch
 * @returns {bindActionCreators} state objects
 */
const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch);

// PROP TYPES
// --------------------------------------------------------
LessonContainer.propTypes = {
  data: PropTypes.any,
  index: PropTypes.number,
};

// EXPORTS
// --------------------------------------------------------
export default connect(mapStateToProps, mapDispatchToProps)(LessonContainer);
