// Imports
import { Button } from 'antd';
import styled, { createGlobalStyle } from 'styled-components';
import Colors from '../../styles/Colors';
import Screens from '../../styles/Screens';

// Main Component
const ReferralStyles = styled.div`
  padding: 20px;
  z-index: 20;
  @media (max-width: ${Screens.small}) {
    span {
      display: none;
    }
  }
`;

ReferralStyles.ReferButton = styled(Button)`
  background-color: ${Colors.green};
`;

ReferralStyles.GlobalStyles = createGlobalStyle`
  .aux-popover {
    width: 320px;
    @media (max-width: ${Screens.small}) {
      width: 100%;
    }
    .ant-form-item {
      margin-bottom: 14px;
    }
  }
`;

// Export
export default ReferralStyles;
