// IMPORTS
import React from 'react';
import PropType from 'prop-types';
import { Layout, Menu } from 'antd';
import { UserOutlined, MenuUnfoldOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import Constants from '../../utils/Constants';

// STYLES
import { AppStyles, MenuItemStyled } from './styles';

const { Header, Content } = Layout;
const { SubMenu } = Menu;

// MAIN COMPONENT
/**
 * Main app component
 * @param {*} props default component props
 * @returns {JSX} react component
 */
const App = props => {
  // State / Props
  const { children, user } = props;

  // Hooks

  // Render
  return (
    <AppStyles>
      <Layout>
        {window.location.pathname.indexOf('/dashboard') > -1 && (
          <Header>
            <div className="logo">
              <Link to="/dashboard">
                <img src={'/images/logos/' + Constants.WEBSITE_NAME + '/logo.png'}></img>
              </Link>
            </div>
            <div className="logo-mob">
              <Link to="/dashboard">
                <img src={'/images/logos/' + Constants.WEBSITE_NAME + '/logo-mob.png'}></img>
              </Link>
            </div>
            {user && (
              <Menu
                theme="dark"
                mode="horizontal"
                overflowedIndicator={
                  <>
                    <MenuUnfoldOutlined /> {'Menu'}
                  </>
                }
              >
                <SubMenu
                  className="aypo-submenu"
                  key="sub1"
                  title={
                    <span className="submenu-title-wrapper">
                      {user?.impersonator || user?.impersonating ? (
                        <span style={{ marginRight: 10 }}>
                          ({/*<a href="#leaveImpersonation" onClick={props.handleLeaveImpersonation}>*/}
                          <a onClick={null}>
                            {props.user?.impersonator?.firstname ? props.user?.impersonator?.firstname : props.user?.impersonating?.firstname ? props.user?.impersonating?.firstname : ''}{' '}
                            {props.user?.impersonator?.lastname ? props.user?.impersonator?.lastname : props.user?.impersonating?.lastname ? props.user?.impersonating?.lastname : ''}
                          </a>
                          )
                        </span>
                      ) : (
                        ''
                      )}
                      <UserOutlined />
                      {(user?.firstname && user?.lastname && `${user?.firstname} ${user?.lastname}`) || user?.email || 'Account'}
                    </span>
                  }
                >
                  <MenuItemStyled key="settings:1">
                    <Link to="/dashboard/account">Account</Link>
                  </MenuItemStyled>
                  <MenuItemStyled key="settings:2">
                    <Link to="/logout">Log Out</Link>
                  </MenuItemStyled>
                </SubMenu>
              </Menu>
            )}
          </Header>
        )}
        <Content>{children}</Content>
      </Layout>
    </AppStyles>
  );
};

// PROP TYPES
App.propTypes = {
  children: PropType.any,
  user: PropType.object,
};

// EXPORTS
export default App;
