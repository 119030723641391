// Imports
import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

// Operations

// Presentation Components
import AuthySetup from '../../components/AuthySetup';
import { doAuthySetup } from '../Login/operations';

// Main Component
/**
 * Main Container
 * @param {*} props any
 * @returns {JSX.Element} Main JSX Routes
 */
const AuthySetupContainer = props => {
  // Props
  const { doAuthySetup: authySetupTrigger, authySetup, authy, history, errors, fetching } = props;

  /**
   * Main function that handle form submit
   *
   *
   * @returns {dispatch} Dispatches Authy function
   * @param code
   * @param authy_user_id
   */
  // eslint-disable-next-line camelcase
  const handleSubmit = values => {
    authySetupTrigger(values);
  };

  useEffect(() => {
    if (!authySetup) {
      history.push('/login');
    }
  }, [authySetup]);

  // Render
  return <AuthySetup authy={authy} loading={fetching} handleSubmit={handleSubmit} errors={errors || {}} />;
};

// Mapping State / Dispatch To Props
/**
 * Main redux state
 * @param {*} state object
 * @returns {Object} state objects
 */
const mapStateToProps = state => ({
  authy: state.loginReducer.authy,
  authySetup: state.loginReducer.authySetup,
  errors: state.loginReducer.errors,
  fetching: state.loginReducer.fetching,
});

/**
 * Main redux dispatch functions
 * @param {*} dispatch redux dispatch
 * @returns {{doAuthySetup: (function({user_id: *, password: *, cellphone: *}): {password: *, user_id: *, cellphone: *, type: string})}} state objects
 */
const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      doAuthySetup,
    },
    dispatch,
  );

// Property Types
AuthySetupContainer.propTypes = {
  doAuthySetup: PropTypes.func,
  history: PropTypes.object,
  authy: PropTypes.object,
  errors: PropTypes.any,
  authySetup: PropTypes.bool,
  fetching: PropTypes.bool,
};

// Export
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AuthySetupContainer));
