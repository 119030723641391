// MAIN TYPES
// --------------------------------------------------------
/**
 * @constant
 */
export const READ_QUESTIONS_RESET = 'READ_QUESTIONS_RESET';

/**
 * @constant
 */
export const READ_QUESTIONS_REQUEST = 'READ_QUESTIONS_REQUEST';

/**
 * @constant
 */
export const READ_QUESTIONS_SUCCESS = 'READ_QUESTIONS_SUCCESS';

/**
 * @constant
 */
export const READ_QUESTIONS_FAILURE = 'READ_QUESTIONS_FAILURE';
