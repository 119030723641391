// Imports
import axios from 'axios';

// Config
const apiUri = window.API_URL;

// Main Actions
/**
 * doGetCourseOutline Action
 * @param {*} Object { token, courseItemId }
 * @returns {promise} axios promise
 */
export const doGetCourseOutline = ({ token, courseId, enrollmentId }) => {
  return axios({
    method: 'get',
    url: `${apiUri}/lmscourses/${courseId}/outline?enrollment_id=${enrollmentId}`,
    headers: { Authorization: `Bearer ${token}` },
  });
};

/**
 * doGetCourseReview Action
 * @param {*} Object { token, courseItemId }
 * @returns {promise} axios promise
 */
export const doGetCourseReview = ({ token, courseId, enrollmentId }) => {
  return axios({
    method: 'get',
    url: `${apiUri}/lmscourse/${courseId}/review?enrollment_id=${enrollmentId}`,
    headers: { Authorization: `Bearer ${token}` },
  });
};
