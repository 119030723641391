// Import
import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'antd';

// Styles
import NotFoundStyles from './styles';

// Style Components

// Main Component
/**
 * Main Container
 * @param {*} props any
 * @returns {JSX.Element} Main JSX Routes
 */
const NotFound = props => {
  // State / Props

  // Functions

  // Hooks

  // Render
  return (
    <NotFoundStyles>
      <h1>404 - Not Found</h1>
      <Link to="/">
        <Button>Back</Button>
      </Link>
    </NotFoundStyles>
  );
};

// Property Types
NotFound.propTypes = {};

// Export
export default NotFound;
