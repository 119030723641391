// Imports
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Tag, Spin, Button, Card, PageHeader, Divider, Icon, Alert } from 'antd';
import { SearchOutlined, RedoOutlined, DoubleRightOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import QuizLessonsReview from '../QuizLessonsReview';

// Styles
import QuizResultsStyles from './styles';
import DOMPurify from 'dompurify';

// Main Component
/**
 * Main Container
 * @param {*} props any
 * @returns {JSX.Element} Main JSX Routes
 */
const QuizResults = props => {
  // State / Props
  const { data, history, loading, match, handleNext, handleRetry, handleReview, errors } = props;
  const { enrollmentId, courseId, lmsQuizId } = match.params;
  const [passed, setPassed] = useState(false);
  const [nextActvityLink, setNextActivityLink] = useState(null);

  // Functions
  /**
   * When the next button is clicked
   * @param {Event} event button click Event
   * @returns {void}
   */
  const onClickNext = event => {
    if (handleNext) {
      handleNext(passed);
    }
    event.preventDefault();
  };

  /**
   * When the retry button is clicked
   * @param {Event} event button click Event
   * @returns {void}
   */
  const onClickRetry = event => {
    if (handleRetry) {
      handleRetry();
    }
    event.preventDefault();
  };

  /**
   * When the review button is clicked
   * @param {Event} event button click Event
   * @returns {void}
   */
  const onClickReview = event => {
    if (handleReview) {
      handleReview();
    }
    event.preventDefault();
  };

  const getLinkToSection = id => {
    if (props.data?.related_sections?.length >= 1 && id) {
      const section = props.data?.related_sections.filter(function(section) {
        return section.id === id;
      })[0];
      if (section?.lms_script) {
        return <Link to={`/dashboard/enrollments/${enrollmentId}/courses/${courseId}/lmslessons/${section.lms_script.id}`}>Review {section?.sectiontype?.title}</Link>;
      } else {
        return <p>Review {section?.sectiontype?.title}</p>;
      }
    }
  };

  // Set the passed boolean
  useEffect(() => {
    setPassed(data?.lms_quiz?.settings.req_grade >= 0 && data?.score >= data?.lms_quiz?.settings.req_grade ? true : false);

    if (data?.lms_quiz?.next_activity?.type) {
      switch (data?.lms_quiz?.next_activity.type) {
        case 'lms_script':
          setNextActivityLink(`/dashboard/enrollments/${enrollmentId}/courses/${courseId}/lmslessons/${data.lms_quiz.next_activity.id}`);
          break;
        case 'lms_quiz':
          setNextActivityLink(`/dashboard/enrollments/${enrollmentId}/courses/${courseId}/lmsquizzes/${data.lms_quiz.next_activity.id}`);
          break;
        case 'reporting':
          setNextActivityLink(`/dashboard/enrollments/${enrollmentId}/courses/${courseId}/reporting`);
          break;
      }
    } else {
      setNextActivityLink(`/dashboard/enrollments/${enrollmentId}/courses/${courseId}/outline`);
    }
  }, [data]);

  // Render
  return (
    <QuizResultsStyles>
      <PageHeader title={'Quiz Results'} subTitle={data?.lms_quiz?.name ?? ''} ghost={false} />
      {errors ? (
        errors?.map(error => {
          // eslint-disable-next-line react/jsx-key
          return <Alert message={error} type="error" />;
        })
      ) : (
        <>
          <Card>
            {loading ? (
              <Spin spinning={true} />
            ) : (
              <>
                <PageHeader
                  title={
                    data?.lms_quiz?.settings.req_grade >= 0 && (
                      <h1>
                        {data?.score >= data?.lms_quiz?.settings.req_grade ? (
                          data?.lms_quiz?.settings?.pass_feedback ? (
                            <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(data?.lms_quiz?.settings?.pass_feedback, { ADD_ATTR: ['target'] }) }} />
                          ) : (
                            'Congratulations, you passed the Quiz.'
                          )
                        ) : data?.lms_quiz?.settings?.fail_feedback ? (
                          <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(data?.lms_quiz?.settings?.fail_feedback, { ADD_ATTR: ['target'] }) }} />
                        ) : (
                          'Sorry you did not pass the Quiz.'
                        )}
                      </h1>
                    )
                  }
                  extra={
                    <Button
                      type="primary"
                      style={{ backgroundColor: '#5e8dbd', borderColor: 'rgb(97 121 146)' }}
                      onClick={() => {
                        history.push(`/dashboard/enrollments/${enrollmentId}/courses/${courseId}/outline`);
                      }}
                    >
                      <Icon type="book" /> Course Outline
                    </Button>
                  }
                />
                {}
                {data?.lms_quiz?.settings.req_grade >= 0 && <h3>Passing Score: {data?.lms_quiz?.settings.req_grade}%</h3>}
                {data?.score >= 0 && <h3>Your Score: {data?.score}%</h3>}
                <Divider />
                <QuizLessonsReview data={data?.quiz_attempt_answers} sections={data?.related_sections} enrollmentId={enrollmentId} courseId={courseId} />
                {data?.lms_quiz?.settings?.review
                  ? data?.quiz_attempt_answers?.length > 0 && (
                      <span>
                        <Divider />
                        <ul>
                          {data.quiz_attempt_answers.map((i, k) => {
                            return (
                              <li key={`question-${k}`}>
                                {i?.question?.correct_question_options?.filter(a => a.id === i?.question_option_id).length > 0 ? (
                                  <Card
                                    title={i?.question?.question ? `Q: ${i?.question?.question}` : 'Unknown question'}
                                    extra={<Tag color="green">Correct</Tag>}
                                    style={{ overflow: 'hidden', marginBottom: '10px' }}
                                    className="correct"
                                  >
                                    <p className={`questionAnswer`}>A: {i?.questionoption?.option}</p>
                                    <p className={`userQuestionAnswer`}>Correct Answer: {i?.question?.correct_question_options?.[0]?.option}</p>
                                  </Card>
                                ) : i?.questionoption === null ? (
                                  <Card
                                    title={i?.question?.question ? `Q: ${i?.question?.question}` : 'Unknown question'}
                                    style={{ overflow: 'hidden', marginBottom: '10px' }}
                                    extra={<Tag color="orange">Skipped</Tag>}
                                    className="skipped"
                                  >
                                    <p className={`questionAnswer`}>A: N/A</p>
                                    <p className={`userQuestionAnswer`}>Correct Answer: {i?.question?.correct_question_options?.[0]?.option}</p>
                                    <p className={`questionHint`}>{getLinkToSection(i?.question?.section_id)}</p>
                                  </Card>
                                ) : (
                                  <Card
                                    title={i?.question?.question ? `Q: ${i?.question?.question}` : 'Unknown question'}
                                    style={{ overflow: 'hidden', marginBottom: '10px' }}
                                    extra={<Tag color="red">Incorrect!</Tag>}
                                    className="incorrect"
                                  >
                                    <p className={`questionAnswer`}>A: {i?.questionoption?.option}</p>
                                    <p className={`userQuestionAnswer`}>Correct Answer: {i?.question?.correct_question_options?.[0]?.option}</p>
                                    <p className={`questionHint`}>{getLinkToSection(i?.question?.section_id)}</p>
                                  </Card>
                                )}
                              </li>
                            );
                          })}
                        </ul>
                      </span>
                    )
                  : ''}
                <Divider />
                <footer>
                  <div></div>
                  <QuizResultsStyles.Controls>
                    {passed ? (
                      nextActvityLink ? (
                        <Link to={nextActvityLink}>
                          <Button type="primary">
                            Next <Icon type="right" />
                          </Button>
                        </Link>
                      ) : (
                        ''
                      )
                    ) : (
                      <Button onClick={onClickRetry} className="retry">
                        <RedoOutlined />
                        <span>Retry</span>
                      </Button>
                    )}
                  </QuizResultsStyles.Controls>
                </footer>
              </>
            )}
          </Card>
        </>
      )}
    </QuizResultsStyles>
  );
};

// Prop Types
QuizResults.propTypes = {
  data: PropTypes.object,
  errors: PropTypes.object,
  loading: PropTypes.bool,
  match: PropTypes.any,
  params: PropTypes.object,
  handleNext: PropTypes.func,
  handleRetry: PropTypes.func,
  handleReview: PropTypes.func,
  history: PropTypes.any,
};

// Exports
export default QuizResults;
