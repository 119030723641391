// Imports
import { takeLatest, put, call } from 'redux-saga/effects';

// Types
import {
  GET_REPORTING_QUESTIONS_REQUEST,
  GET_REPORTING_QUESTIONS_SUCCESS,
  GET_REPORTING_QUESTIONS_FAILURE,
  POST_REPORTING_ANSWER_REQUEST,
  POST_REPORTING_ANSWER_SUCCESS,
  POST_REPORTING_ANSWER_FAILURE,
  GET_REPORTING_ANSWERS_REQUEST,
  GET_REPORTING_ANSWERS_SUCCESS,
  GET_REPORTING_ANSWERS_FAILURE,
} from './types';

// Actions
import { getReportingQuestions, getReportingAnswers, postReportingAnswers } from './actions';

function* workerGetReportingQuestions({ token, courseId }) {
  try {
    const response = yield call(getReportingQuestions, { token, courseId });

    yield put({
      type: GET_REPORTING_QUESTIONS_SUCCESS,
      response,
    });
  } catch (error) {
    yield put({ type: GET_REPORTING_QUESTIONS_FAILURE, ...error.response });
  }
}

export function* watcherGetReportingQuestions() {
  yield takeLatest(GET_REPORTING_QUESTIONS_REQUEST, workerGetReportingQuestions);
}

function* workerGetReportingAnswers({ token, enrollmentId }) {
  try {
    const response = yield call(getReportingAnswers, { token, enrollmentId });

    yield put({
      type: GET_REPORTING_ANSWERS_SUCCESS,
      response,
    });
  } catch (error) {
    yield put({ type: GET_REPORTING_ANSWERS_FAILURE, ...error.response });
  }
}

export function* watcherGetReportingAnswers() {
  yield takeLatest(GET_REPORTING_ANSWERS_REQUEST, workerGetReportingAnswers);
}

function* workerPostReportingAnswer({ token, enrollmentId, answerData, licenseTypeId }) {
  try {
    const response = yield call(postReportingAnswers, { token, enrollmentId, answerData, licenseTypeId });

    yield put({
      type: POST_REPORTING_ANSWER_SUCCESS,
      response,
    });

    yield put({
      type: GET_REPORTING_ANSWERS_REQUEST,
      token,
      enrollmentId,
    });
  } catch (error) {
    yield put({ type: POST_REPORTING_ANSWER_FAILURE, ...error.response });
  }
}

export function* watcherPostReportingAnswer() {
  yield takeLatest(POST_REPORTING_ANSWER_REQUEST, workerPostReportingAnswer);
}
