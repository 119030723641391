// Imports
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { message, Form } from 'antd';

// Operations
import { doSendReferral, doClearReferral } from './operations';

// Redux

// Utils

// Presentation Components
import Referral from '../../components/Referral';

// Main Component
/**
 * Referral Container
 * @param {*} props any
 * @returns {JSX.Element} Main JSX Routes
 */
const ReferralContainer = props => {
  // State / Props
  const { form, token, doSendReferral: sendReferral, doClearReferral: clearReferral, referral, match } = props;
  const { websiteId, courseId } = match?.params || {};

  /**
   * When 'Referral' form is submitted
   * @param {*} Object { email }
   * @returns {void}
   */
  const handleSendReferral = ({ email, courseId, websiteId }) => {
    sendReferral({ token, email, courseId, websiteId });
  };

  /**
   * Clears status messages from form
   * @returns {void}
   */
  const handleClearReferral = () => {
    clearReferral();
  };

  // Hooks
  /**
   * Referral success
   */
  useEffect(() => {
    if (referral?.success) {
      message.success('Referral sent!');
    }
  }, [referral]);

  return <Referral form={form} handleSendReferral={handleSendReferral} handleClearReferral={handleClearReferral} referral={referral} courseId={courseId} websiteId={websiteId} />;
};

// Property Types
ReferralContainer.propTypes = {
  form: PropTypes.object,
  doSendReferral: PropTypes.func,
  doClearReferral: PropTypes.func,
  token: PropTypes.string,
  referral: PropTypes.object,
  match: PropTypes.object,
};

// Mapping State / Dispatch To Props
const mapStateToProps = state => ({
  token: state.loginReducer.token,
  referral: state.referralReducer.referral,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      doSendReferral,
      doClearReferral,
    },
    dispatch,
  );

// Export
export default connect(mapStateToProps, mapDispatchToProps)(Form.create({ name: 'referral' })(ReferralContainer));
