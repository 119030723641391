// Imports
import axios from 'axios';

// Config
const apiUri = window.API_URL;

// Export actions
export function getAmazonCheckoutSession({ token, amazonCheckoutSessionId }) {
  return axios({
    method: 'get',
    url: `${apiUri}/payment/amazon/session/${amazonCheckoutSessionId}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export function getCart({ token, cartId }) {
  return axios({
    method: 'get',
    url: `${apiUri}/carts/${cartId}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export function createCartEnrollment({ token, enrollmentId }) {
  return axios({
    method: 'post',
    url: `${apiUri}/carts/enrollment`,
    data: { enrollment_id: enrollmentId },
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export function createCartCourse({ token, lmsCourseId }) {
  return axios({
    method: 'post',
    url: `${apiUri}/carts/course`,
    data: { lms_course_id: lmsCourseId },
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export function updateStudentInformation({ token, studentId, firstName, lastName, zipCode, address, city, state, email, phone, password, confirmPassword }) {
  return axios({
    method: 'put',
    url: `${apiUri}/students/${studentId}`,
    data: { firstname: firstName, lastname: lastName, zip: zipCode, address: address, city: city, state: state, phone: phone, email: email, password: password, confirm_password: confirmPassword },
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export function createStripePaymentIntent({ token, cartId }) {
  return axios({
    method: 'post',
    url: `${apiUri}/payment/stripe/create`,
    data: { cart_id: cartId },
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export function processStripePaymentIntent({ token, cartId, transactionRef }) {
  return axios({
    method: 'post',
    url: `${apiUri}/payment/stripe/process`,
    data: { cart_id: cartId, transaction_ref: transactionRef },
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export function createAmazonPaymentIntent({ token, enrollmentId, cartId }) {
  return axios({
    method: 'post',
    url: `${apiUri}/payment/amazon/create`,
    data: { enrollment_id: enrollmentId, cart_id: cartId },
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export function processAmazonPaymentIntent({ token, amazonCheckoutSessionId, cartId, transactionRef }) {
  return axios({
    method: 'post',
    url: `${apiUri}/payment/amazon/process`,
    data: { amazon_checkout_session_id: amazonCheckoutSessionId, cart_id: cartId, transaction_ref: transactionRef },
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}
