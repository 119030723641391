// Imports
import storage from 'redux-persist/lib/storage';
import { persistReducer } from 'redux-persist';

// action types
import {
  CREATE_STRIPE_PAYMENT_REQUEST,
  CREATE_STRIPE_PAYMENT_FAILURE,
  CREATE_STRIPE_PAYMENT_SUCCESS,
  PROCESS_STRIPE_PAYMENT_REQUEST,
  PROCESS_STRIPE_PAYMENT_FAILURE,
  PROCESS_STRIPE_PAYMENT_SUCCESS,
  CREATE_AMAZON_PAYMENT_REQUEST,
  CREATE_AMAZON_PAYMENT_FAILURE,
  CREATE_AMAZON_PAYMENT_SUCCESS,
  CREATE_CART_ENROLLMENT_REQUEST,
  CREATE_CART_ENROLLMENT_FAILURE,
  CREATE_CART_ENROLLMENT_SUCCESS,
  UPDATE_STUDENT_INFORMATION_SUCCESS,
  UPDATE_STUDENT_INFORMATION_FAILURE,
  UPDATE_STUDENT_INFORMATION_REQUEST,
  PROCESS_AMAZON_PAYMENT_SUCCESS,
  PROCESS_AMAZON_PAYMENT_FAILURE,
  PROCESS_AMAZON_PAYMENT_REQUEST,
  RESET_STRIPE_DATA,
  RESET_CART_DATA,
  SET_STRIPE_STATUS,
  READ_CART_FAILURE,
  READ_CART_SUCCESS,
  READ_CART_REQUEST,
  CREATE_CART_COURSE_REQUEST,
  CREATE_CART_COURSE_SUCCESS,
  CREATE_CART_COURSE_FAILURE,
} from './types';

// reducer with initial state
const initialState = {
  studentData: {
    fetching: false,
    errors: null,
    data: null,
  },
  cartData: {
    fetching: false,
    errors: null,
    data: null,
  },
  stripeData: {
    fetching: false,
    errors: null,
    data: null,
    clientSecret: null,
    complete: false,
    errorCount: 0,
  },
  amazonData: {
    fetching: false,
    errors: null,
    data: null,
    complete: false,
    signature: null,
  },
};

const persistConfig = {
  key: 'auth',
  storage,
  whitelist: ['amazonData', 'cartData'],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_STUDENT_INFORMATION_REQUEST:
      return {
        ...state,
        studentData: {
          fetching: true,
          errors: null,
          data: null,
        },
      };
    case UPDATE_STUDENT_INFORMATION_FAILURE:
      return {
        ...state,
        studentData: {
          fetching: false,
          errors: action.error,
          data: null,
        },
      };
    case UPDATE_STUDENT_INFORMATION_SUCCESS:
      return {
        ...state,
        studentData: {
          fetching: false,
          errors: null,
          data: action.data,
        },
      };

    case READ_CART_FAILURE:
      return {
        ...state,
        cartData: {
          fetching: false,
          errors: action.error,
          data: null,
        },
      };
    case READ_CART_SUCCESS:
      return {
        ...state,
        cartData: {
          fetching: false,
          errors: null,
          data: action.data,
        },
      };
    case READ_CART_REQUEST:
      return {
        ...state,
        cartData: {
          fetching: true,
          errors: null,
          data: null,
        },
      };
    case CREATE_CART_ENROLLMENT_REQUEST:
      return {
        ...state,
        cartData: {
          fetching: true,
          errors: null,
          data: null,
        },
      };
    case CREATE_CART_COURSE_FAILURE:
      return {
        ...state,
        cartData: {
          fetching: false,
          errors: action.error,
          data: null,
        },
      };
    case CREATE_CART_COURSE_SUCCESS:
      return {
        ...state,
        cartData: {
          fetching: false,
          errors: null,
          data: action.data,
        },
      };
    case CREATE_CART_COURSE_REQUEST:
      return {
        ...state,
        cartData: {
          fetching: true,
          errors: null,
          data: null,
        },
      };
    case CREATE_CART_ENROLLMENT_FAILURE:
      return {
        ...state,
        cartData: {
          fetching: false,
          errors: action.error,
          data: null,
        },
      };
    case CREATE_CART_ENROLLMENT_SUCCESS:
      return {
        ...state,
        cartData: {
          fetching: false,
          errors: null,
          data: action.data,
        },
      };
    case CREATE_STRIPE_PAYMENT_REQUEST:
      return {
        ...state,
        stripeData: {
          ...state.stripeData,
          fetching: true,
          errors: null,
          data: null,
        },
      };
    case PROCESS_STRIPE_PAYMENT_FAILURE:
      return {
        ...state,
        stripeData: {
          fetching: false,
          errors: action.error,
          data: null,
          errorCount: state.stripeData.errorCount + 1,
        },
      };
    case PROCESS_STRIPE_PAYMENT_SUCCESS:
      return {
        ...state,
        stripeData: {
          fetching: false,
          errors: null,
          data: action.data,
          complete: true,
          errorCount: 0,
        },
      };
    case PROCESS_STRIPE_PAYMENT_REQUEST:
      return {
        ...state,
        stripeData: {
          ...state.stripeData,
          fetching: true,
          errors: null,
          data: null,
        },
      };
    case CREATE_STRIPE_PAYMENT_FAILURE:
      return {
        ...state,
        stripeData: {
          fetching: false,
          errors: action.error,
          data: null,
          errorCount: state.stripeData.errorCount + 1,
        },
      };
    case CREATE_STRIPE_PAYMENT_SUCCESS:
      return {
        ...state,
        stripeData: {
          ...state.stripeData,
          fetching: false,
          errors: null,
          data: action.data,
          clientSecret: action.data.clientSecret,
        },
      };
    case SET_STRIPE_STATUS:
      return {
        ...state,
        stripeData: {
          ...state.stripeData,
          fetching: action.fetching,
          errors: action.error,
          errorCount: action.error ? state.stripeData.errorCount + 1 : state.stripeData.errorCount,
        },
      };
    case RESET_STRIPE_DATA:
      return {
        ...state,
        stripeData: {
          ...state.stripeData,
          fetching: false,
          errors: null,
          data: null,
        },
      };
    case RESET_CART_DATA:
      return {
        ...state,
        cartData: {
          fetching: false,
          errors: null,
          data: null,
        },
      };
    case CREATE_AMAZON_PAYMENT_REQUEST:
      return {
        ...state,
        amazonData: {
          fetching: true,
          errors: null,
          data: null,
          signature: null,
        },
      };
    case CREATE_AMAZON_PAYMENT_FAILURE:
      return {
        ...state,
        amazonData: {
          fetching: false,
          errors: action.error,
          data: null,
          signature: null,
        },
      };
    case CREATE_AMAZON_PAYMENT_SUCCESS:
      return {
        ...state,
        amazonData: {
          fetching: false,
          errors: null,
          data: action.data,
          signature: action.data.signature,
        },
      };
    case PROCESS_AMAZON_PAYMENT_REQUEST:
      return {
        ...state,
        amazonData: {
          fetching: true,
          errors: null,
          data: null,
        },
      };
    case PROCESS_AMAZON_PAYMENT_FAILURE:
      return {
        ...state,
        amazonData: {
          fetching: false,
          errors: action.error,
          data: null,
        },
      };
    case PROCESS_AMAZON_PAYMENT_SUCCESS:
      return {
        ...state,
        amazonData: {
          fetching: false,
          errors: null,
          data: action.data,
          complete: true,
        },
      };
    default:
      return state;
  }
};

const paymentReducer = persistReducer(persistConfig, reducer);

export default paymentReducer;
