export const GET_STUDENT_CONTACT_REQUEST = 'GET_STUDENT_CONTACT_REQUEST';
export const GET_STUDENT_CONTACT_SUCCESS = 'GET_STUDENT_CONTACT_SUCCESS';
export const GET_STUDENT_CONTACT_FAILURE = 'GET_STUDENT_CONTACT_FAILURE';

export const UPDATE_STUDENT_CONTACT_REQUEST = 'UPDATE_STUDENT_CONTACT_REQUEST';
export const UPDATE_STUDENT_CONTACT_SUCCESS = 'UPDATE_STUDENT_CONTACT_SUCCESS';
export const UPDATE_STUDENT_CONTACT_FAILURE = 'UPDATE_STUDENT_CONTACT_FAILURE';

export const CREATE_STUDENT_REQUEST = 'CREATE_STUDENT_REQUEST';
export const CREATE_STUDENT_SUCCESS = 'CREATE_STUDENT_SUCCESS';
export const CREATE_STUDENT_FAILURE = 'CREATE_STUDENT_FAILURE';
