// Imports
import { takeLatest, put, call } from 'redux-saga/effects';

// Types
import { POST_FEEDBACK_REQUEST, POST_FEEDBACK_SUCCESS, POST_FEEDBACK_FAILURE } from './types';

// Actions
import { doSendFeedback } from './actions';

// Main Sagas
/**
 *
 * @param {*} param0 Authentication token
 * @returns {void}
 */
function* workerSendFeedback({ token, feedbackType, feedback, enrollmentId, quizAttemptId, scriptAttemptId, quizAttemptAnswerId, sectionId, position, image, alertUser = true }) {
  try {
    const response = yield call(doSendFeedback, { token, feedbackType, feedback, enrollmentId, quizAttemptId, scriptAttemptId, quizAttemptAnswerId, sectionId, position, image });

    // Catch For No Response
    if (!response?.data) {
      throw new Error('workerSendFeedback - Response Error');
    }

    yield put({
      type: POST_FEEDBACK_SUCCESS,
      response,
      alertUser,
    });
  } catch (error) {
    const message = error?.message ?? 'Something went wrong';
    yield put({ type: POST_FEEDBACK_FAILURE, errors: message, alertUser });
  }
}

/**
 * Watches for request
 * @return {void}
 */
export function* watcherSendFeedback() {
  yield takeLatest(POST_FEEDBACK_REQUEST, workerSendFeedback);
}
