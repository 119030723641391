// Imports
import axios from 'axios';

// Config
const apiUri = window.API_URL;

// Export actions
export const getScriptAttempts = ({ lmsScriptId, enrollmentId, token }) => {
  return axios({
    method: 'get',
    url: `${apiUri}/lmsscripts/${lmsScriptId}/scriptattempts?enrollment_id=${enrollmentId}&with=lmsScript.lmsCourse.lmsCourseMembers,section.sectionslides,section.sectiontype,section.videoAsset,section.videoCaptionAsset,section.videoStatus,section.sectionslides.question.questionoptions,lmsScript.script.sections,lmsScript.script.sections.sectiontype,scriptAttemptAnswers,scriptAttemptAnswers.question.questionOptions`,
    headers: { Authorization: `Bearer ${token}` },
  });
};

export const getLessonTracker = ({ lmsScriptId, enrollmentId, token }) => {
  return axios({
    method: 'get',
    url: `${apiUri}/lmsscripts/${lmsScriptId}/lessontracker?enrollment_id=${enrollmentId}`,
    headers: { Authorization: `Bearer ${token}` },
  });
};

export const createScriptAttempts = ({ lmsScriptId, enrollmentId, sectionId, token }) => {
  return axios({
    method: 'post',
    url: `${apiUri}/scriptattempts/`,
    data: { lms_script_id: lmsScriptId, enrollment_id: enrollmentId, section_id: sectionId },
    headers: { Authorization: `Bearer ${token}` },
  });
};

export const updateScriptAttempts = ({ scriptAttemptsId, complete, position, sectionId, token }) => {
  return axios({
    method: 'put',
    url: `${apiUri}/scriptattempts/${scriptAttemptsId}`,
    data: { complete, position, section_id: sectionId },
    headers: { Authorization: `Bearer ${token}` },
  });
};

export const createScriptAttemptWatchedVideo = ({ scriptAttemptId, sectionId, token }) => {
  return axios({
    method: 'post',
    url: `${apiUri}/scriptattempts/${scriptAttemptId}/watchedvideos`,
    data: {
      section_id: sectionId,
    },
    headers: { Authorization: `Bearer ${token}` },
  });
};
