// Types
import { GET_LMSCOURSE_REQUEST, CLEAR_LMSCOURSE, GET_GUEST_LMSCOURSE_REQUEST, GET_GUEST_LMSCOURSES_REQUEST } from './types';

// Main Operations

/**
 * Get Lms Course Id
 * @param {*} Object { token, lmsCourseId }
 * @returns {Dispatch} for redux
 */
export const doGetLmsCourse = ({ token, lmsCourseId }) => ({
  type: GET_LMSCOURSE_REQUEST,
  token,
  lmsCourseId,
});

/**
 * Get Lms Course As Guest
 * @param {*} Object { lmsCourseId }
 * @returns {Dispatch} for redux
 */
export const doGetGuestLmsCourse = ({ lmsCourseId }) => ({
  type: GET_GUEST_LMSCOURSE_REQUEST,
  lmsCourseId,
});

/**
 * Get Lms Courses As Guest
 * @param {*} Object { }
 * @returns {Dispatch} for redux
 */
export const doGetGuestLmsCourses = () => ({
  type: GET_GUEST_LMSCOURSES_REQUEST,
});

/**
 * Clears Lms Course
 * @returns {Dispatch} for redux
 */
export const doClearLmsCourse = () => ({
  type: CLEAR_LMSCOURSE,
});
