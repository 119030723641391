// Types
import { CLEAR_QUIZ_ATTEMPT, CREATE_QUIZ_ATTEMPT_REQUEST, GET_QUIZ_ATTEMPT_REQUEST, UPDATE_QUIZ_ATTEMPT_REQUEST, SET_CURRENT_QUIZ_ATTEMPT_ANSWER } from './types';

// Main Operations
/**
 * Create New Quiz Attempt
 * @param {*} Object { token, lmsQuizId, enrollmentId }
 * @returns {Dispatch} for redux
 */
export const doCreateQuizAttempt = ({ token, enrollmentId, lmsQuizId }) => ({
  type: CREATE_QUIZ_ATTEMPT_REQUEST,
  token,
  lmsQuizId,
  enrollmentId,
});

/**
 * Get Existing Quiz Attempt
 * @param {*} Object { token, quizAttemptId }
 * @returns {Dispatch} for redux
 */
export const doGetQuizAttempt = ({ token, quizAttemptId, enrollmentId }) => ({
  type: GET_QUIZ_ATTEMPT_REQUEST,
  token,
  quizAttemptId,
  enrollmentId,
});

/**
 * Update Existing Quiz Attempt
 * @param {*} Object { token, quizAttemptId, complete }
 * @returns {Dispatch} for redux
 */
export const doUpdateQuizAttempt = ({ token, complete, quizAttemptId }) => ({
  type: UPDATE_QUIZ_ATTEMPT_REQUEST,
  token,
  quizAttemptId,
  complete,
});

/**
 * Clears quiz attempt
 * @returns {Dispatch} for redux
 */
export const doClearQuizAttempt = () => ({
  type: CLEAR_QUIZ_ATTEMPT,
});

export const doSetCurrentQuizAttemptAnswer = quizAttemptAnswer => ({
  type: SET_CURRENT_QUIZ_ATTEMPT_ANSWER,
  quizAttemptAnswer,
});
