// IMPORTS
// --------------------------------------------------------
// Types
import { READ_SCRIPTATTEMPTS_RESET, READ_SCRIPTATTEMPTS_REQUEST, READ_SCRIPTATTEMPTS_FAILURE, READ_SCRIPTATTEMPTS_SUCCESS, SET_CURRENT_POSITION } from './types';

// MAIN ACTIONS
// --------------------------------------------------------
/**
 * Resets main reducer to initialState
 * @returns {Dispatch} Redux Dispatch
 */
export const doReadScriptAttemptsReset = () => ({
  type: READ_SCRIPTATTEMPTS_RESET,
});

/**
 * Main request
 * @returns {Dispatch} Redux Dispatch
 */
export const doReadScriptAttemptsRequest = ({ token, id, enrollmentId, include }) => ({
  type: READ_SCRIPTATTEMPTS_REQUEST,
  token,
  id,
  enrollmentId,
  include,
});

/**
 * Handling request failure
 * @returns {Dispatch} Redux Dispatch
 */
export const doReadScriptAttemptsFailure = ({ data }) => ({
  type: READ_SCRIPTATTEMPTS_FAILURE,
  data,
});

/**
 * Handling request success
 * @returns {Dispatch} Redux Dispatch
 */
export const doReadScriptAttemptsSuccess = ({ data }) => ({
  type: READ_SCRIPTATTEMPTS_SUCCESS,
  data,
});

/**
 * Set current user position
 * @returns {Dispatch} Redux Dispatch
 */
export const doSetCurrentPosition = ({ data }) => ({
  type: SET_CURRENT_POSITION,
  data,
});
