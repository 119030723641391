/* global amazon, OffAmazonPayments */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

const AMAZON_PAY_BUTTON_DIV_ID = 'AmazonPayButton';

class AmazonPayButton extends PureComponent {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    const { merchantId, publicKeyId, payload, signature } = this.props;
    window.amazon.Pay.renderButton(`#${AMAZON_PAY_BUTTON_DIV_ID}`, {
      // set checkout environment
      merchantId: merchantId,
      publicKeyId: publicKeyId,
      ledgerCurrency: 'USD',
      // customize the btn
      checkoutLanguage: 'en_US',
      productType: 'PayOnly',
      placement: 'Cart',
      buttonColor: 'Gold',
      createCheckoutSessionConfig: {
        payloadJSON: payload,
        signature: signature,
      },
    });
  }

  render() {
    return <div style={{ width: '100%', marginBottom: 15 }} id={AMAZON_PAY_BUTTON_DIV_ID} />;
  }
}

AmazonPayButton.propTypes = {
  merchantId: PropTypes.string.isRequired,
  publicKeyId: PropTypes.string.isRequired,
  payload: PropTypes.object.isRequired,
  signature: PropTypes.string.isRequired,
};

export default AmazonPayButton;
