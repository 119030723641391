// MAIN TYPES
// --------------------------------------------------------
/**
 * @constant
 */
export const READ_SCRIPTATTEMPTS_RESET = 'READ_SCRIPTATTEMPTS_RESET';

/**
 * @constant
 */
export const READ_SCRIPTATTEMPTS_REQUEST = 'READ_SCRIPTATTEMPTS_REQUEST';

/**
 * @constant
 */
export const READ_SCRIPTATTEMPTS_SUCCESS = 'READ_SCRIPTATTEMPTS_SUCCESS';

/**
 * @constant
 */
export const READ_SCRIPTATTEMPTS_FAILURE = 'READ_SCRIPTATTEMPTS_FAILURE';

export const SET_CURRENT_POSITION = 'SET_CURRENT_POSITION';
