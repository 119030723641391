// IMPORTS
// --------------------------------------------------------
import styled from 'styled-components';
import Screens from '../../styles/Screens';
import Colors from '../../styles/Colors';

// MAIN STYLES
// --------------------------------------------------------
export const ScriptAttemptResultsStyles = styled.div`
  position: relative;
  padding: 20px;

  strong {
    display: block;
  }

  main {
    .ant-table {
      margin: 0 auto;
      .correct {
        td {
          background-color: ${Colors.green};
        }
      }
      .incorrect {
        td {
          background-color: ${Colors.redLight};
          font-weight: bold;
        }
      }
    }
  }

  footer {
    display: flex;
    justify-content: space-between;
  }

  @media (min-width: ${Screens.small}) {
    main {
      .ant-table {
        margin: 0 auto;
        max-width: 300px;
      }
    }

    h2,
    h3,
    h4,
    h5,
    h6 {
      text-align: center;
      strong {
        display: inline;
      }
    }
  }
`;
