// Import
import React from 'react';
import PropTypes from 'prop-types';
import CourseCompleteStyles from './styles';
import { Icon, PageHeader, Button } from 'antd';

// Constants
import CONSTANTS from '../../utils/Constants';

// Main Component
/**
 * Main Component
 * @param {*} props any
 * @return {JSX.Element} JSX Element
 */
const CourseComplete = props => {
  // Props
  const { data, loading, questionAttempts, forceRetry, onFinished } = props;

  // Functions
  /*
   * Determine text and icon depending on status
   */
  const getStatusValues = status => {
    const statusValues = { statusText: '', icon: '' };

    switch (status) {
      case CONSTANTS.STATUS.LOCKED:
      default:
        statusValues.statusText = 'Locked';
        statusValues.icon = 'lock';
        break;
      case CONSTANTS.STATUS.NOT_STARTED:
        statusValues.statusText = 'Skipped';
        statusValues.icon = 'close';
        break;
      case CONSTANTS.STATUS.COMPLETE:
        statusValues.statusText = 'Correct';
        statusValues.icon = 'check';
        break;
      case CONSTANTS.STATUS.FAILED:
        statusValues.statusText = 'Incorrect';
        statusValues.icon = 'close';
        break;
    }
    return statusValues;
  };

  const passed = data.score >= data?.lms_script?.settings.req_grade;

  return (
    <CourseCompleteStyles loading={!data}>
      <PageHeader title="Results" />

      <CourseCompleteStyles.ResultSummary>
        <h2>{passed ? 'Congratulations!' : 'Sorry!'}</h2>
        <h3>
          You {passed ? 'passed' : 'did not pass'} <strong>{data?.lms_script?.name}.</strong>
        </h3>

        <CourseCompleteStyles.ScoreDisplay>
          <p>
            Passing Score: <strong>{data?.lms_script?.settings.req_grade}%</strong>
          </p>
          <p>
            Your Score: <strong>{data.score}%</strong>
          </p>
        </CourseCompleteStyles.ScoreDisplay>
      </CourseCompleteStyles.ResultSummary>

      <CourseCompleteStyles.Table
        loading={loading}
        rowKey={'id'}
        columns={[
          {
            key: 'certificate',
            render: (text, record, i) => {
              return `Question ${i + 1}`;
            },
          },
          {
            key: 'certstatus',
            width: 150,
            align: 'right',
            render: (text, record) => {
              let status;
              if (!record?.questionAttempts) {
                status = CONSTANTS.STATUS.NOT_STARTED;
              } else if (record?.questionAttempts?.find(attempts => attempts.correct)) {
                status = CONSTANTS.STATUS.COMPLETE;
              } else {
                status = CONSTANTS.STATUS.FAILED;
              }

              const { icon } = getStatusValues(status);

              return <Icon type={icon} />;
            },
          },
        ]}
        dataSource={questionAttempts}
        pagination={false}
        rowClassName={(record, index) => {
          let status;
          if (!record?.questionAttempts) {
            status = CONSTANTS.STATUS.NOT_STARTED;
          } else if (record?.questionAttempts?.find(attempts => attempts.correct)) {
            status = CONSTANTS.STATUS.COMPLETE;
          } else {
            status = CONSTANTS.STATUS.FAILED;
          }
          return status;
        }}
        showHeader={false}
      />
      <CourseCompleteStyles.Actions>
        <Button type="primary" onClick={forceRetry}>
          Retry <Icon type="reload" />
        </Button>
        {passed && (
          <Button type="primary" onClick={onFinished}>
            Return to Outline <Icon type="right" />
          </Button>
        )}
      </CourseCompleteStyles.Actions>
    </CourseCompleteStyles>
  );
};

// Property Types
CourseComplete.propTypes = {
  data: PropTypes.object,
  loading: PropTypes.bool,
  forceRetry: PropTypes.func,
  onFinished: PropTypes.func,
  questionAttempts: PropTypes.any,
};

// Export
export default CourseComplete;
