// Imports
import styled from 'styled-components';
import Screens from '../../styles/Screens';

// Main Component
const QuizLessonsReviewStyles = styled.div`
  .quiz-lessons-review {
    background-color: #fffbe6;
    margin-left: 1rem;
  }
`;

// Export
export default QuizLessonsReviewStyles;

// updateScriptAttempts
