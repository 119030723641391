// IMPORTS
// --------------------------------------------------------
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { PageHeader, Spin, Card, Button, Table, Icon, Divider, Alert } from 'antd';
import { Link } from 'react-router-dom';

// Styled Components
import { ScriptAttemptResultsStyles } from './styles.js';

// Presentation Components

// MAIN PRESENTATION COMPONENT
// --------------------------------------------------------
/**
 * ScriptAttemptResults
 * @param {Object} props component props
 * @returns {JSX} React compoennt
 */
const ScriptAttemptResults = props => {
  // State / Props
  const { data, errors, loading, match, history, handleRetry, handleReview } = props;
  const { enrollmentId, courseId, lmsScriptId } = match.params;
  const [nextActvityLink, setNextActivityLink] = useState(null);
  // Functions
  /**
   * When the retry button is clicked
   * @returns {void}
   */
  const onClickRetry = () => {
    if (handleRetry) {
      handleRetry();
    }
  };

  /**
   * When the review button is clicked
   * @returns {void}
   */
  const onClickReview = () => {
    if (handleReview) {
      handleReview();
    }
  };

  useEffect(() => {
    if (data?.lms_script?.next_activity?.type) {
      switch (data?.lms_script?.next_activity.type) {
        case 'lms_script':
          setNextActivityLink(`/dashboard/enrollments/${enrollmentId}/courses/${courseId}/lmslessons/${data.lms_script.next_activity.id}`);
          break;
        case 'lms_quiz':
          setNextActivityLink(`/dashboard/enrollments/${enrollmentId}/courses/${courseId}/lmsquizzes/${data.lms_script.next_activity.id}`);
          break;
        case 'reporting':
          setNextActivityLink(`/dashboard/enrollments/${enrollmentId}/courses/${courseId}/reporting`);
          break;
      }
    } else {
      setNextActivityLink(`/dashboard/enrollments/${enrollmentId}/courses/${courseId}/outline`);
    }
  }, [data]);

  // Render
  return (
    <ScriptAttemptResultsStyles>
      <PageHeader
        title={'LMS Lesson'}
        subTitle={data?.lms_script?.name ?? ''}
        ghost={false}
        onBack={() => {
          history.push(`/dashboard/enrollments/${enrollmentId}/courses/${courseId}/lmslessons/${lmsScriptId}`);
        }}
        extra={
          <Button
            type="primary"
            style={{ backgroundColor: '#5e8dbd', borderColor: 'rgb(97 121 146)' }}
            onClick={() => {
              history.push(`/dashboard/enrollments/${enrollmentId}/courses/${courseId}/outline`);
            }}
          >
            <Icon type="book" /> Course Outline
          </Button>
        }
      />
      {errors ? (
        errors?.map(error => {
          // eslint-disable-next-line react/jsx-key
          return <Alert message={error} type="error" />;
        })
      ) : (
        <>
          <Card>
            {loading && <Spin />}
            {!loading && data?.score !== null && (
              <>
                <PageHeader
                  title={'Results'}
                  extra={[
                    <Link key={enrollmentId} to={`/dashboard/enrollments/${enrollmentId}/courses/${courseId}/outline`}>
                      <Button type="primary">
                        Outline <Icon type="bars" />
                      </Button>
                    </Link>,
                  ]}
                />

                <h2>{data?.score >= data?.lms_script?.settings?.req_grade ? 'Congratulations' : 'Sorry!'}</h2>
                <h2>
                  You {data?.score >= data?.lms_script?.settings?.req_grade ? 'passed' : 'did not pass'} <strong>{data?.lms_script?.name ?? ''}</strong>
                </h2>
                <h3>
                  Passing Score: <strong>{data?.lms_script?.settings?.req_grade}%</strong>
                </h3>
                <h3>
                  Your Score: <strong>{data?.score.toFixed(2)}%</strong>
                </h3>

                <Divider />

                <main>
                  {data?.script_attempt_answers && data?.lms_script?.script?.sections ? (
                    <div>
                      <Table
                        rowKey={`id`}
                        showHeader={false}
                        dataSource={data?.lms_script?.script?.sections
                          ?.filter(item => item.sectionslides.length === 1 && item.sectiontype?.type === 'question')
                          .map(item => {
                            item.correct = data?.script_attempt_answers?.find(answer => answer?.question?.section_id === item.id && answer?.correct === 1)?.correct ? 1 : 0;
                            return item;
                          })}
                        rowClassName={record => (record && record.correct ? 'correct' : 'incorrect')}
                        columns={[
                          {
                            key: 'question-id',
                            render: (_text, _record, i) => `Question ${i + 1}`,
                          },
                          {
                            key: 'correct-id',
                            align: 'right',
                            render: (_text, record) => (record.correct ? <Icon type={'check'} /> : <Icon type={'close'} />),
                          },
                        ]}
                        pagination={false}
                      />
                      <Divider />
                    </div>
                  ) : (
                    ``
                  )}
                </main>

                <footer>
                  <Button onClick={onClickReview}>
                    <Icon type="left" /> Review
                  </Button>
                  {data?.score >= data?.lms_script?.settings?.req_grade ? (
                    nextActvityLink ? (
                      <Link to={nextActvityLink}>
                        <Button type="primary">
                          Next <Icon type="right" />
                        </Button>
                      </Link>
                    ) : (
                      ''
                    )
                  ) : (
                    <Button key={`results-button-reload`} type="primary" onClick={onClickRetry}>
                      Retry <Icon type="reload" />
                    </Button>
                  )}
                </footer>
              </>
            )}
          </Card>
        </>
      )}
    </ScriptAttemptResultsStyles>
  );
};

// PROP TYPES
// --------------------------------------------------------
ScriptAttemptResults.propTypes = {
  data: PropTypes.any,
  errors: PropTypes.any,
  loading: PropTypes.bool,
  match: PropTypes.any,
  history: PropTypes.any,
  handleRetry: PropTypes.func,
  handleReview: PropTypes.func,
};

// EXPORTS
// --------------------------------------------------------
export default ScriptAttemptResults;
