// Imports
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

// Operations
import { doGetCourseOutline, doGetCourseReview } from './operations';

// Presentation Components
import CourseOutline from '../../components/CourseOutline';

// Main Component
/**
 * Main Component
 * @param {*} props any
 * @return {JSX.Element} JSX Element
 */
const CourseOutlineContainer = props => {
  // State / Props
  const { token, review, data, user, fetching, match, history, outlineErrors, doGetCourseOutline: getCourseOutline, doGetCourseReview: getCourseReview } = props;

  const { courseId, enrollmentId } = match.params;

  const [courseReview, setCourseReview] = useState(null);

  // Hooks
  /**
   * Retrieves initial data
   */
  useEffect(() => {
    if (courseId) {
      getCourseOutline({ token, courseId, enrollmentId });
    }
  }, [getCourseOutline, courseId, enrollmentId, token]);

  useEffect(() => {
    if (courseId) {
      getCourseReview({ token, courseId, enrollmentId });
    }
  }, [getCourseReview, token, courseId, enrollmentId]);

  // Store review
  useEffect(() => {
    if (data && !data?.loading && review?.data && !review?.loading) {
      const studentId = data?.enrollments?.find(enrollment => enrollment.lms_course_id === +courseId)?.student_id;
      const courseReview = review.data.enrollment_id === +enrollmentId;
      if (courseReview) {
        setCourseReview({ ...courseReview });
      }
    }
  }, [data, review, setCourseReview, courseId]);

  // Render
  return <CourseOutline loading={fetching} data={data} history={history} token={token} params={match.params} user={user} outlineErrors={outlineErrors} courseReview={courseReview} />;
};

// Map State / Dispatch To Props
/**
 * Main redux state
 * @param {*} state object
 * @returns {Object} state objects
 */
const mapStateToProps = state => ({
  token: state.loginReducer.token,
  user: state.loginReducer.user,
  fetching: state.courseOutlineReducer.fetching,
  data: state.courseOutlineReducer.data,
  outlineErrors: state.courseOutlineReducer.errors,
  review: {
    data: state.courseOutlineReducer.review.data,
    fetching: state.courseOutlineReducer.review.fetching,
  },
});

/**
 * Main redux dispatch functions
 * @param {*} dispatch redux dispatch
 * @returns {bindActionCreators} state objects
 */
const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      doGetCourseOutline,
      doGetCourseReview,
    },
    dispatch,
  );

// Prop Types
CourseOutlineContainer.propTypes = {
  // Functions
  doGetCourseOutline: PropTypes.func,
  doGetCourseReview: PropTypes.func,

  // Values
  fetching: PropTypes.bool,
  match: PropTypes.object,
  history: PropTypes.object,
  token: PropTypes.string,
  data: PropTypes.object,
  review: PropTypes.object,
  user: PropTypes.object,
  outlineErrors: PropTypes.any,
};

// Exports
export default connect(mapStateToProps, mapDispatchToProps)(CourseOutlineContainer);
