// Imports
import axios from 'axios';

// Config
const apiUri = window.API_URL;

// Export actions
export const getCourseItems = ({ token, enrollmentId = null }) => {
  return axios({
    method: 'get',
    url: `${apiUri}/courseitems?${enrollmentId ? `enrollment_id=${enrollmentId}` : ''}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};
