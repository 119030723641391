// action types
import { POST_REVIEW_REQUEST, POST_REVIEW_SUCCESS, POST_REVIEW_FAILURE, GET_STUDENT_REVIEW_REQUEST, GET_STUDENT_REVIEW_SUCCESS, GET_STUDENT_REVIEW_FAILURE } from './types';

// reducer with initial state
const initialState = {
  fetching: false,
  data: null,
  errors: null,
};

const reviewReducer = (state = initialState, action) => {
  switch (action.type) {
    case POST_REVIEW_REQUEST:
    case GET_STUDENT_REVIEW_REQUEST:
      return {
        ...state,
        fetching: true,
        errors: null,
        data: null,
      };
    case POST_REVIEW_SUCCESS:
    case GET_STUDENT_REVIEW_SUCCESS:
      return {
        ...state,
        fetching: false,
        errors: null,
        data: action.response.data.data,
      };
    case POST_REVIEW_FAILURE:
    case GET_STUDENT_REVIEW_FAILURE:
      return {
        ...state,
        fetching: false,
        errors: action.data,
      };
    default:
      return state;
  }
};

export default reviewReducer;
