import AmazonPayV2 from '../../../../../AmazonPayV2';
import { Alert, Form, Icon } from 'antd';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import AmazonPaymentStyles from './styles';
import PurchaseStyles from '../../../../styles';
import AmazonPayButton from '../../../../../AmazonPayV2/components/AmazonPayButton';
import useScript from '../../../../../../hooks/useScript';

const AmazonPayment = props => {
  const { amazonData, cartData, user, enrollmentId, token, handleConfirmationStep, handleStudentInformationStep, processAmazonPaymentIntent, getMe } = props;
  const [accessToken, setAccessToken] = useState('');
  const [orderId, setOrderId] = useState('');

  const [processOrder, setProcessOrder] = useState(false);

  const handleAmazonProcessing = () => {
    if (accessToken && orderId) {
      setProcessOrder(true);
    }
  };

  useEffect(() => {
    if (token && !amazonData?.fetching && amazonData?.data && accessToken && orderId && processOrder) {
      handleStudentInformationStep();
      getMe({ token });
    }
  }, [token, accessToken, orderId, amazonData]);

  useEffect(() => {
    if (processOrder && cartData && cartData?.data?.id && amazonData && amazonData?.data?.transaction_ref && !amazonData?.complete) {
      let cartId = cartData?.data?.id;
      let transactionRef = amazonData?.data?.transaction_ref;
      processAmazonPaymentIntent({ token, accessToken, orderId, cartId, transactionRef });
    }
  }, [accessToken, orderId, processOrder, cartData, amazonData]);

  return (
    <div style={{ textAlign: 'center' }}>
      {amazonData && amazonData?.signature ? (
        <AmazonPayV2
          merchantId={window.AMAZON_MERCHANT_ID}
          publicKeyId={window.AMAZON_PUBLIC_ID}
          payload={{
            webCheckoutDetails: {
              checkoutResultReturnUrl: `${window.location.protocol}//${window?.location?.host}/dashboard/enrollments/${enrollmentId}/purchase`,
              checkoutMode: 'ProcessOrder',
            },
            scopes: ['name', 'email', 'phoneNumber', 'billingAddress'],
            paymentDetails: {
              paymentIntent: 'AuthorizeWithCapture',
              chargeAmount: {
                amount: cartData?.data?.cart_total,
                currencyCode: 'USD',
              },
            },
            merchantMetadata: {
              merchantReferenceId: amazonData?.data?.transaction_ref,
            },
            chargePermissionType: 'OneTime',
            storeId: window.AMAZON_STORE_ID,
          }}
          signature={amazonData?.signature}
        />
      ) : (
        ''
      )}
      {accessToken && orderId && (
        <AmazonPaymentStyles.Button
          disabled={amazonData?.fetching}
          onClick={() => {
            handleAmazonProcessing();
          }}
          id="submit"
          style={{ marginTop: 15 }}
        >
          <span id="button-text">{amazonData?.fetching ? <Icon type="loading" /> : 'Pay now'}</span>
        </AmazonPaymentStyles.Button>
      )}
    </div>
  );
};

// PROPS TYPES
// --------------------------------------------------------
AmazonPayment.propTypes = {
  stripePromise: PropTypes.string,
  token: PropTypes.string,
  accessToken: PropTypes.string,
  enrollmentId: PropTypes.string,
  orderId: PropTypes.string,
  cartData: PropTypes.object,
  amazonData: PropTypes.object,
  user: PropTypes.object,
  processOrder: PropTypes.bool,
  handleAmazonProcessing: PropTypes.func,
  createAmazonPaymentIntent: PropTypes.func,
  processAmazonPaymentIntent: PropTypes.func,
  getMe: PropTypes.func,
  handleConfirmationStep: PropTypes.func,
  handleStudentInformationStep: PropTypes.func,
};

export default AmazonPayment;
