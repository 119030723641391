/* global amazon */
import React from 'react';
import AmazonPayButton from './AmazonPayButton';

import PropTypes from 'prop-types';

import { AmazonPayStyles } from '../styles';

const AmazonPayV2 = props => {
  const { merchantId, publicKeyId, payload, signature } = props;
  return (
    window.amazon && (
      <AmazonPayStyles>
        <React.Fragment>{<AmazonPayButton style={{ width: '100%', marginBottom: 15 }} merchantId={merchantId} publicKeyId={publicKeyId} payload={payload} signature={signature} />}</React.Fragment>
      </AmazonPayStyles>
    )
  );
};

AmazonPayV2.propTypes = {
  merchantId: PropTypes.string.isRequired,
  publicKeyId: PropTypes.string.isRequired,
  signature: PropTypes.string.isRequired,
  payload: PropTypes.object.isRequired,
  onAmazonLoginReady: PropTypes.func,
};

export default AmazonPayV2;
