// Imports
import storage from 'redux-persist/lib/storage';
import { persistReducer } from 'redux-persist';

// action types
import { PASSWORD_RESET_REQUEST, PASSWORD_RESET_FAILURE, PASSWORD_RESET_SUCCESS } from './types';

// reducer with initial state
const initialState = {
  fetching: false,
  errors: null,
  data: null,
};

const persistConfig = {
  key: 'auth',
  storage,
  whitelist: ['token', 'refresh', 'user', 'redirect'],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case PASSWORD_RESET_REQUEST:
      return {
        ...state,
        fetching: true,
        errors: null,
        data: null,
      };
    case PASSWORD_RESET_FAILURE:
      return {
        ...state,
        fetching: false,
        errors: action.error,
        data: null,
      };
    case PASSWORD_RESET_SUCCESS:
      return {
        ...state,
        fetching: false,
        errors: null,
        data: action.data,
      };
    default:
      return state;
  }
};

const passwordResetReducer = persistReducer(persistConfig, reducer);

export default passwordResetReducer;
