// MAIN TYPES
// --------------------------------------------------------
/**
 * @constant
 */
export const CREATE_SCRIPTATTEMPTS_RESET = 'CREATE_SCRIPTATTEMPTS_RESET';

/**
 * @constant
 */
export const CREATE_SCRIPTATTEMPTS_REQUEST = 'CREATE_SCRIPTATTEMPTS_REQUEST';

/**
 * @constant
 */
export const CREATE_SCRIPTATTEMPTS_SUCCESS = 'CREATE_SCRIPTATTEMPTS_SUCCESS';

/**
 * @constant
 */
export const CREATE_SCRIPTATTEMPTS_FAILURE = 'CREATE_SCRIPTATTEMPTS_FAILURE';
