// IMPORTS
// --------------------------------------------------------
// Redux
import { put, takeLatest, call } from 'redux-saga/effects';

// Type
import { READ_LMSSCRIPTS_REQUEST } from './types';

// Actions
import { doReadLmsScriptFailure, doReadLmsScriptSuccess } from './actions';

// Requests
import { readLmsScripts } from '../requests';

// MAIN SAGAS
// --------------------------------------------------------
/**
 * Worker for handling readLmsScripts
 * @return {void}
 */
function* workerReadLmsScripts({ token, id, enrollmentId, include }) {
  try {
    const response = yield call(readLmsScripts, { token, id, enrollmentId, include });

    if (!response?.data?.data) {
      throw new Error('workerReadLmsScripts - Response Error');
    }

    const { data } = response.data;

    yield put(doReadLmsScriptSuccess({ data }));
  } catch (error) {
    const errors = error?.message ?? 'Something went wrong';
    yield put(doReadLmsScriptFailure({ errors }));
  }
}

/**
 * Watches for Dispatch READ_LMSSCRIPTS_REQUEST
 * @return {void}
 */
export function* watcherReadLmsScripts() {
  yield takeLatest(READ_LMSSCRIPTS_REQUEST, workerReadLmsScripts);
}
