//IMPORTS
import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ConnectedRouter } from 'connected-react-router';
import PropTypes from 'prop-types';

//ROUTE COMPONENTS
import PrivateRoute from './private';

//REDUX
import store, { history } from '../redux/store';

//CONTAINERS
import AppContainer from '../containers/App';
import Dashboard from '../containers/Dashboard';
import AuthySetup from '../containers/AuthySetup';
import Authy from '../containers/Authy';
import Login from '../containers/Login';
import PasswordReset from '../containers/PasswordReset';
import ForgotPassword from '../containers/ForgotPassword';
import Logout from '../containers/Logout';
import NotFound from '../containers/NotFound';
import Feedback from '../containers/Feedback';
import Referral from '../containers/Referral';
import CourseOutline from '../containers/CourseOutline';
import LmsQuiz from '../containers/LmsQuiz';
import LmsScript from '../containers/LmsScript';
import QuizAttempt from '../containers/QuizAttempt';
import Reporting from '../containers/Reporting';
import CourseComplete from '../containers/CourseComplete';
import Review from '../containers/Review';
import Account from '../containers/Account';
import QuizResults from '../containers/QuizResults';
import GuestAccess from '../containers/GuestAccess';
//
import ScriptAttemptContainer from '../containers/ScriptAttempt';
import PurchaseContainer from '../containers/Purchase';
import ScriptAttemptResultsContainer from '../containers/ScriptAttemptResults';

//ACTIONS
import { doRedirectReset } from '../containers/Login/operations';

//UTILS
import NetworkService from '../utils/NetworkService';

//CONFIG

//INTERCEPTORS
NetworkService.setupInterceptors({ store, history });

//MAIN COMPONENT
/**
 * Main route component
 * @param {*} props default component props
 * @returns {JSX} react component
 */
const RoutesContainer = props => {
  // State / Props
  const { user } = props;

  return (
    <ConnectedRouter history={history}>
      <AppContainer>
        <Switch>
          {/* PUBLIC ROUTES */}
          <Route exact path="/" render={() => (user ? <Redirect to="/dashboard" /> : <Redirect to="/login" />)} />
          <Route exact path="/two-factor-setup" component={AuthySetup} />
          <Route exact path="/two-factor" component={Authy} />
          <Route exact path="/passwordreset/:token/:validator" component={PasswordReset} />
          <Route exact path="/guest" component={GuestAccess} />
          <Route exact path="/guest/:meta" component={GuestAccess} />
          <Route exact path="/guest/course/:lmsCourseId" component={GuestAccess} />
          <Route exact path="/guest/course/:lmsCourseId/:meta" component={GuestAccess} />
          <Route exact path="/forgotpassword" component={ForgotPassword} />
          <Route
            exact
            path="/login"
            render={() => {
              const { redirect } = props;
              if (user) {
                props.doRedirectReset();
              }
              return user ? <Redirect to={redirect || '/dashboard'} /> : <Login />;
            }}
          />

          <Route exact path="/logout" component={Logout} />

          {/* PRIVATE ROUTES */}
          <PrivateRoute exact path="/dashboard" redirect={!user} component={Dashboard} />
          <PrivateRoute exact path="/dashboard/account" redirect={!user} component={Account} />
          <PrivateRoute exact path="/dashboard/enrollments/:enrollmentId/courses/:courseId/outline" redirect={!user} component={CourseOutline} />

          {/* Quiz Attempts */}
          <PrivateRoute exact path="/dashboard/enrollments/:enrollmentId/courses/:courseId/lmsquizzes/:lmsQuizId" redirect={!user} component={LmsQuiz} />
          <PrivateRoute exact path="/dashboard/enrollments/:enrollmentId/courses/:courseId/lmsquizzes/:lmsQuizId/attempts/:quizAttemptId" redirect={!user} component={QuizAttempt} />
          <PrivateRoute exact path="/dashboard/enrollments/:enrollmentId/courses/:courseId/lmsquizzes/:lmsQuizId/attempts/:quizAttemptId/results" redirect={!user} component={QuizResults} />

          {/* Script Attempts */}
          <PrivateRoute exact path="/dashboard/enrollments/:enrollmentId/courses/:courseId/lmslessons/:lmsScriptId" redirect={!user} component={LmsScript} />
          <PrivateRoute exact path="/dashboard/enrollments/:enrollmentId/courses/:courseId/lmslessons/:lmsScriptId/attempts/:scriptAttemptId" redirect={!user} component={ScriptAttemptContainer} />
          <PrivateRoute exact path="/dashboard/course/:lmsCourseId/purchase" redirect={!user} component={PurchaseContainer} />
          <PrivateRoute exact path="/dashboard/cart/:cartId/purchase" redirect={!user} component={PurchaseContainer} />
          <PrivateRoute exact path="/dashboard/enrollments/:enrollmentId/purchase" redirect={!user} component={PurchaseContainer} />
          <PrivateRoute
            exact
            path="/dashboard/enrollments/:enrollmentId/courses/:courseId/lmslessons/:lmsScriptId/attempts/:scriptAttemptId/results"
            redirect={!user}
            component={ScriptAttemptResultsContainer}
          />

          <PrivateRoute exact path="/dashboard/enrollments/:enrollmentId/scripts/:scriptId/status" redirect={!user} component={CourseComplete} />
          <PrivateRoute exact path="/dashboard/enrollments/:enrollmentId/courses/:courseId/reporting" redirect={!user} component={Reporting} />
          <PrivateRoute exact path="/dashboard/enrollments/:enrollmentId/courses/:courseId/review" redirect={!user} component={Review} />
          {/* CATCH ALL OTHER ROUTES */}
          <Route
            exact
            path="/version"
            render={() => (
              <>
                <p>
                  <strong>SHA1:</strong> {window.SHA1}
                </p>
                <p>
                  <strong>API_URL:</strong> {window.API_URL}
                </p>
              </>
            )}
          />
          <Route path="*" component={NotFound} status={404} />
        </Switch>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          {/* FEEDBACK ACTIONS */}
          {user && (
            <PrivateRoute
              path={[
                '/dashboard/enrollments/:enrollmentId/*/lmsquizzes/:lmsQuizId/attempts/:quizAttemptId',
                '/dashboard/enrollments/:enrollmentId/*/lmslessons/:lmsScriptId/attempts/:scriptAttemptId',
                '/dashboard/enrollments/:enrollmentId',
                '/dashboard',
              ]}
              component={Feedback}
            />
          )}

          {/* REFERRAL ACTIONS */}
          {user && <PrivateRoute path="/dashboard/websites/:websiteId/*" component={Referral} />}
          {user && <PrivateRoute path="/dashboard/*/*/courses/:courseId/*" component={Referral} />}
          {user && <PrivateRoute path="/dashboard/courses/:courseId/*" component={Referral} />}
        </div>
      </AppContainer>
    </ConnectedRouter>
  );
};

//REDUX
/**
 * Maps redux state to local component state
 * @param {*} state incoming redux state
 * @return {Object} redux state
 */
const mapStateToProps = state => ({
  user: state.loginReducer.user,
  redirect: state.loginReducer.redirect,
});

/**
 * Maps redux actions to local component state
 * @param {*} dispatch incoming redux dispatch
 * @return {Object} redux actions
 */
const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      doRedirectReset,
    },
    dispatch,
  );

RoutesContainer.propTypes = {
  user: PropTypes.object,
  redirect: PropTypes.string || null,
  doRedirectReset: PropTypes.func,
  location: PropTypes.any,
};

//EXPORTS
export default connect(mapStateToProps, mapDispatchToProps)(RoutesContainer);
