// IMPORTS
// --------------------------------------------------------
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import GuestAccess from '../../components/GuestAccess';
// Operations
import { doCreateScriptAttempts } from '../Lessons/operations';
import { doGetGuestLmsCourse, doGetGuestLmsCourses } from '../LmsCourse/operations';
import { doCreateGuestEnrollment, doClearFetching, doClearGuestEnrollment } from '../Login/operations';
import Bugsnag from '@bugsnag/js';

// GuestAccess CONTAINER
// --------------------------------------------------------
/**
 * GuestAccessContainer
 * @param {Object} props component props
 * @returns {JSX.Element} React component
 */
const GuestAccessContainer = props => {
  const {
    token,
    doClearFetching: clearFetching,
    doCreateGuestEnrollment: createGuestEnrollment,
    doGetGuestLmsCourses: getGuestLmsCourses,
    doGetGuestLmsCourse: getGuestLmsCourse,
    doClearGuestEnrollment: clearGuestEnrollment,
    match,
    history,
    user,
    fetching,
    errors,
    lmsCourseData,
    lmsCourseErrors,
    scriptAttempts,
    enrollment,
  } = props;

  const [lmsCourseId, setLmsCourseId] = useState(match?.params?.lmsCourseId);

  const { meta } = match?.params;

  const REDIRECT_TO_GUEST_ERRORS = ['Cart course has no price', 'Guest enrollment in this course is not allowed.'];
  const REDIRECT_TO_LOGIN_ERRORS = ['You must login to start a new guest enrollment.'];

  useEffect(() => {
    clearFetching();
    if (lmsCourseId) {
      getGuestLmsCourse({ lmsCourseId });
    } else {
      getGuestLmsCourses();
    }
  }, [lmsCourseId, getGuestLmsCourse, getGuestLmsCourses, clearFetching]);

  useEffect(() => {
    if (!fetching && user && enrollment) {
      if (enrollment) {
        let enrollmentId = enrollment?.id;
        let lmsCourseId = enrollment?.lms_course_id;
        let scriptAttempt = enrollment?.script_attempt;
        let lmsScriptId = scriptAttempt?.lms_script_id;
        let scriptAttemptId = scriptAttempt?.id;
        clearGuestEnrollment();
        history.push(`/dashboard/enrollments/${enrollmentId}/courses/${lmsCourseId}/lmslessons/${lmsScriptId}/attempts/${scriptAttemptId}`);
      }
    }
  }, [token, fetching, user, scriptAttempts, history, enrollment]);

  useEffect(() => {
    if (lmsCourseId && lmsCourseErrors) {
      setLmsCourseId(null);
      history.push(`/guest`);
    } else if (lmsCourseId && lmsCourseData && !lmsCourseData?.name) {
      var result = lmsCourseData.filter(course => {
        return course.id === lmsCourseId;
      });
      if (!result) {
        setLmsCourseId(null);
        history.push(`/guest`);
      }
    }
  }, [lmsCourseErrors, lmsCourseData]);

  useEffect(() => {
    if (lmsCourseErrors) {
      let notification = {};
      notification['lms_course_id'] = lmsCourseId;
      notification['errors'] = lmsCourseErrors;
      Bugsnag.notify(notification);
      const redirect = lmsCourseErrors?.find(error => REDIRECT_TO_GUEST_ERRORS.find(msg => msg === error));
      if (redirect) {
        history.push(`/guest`);
      }
    }
    if (errors && Array.isArray(errors)) {
      const redirect = errors?.find(error => REDIRECT_TO_LOGIN_ERRORS.find(msg => msg === error));
      if (redirect) {
        history.push(`/login`);
      }
    }
  }, [lmsCourseErrors, REDIRECT_TO_GUEST_ERRORS, errors]);

  // RENDER
  return (
    <GuestAccess
      meta={meta}
      errors={errors}
      fetching={fetching}
      lmsCourseId={lmsCourseId ? lmsCourseId : lmsCourseData?.id}
      lmsCourseData={lmsCourseData}
      lmsCourseErrors={lmsCourseErrors}
      createGuestEnrollment={createGuestEnrollment}
      token={token}
      user={user}
    />
  );
};

// REDUX
// --------------------------------------------------------
/**
 * Maps Redux State To Component
 * @param {Object} state main redux state
 * @returns {Object} Redux Reducer States
 */
const mapStateToProps = state => ({
  token: state.loginReducer.token,
  fetching: state.loginReducer.fetching,
  enrollment: state.loginReducer.enrollment,
  user: state.loginReducer.user,
  errors: state.loginReducer.errors,
  scriptAttempts: state.lessonsReducer.scriptAttempts,
  lmsCourseData: state.lmsCourseReducer.data,
  lmsCourseErrors: state.lmsCourseReducer.errors,
});

/**
 * Maps Redux Actions To Component
 * @param {*} dispatch main redux dispatch event
 * @returns {{}} Redux Dispatch
 */
const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      doCreateGuestEnrollment,
      doClearGuestEnrollment,
      doGetGuestLmsCourse,
      doGetGuestLmsCourses,
      doCreateScriptAttempts,
      doClearFetching,
    },
    dispatch,
  );

// PROPS TYPES
// --------------------------------------------------------
GuestAccessContainer.propTypes = {
  token: PropTypes.string,
  fetching: PropTypes.bool,
  history: PropTypes.object,
  user: PropTypes.object,
  scriptAttempts: PropTypes.object,
  errors: PropTypes.object,
  lmsCourseData: PropTypes.any,
  lmsCourseErrors: PropTypes.any,
  match: PropTypes.object,
  doCreateGuestEnrollment: PropTypes.func,
  doGetGuestLmsCourse: PropTypes.func,
  doGetGuestLmsCourses: PropTypes.func,
  doCreateScriptAttempts: PropTypes.func,
  doClearGuestEnrollment: PropTypes.func,
  doClearFetching: PropTypes.func,
  enrollment: PropTypes.object,
};

// EXPORTS
// --------------------------------------------------------
export default connect(mapStateToProps, mapDispatchToProps)(GuestAccessContainer);
