// Types
import { GET_QUESTION_REQUEST } from './types';

// Main Operations

/**
 * Get Question Single
 * @param {*} Object { token, questionId }
 * @returns {Dispatch} for redux
 */
export const doGetQuestion = ({ token, questionId }) => ({
  type: GET_QUESTION_REQUEST,
  token,
  questionId,
});
