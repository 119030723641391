// Imports
import axios from 'axios';

// Config
const apiUri = window.API_URL;

// Export actions
export const getQuestion = ({ token, questionId }) => {
  return axios({
    method: 'get',
    url: `${apiUri}/questions/${questionId}?with=questionoptions`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};
