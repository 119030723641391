// Import
import React from 'react';
import PropTypes from 'prop-types';
import { Popover, Form, Input, Select, Button, Alert } from 'antd';
import FeedbackStyles from './styles';

// Main Component
/**
 * Feedback Component
 * @param {*} props any
 * @returns {JSX.Element} Main JSX Routes
 */
const Feedback = props => {
  // State / Props
  const { form, handleSendFeedback, handleClearFeedback, feedback } = props;
  const { getFieldDecorator } = form;

  // Functions
  /**
   * Handles form submission
   * @param {Event} event Form Event
   * @returns {void}
   */
  const onSubmitSuggestion = event => {
    event.preventDefault();

    form.validateFields(['feedbackType', 'feedback'], (err, values) => {
      if (!err) {
        handleSendFeedback(values);
        form.setFieldsValue({
          feedbackType: undefined,
          feedback: '',
        });
      }
    });
  };

  /**
   * Clears status messages from form
   * @param {Event} event Button Event
   * @returns {void}
   */
  const onClickButton = event => {
    handleClearFeedback();
    form.setFieldsValue({
      feedbackType: undefined,
      feedback: '',
    });
  };

  // Render
  return (
    <FeedbackStyles>
      <FeedbackStyles.GlobalStyles />
      <Popover
        overlayClassName="aux-popover"
        trigger={['click']}
        placement="topLeft"
        content={
          <div>
            <h3>If you have a suggestion or need to report a typo or problem to us, submit it here.</h3>
            <Form onSubmit={onSubmitSuggestion}>
              <Form.Item>
                {getFieldDecorator('feedbackType', {
                  rules: [
                    {
                      required: true,
                      message: 'Feedback type is required',
                    },
                  ],
                })(
                  <Select placeholder="Choose Type">
                    <Select.Option value="support">Support</Select.Option>
                    <Select.Option value="content">Content</Select.Option>
                    <Select.Option value="typo">Typo</Select.Option>
                  </Select>,
                )}
              </Form.Item>
              <Form.Item>
                {getFieldDecorator('feedback', {
                  rules: [
                    {
                      required: true,
                      message: 'Feedback is required',
                    },
                  ],
                })(<Input.TextArea rows={4} placeholder="Your suggestion or help request goes here" />)}
              </Form.Item>
              {feedback?.success && (
                <Form.Item>
                  <Alert message="Your feedback has been received." type="success" />
                </Form.Item>
              )}

              <Button type="primary" htmlType="submit" disabled={feedback.fetching}>
                Submit
              </Button>
            </Form>
          </div>
        }
      >
        <FeedbackStyles.SuggestionButton shape="round" icon="question-circle" onClick={onClickButton}>
          Suggestion
        </FeedbackStyles.SuggestionButton>
      </Popover>
    </FeedbackStyles>
  );
};

// Property Types
Feedback.propTypes = {
  form: PropTypes.object,
  handleSendFeedback: PropTypes.func,
  handleClearFeedback: PropTypes.func,
  feedback: PropTypes.object,
  courseItemId: PropTypes.string,
};

// Export
export default Feedback;
