// Imports
import axios from 'axios';

// Config
const apiUri = window.API_URL;

// Export actions
export const getQuizQuestions = ({ token, courseId, enrollmentId }) => {
  return axios({
    method: 'get',
    url: `${apiUri}/courses/${courseId}?with=quiz.lmsQuizzes.quizAttempts.quizAttemptAnswers&${enrollmentId ? `enrollment_id=${enrollmentId}` : ''}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};
