// IMPORTS
// --------------------------------------------------------
// Types
import { TRACKING_SCRIPTATTEMPTS_RESET, TRACKING_SCRIPTATTEMPTS_REQUEST, TRACKING_SCRIPTATTEMPTS_FAILURE, TRACKING_SCRIPTATTEMPTS_SUCCESS } from './types';

// MAIN ACTIONS
// --------------------------------------------------------
/**
 * Resets main reducer to initialState
 * @returns {Dispatch} Redux Dispatch
 */
export const doTrackingScriptAttemptsReset = () => ({
  type: TRACKING_SCRIPTATTEMPTS_RESET,
});

/**
 * Main request
 * @returns {Dispatch} Redux Dispatch
 */
export const doTrackingScriptAttemptsRequest = ({ token, id }) => ({
  type: TRACKING_SCRIPTATTEMPTS_REQUEST,
  token,
  id,
});

/**
 * Handling request failure
 * @returns {Dispatch} Redux Dispatch
 */
export const doTrackingScriptAttemptsFailure = ({ errors }) => ({
  type: TRACKING_SCRIPTATTEMPTS_FAILURE,
  errors,
});

/**
 * Handling request success
 * @returns {Dispatch} Redux Dispatch
 */
export const doTrackingScriptAttemptsSuccess = ({ data }) => ({
  type: TRACKING_SCRIPTATTEMPTS_SUCCESS,
  data,
});
