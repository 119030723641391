// Export types
export const GET_REPORTING_QUESTIONS_REQUEST = 'GET_REPORTING_QUESTIONS_REQUEST';
export const GET_REPORTING_QUESTIONS_SUCCESS = 'GET_REPORTING_QUESTIONS_SUCCESS';
export const GET_REPORTING_QUESTIONS_FAILURE = 'GET_REPORTING_QUESTIONS_FAILURE';

export const POST_REPORTING_ANSWER_REQUEST = 'POST_REPORTING_ANSWER_REQUEST';
export const POST_REPORTING_ANSWER_SUCCESS = 'POST_REPORTING_ANSWER_SUCCESS';
export const POST_REPORTING_ANSWER_FAILURE = 'POST_REPORTING_ANSWER_FAILURE';

export const GET_REPORTING_ANSWERS_REQUEST = 'GET_REPORTING_ANSWERS_REQUEST';
export const GET_REPORTING_ANSWERS_SUCCESS = 'GET_REPORTING_ANSWERS_SUCCESS';
export const GET_REPORTING_ANSWERS_FAILURE = 'GET_REPORTING_ANSWERS_FAILURE';
