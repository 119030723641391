const TYPING_TIMEOUT_MS = 1000;

const ERROR_MESSAGE_DEFAULT = 'Something went wrong.';

const ERROR_MESSAGE_DURATION = 1; // 1 second

const STATUS = {
  LOCKED: 'locked',
  NOT_STARTED: 'notStarted',
  STARTED: 'started',
  RESTART: 'restart',
  COMPLETE: 'complete',
  FAILED: 'failed',
};

const ERROR_MESSAGE_BLACKLIST = [
  'ResizeObserver loop limit exceeded',
  'ResizeObserver loop completed with undelivered notifications.',
  "Failed to execute 'removeChild' on 'Node': The node to be removed is not a child of this node.",
  'The play() request was interrupted by a new load request.',
];

const getWebsite = () => {
  let host = window.location.hostname;
  let websiteName = 'wicontractortraining';
  if (host.includes('wicontractortraining')) {
    websiteName = 'wicontractortraining';
  } else if (host.includes('vaconstractortraining')) {
    websiteName = 'vaconstractortraining';
  } else if (host.includes('aypocompliance')) {
    websiteName = 'aypocompliance';
  } else if (host.includes('taxce')) {
    websiteName = 'taxce';
  } else if (host.includes('stglms')) {
    websiteName = 'staging';
  } else if (host.includes('devlms')) {
    websiteName = 'development';
  } else if (host.includes('localhost')) {
    websiteName = 'localhost';
  }
  return websiteName;
};

const WEBSITE_NAME = getWebsite();

// Exports
exports.TYPING_TIMEOUT_MS = TYPING_TIMEOUT_MS;
exports.ERROR_MESSAGE_DEFAULT = ERROR_MESSAGE_DEFAULT;
exports.ERROR_MESSAGE_DURATION = ERROR_MESSAGE_DURATION;
exports.STATUS = STATUS;
exports.ERROR_MESSAGE_BLACKLIST = ERROR_MESSAGE_BLACKLIST;
exports.WEBSITE_NAME = WEBSITE_NAME;
