// Export Types
// Get Course Outline
export const GET_COURSE_OUTLINE_REQUEST = 'GET_COURSE_OUTLINE_REQUEST';
export const GET_COURSE_OUTLINE_SUCCESS = 'GET_COURSE_OUTLINE_SUCCESS';
export const GET_COURSE_OUTLINE_FAILURE = 'GET_COURSE_OUTLINE_FAILURE';
export const CLEAR_COURSE_OUTLINE = 'CLEAR_COURSE_OUTLINE';

// Get Course Review
export const GET_COURSE_REVIEW_REQUEST = 'GET_COURSE_REVIEW_REQUEST';
export const GET_COURSE_REVIEW_SUCCESS = 'GET_COURSE_REVIEW_SUCCESS';
export const GET_COURSE_REVIEW_FAILURE = 'GET_COURSE_REVIEW_FAILURE';
