// IMPORTS
// --------------------------------------------------------
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

// Actions
import { doUpdateScriptAttemptsRequest, doUpdateScriptAttemptsReset } from '../../redux/modules/ScriptAttempts/update/actions';
import { doWatchedVideosScriptAttemptsRequest, doWatchedVideosScriptAttemptsReset } from '../../redux/modules/ScriptAttempts/watchedvideos/actions';

// Presentation Components
import Videos from '../../components/Videos';

// MAIN COMPONENT
// --------------------------------------------------------
/**
 * Main VideosContainer
 * @param {Object} props component props
 * @returns {JSX} React compoennt
 */
const VideosContainer = props => {
  // STATE / PROPS
  const {
    token,
    title,
    watched,
    position,
    videoUrl,
    captionUrl,
    scriptAttemptId,
    sectionId,
    handleVideoDone,
    handleSetUseTranscript,
    handleSetVideoFallback,
    watchedVideosScriptAttempt,
    doUpdateScriptAttemptsRequest: updateScriptAttemptsRequest,
    doUpdateScriptAttemptsReset: updateScriptAttemptsReset,
    doWatchedVideosScriptAttemptsRequest: watchedVideosScriptAttemptsRequest,
    doWatchedVideosScriptAttemptsReset: watchedVideosScriptAttemptsReset,
  } = props;

  // FUNCTIONS
  /**
   * When video has been fully watched
   * @returns {void}
   */
  const handleVideoWatched = () => {
    if (scriptAttemptId && sectionId) {
      watchedVideosScriptAttemptsRequest({
        token,
        id: scriptAttemptId,
        payload: {
          section_id: sectionId,
        },
      });
    }
  };

  /**
   * When update / seek is updated
   * @param {Number} seconds seconds to update
   * @returns {void}
   */
  const handleVideoUpdatePosition = seconds => {
    if (scriptAttemptId) {
      updateScriptAttemptsRequest({
        token,
        id: scriptAttemptId,
        payload: {
          position: seconds,
        },
      });
    }
  };

  useEffect(() => {
    // If video finished being watched
    if (watchedVideosScriptAttempt?.data) {
      handleVideoDone();
    }
  }, [watchedVideosScriptAttempt]);

  // HOOKS
  // Clean up
  useEffect(() => {
    return () => {
      watchedVideosScriptAttemptsReset();
      updateScriptAttemptsReset();
    };
  }, [watchedVideosScriptAttemptsReset, updateScriptAttemptsReset]);

  // RENDER
  return (
    <Videos
      title={title}
      watched={watched || watchedVideosScriptAttempt?.data}
      position={position}
      videoUrl={videoUrl}
      captionUrl={captionUrl}
      handleVideoWatched={handleVideoWatched}
      handleVideoUpdatePosition={handleVideoUpdatePosition}
      handleSetUseTranscript={handleSetUseTranscript}
      handleSetVideoFallback={handleSetVideoFallback}
      token={token}
    />
  );
};

// Mapping State / Dispatch To Props
const mapStateToProps = state => {
  return {
    token: state.loginReducer.token,
    watchedVideosScriptAttempt: state.WatchedVideosScriptAttemptsReducer,
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      doUpdateScriptAttemptsRequest,
      doUpdateScriptAttemptsReset,
      doWatchedVideosScriptAttemptsRequest,
      doWatchedVideosScriptAttemptsReset,
    },
    dispatch,
  );

// Property Types
VideosContainer.propTypes = {
  token: PropTypes.string,
  title: PropTypes.string,
  watched: PropTypes.bool,
  position: PropTypes.number,
  videoUrl: PropTypes.any,
  captionUrl: PropTypes.any,
  scriptAttemptId: PropTypes.any,
  sectionId: PropTypes.any,
  handleVideoDone: PropTypes.func,
  handleSetUseTranscript: PropTypes.func,
  handleSetVideoFallback: PropTypes.func,
  watchedVideosScriptAttempt: PropTypes.any,
  doUpdateScriptAttemptsRequest: PropTypes.func,
  doUpdateScriptAttemptsReset: PropTypes.func,
  doWatchedVideosScriptAttemptsRequest: PropTypes.func,
  doWatchedVideosScriptAttemptsReset: PropTypes.func,
};

// Export
export default connect(mapStateToProps, mapDispatchToProps)(VideosContainer);
