// Imports
import { takeLatest, put, call } from 'redux-saga/effects';

// Types
import {
  CREATE_AMAZON_PAYMENT_FAILURE,
  CREATE_AMAZON_PAYMENT_REQUEST,
  CREATE_AMAZON_PAYMENT_SUCCESS,
  CREATE_CART_ENROLLMENT_FAILURE,
  CREATE_CART_ENROLLMENT_REQUEST,
  CREATE_CART_ENROLLMENT_SUCCESS,
  CREATE_STRIPE_PAYMENT_FAILURE,
  CREATE_STRIPE_PAYMENT_REQUEST,
  CREATE_STRIPE_PAYMENT_SUCCESS,
  PROCESS_STRIPE_PAYMENT_FAILURE,
  PROCESS_STRIPE_PAYMENT_REQUEST,
  PROCESS_STRIPE_PAYMENT_SUCCESS,
  UPDATE_STUDENT_INFORMATION_FAILURE,
  UPDATE_STUDENT_INFORMATION_REQUEST,
  UPDATE_STUDENT_INFORMATION_SUCCESS,
  PROCESS_AMAZON_PAYMENT_REQUEST,
  PROCESS_AMAZON_PAYMENT_FAILURE,
  PROCESS_AMAZON_PAYMENT_SUCCESS,
  READ_CART_SUCCESS,
  READ_CART_FAILURE,
  READ_CART_REQUEST,
  CREATE_CART_COURSE_SUCCESS,
  CREATE_CART_COURSE_FAILURE,
  CREATE_CART_COURSE_REQUEST,
} from './types';

// Actions
import {
  processStripePaymentIntent,
  createStripePaymentIntent,
  createAmazonPaymentIntent,
  createCartEnrollment,
  updateStudentInformation,
  processAmazonPaymentIntent,
  getCart,
  createCartCourse,
} from './actions';

/**
 * Function that handles the cart enrollment create http request
 *
 * @param {Object} deconstructed object
 * @returns {void}
 */
function* workerUpdateStudentInformation({ token, studentId, firstName, lastName, zipCode, address, city, state, email, phone, password, confirmPassword }) {
  try {
    const response = yield call(updateStudentInformation, { token, studentId, firstName, lastName, zipCode, address, city, state, email, phone, password, confirmPassword });
    if (!response || !response.data || !response.data.data) {
      throw new Error('workerUpdateStudentInformation - Response Error');
    }

    yield put({
      type: UPDATE_STUDENT_INFORMATION_SUCCESS,
      data: response.data.data,
    });
  } catch (error) {
    const message = error.response && error.response.data ? error.response.data : null;
    yield put({ type: UPDATE_STUDENT_INFORMATION_FAILURE, ...message });
  }
}

// Exports
export function* watcherUpdateStudentInformation() {
  yield takeLatest(UPDATE_STUDENT_INFORMATION_REQUEST, workerUpdateStudentInformation);
}

/**
 * Function that handles the cart read http request
 *
 * @param {Object} deconstructed object
 * @returns {void}
 */
function* workerGetCart({ token, cartId }) {
  try {
    const response = yield call(getCart, { token, cartId });
    if (!response || !response.data || !response.data.data) {
      throw new Error('workerGetCart - Response Error');
    }

    yield put({
      type: READ_CART_SUCCESS,
      data: response.data.data,
    });
  } catch (error) {
    const message = error.response && error.response.data ? error.response.data : null;
    yield put({ type: READ_CART_FAILURE, ...message });
  }
}

// Exports
export function* watcherGetCart() {
  yield takeLatest(READ_CART_REQUEST, workerGetCart);
}

/**
 * Function that handles the cart enrollment create http request
 *
 * @param {Object} deconstructed object
 * @returns {void}
 */
function* workerCreateCartEnrollment({ token, enrollmentId }) {
  try {
    const response = yield call(createCartEnrollment, { token, enrollmentId });
    if (!response || !response.data || !response.data.data) {
      throw new Error('workerCreateCartEnrollment - Response Error');
    }

    yield put({
      type: CREATE_CART_ENROLLMENT_SUCCESS,
      data: response.data.data,
    });
  } catch (error) {
    const message = error.response && error.response.data ? error.response.data : null;
    yield put({ type: CREATE_CART_ENROLLMENT_FAILURE, ...message });
  }
}

// Exports
export function* watcherCreateCartEnrollment() {
  yield takeLatest(CREATE_CART_ENROLLMENT_REQUEST, workerCreateCartEnrollment);
}

/**
 * Function that handles the cart course create http request
 *
 * This does not create the cart course model. It creates a cart based on a lms course id
 *
 * @param {Object} deconstructed object
 * @returns {void}
 */
function* workerCreateCartCourse({ token, lmsCourseId }) {
  try {
    const response = yield call(createCartCourse, { token, lmsCourseId });
    if (!response || !response.data || !response.data.data) {
      throw new Error('workerCreateCartCourse - Response Error');
    }

    yield put({
      type: CREATE_CART_COURSE_SUCCESS,
      data: response.data.data,
    });
  } catch (error) {
    const message = error.response && error.response.data ? error.response.data : null;
    yield put({ type: CREATE_CART_COURSE_FAILURE, ...message });
  }
}

// Exports
export function* watcherCreateCartCourse() {
  yield takeLatest(CREATE_CART_COURSE_REQUEST, workerCreateCartCourse);
}

/**
 * Function that handles the stripe payment confirm http request
 *
 * @param {Object} deconstructed object
 * @returns {void}
 */
function* workerProcessStripePaymentIntent({ token, cartId, transactionRef }) {
  try {
    const response = yield call(processStripePaymentIntent, { token, cartId, transactionRef });
    if (!response || !response.data || !response.data.data) {
      throw new Error('workerProcessStripePaymentIntent - Response Error');
    }

    yield put({
      type: PROCESS_STRIPE_PAYMENT_SUCCESS,
      data: response.data.data,
    });
  } catch (error) {
    const message = error.response && error.response.data ? error.response.data : null;
    yield put({ type: PROCESS_STRIPE_PAYMENT_FAILURE, ...message });
  }
}

// Exports
export function* watcherProcessStripePaymentIntent() {
  yield takeLatest(PROCESS_STRIPE_PAYMENT_REQUEST, workerProcessStripePaymentIntent);
}

/**
 * Function that handles the stripe payment create http request
 *
 * @param {Object} deconstructed object
 * @returns {void}
 */
function* workerCreateStripePaymentIntent({ token, cartId }) {
  try {
    const response = yield call(createStripePaymentIntent, { token, cartId });
    if (!response || !response.data || !response.data.data) {
      throw new Error('workerCreateStripePaymentIntent - Response Error');
    }

    yield put({
      type: CREATE_STRIPE_PAYMENT_SUCCESS,
      data: response.data.data,
    });
  } catch (error) {
    const message = error.response && error.response.data ? error.response.data : null;
    yield put({ type: CREATE_STRIPE_PAYMENT_FAILURE, ...message });
  }
}

// Exports
export function* watcherCreateStripePaymentIntent() {
  yield takeLatest(CREATE_STRIPE_PAYMENT_REQUEST, workerCreateStripePaymentIntent);
}

/**
 * Function that handles the amazon payment create http request
 *
 * @param {Object} deconstructed object
 * @returns {void}
 */
function* workerCreateAmazonPaymentIntent({ token, enrollmentId, cartId }) {
  try {
    const response = yield call(createAmazonPaymentIntent, { token, enrollmentId, cartId });
    if (!response || !response.data || !response.data.data) {
      throw new Error('workerCreateAmazonPaymentIntent - Response Error');
    }

    yield put({
      type: CREATE_AMAZON_PAYMENT_SUCCESS,
      data: response.data.data,
    });
  } catch (error) {
    const message = error.response && error.response.data ? error.response.data : null;
    yield put({ type: CREATE_AMAZON_PAYMENT_FAILURE, ...message });
  }
}

// Exports
export function* watcherCreateAmazonPaymentIntent() {
  yield takeLatest(CREATE_AMAZON_PAYMENT_REQUEST, workerCreateAmazonPaymentIntent);
}

/**
 * Function that handles the amazon payment confirm http request
 *
 * @param {Object} deconstructed object
 * @returns {void}
 */
function* workerProcessAmazonPaymentIntent({ token, amazonCheckoutSessionId, cartId, transactionRef }) {
  try {
    const response = yield call(processAmazonPaymentIntent, { token, amazonCheckoutSessionId, cartId, transactionRef });
    if (!response || !response.data || !response.data.data) {
      throw new Error('workerProcessAmazonPaymentIntent - Response Error');
    }

    yield put({
      type: PROCESS_AMAZON_PAYMENT_SUCCESS,
      data: response.data.data,
    });
  } catch (error) {
    const message = error.response && error.response.data ? error.response.data : null;
    yield put({ type: PROCESS_AMAZON_PAYMENT_FAILURE, ...message });
  }
}

// Exports
export function* watcherProcessAmazonPaymentIntent() {
  yield takeLatest(PROCESS_AMAZON_PAYMENT_REQUEST, workerProcessAmazonPaymentIntent);
}
