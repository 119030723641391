// Import
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import PasswordResetStyles from './styles';
import { Icon, Row, Col, Form, Input, Alert } from 'antd';
import { PasswordInput } from 'antd-password-input-strength';
import Constants from '../../utils/Constants';

// Main Component
/**
 * Main Component
 * @param {*} props any
 * @return {JSX.Element} JSX Element
 */
const PasswordReset = props => {
  // Props
  const { token, validator, data, form, handleSubmit } = props;
  const { getFieldDecorator } = form;

  // Functions
  const onSubmitForm = event => {
    event.preventDefault();
    form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        handleSubmit(values);
      }
    });
  };

  // Display of form fields
  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 24 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 24 },
    },
  };
  return (
    <PasswordResetStyles>
      <PasswordResetStyles.Card>
        <Row gutter={[0, 0]} style={{ width: '100%' }}>
          <Col span={24}>
            <div style={{ padding: 20 }}>
              {data ? <Alert message="Password was successfully changed. Redirecting to login now..." type="success" /> : <></>}
              <div style={{ textAlign: `center`, padding: '15px 0 30px 0' }}>
                <img style={{ width: 350 }} src={'/images/logos/' + Constants.WEBSITE_NAME + '/logo-on-white.png'} alt={'logo'} />
              </div>
              <h2 style={{ textAlign: 'center' }}>Password Reset</h2>
              <Form onSubmit={onSubmitForm}>
                <Form.Item {...formItemLayout}>
                  {getFieldDecorator('password', {
                    rules: [
                      {
                        required: true,
                        message: 'Password is required',
                      },
                    ],
                  })(<PasswordInput required={true} placeholder="Password" />)}
                </Form.Item>
                <Form.Item {...formItemLayout}>
                  {getFieldDecorator('password_confirmation', {
                    rules: [
                      {
                        required: true,
                        message: 'Password confirm is required',
                      },
                      {
                        validator(rule, value) {
                          if (!value || props.form.getFieldValue(`password`) === value) {
                            return Promise.resolve();
                          }
                          return Promise.reject('The two passwords that you entered do not match!');
                        },
                      },
                    ],
                  })(<PasswordInput required={true} placeholder="Password" />)}
                </Form.Item>
                <Form.Item style={{ marginBottom: 0 }}>
                  {getFieldDecorator('token', {
                    initialValue: token,
                    rules: [
                      {
                        required: true,
                      },
                    ],
                  })(<Input type="hidden" />)}
                </Form.Item>
                <Form.Item style={{ marginBottom: 0 }}>
                  {getFieldDecorator('validator', {
                    initialValue: validator,
                    rules: [
                      {
                        required: true,
                      },
                    ],
                  })(<Input type="hidden" />)}
                </Form.Item>

                <PasswordResetStyles.Errors style={{ height: '100%' }} show={props.errors && props.errors.length > 0} count={props.errors && props.errors.length}>
                  {props.errors && props.errors.map((val, key) => <PasswordResetStyles.Error key={key.toString()}>{val}</PasswordResetStyles.Error>)}
                </PasswordResetStyles.Errors>
                <Row gutter={[16, 16]}>
                  <Col span={24}>
                    <div style={{ textAlign: `center` }}>
                      <PasswordResetStyles.Button disabled={props.loading} htmlType={'submit'} block>
                        {props.loading ? '...' : 'Reset password'}
                      </PasswordResetStyles.Button>
                    </div>
                  </Col>
                </Row>
              </Form>
            </div>
          </Col>
        </Row>
      </PasswordResetStyles.Card>
    </PasswordResetStyles>
  );
};

// Property Types
PasswordReset.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  errors: PropTypes.array,
  data: PropTypes.object,
  form: PropTypes.object,
  token: PropTypes.string,
  validator: PropTypes.string,
};

// Export
export default Form.create({ name: 'passwordReset' })(PasswordReset);
