// Imports
import axios from 'axios';

// Config
const apiUri = window.API_URL;

// Main Actions
/**
 * @param {*} Object { token, type, feedback }
 * @returns {promise} axios promise
 */
export const doSendFeedback = ({ token, feedbackType, feedback, enrollmentId, quizAttemptId, scriptAttemptId, quizAttemptAnswerId, sectionId, position, image }) => {
  const location = document.location.href.split('?')[0];

  return axios({
    method: 'post',
    url: `${apiUri}/webcontacts`,
    data: {
      message_type: feedbackType,
      message_content: feedback,
      enrollment_id: enrollmentId,
      quiz_attempt_id: quizAttemptId,
      script_attempt_id: scriptAttemptId,
      submission_url: location,
      quiz_attempt_answer_id: quizAttemptAnswerId,
      section_id: sectionId,
      script_attempt_position: position,
      screenshot: image,
    },
    headers: { Authorization: `Bearer ${token}` },
  });
};
