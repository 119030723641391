// Types
import {
  CLEAR_QUIZ_ATTEMPT,
  MODIFY_QUIZ_ATTEMPT_QUIZ_ATTEMPT_ANSWER,
  CREATE_QUIZ_ATTEMPT_REQUEST,
  CREATE_QUIZ_ATTEMPT_SUCCESS,
  CREATE_QUIZ_ATTEMPT_FAILURE,
  UPDATE_QUIZ_ATTEMPT_REQUEST,
  UPDATE_QUIZ_ATTEMPT_SUCCESS,
  UPDATE_QUIZ_ATTEMPT_FAILURE,
  GET_QUIZ_ATTEMPT_REQUEST,
  GET_QUIZ_ATTEMPT_SUCCESS,
  GET_QUIZ_ATTEMPT_FAILURE,
  SET_CURRENT_QUIZ_ATTEMPT_ANSWER,
} from './types';

// Initial State
const initialState = {
  submitting: false,
  fetching: false,
  data: null,
  errors: null,
};

// Main Reducer
/**
 * quizAttemptReducer Reducer
 * @param {Object} state current state of redux
 * @param {Object} action action state object
 * @returns {state} Redux State
 */
const quizAttemptReducer = (state = initialState, action) => {
  switch (action.type) {
    case CLEAR_QUIZ_ATTEMPT:
      return initialState;
    case SET_CURRENT_QUIZ_ATTEMPT_ANSWER:
      return {
        ...state,
        current_quiz_attempt_answer: action.quizAttemptAnswer,
      };
    case MODIFY_QUIZ_ATTEMPT_QUIZ_ATTEMPT_ANSWER:
      const result =
        (state.data &&
          state.data.quiz_attempt_answers.map(i => {
            if (i.id === action.data.id && i.quiz_attempt_id === action.data.quiz_attempt_id) {
              i.question_option_id = action.data.question_option_id || null;
            }
            return i;
          })) ||
        null;
      // action.data
      return {
        ...state,
        data: {
          ...state.data,
          quiz_attempt_answers: result,
        },
      };
    case CREATE_QUIZ_ATTEMPT_REQUEST:
      return {
        ...state,
        submitting: true,
        errors: null,
      };
    case CREATE_QUIZ_ATTEMPT_SUCCESS:
      return {
        ...state,
        submitting: false,
        data: action.data,
      };
    case CREATE_QUIZ_ATTEMPT_FAILURE:
      return {
        ...state,
        submitting: false,
        errors: action.errors,
      };
    case GET_QUIZ_ATTEMPT_REQUEST:
      return {
        ...state,
        fetching: true,
        errors: null,
      };
    case GET_QUIZ_ATTEMPT_SUCCESS:
      return {
        ...state,
        fetching: false,
        data: action.data,
      };
    case GET_QUIZ_ATTEMPT_FAILURE:
      return {
        ...state,
        fetching: false,
        errors: action.data.error,
      };
    case UPDATE_QUIZ_ATTEMPT_REQUEST:
      return {
        ...state,
        submitting: true,
        errors: null,
      };
    case UPDATE_QUIZ_ATTEMPT_SUCCESS:
      return {
        ...state,
        submitting: false,
        data: action.data,
      };
    case UPDATE_QUIZ_ATTEMPT_FAILURE:
      return {
        ...state,
        submitting: false,
        errors: action.errors,
      };
    default:
      return state;
  }
};

export default quizAttemptReducer;
