// Imports
import axios from 'axios';

// Config
const apiUri = window.API_URL;

// Export actions
/**
 * Creates new quiz attempt
 * @param {*} param0 { token, enrollmentId, lmsQuizId }
 * @returns {AxiosPromise} object
 */
export const createQuizAttempt = ({ token, enrollmentId, lmsQuizId }) => {
  return axios({
    method: 'post',
    url: `${apiUri}/quizattempts`,
    data: { enrollment_id: enrollmentId, lms_quiz_id: lmsQuizId },
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

/**
 * Get existing quiz attempt
 * @param {*} param0 { token, quizAttemptId }
 * @returns {AxiosPromise} object
 */
export const getQuizAttempt = ({ token, quizAttemptId, enrollmentId = null }) => {
  return axios({
    method: 'get',
    url: `${apiUri}/quizattempts/${quizAttemptId}?with=lmsQuiz,quizAttemptAnswers.question,quizAttemptAnswers.question.correctQuestionOptions,quizAttemptAnswers.questionoption&section_review=true,${
      enrollmentId ? `&enrollment_id=${enrollmentId}` : ''
    }`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

/**
 * Update existing quiz attempt
 * @param {*} param0 { token, lmsQuizId, complete }
 * @returns {AxiosPromise} object
 */
export const updateQuizAttempt = ({ token, quizAttemptId, complete }) => {
  return axios({
    method: 'put',
    url: `${apiUri}/quizattempts/${quizAttemptId}`,
    data: { complete },
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};
