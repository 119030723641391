// IMPORTS
// --------------------------------------------------------
// Redux
import { put, takeLatest, call } from 'redux-saga/effects';

// Type
import { WATCHEDVIDEOS_SCRIPTATTEMPTS_REQUEST } from './types';

// Actions
import { doWatchedVideosScriptAttemptsFailure, doWatchedVideosScriptAttemptsSuccess } from './actions';

// Requests
import { watchedVideosScriptAttempts } from '../requests';

// MAIN SAGAS
// --------------------------------------------------------
/**
 * Worker for handling watchedVideosScriptAttempts
 * @return {void}
 */
function* workerWatchedVideosScriptsAttempts({ token, id, payload }) {
  try {
    const response = yield call(watchedVideosScriptAttempts, { token, id, payload });

    if (!response?.data?.success) {
      throw new Error('workerWatchedVideosScriptsAttempts - Response Error');
    }

    yield put(doWatchedVideosScriptAttemptsSuccess({ data: true }));
  } catch (error) {
    const errors = error?.message ?? 'Something went wrong';
    yield put(doWatchedVideosScriptAttemptsFailure({ errors }));
  }
}

/**
 * Watches for Dispatch WATCHEDVIDEOS_SCRIPTATTEMPTS_REQUEST
 * @return {void}
 */
export function* watcherWatchedVideosScriptsAttempts() {
  yield takeLatest(WATCHEDVIDEOS_SCRIPTATTEMPTS_REQUEST, workerWatchedVideosScriptsAttempts);
}
