// MAIN TYPES
// --------------------------------------------------------
/**
 * @constant
 */
export const SCRIPTATTEMPTS_LMSSCRIPTS_RESET = 'SCRIPTATTEMPTS_LMSSCRIPTS_RESET';

/**
 * @constant
 */
export const SCRIPTATTEMPTS_LMSSCRIPTS_REQUEST = 'SCRIPTATTEMPTS_LMSSCRIPTS_REQUEST';

/**
 * @constant
 */
export const SCRIPTATTEMPTS_LMSSCRIPTS_SUCCESS = 'SCRIPTATTEMPTS_LMSSCRIPTS_SUCCESS';

/**
 * @constant
 */
export const SCRIPTATTEMPTS_LMSSCRIPTS_FAILURE = 'SCRIPTATTEMPTS_LMSSCRIPTS_FAILURE';
