// Imports
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

// Presentations
import Dashboard from '../../components/Dashboard';

// Operations
import { doGetMe } from '../Login/operations';

// Main Component
/**
 * Main Container
 * @param {*} props any
 * @returns {JSX.Element} Main JSX Routes
 */
const DashboardContainer = props => {
  // State / Props
  const { user, fetching, token, doGetMe: getMe } = props;

  // Hooks
  useEffect(() => {
    getMe({ token });
  }, [token, getMe]);

  // Render
  return <Dashboard user={user} fetching={fetching} />;
};

// Map State / Dispatch To Props
/**
 * Main redux state
 * @param {*} state object
 * @returns {Object} state objects
 */
const mapStateToProps = state => ({
  token: state.loginReducer.token,
  user: state.loginReducer.user,
  fetching: state.loginReducer.fetching,
});

/**
 * Main redux dispatch functions
 * @param {*} dispatch redux dispatch
 * @returns {bindActionCreators} state objects
 */
const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      doGetMe,
    },
    dispatch,
  );

// Prop Types
DashboardContainer.propTypes = {
  user: PropTypes.object,
  fetching: PropTypes.bool,
  token: PropTypes.string,
  doGetMe: PropTypes.func,
};

// Exports
export default connect(mapStateToProps, mapDispatchToProps)(DashboardContainer);
