import React from 'react';
import { Row, Col, Icon } from 'antd';
import ConfirmationStyles from './styles';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

const Confirmation = props => {
  const { user, history, cartData, enrollmentId, lmsCourseId, cartId } = props;
  const handleRedirectBack = () => {
    let enrollments = user?.student?.enrollments;
    if (enrollments && enrollments?.length) {
      if (enrollmentId) {
        let enrollment = user?.student?.enrollments.find(item => parseInt(item.id) === parseInt(enrollmentId));
        if (enrollment) {
          let lmsCourseId = enrollment?.lms_course_id;
          let scriptAttempts = enrollment?.script_attempts;
          let scriptAttempt = scriptAttempts[0];
          let lmsScriptId = scriptAttempt?.lms_script_id;
          let scriptAttemptId = scriptAttempt?.id;
          history.push(`/dashboard/enrollments/${enrollmentId}/courses/${lmsCourseId}/lmslessons/${lmsScriptId}/attempts/${scriptAttemptId}`);
        }
      } else if (lmsCourseId) {
        let enrollment = user?.student?.enrollments.find(item => parseInt(item.lms_course_id) === parseInt(lmsCourseId) && item?.script_attempts?.length >= 1);
        if (enrollment) {
          let enrollmentId = enrollment?.id;
          let lmsCourseId = enrollment?.lms_course_id;
          let scriptAttempts = enrollment?.script_attempts;
          let scriptAttempt = scriptAttempts[0];
          let lmsScriptId = scriptAttempt?.lms_script_id;
          let scriptAttemptId = scriptAttempt?.id;
          history.push(`/dashboard/enrollments/${enrollmentId}/courses/${lmsCourseId}/lmslessons/${lmsScriptId}/attempts/${scriptAttemptId}`);
        }
      } else if (cartId) {
        let lmsCourseId = cartData?.data?.cart_items[0]?.cart_course?.lms_course[0]?.id;
        let enrollment = user?.student?.enrollments.find(item => parseInt(item.lms_course_id) === parseInt(lmsCourseId) && item?.script_attempts?.length >= 1);
        if (enrollment) {
          let enrollmentId = enrollment?.id;
          let lmsCourseId = enrollment?.lms_course_id;
          let scriptAttempts = enrollment?.script_attempts;
          let scriptAttempt = scriptAttempts[0];
          let lmsScriptId = scriptAttempt?.lms_script_id;
          let scriptAttemptId = scriptAttempt?.id;
          history.push(`/dashboard/enrollments/${enrollmentId}/courses/${lmsCourseId}/lmslessons/${lmsScriptId}/attempts/${scriptAttemptId}`);
        }
      }
    }
  };
  return (
    <ConfirmationStyles>
      <div style={{ textAlign: 'center' }}>
        <Row>
          <Col span={24}>
            <Icon type="check-circle" style={{ fontSize: '5em', color: 'green' }} />
          </Col>
          <Col span={24}>
            <legend style={{ fontSize: 32, marginBottom: 10 }}>Thank you for your purchase.</legend>
          </Col>
          <Col span={24}>
            <div style={{ textAlign: 'center' }}>
              <ConfirmationStyles.Button type="primary" onClick={handleRedirectBack}>
                Continue Course
              </ConfirmationStyles.Button>
            </div>
          </Col>
        </Row>
      </div>
    </ConfirmationStyles>
  );
};

// PROPS TYPES
// --------------------------------------------------------
Confirmation.propTypes = {
  enrollmentId: PropTypes.string,
  lmsCourseId: PropTypes.string,
  cartId: PropTypes.string,
  user: PropTypes.object,
  cartData: PropTypes.object,
  history: PropTypes.object,
};

export default withRouter(Confirmation);
