// IMPORTS
// --------------------------------------------------------
import styled from 'styled-components';

// MAIN STYLED COMPONENTS
// --------------------------------------------------------
/**
 * Parent styles for component
 * @constant
 */

const ProcessorStyles = styled.div`
  button:disabled,
  button[disabled] {
    border: 1px solid #999999;
    background-color: #cccccc;
    color: #666666;
  }
  .ant-steps-vertical .ant-steps-item-content {
    display: block;
    min-height: 100px;
    overflow: hidden;
  }
  @media only screen and (max-width: 600px) {
    .ant-steps-vertical .ant-steps-item-content {
      display: block;
      min-height: 48px;
      overflow: hidden;
    }
    .payment-processor-steps {
      display: none;
    }
  }
  @media only screen and (min-width: 600px) {
    .payment-processor-steps {
      display: none;
    }
  }
  @media only screen and (min-width: 768px) {
    .payment-processor-steps {
      display: none;
    }
  }
  @media only screen and (min-width: 992px) {
    .payment-processor-steps {
      display: block;
    }
  }
  .ant-steps-item-finish {
    cursor: pointer;
  }
`;
ProcessorStyles.Button = styled.button`
  margin: auto;
  width: 329px;
  height: 61px;
  background-color: #ff8a00;
  font-size: 24px;
  text-transform: uppercase;
  color: #ffffff;
  cursor: pointer;
  border: 0;
  :hover {
    background-color: #0098e4;
    color: #fff;
    box-shadow: 0px 0px 9px 0px rgb(47 143 205 / 25%);
  }
`;

export default ProcessorStyles;
