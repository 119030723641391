// Import
import React, { useState, useRef, useEffect } from 'react';
import ReactJWPlayer from 'react-jw-player';
import YouTube from 'react-youtube';
import PropTypes from 'prop-types';
import VideoStyles from './styles';
import { PageHeader, Button, Icon } from 'antd';

// Needed because of a bug
// useState is not accessible in the onSeek function

// Main Component
/**
 * Main Component
 * @param {*} props any
 * @return {JSX.Element} JSX Element
 */
let completedTime = 0;

const Videos = props => {
  // State / Props
  const { title, watched, position, videoUrl, captionUrl, handleVideoWatched, handleVideoUpdatePosition, handleSetUseTranscript, handleSetVideoFallback, token } = props;
  const playerUrl = window.JW_PLAYER_URL;
  const [sent, setSent] = useState(false); // to avoid duplication of sending position
  const [youtubeEl, setYoutubeEl] = useState(null);
  const [volume, setVolume] = useState(100);
  const youtubeTimer = useRef(false);

  // Functions
  /**
   * Ticker for video progression
   * @param {*} event JWPlayer Event
   * @returns {void}
   */
  const onTime = event => {
    // if the current time is greater than the completed time update current time
    // Update completedTime
    if (event.position > completedTime + 0.5) {
      completedTime = event.position;
    }
    const pos = parseInt(event.position);
    if (pos % 10 === 0 && !sent) {
      handleVideoUpdatePosition(pos);
      setSent(true);
    } else if (pos % 10 > 0 && sent) {
      setSent(false);
    }
  };

  const turnVolumeDown = event => {
    if (youtubeEl && volume >= 5) {
      setVolume(volume - 5);
      youtubeEl.setVolume(youtubeEl.getVolume() - 5);
    }
  };

  const turnVolumeUp = event => {
    if (youtubeEl && volume <= 95) {
      setVolume(volume + 5);
      youtubeEl.setVolume(youtubeEl.getVolume() + 5);
    }
  };

  const onYoutubePlay = event => {
    var youtube = event.target;
    setVolume(youtube.getVolume());
    setYoutubeEl(youtube);
    youtube.setOption('captions', 'track', { languageCode: 'en' });
    clearYoutubeTimer();
    youtubeTimer.current = setInterval(() => {
      if (youtubeStatusIsStopped(youtube.getPlayerState())) {
        clearYoutubeTimer();
      } else {
        let currentTime = Math.floor(youtube.getCurrentTime());
        handleVideoUpdatePosition(Math.floor(currentTime));
      }
    }, 5000);
  };

  const clearYoutubeTimer = () => {
    if (youtubeTimer.current) {
      clearTimeout(youtubeTimer.current);
      youtubeTimer.current = null;
    }
  };

  /**
   * When the video is paused, save their position
   * @param {*} event JWPlayer Event
   * @returns {void}
   */
  const onPause = event => {
    const pos = parseInt(window.jwplayer().getPosition(), 0);
    handleVideoUpdatePosition(pos);
  };

  const onYoutubePause = event => {
    clearYoutubeTimer();
    const pos = Math.floor(event.target.getCurrentTime());
    handleVideoUpdatePosition(pos);
  };

  /**
   * When completed the video perform this function
   * @param {*} event JWPlayer Event
   * @return {void}
   */
  const onComplete = event => {
    window.jwplayer().setFullscreen(false);
    handleVideoWatched();
  };

  const onYoutubeEnd = event => {
    clearYoutubeTimer();
    handleVideoWatched();
  };

  /**
   * When video stars, skips to x seconds ahead with 'position'
   * @param {*} event JWPlayer Event
   * @return {void}
   */
  const onFirstFrame = event => {
    completedTime = position || 0;
    window.jwplayer().seek(position || 0);
  };

  /**
   * The equivalent to on('timeupdate') whcih tracks when the user skips
   * @param {*} event JWPlayer Event / Object
   * @returns {void}
   */
  const onSeek = event => {
    // Prevent skipping ahead / UNLESSS they have already watchd it
    if (event.offset > completedTime && !watched) {
      window.jwplayer().seek(completedTime);
    }
  };

  /**
   *
   * @param {*} event JWPlayer Event / Object
   * @return {void}
   */
  const onError = event => {
    handleSetUseTranscript();
  };

  const onSetupError = event => {
    handleSetUseTranscript();
  };

  useEffect(() => {
    return () => {
      clearYoutubeTimer();
    };
  }, []);

  /**
   *
   * @param {*} event YouTube Event / Object
   * @return {void}
   */
  const onYoutubeError = event => {
    clearYoutubeTimer();
    handleSetVideoFallback();
  };

  const getYoutubeVideoId = url => {
    let youtubeRegex = /^.*(?:(?:youtu\.be\/|v\/|vi\/|u\/\w\/|embed\/)|(?:(?:watch)?\?v(?:i)?=|\&v(?:i)?=))([^#\&\?]*).*/;
    let matches = url.match(youtubeRegex);
    return matches[1];
  };

  const isYoutubeLink = url => {
    return url && (url.includes('youtube.com') || url.includes('youtu.be') || url.includes('youtube-nocookie.com'));
  };

  const youtubeStatusIsStopped = status => {
    return status === -1 || status === 0 || status === 2;
  };

  const playlist = [
    {
      file: videoUrl,
      type: 'mp4',
      tracks: [
        captionUrl
          ? {
              file: captionUrl,
              type: 'vtt',
              label: 'English',
              kind: 'captions',
              default: true,
            }
          : {},
      ],
    },
  ];

  // Render
  return (
    <VideoStyles>
      {/* <Card>
        <PageHeader title={title ?? ''} /> */}
      <PageHeader title={title ?? ''} />

      {isYoutubeLink(videoUrl) ? (
        <div>
          <YouTube
            id="lesson-video-player"
            videoId={getYoutubeVideoId(videoUrl)}
            onPlay={onYoutubePlay}
            onPause={onYoutubePause}
            onEnd={onYoutubeEnd}
            onError={onYoutubeError}
            start={position}
            showCaptions={true}
            opts={{
              playerVars: { disablekb: 0, controls: 0, cc_load_policy: 1, cc_lang_pref: 'en', modestbranding: 1, rel: 0, showinfo: 0, start: position, autoplay: 1 },
            }}
          />
          <div style={{ textAlign: `center` }}>
            <span style={{ fontSize: 25, position: 'relative', top: 3 }}>Volume:</span>
            <Button type={`primary`} style={{ marginLeft: 5 }} disabled={!youtubeEl || volume <= 0} onClick={turnVolumeDown} ghost={`true`}>
              <Icon type="minus" />
            </Button>
            <Button type={`primary`} style={{ marginLeft: 5 }} disabled={!youtubeEl || volume >= 100} onClick={turnVolumeUp} ghost={`true`}>
              <Icon type="plus" />
            </Button>
            <span style={{ fontSize: 25, position: 'relative', top: 3, marginLeft: 5 }}>{youtubeEl ? `(${volume})` : ``}</span>
          </div>
        </div>
      ) : (
        <ReactJWPlayer
          completedTime={completedTime}
          onFirstFrame={onFirstFrame}
          onTime={onTime.bind(completedTime)}
          onSeek={onSeek.bind(completedTime)}
          onPause={onPause}
          onOneHundredPercent={onComplete}
          onError={onError}
          onSetupError={onError}
          playerId="lesson-video-player"
          playerScript={playerUrl}
          playlist={playlist}
        />
      )}
      {/* )} */}
      {/* </Card> */}
    </VideoStyles>
  );
};

// Property Types
Videos.propTypes = {
  title: PropTypes.string,
  watched: PropTypes.bool,
  position: PropTypes.number,
  videoUrl: PropTypes.any,
  captionUrl: PropTypes.any,
  handleVideoWatched: PropTypes.func,
  handleVideoUpdatePosition: PropTypes.func,
  handleSetUseTranscript: PropTypes.func,
  handleSetVideoFallback: PropTypes.func,
  token: PropTypes.string,
};

// Export
export default Videos;
