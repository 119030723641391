// Imports
import axios from 'axios';

// Config
const apiUri = window.API_URL;

// Main Actions
/**
 * @param {*} Object { token, courseId, websiteId, email }
 * @returns {promise} axios promise
 */
export const doSendReferral = ({ token, courseId, websiteId, email }) => {
  return axios({
    method: 'post',
    url: `${apiUri}/referrals`,
    data: { lms_course_id: courseId, website_id: websiteId, recipient: email },
    headers: { Authorization: `Bearer ${token}` },
  });
};
