import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import StripeFormStyles from './styles';
import PurchaseStyles from '../../../../../styles';
import { Row, Col, Icon } from 'antd';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Bugsnag from '@bugsnag/js';

const StripeForm = props => {
  const { token, handleStudentInformationStep, resetStripeData, processStripePaymentIntent, setStripeStatus, cartData, stripeData, getMe } = props;
  const [disabled, setDisabled] = useState(true);
  const stripe = useStripe();
  const elements = useElements();
  const [confirmationRequest, setConfirmationRequest] = useState(false);

  const cardStyle = {
    style: {
      base: {
        color: '#32325d',
        fontFamily: 'Arial, sans-serif',
        fontSmoothing: 'antialiased',
        fontSize: '16px',
        '::placeholder': {
          color: '#32325d',
        },
      },
      invalid: {
        color: '#fa755a',
        iconColor: '#fa755a',
      },
    },
  };

  const handleStripeSubmit = async ev => {
    ev.preventDefault();
    setStripeStatus({ error: null, fetching: true });
    const payload = await stripe.confirmCardPayment(stripeData?.clientSecret, {
      payment_method: {
        card: elements.getElement(CardElement),
      },
    });
    if (payload.error) {
      setStripeStatus({ fetching: false, error: sanitizeStripeError(payload.error) });
    } else {
      let cartId = cartData?.data?.id;
      let transactionRef = stripeData?.data?.transaction_ref;
      resetStripeData();
      processStripePaymentIntent({ token, cartId, transactionRef });
      setConfirmationRequest(true);
    }
  };

  const sanitizeStripeError = error => {
    if (error.payment_method) {
      delete error.payment_method;
    }
    if (error.payment_intent) {
      delete error.payment_intent.client_secret;
      if (error.payment_intent.last_payment_intent?.payment_method) {
        delete error.payment_intent.last_payment_intent.payment_method;
      }
    }
    return error;
  };

  useEffect(() => {
    if (token && confirmationRequest && !stripeData?.fetching && stripeData?.data) {
      setConfirmationRequest(false);
      handleStudentInformationStep();
      getMe({ token });
    }
    if (token && confirmationRequest && !stripeData?.fetching && stripeData?.errors) {
      setConfirmationRequest(false);
    }
  }, [token, stripeData, confirmationRequest, getMe, handleStudentInformationStep]);

  const handleChange = async event => {
    // Listen for changes in the CardElement
    // and display any errors as the customer types their card details
    setDisabled(event.empty);
  };

  return (
    <StripeFormStyles>
      <form onSubmit={handleStripeSubmit}>
        <Row>
          <Col span={24}>
            <div style={{ padding: 15 }}>
              <CardElement options={cardStyle} onChange={handleChange} />
            </div>
          </Col>
          <Col span={24}>
            <div style={{ textAlign: 'center' }}>
              <StripeFormStyles.Button disabled={stripeData.fetching || disabled} id="submit">
                <span id="button-text">{stripeData.fetching ? <Icon type="loading" /> : 'Pay now'}</span>
              </StripeFormStyles.Button>
            </div>
          </Col>
        </Row>
      </form>
    </StripeFormStyles>
  );
};
// PROPS TYPES
// --------------------------------------------------------
StripeForm.propTypes = {
  stripePromise: PropTypes.string,
  token: PropTypes.string,
  error: PropTypes.string,
  disabled: PropTypes.bool,
  succeeded: PropTypes.bool,
  processing: PropTypes.bool,
  cartData: PropTypes.object,
  stripeData: PropTypes.object,
  cardStyle: PropTypes.object,
  handleChange: PropTypes.func,
  resetStripeData: PropTypes.func,
  getMe: PropTypes.func,
  createStripePaymentIntent: PropTypes.func,
  processStripePaymentIntent: PropTypes.func,
  handleConfirmationStep: PropTypes.func,
  handleStudentInformationStep: PropTypes.func,
  setStripeStatus: PropTypes.func,
};
export default StripeForm;
