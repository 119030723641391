// IMPORTS
// --------------------------------------------------------
// Redux
import { put, takeLatest, call } from 'redux-saga/effects';

// Type
import { UPDATE_SCRIPTATTEMPTS_FAILURE, UPDATE_SCRIPTATTEMPTS_REQUEST } from './types';

// Actions
import { doUpdateScriptAttemptsFailure, doUpdateScriptAttemptsSuccess } from './actions';
import { doSetCurrentPosition } from '../read/actions';

// Requests
import { updateScriptAttempts } from '../requests';
import { READ_SCRIPTATTEMPTS_FAILURE } from '../read/types';

// MAIN SAGAS
// --------------------------------------------------------
/**
 * Worker for handling updateScriptAttempts
 * @return {void}
 */
function* workerUpdateScriptAttempts({ token, id, payload }) {
  try {
    const response = yield call(updateScriptAttempts, { token, id, payload });

    if (!response?.data?.data) {
      throw new Error('workerUpdateScriptAttempts - Response Error');
    }

    const { data } = response.data;

    yield put(doUpdateScriptAttemptsSuccess({ data }));
    yield put(doSetCurrentPosition({ data }));
  } catch (error) {
    yield put({ type: UPDATE_SCRIPTATTEMPTS_FAILURE, ...error?.response });
  }
}

/**
 * Watches for Dispatch UPDATE_SCRIPTATTEMPTS_REQUEST
 * @return {void}
 */
export function* watcherUpdateScriptAttempts() {
  yield takeLatest(UPDATE_SCRIPTATTEMPTS_REQUEST, workerUpdateScriptAttempts);
}
