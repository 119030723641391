// Imports
import axios from 'axios';

// Config
const apiUri = window.API_URL;

// Export actions
export const getReportingQuestions = ({ token, courseId }) => {
  return axios({
    method: 'get',
    url: `${apiUri}/lmscourses/${courseId}/reportingquestions`,
    headers: { Authorization: `Bearer ${token}` },
  });
};

export const getReportingAnswers = ({ token, enrollmentId }) => {
  return axios({
    method: 'get',
    url: `${apiUri}/enrollments/${enrollmentId}/reportinganswers?with=enrollment.reportingQueue`,
    headers: { Authorization: `Bearer ${token}` },
  });
};

export const postReportingAnswers = ({ token, enrollmentId, answerData, licenseTypeId }) => {
  return axios({
    method: 'post',
    url: `${apiUri}/reportinganswers`,
    data: { enrollment_id: enrollmentId, reporting_answers: answerData, license_type_id: licenseTypeId },
    headers: { Authorization: `Bearer ${token}` },
  });
};
