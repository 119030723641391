// Imports
import { takeLatest, put, call, select } from 'redux-saga/effects';
import { push } from 'connected-react-router';

// Types
import {
  CREATE_QUIZ_ATTEMPT_REQUEST,
  CREATE_QUIZ_ATTEMPT_SUCCESS,
  CREATE_QUIZ_ATTEMPT_FAILURE,
  UPDATE_QUIZ_ATTEMPT_REQUEST,
  UPDATE_QUIZ_ATTEMPT_SUCCESS,
  UPDATE_QUIZ_ATTEMPT_FAILURE,
  GET_QUIZ_ATTEMPT_REQUEST,
  GET_QUIZ_ATTEMPT_SUCCESS,
  GET_QUIZ_ATTEMPT_FAILURE,
} from './types';

// Actions
import { getQuizAttempt, createQuizAttempt, updateQuizAttempt } from './actions';

// Main Sagas
/**
 *
 * @param {*} param0 Authentication token
 * @returns {void}
 */
function* workerGetQuizAttempt({ token, quizAttemptId, enrollmentId }) {
  try {
    const response = yield call(getQuizAttempt, { token, quizAttemptId, enrollmentId });

    // Catch For No Response
    if (!response?.data?.data) {
      throw new Error('workerGetQuizAttempt - Response Error');
    }

    const { data } = response.data;

    yield put({
      type: GET_QUIZ_ATTEMPT_SUCCESS,
      data,
    });
  } catch (error) {
    yield put({ type: GET_QUIZ_ATTEMPT_FAILURE, ...error.response });
  }
}

/**
 * Watches for request
 * @return {void}
 */
export function* watcherGetQuizAttempt() {
  yield takeLatest(GET_QUIZ_ATTEMPT_REQUEST, workerGetQuizAttempt);
}

/**
 *
 * @param {*} param0 Authentication token
 * @returns {void}
 */
function* workerCreateQuizAttempt({ token, lmsQuizId, enrollmentId }) {
  try {
    const response = yield call(createQuizAttempt, { token, lmsQuizId, enrollmentId });

    // Catch For No Response
    if (!response?.data?.data) {
      throw new Error('workerCreateQuizAttempt - Response Error');
    }

    yield put({
      type: CREATE_QUIZ_ATTEMPT_SUCCESS,
      data: response.data.data,
    });

    yield put(push(`${window.location.pathname}/attempts/${response?.data?.data?.id}`));
  } catch (error) {
    const message = error?.message ?? 'Something went wrong';
    yield put({ type: CREATE_QUIZ_ATTEMPT_FAILURE, errors: message });
  }
}

/**
 * Watches for request
 * @return {void}
 */
export function* watcherCreateQuizAttempt() {
  yield takeLatest(CREATE_QUIZ_ATTEMPT_REQUEST, workerCreateQuizAttempt);
}

/**
 *
 * @param {*} param0 Authentication token
 * @returns {void}
 */
function* workerUpdateQuizAttempt({ token, quizAttemptId, complete }) {
  try {
    const response = yield call(updateQuizAttempt, { token, quizAttemptId, complete });

    // Catch For No Response
    if (!response?.data?.data) {
      throw new Error('workerUpdateQuizAttempt - Response Error');
    }

    yield put({
      type: UPDATE_QUIZ_ATTEMPT_SUCCESS,
      data: response.data.data,
    });

    const state = yield select();
    yield put(push(`${state.router.location.pathname}/results`));
  } catch (error) {
    const message = error?.message ?? 'Something went wrong';
    yield put({ type: UPDATE_QUIZ_ATTEMPT_FAILURE, errors: message });
  }
}

/**
 * Watches for request
 * @return {void}
 */
export function* watcherUpdateQuizAttempt() {
  yield takeLatest(UPDATE_QUIZ_ATTEMPT_REQUEST, workerUpdateQuizAttempt);
}
