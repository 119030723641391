// Imports
import React, { useEffect, useState, createRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { message, Form } from 'antd';
import html2canvas from 'html2canvas';

// Operations
import { doSendFeedback, doClearFeedback } from './operations';

// Redux

// Utils

// Presentation Components
import Feedback from '../../components/Feedback';

// Main Component
/**
 * Feedback Container
 * @param {*} props any
 * @returns {JSX.Element} Main JSX Routes
 */
const FeedbackContainer = props => {
  // State / Props
  const { form, token, match, doSendFeedback: sendFeedback, doClearFeedback: clearFeedback, feedback, current_section_id, current_position, quizAttemptAnswer } = props;

  const { enrollmentId, quizAttemptId, scriptAttemptId } = match?.params || {};

  const [showFeedback, setShowFeedback] = useState(false);

  /**
   * When 'Feedback' form is submitted
   * @param {*} Object { type, feedback }
   * @returns {void}
   */
  const handleSendFeedback = ({ feedbackType, feedback }) => {
    let sectionId = null;
    let position = null;
    let quizAttemptAnswerId = null;
    if (scriptAttemptId) {
      sectionId = current_section_id;
      position = current_position;
    } else if (quizAttemptId) {
      quizAttemptAnswerId = quizAttemptAnswer.id;
    }

    html2canvas(document.body).then(function(canvas) {
      var image = canvas.toDataURL('image/jpeg');
      sendFeedback({ token, feedbackType, feedback, enrollmentId, quizAttemptId, scriptAttemptId, quizAttemptAnswerId, sectionId, position, image });
    });
  };

  /**
   * Clears the status and error messages from the feedback form
   * @returns {void}
   */
  const handleClearFeedback = () => {
    clearFeedback();
  };

  // Hooks
  /**
   * Feedback success
   */
  useEffect(() => {
    if (feedback?.success && feedback?.alertUser) {
      message.success('Feedback received!');
    }
  }, [feedback]);

  return <Feedback form={form} handleSendFeedback={handleSendFeedback} handleClearFeedback={handleClearFeedback} feedback={feedback} />;
};

// Property Types
FeedbackContainer.propTypes = {
  form: PropTypes.object,
  doSendFeedback: PropTypes.func,
  doClearFeedback: PropTypes.func,
  token: PropTypes.string,
  feedback: PropTypes.object,
  match: PropTypes.object,
  current_section_id: PropTypes.string,
  current_position: PropTypes.number,
  quizAttemptAnswer: PropTypes.object,
};

// Mapping State / Dispatch To Props
const mapStateToProps = state => ({
  token: state.loginReducer.token,
  feedback: state.feedbackReducer.feedback,
  current_section_id: state.ReadScriptAttemptsReducer.current_section_id,
  current_position: state.ReadScriptAttemptsReducer.current_position,
  quizAttemptAnswer: state.quizAttemptReducer.current_quiz_attempt_answer,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      doSendFeedback,
      doClearFeedback,
    },
    dispatch,
  );

// Export
export default connect(mapStateToProps, mapDispatchToProps)(Form.create({ name: 'feedback' })(FeedbackContainer));
