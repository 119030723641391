// Imports
import { takeLatest, put, call } from 'redux-saga/effects';

// Types
import {
  GET_LESSON_TRACKER_REQUEST,
  GET_LESSON_TRACKER_SUCCESS,
  GET_LESSON_TRACKER_FAILURE,
  GET_SCRIPT_ATTEMPTS_REQUEST,
  GET_SCRIPT_ATTEMPTS_SUCCESS,
  GET_SCRIPT_ATTEMPTS_FAILURE,
  CREATE_SCRIPT_ATTEMPTS_REQUEST,
  CREATE_SCRIPT_ATTEMPTS_SUCCESS,
  CREATE_SCRIPT_ATTEMPTS_FAILURE,
  UPDATE_SCRIPT_ATTEMPTS_REQUEST,
  UPDATE_SCRIPT_ATTEMPTS_SUCCESS,
  UPDATE_SCRIPT_ATTEMPTS_FAILURE,
  CREATE_SCRIPT_ATTEMPT_WATCHED_REQUEST,
  CREATE_SCRIPT_ATTEMPT_WATCHED_SUCCESS,
  CREATE_SCRIPT_ATTEMPT_WATCHED_FAILURE,
} from './types';

// Actions
import { getLessonTracker, getScriptAttempts, createScriptAttempts, updateScriptAttempts, createScriptAttemptWatchedVideo } from './actions';

function* workerGetLessonTracker({ token, lmsScriptId, enrollmentId }) {
  try {
    const response = yield call(getLessonTracker, { token, lmsScriptId, enrollmentId });

    yield put({
      type: GET_LESSON_TRACKER_SUCCESS,
      response,
    });
  } catch (error) {
    const message = error?.response?.data?.error || error?.message || 'Something went wrong';
    yield put({ type: GET_LESSON_TRACKER_FAILURE, errors: message });
  }
}

export function* watcherGetLessonTracker() {
  yield takeLatest(GET_LESSON_TRACKER_REQUEST, workerGetLessonTracker);
}

function* workerGetScriptAttempts({ token, lmsScriptId, enrollmentId }) {
  try {
    const response = yield call(getScriptAttempts, { token, lmsScriptId, enrollmentId });

    yield put({
      type: GET_SCRIPT_ATTEMPTS_SUCCESS,
      response,
    });
  } catch (error) {
    const message = error?.response?.data?.error || error?.message || 'Something went wrong';
    yield put({ type: GET_SCRIPT_ATTEMPTS_FAILURE, errors: message });
  }
}

export function* watcherGetScriptAttempts() {
  yield takeLatest(GET_SCRIPT_ATTEMPTS_REQUEST, workerGetScriptAttempts);
}

function* workerCreateScriptAttempts({ token, lmsScriptId, enrollmentId, sectionId }) {
  try {
    const response = yield call(createScriptAttempts, { token, lmsScriptId, enrollmentId, sectionId });

    yield put({
      type: CREATE_SCRIPT_ATTEMPTS_SUCCESS,
      response,
    });
  } catch (error) {
    yield put({ type: CREATE_SCRIPT_ATTEMPTS_FAILURE, ...error.response });
  }
}

export function* watcherCreateScriptAttempts() {
  yield takeLatest(CREATE_SCRIPT_ATTEMPTS_REQUEST, workerCreateScriptAttempts);
}

function* workerUpdateScriptAttempts({ token, scriptAttemptsId, complete, position, sectionId }) {
  try {
    const response = yield call(updateScriptAttempts, { token, scriptAttemptsId, complete, position, sectionId });

    yield put({
      type: UPDATE_SCRIPT_ATTEMPTS_SUCCESS,
      response,
    });
  } catch (error) {
    yield put({ type: UPDATE_SCRIPT_ATTEMPTS_FAILURE, ...error.response });
  }
}

export function* watcherUpdateScriptAttempts() {
  yield takeLatest(UPDATE_SCRIPT_ATTEMPTS_REQUEST, workerUpdateScriptAttempts);
}

function* workerCreateScriptAttemptWatchedVideo({ token, scriptAttemptId, sectionId }) {
  try {
    const response = yield call(createScriptAttemptWatchedVideo, { token, scriptAttemptId, sectionId });

    yield put({
      type: CREATE_SCRIPT_ATTEMPT_WATCHED_SUCCESS,
      response,
    });
  } catch (error) {
    yield put({ type: CREATE_SCRIPT_ATTEMPT_WATCHED_FAILURE, ...error.response });
  }
}

export function* watcherCreateScriptAttemptWatchedVideo() {
  yield takeLatest(CREATE_SCRIPT_ATTEMPT_WATCHED_REQUEST, workerCreateScriptAttemptWatchedVideo);
}
