// Imports
import styled from 'styled-components';
import Screens from '../../styles/Screens';

// Main
/**
 * Main container style
 */
const QuizAttemptStyles = styled.div`
  padding: 20px;
`;

QuizAttemptStyles.Controls = styled.div`
  display: flex;
  justify-content: space-between;
  button {
    margin-right: 10px;
  }
`;

// Export
export default QuizAttemptStyles;
