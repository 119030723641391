// Imports
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

// Presentation Components
import CourseComplete from '../../components/CourseComplete';

// Main Component
/**
 * Main Component
 * @param {*} props any
 * @return {JSX.Element} JSX Element
 */
const CourseCompleteContainer = props => {
  // State / Props
  const { data } = props;

  const [questionAttempts, setQuestionAttempts] = useState(null);
  // Hooks

  /**
   * Create a usable array of questions and attempts
   */
  useEffect(() => {
    if (data?.script_attempt_answers) {
      const scriptAttemptAnswers = data?.script_attempt_answers;
      const questionAttemptsArray = [];
      for (let i = 0; i < scriptAttemptAnswers.length; i = i + 1) {
        const questionAttempt = scriptAttemptAnswers[i];
        const thisQuestionIndex = questionAttemptsArray.findIndex(question => question.question_id === questionAttempt.question_id);

        if (thisQuestionIndex === -1) {
          questionAttemptsArray.push({ question_id: questionAttempt.question_id, questionAttempts: [questionAttempt.questionoption] });
        } else {
          questionAttemptsArray[thisQuestionIndex].questionAttempts.push(questionAttempt.questionoption);
        }
      }
      setQuestionAttempts(questionAttemptsArray);
    }
  }, [setQuestionAttempts, data]);

  // Render
  return <CourseComplete {...props} data={data} questionAttempts={questionAttempts} />;
};

// Property Types
CourseCompleteContainer.propTypes = {
  data: PropTypes.object,
  token: PropTypes.string,
};

// Exports
export default CourseCompleteContainer;
