// Imports
import { createGlobalStyle } from 'styled-components';
import Colors from './Colors';
import 'typeface-lato';

// Main Component
const GlobalStyles = createGlobalStyle`
  html {
    width: 100%;
    height: 100%;
  }
  
  body {
    font-family: 'Lato', Arial, sans-serif; 
    width: 100%;
    height: 100%;
    background: ${Colors.grey};
    overflow-x: hidden;
  }
  
  #root {
    width: 100%;
    height: 100%;
  }

  a {
    text-decoration: none;
  }

  button {
    border: none;
    background: green;
    border-radius: 2px;
    color: white;
    padding: 10px;
  }

  .ant-page-header {
    padding: 0 0 15px 0;
    margin-bottom: 20px;
    border-bottom: 1px solid #e8e8e8;
    background: none;
  }

  h1, h2, h3, h4, h5, h6 {
    font-weight: 500;
  }
  
  .swal2-confirm {
      margin: auto;
    width: 100%;
    max-width: 300px;
    height: 61px;
    background-color: #ff8a00;
    font-size: 24px;
    text-transform: uppercase;
    color: #ffffff;
    cursor: pointer;
    border: 0;
  }
  .swal2-styled.swal2-confirm {
      margin: auto;
    width: 100%;
    max-width: 300px;
    height: 61px;
    background-color: #ff8a00;
    font-size: 24px;
    text-transform: uppercase;
    color: #ffffff;
    cursor: pointer;
    border: 0;
}
`;

// Export
export default GlobalStyles;
