// Types
export const CLEAR_QUIZ_ATTEMPT = 'CLEAR_QUIZ_ATTEMPT';
export const MODIFY_QUIZ_ATTEMPT_QUIZ_ATTEMPT_ANSWER = 'MODIFY_QUIZ_ATTEMPT_QUIZ_ATTEMPT_ANSWER';

export const CREATE_QUIZ_ATTEMPT_REQUEST = 'CREATE_QUIZ_ATTEMPT_REQUEST';
export const CREATE_QUIZ_ATTEMPT_SUCCESS = 'CREATE_QUIZ_ATTEMPT_SUCCESS';
export const CREATE_QUIZ_ATTEMPT_FAILURE = 'CREATE_QUIZ_ATTEMPT_FAILURE';

export const GET_QUIZ_ATTEMPT_REQUEST = 'GET_QUIZ_ATTEMPT_REQUEST';
export const GET_QUIZ_ATTEMPT_SUCCESS = 'GET_QUIZ_ATTEMPT_SUCCESS';
export const GET_QUIZ_ATTEMPT_FAILURE = 'GET_QUIZ_ATTEMPT_FAILURE';

export const UPDATE_QUIZ_ATTEMPT_REQUEST = 'UPDATE_QUIZ_ATTEMPT_REQUEST';
export const UPDATE_QUIZ_ATTEMPT_SUCCESS = 'UPDATE_QUIZ_ATTEMPT_SUCCESS';
export const UPDATE_QUIZ_ATTEMPT_FAILURE = 'UPDATE_QUIZ_ATTEMPT_FAILURE';

export const SET_CURRENT_QUIZ_ATTEMPT_ANSWER = 'SET_CURRENT_QUIZ_ATTEMPT_ANSWER ';
