// Imports
import axios from 'axios';

// Config
const apiUri = window.API_URL;

// Export actions
export function login({ email, password }) {
  return axios({
    method: 'post',
    url: `${apiUri}/auth/login`,
    data: { email, password },
  });
}

export function logout({ token }) {
  return axios({
    method: 'get',
    url: `${apiUri}/auth/logout`,
    headers: { Authorization: `Bearer ${token}` },
  });
}

export function logoutInPlace({ token, tempToken }) {
  return axios({
    method: 'get',
    url: `${apiUri}/auth/logout`,
    headers: { Authorization: `Bearer ${token}` },
  });
}

export function refreshAuth({ token, refresh }) {
  return axios({
    method: 'get',
    url: `${apiUri}/auth/refresh`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

/**
 * me Action
 * @param {*} Object { token }
 * @returns {promise} axios promise
 */
export const me = ({ token }) => {
  return axios({
    method: 'get',
    url: `${apiUri}/auth/me`,
    headers: { Authorization: `Bearer ${token}` },
  });
};

export function leaveImpersonation({ token }) {
  return axios({
    method: 'post',
    url: `${apiUri}/users/1/leaveimpersonation`,
    headers: { Authorization: `Bearer ${token}` },
    data: {},
  });
}

export function authyVerify({ authy_user_id, code }) {
  return axios({
    method: 'post',
    url: `${apiUri}/auth/authy/verify`,
    data: { authy_user_id, code },
  });
}

export function authySetup({ user_id, password, cellphone }) {
  return axios({
    method: 'post',
    url: `${apiUri}/auth/authy/setup`,
    data: { user_id, password, cellphone },
  });
}

export function authyRequest({ authy_user_id }) {
  return axios({
    method: 'get',
    url: `${apiUri}/auth/authy/request/${authy_user_id}`,
  });
}

export function createGuestEnrollment({ lmsCourseId, token, email, firstName }) {
  if (token) {
    return axios({
      method: 'post',
      url: `${apiUri}/auth/guest`,
      headers: { Authorization: `Bearer ${token}` },
      data: { lms_course_id: lmsCourseId, email, firstname: firstName },
    });
  } else {
    return axios({
      method: 'post',
      url: `${apiUri}/auth/guest`,
      data: { lms_course_id: lmsCourseId, email, firstname: firstName },
    });
  }
}
