// Imports
import { takeLatest, put, call } from 'redux-saga/effects';
import jwtDecode from 'jwt-decode';

// Types
import {
  LOGIN_FAILURE,
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGOUT_FAILURE,
  LOGOUT_REQUEST,
  LOGOUT_SUCCESS,
  LOGOUT_IN_PLACE_REQUEST,
  LOGOUT_IN_PLACE_SUCCESS,
  LOGOUT_IN_PLACE_FAILURE,
  REFRESH_FAILURE,
  REFRESH_REQUEST,
  REFRESH_SUCCESS,
  AUTH_ME_REQUEST,
  AUTH_ME_SUCCESS,
  AUTH_ME_FAILURE,
  LEAVEIMPERSONATION_SUCCESS,
  LEAVEIMPERSONATION_FAILURE,
  LEAVEIMPERSONATION_REQUEST,
  AUTHY_CHECKPOINT,
  AUTHY_VERIFY_FAILURE,
  AUTHY_VERIFY_REQUEST,
  AUTHY_VERIFY_SUCCESS,
  AUTHY_REQUEST_FAILURE,
  AUTHY_REQUEST_REQUEST,
  AUTHY_REQUEST_SUCCESS,
  AUTHY_SETUP_REQUEST,
  AUTHY_SETUP_SUCCESS,
  AUTHY_SETUP_FAILURE,
  CREATE_GUEST_ENROLLMENT_FAILURE,
  CREATE_GUEST_ENROLLMENT_REQUEST,
  CREATE_GUEST_ENROLLMENT_SUCCESS,
  CLEAR_GUEST_ENROLLMENT,
} from './types';

// Actions
import { login, logout, refreshAuth, me, leaveImpersonation, authyVerify, authyRequest, authySetup, createGuestEnrollment } from './actions';

/**
 * Function that handles the login http request
 *
 * @param {Object} deconstructed object
 * @returns {void}
 */
function* workerLogin({ email, password }) {
  try {
    const response = yield call(login, { email, password });

    const { token, refresh, data, setupAuthy } = response.data;

    if (token) {
      let { user, exp } = jwtDecode(token);
      const responseMe = yield call(me, { token });

      if (!responseMe || !responseMe.data || !responseMe.data.data) {
        throw new Error('workerLogin - Response Error');
      }

      yield put({
        type: LOGIN_SUCCESS,
        token,
        refresh,
        exp,
        user,
      });

      yield put({
        type: AUTH_ME_SUCCESS,
        user: responseMe.data.data,
      });
    } else {
      yield put({
        type: AUTHY_CHECKPOINT,
        authy: data,
        authySetup: setupAuthy,
      });
    }
  } catch (error) {
    const message = error.response && error.response.data ? error.response.data : null;
    yield put({ type: LOGIN_FAILURE, ...message });
  }
}

/**
 * Function that handles the logout http request
 *
 * @param {Object} deconstructed object
 * @returns {void}
 */
function* workerLogout({ token, redirect }) {
  try {
    yield call(logout, { token });
    yield put({ type: LOGOUT_SUCCESS, redirect });
  } catch (error) {
    const message = error.response && error.response.data ? error.response.data : null;
    yield put({ type: LOGOUT_FAILURE, ...message, redirect });
  }
}

/**
 * Function that handles the logout http request
 *
 * @param {Object} deconstructed object
 * @returns {void}
 */
function* workerLogoutInPLace({ token, tempToken }) {
  try {
    yield call(logout, { token });
    yield put({ type: LOGOUT_IN_PLACE_SUCCESS, tempToken });
  } catch (error) {
    const message = error.response && error.response.data ? error.response.data : null;
    yield put({ type: LOGOUT_IN_PLACE_FAILURE, ...message });
  }
}

/**
 * Function that handles the refresh token http request
 *
 * @param {Object} data object
 * @returns {void}
 */
function* workerRefresh(data) {
  const tToken = data.token;
  const tRefresh = data.refresh;

  try {
    const response = yield call(refreshAuth, { token: tToken, refresh: tRefresh });
    const { token, refresh } = response.data;
    const { user, exp } = jwtDecode(token);

    // const { claims, admin, role, name, venueId, eventId } = jwtDecode(token);

    yield put({
      type: REFRESH_SUCCESS,
      token,
      refresh,
      exp,
      user,
    });
  } catch (error) {
    yield put({ type: REFRESH_FAILURE, ...error.response.data });
  }
}

/**
 * Function that retrieves latest user information
 *
 * @param {Object} data { token }
 * @returns {void}
 */
function* workerMe({ token }) {
  try {
    const response = yield call(me, { token });
    const { data } = response.data;

    yield put({
      type: AUTH_ME_SUCCESS,
      user: data,
    });
  } catch (error) {
    const message = error?.message ?? 'Something went wrong';
    yield put({ type: AUTH_ME_FAILURE, errors: message });
  }
}

/**
 * Function that handles the leaveimpersonation http request
 *
 * @param {Object} deconstructed object
 * @returns {void}
 */
function* workerLeaveImpersonation({ token }) {
  try {
    const response = yield call(leaveImpersonation, { token });
    const user = response.data;
    yield put({ type: LEAVEIMPERSONATION_SUCCESS, ...user });
  } catch (error) {
    const message = error.response && error.response.data ? error.response.data : null;
    yield put({ type: LEAVEIMPERSONATION_FAILURE, ...message });
  }
}

/**
 * Function that handles the authy verify request
 *
 * @param {Object} deconstructed object
 * @returns {void}
 */
// eslint-disable-next-line camelcase
function* workerAuthyVerify({ authy_user_id, code }) {
  try {
    const response = yield call(authyVerify, { authy_user_id, code });
    const { token, refresh } = response.data;
    let exp;
    if (token) {
      exp = jwtDecode(token);
      yield put({
        type: AUTHY_VERIFY_SUCCESS,
        token,
        refresh,
        exp,
      });
    }
  } catch (error) {
    const message = error.response && error.response.data ? error.response.data : null;
    yield put({ type: AUTHY_VERIFY_FAILURE, ...message });
  }
}

/**
 * Function that handles the authy verify request
 *
 * @param {Object} deconstructed object
 * @returns {void}
 */
// eslint-disable-next-line camelcase
function* workerAuthyRequest({ authy_user_id }) {
  try {
    const response = yield call(authyRequest, { authy_user_id });
    const { data } = response.data;
    yield put({
      type: AUTHY_REQUEST_SUCCESS,
      data,
    });
  } catch (error) {
    const message = error.response && error.response.data ? error.response.data : null;
    yield put({ type: AUTHY_REQUEST_FAILURE, ...message });
  }
}

/**
 * Function that handles the authy setup request
 *
 * @param {Object} deconstructed object
 * @returns {void}
 */
// eslint-disable-next-line camelcase
function* workerAuthySetup({ user_id, password, cellphone }) {
  try {
    const response = yield call(authySetup, { user_id, password, cellphone });
    const { data } = response.data;
    yield put({
      type: AUTHY_SETUP_SUCCESS,
      data,
    });
  } catch (error) {
    let message = error.response && error.response.data ? error.response.data : null;
    let obj = {};
    if (message?.error) {
      obj['errors'] = {};
      obj['errors']['authy'] = [message?.error[0]];
      message = obj;
    }
    yield put({ type: AUTHY_SETUP_FAILURE, ...message });
  }
}

/**
 * Function that handles the creation of a guest enrollment
 *
 * @param {Object} deconstructed object
 * @returns {void}
 */
function* workerCreateGuestEnrollment({ lmsCourseId, token, email, firstName }) {
  try {
    const response = yield call(createGuestEnrollment, { lmsCourseId, token, email, firstName });
    const data = response.data;

    yield put({
      type: CREATE_GUEST_ENROLLMENT_SUCCESS,
      data: data,
    });

    const newToken = data.token;
    const refresh = data.refresh;

    if (newToken) {
      let { user, exp } = jwtDecode(newToken);

      yield put({
        type: LOGIN_SUCCESS,
        token: newToken,
        refresh,
        exp,
        user,
      });
    }

    const responseMe = yield call(me, { token: newToken ? newToken : token });

    if (!responseMe || !responseMe.data || !responseMe.data.data) {
      throw new Error('workerLogin - Response Error');
    }

    yield put({
      type: AUTH_ME_SUCCESS,
      user: responseMe.data.data,
    });
  } catch (error) {
    const message = error.response && error.response.data ? error.response.data : null;
    yield put({ type: CREATE_GUEST_ENROLLMENT_FAILURE, ...message });
  }
}

// Exports
export function* watcherCreateGuestEnrollment() {
  yield takeLatest(CREATE_GUEST_ENROLLMENT_REQUEST, workerCreateGuestEnrollment);
}

/**
 * Function that handles the creation of a guest enrollment
 *
 * @param {Object} deconstructed object
 * @returns {void}
 */
function* workerClearGuestEnrollment() {
  yield put({
    type: CLEAR_GUEST_ENROLLMENT,
  });
}

// Exports
export function* watcherClearGuestEnrollment() {
  yield takeLatest(CLEAR_GUEST_ENROLLMENT, workerClearGuestEnrollment);
}

// Exports
export function* watcherAuthySetup() {
  yield takeLatest(AUTHY_SETUP_REQUEST, workerAuthySetup);
}

export function* watcherAuthyVerify() {
  yield takeLatest(AUTHY_VERIFY_REQUEST, workerAuthyVerify);
}

// Exports
export function* watcherAuthyRequest() {
  yield takeLatest(AUTHY_REQUEST_REQUEST, workerAuthyRequest);
}

export function* watcherLeaveImpersonation() {
  yield takeLatest(LEAVEIMPERSONATION_REQUEST, workerLeaveImpersonation);
}

// Exports
export function* watcherLogin() {
  yield takeLatest(LOGIN_REQUEST, workerLogin);
}

export function* watcherLogout() {
  yield takeLatest(LOGOUT_REQUEST, workerLogout);
}

export function* watcherLogoutInPlace() {
  yield takeLatest(LOGOUT_IN_PLACE_REQUEST, workerLogoutInPLace);
}

export function* watcherRefresh() {
  yield takeLatest(REFRESH_REQUEST, workerRefresh);
}

export function* watcherMe() {
  yield takeLatest(AUTH_ME_REQUEST, workerMe);
}
