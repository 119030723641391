// Imports
import { takeLatest, put, call } from 'redux-saga/effects';

// Types
import { GET_LMSQUIZ_REQUEST, GET_LMSQUIZ_SUCCESS, GET_LMSQUIZ_FAILURE, RESET_LMSCOURSEMEMBER_SUCCESS, RESET_LMSCOURSEMEMBER_FAILURE, RESET_LMSCOURSEMEMBER_REQUEST } from './types';

// Actions
import { getLmsQuiz, resetLmsCourse } from './actions';

// Main Sagas
/**
 * Retrieve LMS QUIZ by id
 * @param {*} param0 { token, lmsQuizId, enrollmentId}
 * @returns {void}
 */
function* workerGetLmsQuiz({ token, lmsQuizId, enrollmentId }) {
  try {
    const response = yield call(getLmsQuiz, { token, lmsQuizId, enrollmentId });

    // Catch For No Response
    if (!response?.data?.data) {
      throw new Error('workerGetLmsQuiz - Response Error');
    }

    const { data } = response.data;

    yield put({
      type: GET_LMSQUIZ_SUCCESS,
      data,
    });
  } catch (error) {
    const message = error?.message ?? 'Something went wrong';
    yield put({ type: GET_LMSQUIZ_FAILURE, errors: message });
  }
}

/**
 * Watches for request
 * @return {void}
 */
export function* watcherGetLmsQuiz() {
  yield takeLatest(GET_LMSQUIZ_REQUEST, workerGetLmsQuiz);
}

/**
 * Reset LMS Course Member
 * @param {*} param0 { token, enrollmentId, lmsCourseMemberId}
 * @returns {void}
 */
function* workerResetLmsCourse({ token, enrollmentId, lmsCourseMemberId }) {
  try {
    const response = yield call(resetLmsCourse, { token, enrollmentId, lmsCourseMemberId });

    // Catch For No Response
    if (!response?.data?.data) {
      throw new Error('workerResetLmsCourse - Response Error');
    }

    const { data } = response.data;

    yield put({
      type: RESET_LMSCOURSEMEMBER_SUCCESS,
      data,
    });
  } catch (error) {
    const message = error?.message ?? 'Something went wrong';
    yield put({ type: RESET_LMSCOURSEMEMBER_FAILURE, errors: message });
  }
}

/**
 * Watches for request
 * @return {void}
 */
export function* watcherResetLmsCourse() {
  yield takeLatest(RESET_LMSCOURSEMEMBER_REQUEST, workerResetLmsCourse);
}
