// IMPORTS
import styled from 'styled-components';
import Screens from '../../styles/Screens';
import { Menu } from 'antd';

// MAIN STYLES
export const AppStyles = styled.div`
  // padding-bottom: 100px;

  .logo {
    display: none;
    color: white;
    margin-right: 10px;
    line-height: 72px;
    min-width: 230px;

    a {
      color: white;
    }
  }

  .logo img {
    height: 60px;
  }

  .logo-mob {
    display: block;

    a {
      color: white;
    }
  }

  .ant-layout-header {
    height: 80px;
    display: flex;
    position: static;
    padding: 0 20px;
    border-bottom: none;
    z-index: 10;

    .ant-menu {
      width: 100%;
      display: flex;
      justify-content: flex-end;

      .ant-menu-submenu {
        line-height: 72px;
      }
      .ant-menu-item {
        line-height: 72px;
      }
    }
  }

  @media (min-width: ${Screens.small}) {
    .logo {
      display: block;
      min-width: 230px;
    }
    .logo img {
      height: 40px;
    }
    .logo-mob {
      display: none;
    }
  }
`;

export const MenuItemStyled = styled(Menu.Item)`
  background-color: #001528 !important;
`;
