// action types
import {
  POST_ATTEMPT_ANSWER_REQUEST,
  POST_ATTEMPT_ANSWER_SUCCESS,
  POST_ATTEMPT_ANSWER_FAILURE,
  GET_QUESTION_ATTEMPTS_REQUEST,
  GET_QUESTION_ATTEMPTS_SUCCESS,
  GET_QUESTION_ATTEMPTS_FAILURE,
} from './types';

// reducer with initial state
const initialState = {
  fetching: false,
  data: null,
  errors: null,
  attempts: {
    fetching: false,
    data: null,
    errors: null,
  },
  attempt: {
    fetching: false,
    data: null,
    errors: null,
  },
};

const lessonsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_QUESTION_ATTEMPTS_REQUEST:
      return {
        ...state,
        attempts: {
          fetching: true,
          errors: null,
        },
        attempt: {
          data: null,
        },
      };
    case GET_QUESTION_ATTEMPTS_SUCCESS:
      return {
        ...state,
        attempts: {
          fetching: false,
          errors: null,
          data: action.response.data.data,
        },
      };
    case GET_QUESTION_ATTEMPTS_FAILURE:
      return {
        ...state,
        attempts: {
          fetching: false,
          errors: action.error,
        },
      };
    case POST_ATTEMPT_ANSWER_REQUEST:
      return {
        ...state,
        attempt: {
          fetching: true,
          errors: null,
          data: null,
        },
      };
    case POST_ATTEMPT_ANSWER_SUCCESS:
      return {
        ...state,
        attempt: {
          fetching: false,
          errors: null,
          data: action.response.data.data,
        },
      };
    case POST_ATTEMPT_ANSWER_FAILURE:
      return {
        ...state,
        attempt: {
          fetching: false,
          errors: action.error,
        },
      };
    default:
      return state;
  }
};

export default lessonsReducer;
