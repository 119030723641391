// IMPORTS
// --------------------------------------------------------
// Redux
import { put, takeLatest, call } from 'redux-saga/effects';

// Type
import { READ_QUESTIONS_REQUEST } from './types';

// Actions
import { doReadQuestionsFailure, doReadQuestionsSuccess } from './actions';

// Requests
import { readQuestions } from '../requests';

// MAIN SAGAS
// --------------------------------------------------------
/**
 * Worker for handling readQuestions
 * @return {void}
 */
function* workerReadQuestions({ token, id }) {
  try {
    const response = yield call(readQuestions, { token, id });

    if (!response?.data?.data) {
      throw new Error('workerReadQuestions - Response Error');
    }

    const { data } = response.data;

    yield put(doReadQuestionsSuccess({ data }));
  } catch (error) {
    const errors = error?.message ?? 'Something went wrong';
    yield put(doReadQuestionsFailure({ errors }));
  }
}

/**
 * Watches for Dispatch READ_QUESTIONS_REQUEST
 * @return {void}
 */
export function* watcherReadQuestions() {
  yield takeLatest(READ_QUESTIONS_REQUEST, workerReadQuestions);
}
