// Imports
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

// Presentations
import QuizResults from '../../components/QuizResults';

// Operations
import { doGetQuizAttempt, doClearQuizAttempt } from '../QuizAttempt/operations';

// Main Component
/**
 * Results Container
 * @param {*} props any
 * @returns {JSX.Element} Main JSX Routes
 */
const QuizResultsContainer = props => {
  // State / Props
  const { history, token, loading, data, doGetQuizAttempt: getQuizAttempt, doClearQuizAttempt: clearQuizAttempt, match, errors } = props;
  const { enrollmentId, courseId, lmsQuizId, quizAttemptId } = match.params;

  // Functions
  /**
   * Callback for when next button is clicked
   * @param {boolean} passed holds value if quiz was passed
   * @returns {void}
   */
  const handleNext = passed => {
    clearQuizAttempt();
    if (passed) {
      history.push(`/dashboard/enrollments/${enrollmentId}/courses/${courseId}/outline`);
    } else {
      history.push(`/dashboard/enrollments/${enrollmentId}/courses/${courseId}/lmsquizzes/${lmsQuizId}`);
    }
  };

  /**
   * Callback for when retry is clicked
   * @param {boolean} passed holds value if quiz was passed
   * @returns {void}
   */
  const handleRetry = passed => {
    clearQuizAttempt();
    history.push(`/dashboard/enrollments/${enrollmentId}/courses/${courseId}/lmsquizzes/${lmsQuizId}`);
  };

  /**
   * Callback for when review button is clicked
   * @returns {void}
   */
  const handleReview = () => {
    clearQuizAttempt();
    history.push(`/dashboard/enrollments/${enrollmentId}/courses/${courseId}/lmsquizzes/${lmsQuizId}/attempts/${quizAttemptId}`);
  };

  // Hooks
  /**
   * Onload get the quiz attempt results
   */
  useEffect(() => {
    getQuizAttempt({ token, quizAttemptId, enrollmentId });
  }, [getQuizAttempt, quizAttemptId, enrollmentId, token]);

  // Render
  return (
    <QuizResults
      history={history}
      loading={loading}
      data={data}
      errors={errors}
      match={match}
      params={match?.params || {}}
      handleNext={handleNext}
      handleReview={handleReview}
      handleRetry={handleRetry}
    />
  );
};

// Map State / Dispatch To Props
/**
 * Main redux state
 * @param {*} state object
 * @returns {Object} state objects
 */
const mapStateToProps = state => ({
  token: state.loginReducer.token,
  loading: state.quizAttemptReducer.fetching,
  data: state.quizAttemptReducer.data,
  errors: state.quizAttemptReducer.errors,
});

/**
 * Main redux dispatch functions
 * @param {*} dispatch redux dispatch
 * @returns {bindActionCreators} state objects
 */
const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      doGetQuizAttempt,
      doClearQuizAttempt,
    },
    dispatch,
  );

// Prop Types
QuizResultsContainer.propTypes = {
  token: PropTypes.string,
  data: PropTypes.any,
  match: PropTypes.object,
  history: PropTypes.object,
  doClearQuizAttempt: PropTypes.func,
  loading: PropTypes.bool,
  doGetQuizAttempt: PropTypes.func,
  errors: PropTypes.object,
};

// Exports
export default connect(mapStateToProps, mapDispatchToProps)(QuizResultsContainer);
