import { Col, Row, Steps, Tabs, Radio, Icon, Alert } from 'antd';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import ProcessorStyles from './styles';
import StripePayment from './Processors/StripePayment';
import AmazonPayment from './Processors/AmazonPayment';
import AmazonPayV2 from '../../../AmazonPayV2';
import useScript from '../../../../hooks/useScript';

const PaymentProcessor = props => {
  useScript('https://static-na.payments-amazon.com/checkout.js');
  const {
    onPaymentTypeChange,
    token,
    enrollmentId,
    cartData,
    stripeData,
    amazonData,
    user,
    handleConfirmationStep,
    handleStudentInformationStep,
    resetStripeData,
    createStripePaymentIntent,
    processStripePaymentIntent,
    createAmazonPaymentIntent,
    processAmazonPaymentIntent,
    setStripeStatus,
    getMe,
  } = props;

  // STATE / PROPS
  const [paymentStep, setPaymentStep] = useState(0);

  const [paymentProcessorSelect, setPaymentProcessorSelect] = useState(null);

  const [paymentProcessorRequest, setPaymentProcessorRequest] = useState(false);

  const onPaymentTypeSelectChange = event => {
    const { value } = event?.target;
    setPaymentProcessorSelect(value);
  };

  const onPaymentProcessorSelectStep = () => {
    setPaymentStep(0);
  };

  const onPaymentProcessorSelect = () => {
    if (paymentProcessorSelect) {
      let cartId = cartData?.data?.id;
      switch (paymentProcessorSelect) {
        case 'stripe':
          createStripePaymentIntent({ token, cartId });
          break;
        case 'amazon':
          createAmazonPaymentIntent({ token, enrollmentId, cartId });
          break;
      }
      setPaymentProcessorRequest(true);
    } else {
    }
  };

  useEffect(() => {
    if ((paymentProcessorRequest && amazonData?.data) || stripeData?.data) {
      setPaymentProcessorRequest(false);
      setPaymentStep(1);
    }
  }, [amazonData, stripeData, paymentProcessorRequest]);

  return (
    <ProcessorStyles>
      <Row gutter={[16, 16]}>
        <Col xs={24} sm={24} md={24} lg={5}>
          <Steps current={paymentStep} direction="vertical" size={`default`} className={`payment-processor-steps`} style={{ marginBottom: 15 }}>
            <Steps.Step icon={<Icon type={`credit-card`} />} title="Select Payment Type" onClick={onPaymentProcessorSelectStep} />
            <Steps.Step title="Process Payment" />
          </Steps>
        </Col>
        <Col xs={24} sm={24} md={24} lg={19}>
          <div>
            {
              {
                0: (
                  <div>
                    <div style={{ padding: 15 }}>
                      <p>Select a payment processor then click continue to proceed.</p>
                    </div>
                    <div style={{ padding: 0 }}>
                      <Radio.Group onChange={onPaymentTypeSelectChange} value={paymentProcessorSelect}>
                        <Radio value={`stripe`}>
                          <img style={{ width: '100%', maxWidth: 250 }} src={`../../../../images/stripepay.png`} alt={`Stripe Payment`} />
                        </Radio>
                        <Radio value={`amazon`}>
                          <img style={{ width: '100%', maxWidth: 250 }} src={`../../../../images/amazonpay.png`} alt={`Amazon Payment`} />
                        </Radio>
                      </Radio.Group>
                    </div>

                    <div style={{ padding: 25 }}>
                      <ProcessorStyles.Button disabled={paymentProcessorSelect === null} onClick={onPaymentProcessorSelect}>
                        <span id="button-text">{stripeData?.fetching || amazonData?.fetching ? <Icon type="loading" /> : `Continue`}</span>
                      </ProcessorStyles.Button>
                    </div>
                  </div>
                ),
                1: {
                  stripe: (
                    <StripePayment
                      token={token}
                      cartData={cartData}
                      stripeData={stripeData}
                      resetStripeData={resetStripeData}
                      createStripePaymentIntent={createStripePaymentIntent}
                      processStripePaymentIntent={processStripePaymentIntent}
                      handleStudentInformationStep={handleStudentInformationStep}
                      handleConfirmationStep={handleConfirmationStep}
                      getMe={getMe}
                      setStripeStatus={setStripeStatus}
                    />
                  ),
                  amazon: (
                    <AmazonPayment
                      token={token}
                      enrollmentId={enrollmentId}
                      cartData={cartData}
                      amazonData={amazonData}
                      user={user}
                      processAmazonPaymentIntent={processAmazonPaymentIntent}
                      handleConfirmationStep={handleConfirmationStep}
                      handleStudentInformationStep={handleStudentInformationStep}
                      getMe={getMe}
                    />
                  ),
                }[paymentProcessorSelect],
              }[paymentStep]
            }
          </div>
        </Col>
      </Row>
      <div style={{ padding: 15, borderBottom: '1px solid #e8e8e8' }} />
    </ProcessorStyles>
  );
};

// PROPS TYPES
// --------------------------------------------------------
PaymentProcessor.propTypes = {
  token: PropTypes.string,
  enrollmentId: PropTypes.string,
  accessToken: PropTypes.string,
  orderId: PropTypes.string,
  cartData: PropTypes.object,
  stripeData: PropTypes.object,
  amazonData: PropTypes.object,
  user: PropTypes.object,
  processOrder: PropTypes.bool,
  onPaymentTypeChange: PropTypes.func,
  resetStripeData: PropTypes.func,
  createStripePaymentIntent: PropTypes.func,
  processStripePaymentIntent: PropTypes.func,
  generateAmazonButtonSignature: PropTypes.func,
  createAmazonPaymentIntent: PropTypes.func,
  processAmazonPaymentIntent: PropTypes.func,
  getMe: PropTypes.func,
  handleConfirmationStep: PropTypes.func,
  handleStudentInformationStep: PropTypes.func,
  setStripeStatus: PropTypes.func,
};

export default PaymentProcessor;
