// Imports
import { takeLatest, put, call } from 'redux-saga/effects';

// Types
import {
  POST_ATTEMPT_ANSWER_REQUEST,
  POST_ATTEMPT_ANSWER_SUCCESS,
  POST_ATTEMPT_ANSWER_FAILURE,
  GET_QUESTION_ATTEMPTS_REQUEST,
  GET_QUESTION_ATTEMPTS_SUCCESS,
  GET_QUESTION_ATTEMPTS_FAILURE,
} from './types';

// Actions
import { postAttemptAnswer, getQuestionAttempts } from './actions';

function* workerPostAttemptAnswer({ token, scriptAttemptId, questionId, questionOptionId }) {
  try {
    const response = yield call(postAttemptAnswer, { token, scriptAttemptId, questionId, questionOptionId });

    yield put({
      type: POST_ATTEMPT_ANSWER_SUCCESS,
      response,
    });
  } catch (error) {
    yield put({ type: POST_ATTEMPT_ANSWER_FAILURE, ...error.response });
  }
}

export function* watcherPostAttemptAnswer() {
  yield takeLatest(POST_ATTEMPT_ANSWER_REQUEST, workerPostAttemptAnswer);
}

function* workerGetQuestionAttempts({ token, scriptAttemptId, questionId }) {
  try {
    const response = yield call(getQuestionAttempts, { token, scriptAttemptId, questionId });

    yield put({
      type: GET_QUESTION_ATTEMPTS_SUCCESS,
      response,
    });
  } catch (error) {
    yield put({ type: GET_QUESTION_ATTEMPTS_FAILURE, ...error.response });
  }
}

export function* watcherGetQuestionAttempts() {
  yield takeLatest(GET_QUESTION_ATTEMPTS_REQUEST, workerGetQuestionAttempts);
}
