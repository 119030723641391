// Types
import {
  CREATE_QUIZ_ATTEMPT_ANSWER_REQUEST,
  CREATE_QUIZ_ATTEMPT_ANSWER_SUCCESS,
  CREATE_QUIZ_ATTEMPT_ANSWER_FAILURE,
  UPDATE_QUIZ_ATTEMPT_ANSWER_REQUEST,
  UPDATE_QUIZ_ATTEMPT_ANSWER_SUCCESS,
  UPDATE_QUIZ_ATTEMPT_ANSWER_FAILURE,
} from './types';

// Initial State
const initialState = {
  submitting: false,
  fetching: false,
  data: null,
  errors: null,
};

// Main Reducer
/**
 * quizAttemptAnswerReducer Reducer
 * @param {Object} state current state of redux
 * @param {Object} action action state object
 * @returns {state} Redux State
 */
const quizAttemptAnswerReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_QUIZ_ATTEMPT_ANSWER_REQUEST:
    case CREATE_QUIZ_ATTEMPT_ANSWER_REQUEST:
      return {
        ...state,
        submitting: true,
        errors: null,
      };
    case UPDATE_QUIZ_ATTEMPT_ANSWER_SUCCESS:
      return {
        ...state,
        submitting: false,
        data: action.data,
      };
    case CREATE_QUIZ_ATTEMPT_ANSWER_SUCCESS:
      return {
        ...state,
        submitting: false,
        data: action.data,
      };
    case UPDATE_QUIZ_ATTEMPT_ANSWER_FAILURE:
    case CREATE_QUIZ_ATTEMPT_ANSWER_FAILURE:
      return {
        ...state,
        submitting: false,
        errors: action.errors,
      };
    default:
      return state;
  }
};

export default quizAttemptAnswerReducer;
