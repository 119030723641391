// Imports
import styled from 'styled-components';

// Main
/**
 * Main container style
 */
const LmsQuizStyles = styled.div`
  padding: 20px;
`;

// Export
export default LmsQuizStyles;
