// Import
import React from 'react';
import PropTypes from 'prop-types';
import { Popover, Form, Input, Button, Alert } from 'antd';
import ReferralStyles from './styles';

// Main Component
/**
 * Referral Component
 * @param {*} props any
 * @returns {JSX.Element} Main JSX Routes
 */
const Referral = props => {
  // State / Props
  const { form, handleSendReferral, handleClearReferral, referral, courseId, websiteId } = props;
  const { getFieldDecorator } = form;

  // Functions
  /**
   * Handles form submission
   * @param {Event} event Form Event
   * @returns {void}
   */
  const onSubmitReferral = event => {
    event.preventDefault();
    form.validateFields(['email'], (err, values) => {
      if (!err) {
        const { email } = values;
        handleSendReferral({ email, courseId, websiteId });
        form.setFieldsValue({
          email: '',
        });
      }
    });
  };

  /**
   * When the button to open or close the modal is clicked
   * @param {Event} event Button Event
   * @returns {void}
   */
  const onClickButton = event => {
    handleClearReferral();
  };

  // Render
  return (
    <ReferralStyles>
      <ReferralStyles.GlobalStyles />
      {(courseId || websiteId) && (
        <Popover
          overlayClassName="aux-popover"
          trigger={['click']}
          placement="topRight"
          content={
            <div>
              <h3>Refer a friend and get cash back!</h3>
              <p>Enter your friend&apos;s email address below and we will send them an email. If your friend buys any course we will credit your account for 10% of your last purchase!</p>
              <Form onSubmit={onSubmitReferral}>
                <Form.Item>
                  {getFieldDecorator('email', {
                    rules: [
                      {
                        type: 'email',
                        message: 'Not a valid email',
                      },
                      {
                        required: true,
                        message: 'Email is required',
                      },
                    ],
                  })(<Input type="email" placeholder="email@address.com" />)}
                </Form.Item>
                {referral?.success && (
                  <Form.Item>
                    <Alert message="Your referral has been sent." type="success" />
                  </Form.Item>
                )}
                <Button type="primary" htmlType="submit" disabled={referral.fetching}>
                  Send
                </Button>
              </Form>
            </div>
          }
        >
          <ReferralStyles.ReferButton shape="round" icon="dollar" onClick={onClickButton}>
            Referral
          </ReferralStyles.ReferButton>
        </Popover>
      )}
    </ReferralStyles>
  );
};

// Property Types
Referral.propTypes = {
  form: PropTypes.object,
  handleSendReferral: PropTypes.func,
  handleClearReferral: PropTypes.func,
  referral: PropTypes.object,
  courseId: PropTypes.string,
  websiteId: PropTypes.string,
};

// Export
export default Referral;
