// Imports
import styled from 'styled-components';
import Screens from '../../styles/Screens';

// Main
/**
 * Main container style
 */
const DashboardStyles = styled.div`
  padding: 20px;
  .ant-card {
    margin-bottom: 20px;
  }
`;

DashboardStyles.Active = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 20px;

  .ant-card {
    width: 100%;
    margin-bottom: 15px;

    .ant-card-body {
      display: flex;
      justify-content: center;
    }
  }

  @media (min-width: ${Screens.small}) {
    margin: 0 -15px;

    .ant-card {
      width: auto;
      margin-left: 15px;
      margin-right: 15px;
    }

    p {
      margin-left: 15px;
      margin-right: 15px;
    }
  }
`;

DashboardStyles.Completed = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 20px;

  .ant-card {
    width: 100%;
    margin-bottom: 15px;

    .ant-card-body {
      display: flex;
      justify-content: center;
    }
  }

  @media (min-width: ${Screens.small}) {
    margin: 0 -15px;

    .ant-card {
      width: auto;
      margin-left: 15px;
      margin-right: 15px;
    }

    p {
      margin-left: 15px;
      margin-right: 15px;
    }
  }
`;

// Export
export default DashboardStyles;
