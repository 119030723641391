// IMPORTS
// --------------------------------------------------------
// Types
import { ANSWERS_SCRIPTATTEMPTS_RESET, ANSWERS_SCRIPTATTEMPTS_REQUEST, ANSWERS_SCRIPTATTEMPTS_FAILURE, ANSWERS_SCRIPTATTEMPTS_SUCCESS } from './types';

// MAIN ACTIONS
// --------------------------------------------------------
/**
 * Resets main reducer to initialState
 * @returns {Dispatch} Redux Dispatch
 */
export const doAnswersScriptAttemptsReset = () => ({
  type: ANSWERS_SCRIPTATTEMPTS_RESET,
});

/**
 * Main request
 * @param {Object} param0 { token, enrollmentId, lmsScriptId, sectionId }
 * @returns {Dispatch} Redux Dispatch
 */
export const doAnswersScriptAttemptsRequest = ({ token, id, questionId, questionOptionId }) => ({
  type: ANSWERS_SCRIPTATTEMPTS_REQUEST,
  token,
  id,
  questionId,
  questionOptionId,
});

/**
 * Handling request failure
 * @returns {Dispatch} Redux Dispatch
 */
export const doAnswersScriptAttemptsFailure = ({ errors }) => ({
  type: ANSWERS_SCRIPTATTEMPTS_FAILURE,
  errors,
});

/**
 * Handling request success
 * @returns {Dispatch} Redux Dispatch
 */
export const doAnswersScriptAttemptsSuccess = ({ data }) => ({
  type: ANSWERS_SCRIPTATTEMPTS_SUCCESS,
  data,
});
