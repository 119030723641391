// Imports
import axios from 'axios';

// Config
const apiUri = window.API_URL;

// Main Actions
/**
 * getStudentContact Action
 * @param {*} Object { token, id }
 * @returns {promise} axios promise
 */
export const getStudentContact = ({ token, id }) => {
  return axios({
    method: 'get',
    url: `${apiUri}/students/${id}`,
    headers: { Authorization: `Bearer ${token}` },
  });
};

/**
 * createStudent Action
 * @param {*} Object { token, user_id }
 * @returns {promise} axios promise
 */
export const createStudent = ({ token, user_id }) => {
  return axios({
    method: 'post',
    url: `${apiUri}/students`,
    headers: { Authorization: `Bearer ${token}` },
    data: {
      user_id: user_id,
    },
  });
};

/**
 * updateStudentContact Action
 * @param {*} Object { token, id, firstname, lastname, email, phone, address, city, state, zip, password }
 * @returns {promise} axios promise
 */
export const updateStudentContact = ({ token, id, firstname, lastname, email, phone, address, city, state, zip, password }) => {
  return axios({
    method: 'put',
    url: `${apiUri}/students/${id}`,
    headers: { Authorization: `Bearer ${token}` },
    data: {
      firstname: firstname,
      lastname: lastname,
      email: email,
      phone: phone,
      address: address,
      city: city,
      state: state,
      zip: zip,
      password: password,
    },
  });
};
