// Imports
import axios from 'axios';

// Config
const apiUri = window.API_URL;

// Export actions
export function passwordReset({ password, password_confirmation, token, validator }) {
  return axios({
    method: 'post',
    url: `${apiUri}/auth/passwordreset`,
    data: { password, password_confirmation, token, validator },
  });
}
