// IMPORTS
// --------------------------------------------------------
// Types
import { SCRIPTATTEMPTS_LMSSCRIPTS_RESET, SCRIPTATTEMPTS_LMSSCRIPTS_REQUEST, SCRIPTATTEMPTS_LMSSCRIPTS_FAILURE, SCRIPTATTEMPTS_LMSSCRIPTS_SUCCESS } from './types';

// MAIN ACTIONS
// --------------------------------------------------------
/**
 * Resets main reducer to initialState
 * @returns {Dispatch} Redux Dispatch
 */
export const doScriptAttemptsLmsScriptsReset = () => ({
  type: SCRIPTATTEMPTS_LMSSCRIPTS_RESET,
});

/**
 * Main request
 * @returns {Dispatch} Redux Dispatch
 */
export const doScriptAttemptsLmsScriptsRequest = ({ token, id, enrollmentId, include }) => ({
  type: SCRIPTATTEMPTS_LMSSCRIPTS_REQUEST,
  token,
  id,
  enrollmentId,
  include,
});

/**
 * Handling request failure
 * @returns {Dispatch} Redux Dispatch
 */
export const doScriptAttemptsLmsScriptsFailure = ({ errors }) => ({
  type: SCRIPTATTEMPTS_LMSSCRIPTS_FAILURE,
  errors,
});

/**
 * Handling request success
 * @returns {Dispatch} Redux Dispatch
 */
export const doScriptAttemptsLmsScriptsSuccess = ({ data }) => ({
  type: SCRIPTATTEMPTS_LMSSCRIPTS_SUCCESS,
  data,
});
