// Imports
import styled from 'styled-components';

// Main Component
const NotFoundStyles = styled.div`
  padding: 20px;
`;

NotFoundStyles.Title = styled.h1``;

// Export
export default NotFoundStyles;
