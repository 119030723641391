// Imports
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

// Presentations
import Question from '../../components/Question';

// Containers

// Operations
import { doGetQuestion } from './operations';

// Main Component
/**
 * Main Container
 * @param {*} props any
 * @returns {JSX.Element} Main JSX Routes
 */
const QuestionContainer = props => {
  // State / Props
  const { token, attempt, question, doGetQuestion: getQuestion, data, loading, submitting, handleSubmit, index, complete, settings, answer } = props;
  const questionId = question?.question_id;

  /**
   * Handle submit indiviudal answer
   * @param {object} data number
   * @returns {void}
   */
  const handleSubmitAnswer = data => {
    if (handleSubmit) {
      handleSubmit(data);
    }
  };

  // Hooks
  /**
   * On load get question by id
   */
  useEffect(() => {
    if (questionId) {
      getQuestion({ token, questionId });
    }
  }, [getQuestion, token, questionId]);

  // Render
  return <Question data={data} attempt={attempt} index={index} loading={loading} submitting={submitting} handleSubmit={handleSubmitAnswer} complete={complete} settings={settings} answer={answer} />;
};

// Map State / Dispatch To Props
/**
 * Main redux state
 * @param {*} state object
 * @returns {Object} state objects
 */
const mapStateToProps = state => ({
  token: state.loginReducer.token,
  //loading: state.questionReducer.fetchint,
  data: state.questionReducer.data,
  errors: state.questionReducer.errors,
});

/**
 * Main redux dispatch functions
 * @param {*} dispatch redux dispatch
 * @returns {bindActionCreators} state objects
 */
const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      doGetQuestion,
    },
    dispatch,
  );

// Prop Types
QuestionContainer.propTypes = {
  question: PropTypes.object,
  attempt: PropTypes.object,
  token: PropTypes.string,
  doGetQuestion: PropTypes.func,
  data: PropTypes.object || PropTypes.array,
  loading: PropTypes.bool,
  index: PropTypes.number,
  handleSubmit: PropTypes.func,
  complete: PropTypes.bool,
  settings: PropTypes.object,
  answer: PropTypes.any,
  submitting: PropTypes.any,
};

// Exports
export default connect(mapStateToProps, mapDispatchToProps)(QuestionContainer);
