// Types
import { CLEAR_REFERRAL, POST_REFERRAL_REQUEST } from './types';

// Main Operations

/**
 * @param {*} Object { token, email }
 * @returns {Dispatch} for redux
 */
export const doSendReferral = ({ token, courseId, websiteId, email }) => ({
  type: POST_REFERRAL_REQUEST,
  token,
  courseId,
  websiteId,
  email,
});

/**
 * @returns {Dispatch} for redux
 */
export const doClearReferral = () => ({
  type: CLEAR_REFERRAL,
});
