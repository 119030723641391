// Import types
import { GET_SCRIPT_ATTEMPTS_REQUEST, GET_LESSON_TRACKER_REQUEST, CREATE_SCRIPT_ATTEMPTS_REQUEST, UPDATE_SCRIPT_ATTEMPTS_REQUEST, CREATE_SCRIPT_ATTEMPT_WATCHED_REQUEST } from './types';

// Export operations
export const doGetScriptAttempts = ({ token, lmsScriptId, enrollmentId }) => ({
  type: GET_SCRIPT_ATTEMPTS_REQUEST,
  token,
  lmsScriptId,
  enrollmentId,
});

export const doGetLessonTracker = ({ token, lmsScriptId, enrollmentId }) => ({
  type: GET_LESSON_TRACKER_REQUEST,
  token,
  lmsScriptId,
  enrollmentId,
});

export const doCreateScriptAttempts = ({ token, lmsScriptId, enrollmentId, sectionId }) => ({
  type: CREATE_SCRIPT_ATTEMPTS_REQUEST,
  token,
  lmsScriptId,
  enrollmentId,
  sectionId,
});

export const doUpdateScriptAttempts = ({ token, scriptAttemptsId, complete, position, sectionId }) => ({
  type: UPDATE_SCRIPT_ATTEMPTS_REQUEST,
  token,
  scriptAttemptsId,
  complete,
  position,
  sectionId,
});

export const doCreateScriptAttemptWatched = ({ token, scriptAttemptId, sectionId }) => ({
  type: CREATE_SCRIPT_ATTEMPT_WATCHED_REQUEST,
  token,
  scriptAttemptId,
  sectionId,
});
