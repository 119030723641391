// Imports
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Form, message } from 'antd';

// // Operations
import { doCreateStudent, doUpdateStudentContact } from './operations';

// Presentation Components
import Account from '../../components/Account';

// Operations
import { doGetMe } from '../Login/operations';

// Main Component
/**
 * Main Component
 * @param {*} props any
 * @return {JSX.Element} JSX Element
 */
const AccountContainer = props => {
  // State / Props

  const { doUpdateStudentContact: updateStudentContact, doCreateStudent: createStudent, doGetMe: getMe, form, token, student, contact, user, request, errors, loginBusy } = props;

  //This is the main state of the table
  const [updateContactRequest, setUpdateContactRequest] = useState(false);

  const [password, setPassword] = useState(null);
  const [fetchedMe, setFetchedMe] = useState(false);

  const submitForm = event => {
    event.preventDefault();
    form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        if (values.password !== undefined || values.confirmpassword !== undefined) {
          if (values.password !== values.confirmpassword) {
            message.error('The password and confirm password are not the same.');
            return;
          } else {
            setPassword(values.password);
          }
        }
        user.firstname = values.firstname;
        user.lastname = values.lastname;
        user.firstname = values.firstname;
        user.lastname = values.lastname;
        user.email = values.email;
        user.student.phone = values.phone;
        user.student.address = values.address;
        user.student.city = values.city;
        user.student.state = values.state;
        user.student.zip = values.zip;
        setUpdateContactRequest(true);
      }
    });
  };

  const getMessageFromError = error => {
    if (typeof error === 'string' || error instanceof String) {
      return error;
    } else if (error?.message) {
      return error.message;
    } else if (error?.error) {
      return error.message;
    } else {
      return null;
    }
  };

  // Hooks
  /*
   * Get Student Contact Data Hook
   */
  useEffect(() => {
    if (user && !user?.student?.id && !loginBusy && !student.fetching && fetchedMe) {
      if (student.errors) {
        message.error(getMessageFromError(student.errors));
      } else {
        let user_id = user?.id;
        createStudent({ token, user_id });
      }
    }
  }, [token, student, user, createStudent, loginBusy]);
  /*
   * Get Student Contact Data Hook
   */
  useEffect(() => {
    if (!user?.student?.id && !loginBusy && !fetchedMe) {
      getMe({ token });
      setFetchedMe(true);
    }
  }, [token, user, loginBusy, request]);

  /*
   * Update Student Contact Hook
   */
  useEffect(() => {
    if (updateContactRequest && user?.student && request) {
      let id = user.student.id;
      let firstname = user.firstname;
      let lastname = user.lastname;
      let email = user.email;
      let phone = user.student.phone;
      let address = user.student.address;
      let city = user.student.city;
      let state = user.student.state;
      let zip = user.student.zip;
      if (password != null) {
        updateStudentContact({ token, id, firstname, lastname, email, phone, address, city, state, zip, password });
        setPassword(null);
      } else {
        updateStudentContact({ token, id, firstname, lastname, email, phone, address, city, state, zip });
      }
      setUpdateContactRequest(false);
    }
  }, [updateContactRequest, user, request, password, token, getMe]);

  /**
   * Request Success/Failure Message Prompt
   */
  useEffect(() => {
    if (request && !request.fetching && request.message && request.message.length) {
      if (request && request.success && request.message !== 'blank') {
        message.success(request.message);
      } else if (request.errors) {
        message.error(getMessageFromError(request.errors));
      }
      getMe({ token });
      setFetchedMe(true);
      request.message = null;
    }
  }, [request]);

  // Render
  return <Account contact={contact} request={request} form={form} submitForm={submitForm} errors={request.errors} />;
};

// Map State / Dispatch To Props
/**
 * Main redux state
 * @param {*} state object
 * @returns {Object} state objects
 */
const mapStateToProps = state => ({
  token: state.loginReducer.token,
  user: state.loginReducer.user,
  loginBusy: state.loginReducer.fetching,
  contact: state.accountReducer.contact,
  student: state.accountReducer.student,
  request: state.accountReducer.request,
});

/**
 * Main redux dispatch functions
 * @param {*} dispatch redux dispatch
 * @returns {bindActionCreators} state objects
 */
const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      doUpdateStudentContact,
      doCreateStudent,
      doGetMe,
    },
    dispatch,
  );

// Prop Types
AccountContainer.propTypes = {
  //Auth
  token: PropTypes.string,
  contact: PropTypes.any,
  user: PropTypes.any,
  student: PropTypes.any,
  loginBusy: PropTypes.bool,
  errors: PropTypes.any,

  // Functions
  doUpdateStudentContact: PropTypes.func,
  doGetMe: PropTypes.func,
  doCreateStudent: PropTypes.func,

  // Values
  form: PropTypes.object,
  request: PropTypes.object,
};

// Exports
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(
  Form.create({
    name: 'account',
    mapPropsToFields(props) {
      return {
        firstname: Form.createFormField({
          ...props.user,
          value: props.user.firstname,
        }),
        lastname: Form.createFormField({
          ...props.user,
          value: props.user.lastname,
        }),
        phone: Form.createFormField({
          ...props.user?.student,
          value: props.user?.student?.phone ? props.user?.student?.phone : '',
        }),
        email: Form.createFormField({
          ...props.user,
          value: props.user.email,
        }),
        address: Form.createFormField({
          ...props.user?.student,
          value: props.user?.student?.address ? props.user?.student?.address : '',
        }),
        city: Form.createFormField({
          ...props.user?.student,
          value: props.user?.student?.city ? props.user?.student?.city : '',
        }),
        state: Form.createFormField({
          ...props.user?.student,
          value: props.user?.student?.state ? props.user?.student?.state : '',
        }),
        zip: Form.createFormField({
          ...props.user?.student,
          value: props.user?.student?.zip ? props.user?.student?.zip : '',
        }),
      };
    },
  })(AccountContainer),
);
