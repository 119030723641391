// Types
import { POST_FEEDBACK_REQUEST, CLEAR_FEEDBACK } from './types';

// Main Operations

/**
 * @param {*} Object { token, type, feedback }
 * @returns {Dispatch} for redux
 */
export const doSendFeedback = ({ token, feedbackType, feedback, enrollmentId, quizAttemptId, scriptAttemptId, location, quizAttemptAnswerId, sectionId, position, image, alertUser = true }) => ({
  type: POST_FEEDBACK_REQUEST,
  token,
  feedbackType,
  feedback,
  enrollmentId,
  quizAttemptId,
  scriptAttemptId,
  location,
  quizAttemptAnswerId,
  sectionId,
  position,
  image,
  alertUser,
});

/**
 * @returns {Dispatch} for redux
 */
export const doClearFeedback = () => ({
  type: CLEAR_FEEDBACK,
});
