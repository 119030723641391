// IMPORTS
// --------------------------------------------------------
// Types
import { READ_QUESTIONS_RESET, READ_QUESTIONS_REQUEST, READ_QUESTIONS_SUCCESS, READ_QUESTIONS_FAILURE } from './types';

// INITIAL STATE
// --------------------------------------------------------
/**
 * @constant
 */
const initialState = {
  loading: false,
  data: null,
  errors: null,
};

// MAIN REDUCER
// --------------------------------------------------------
/**
 * ReadQuestions Reducer
 * @param {object} state initialState
 * @param {object} action any
 * @returns {object} Redux state
 */
const ReadQuestionsReducer = (state = initialState, action) => {
  switch (action.type) {
    case READ_QUESTIONS_RESET:
      return initialState;
    case READ_QUESTIONS_REQUEST:
      return {
        ...state,
        loading: true,
        errors: null,
      };
    case READ_QUESTIONS_FAILURE:
      return {
        ...state,
        loading: false,
        errors: action.errors,
      };
    case READ_QUESTIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.data,
      };
    default:
      return state;
  }
};

// EXPORTS
// --------------------------------------------------------
export default ReadQuestionsReducer;
