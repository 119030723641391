// IMPORTS
// --------------------------------------------------------
import axios from 'axios';

// CONFIG
// --------------------------------------------------------
const apiUri = window.API_URL;

// MAIN REQUESTS
// --------------------------------------------------------
/**
 * Read individual Questions
 * @returns {Promise} Axios Promise
 */
export const readQuestions = ({ token, id, include }) => {
  const defaultWith = include ? include : 'questionoptions';
  return axios({ method: 'get', url: `${apiUri}/questions/${id}?with=${defaultWith}`, headers: { Authorization: `Bearer ${token}` } });
};
