// Imports
import { takeLatest, put, call } from 'redux-saga/effects';
import jwtDecode from 'jwt-decode';

// Types
import { FORGOT_PASSWORD_FAILURE, FORGOT_PASSWORD_REQUEST, FORGOT_PASSWORD_SUCCESS } from './types';

// Actions
import { forgotPassword } from './actions';

/**
 * Function that handles the forgot password http request
 *
 * @param {Object} deconstructed object
 * @returns {void}
 */
function* workerForgotPassword({ email }) {
  try {
    const response = yield call(forgotPassword, { email });
    if (!response || !response.data || !response.data.data) {
      throw new Error('workerForgotPassword - Response Error');
    }

    yield put({
      type: FORGOT_PASSWORD_SUCCESS,
      data: response.data.data,
    });
  } catch (error) {
    const message = error.response && error.response.data ? error.response.data : null;
    yield put({ type: FORGOT_PASSWORD_FAILURE, ...message });
  }
}

// Exports
export function* watcherForgotPassword() {
  yield takeLatest(FORGOT_PASSWORD_REQUEST, workerForgotPassword);
}
