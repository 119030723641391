// Import
import React from 'react';
import PropTypes from 'prop-types';
import { Icon, Row, Col, Form, Input, Alert } from 'antd';
import AuthyStyles from './styles';
import Constants from '../../utils/Constants';

// Main Component
/**
 * Main Component
 * @param {*} props any
 * @return {JSX.Element} JSX Element
 */
const Authy = props => {
  // Props
  const { authy, form, handleSubmit, handleResend } = props;
  const { getFieldDecorator } = form;

  // Functions
  const onSubmitForm = event => {
    event.preventDefault();
    form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        handleSubmit(values);
      }
    });
  };

  // Display of form fields
  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 24 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 24 },
    },
  };

  const renderAuthyError = (errors, external_error_code) => {
    if (external_error_code === '60020' || external_error_code === '60118') {
      return <AuthyStyles.Error>Authentication code was not recognized.</AuthyStyles.Error>;
    } else if (external_error_code === '60019') {
      return <AuthyStyles.Error>Authentication code has already been used.</AuthyStyles.Error>;
    } else if (errors.message) {
      return <AuthyStyles.Error> {errors.message}</AuthyStyles.Error>;
    } else if (errors && errors.length >= 1) {
      return errors.map((val, key) => <AuthyStyles.Error key={key.toString()}>{val}</AuthyStyles.Error>);
    } else {
      return '';
    }
  };

  return (
    <AuthyStyles>
      <AuthyStyles.Card>
        <Row gutter={[0, 0]} style={{ width: '100%' }}>
          <Col span={24}>
            <div style={{ padding: 20 }}>
              <div style={{ textAlign: `center`, padding: '15px 0 30px 0' }}>
                <img style={{ width: 350 }} src={'/images/logos/' + Constants.WEBSITE_NAME + '/logo-on-white.png'} alt={'logo'} />
              </div>
              <h2 style={{ textAlign: 'center' }}>Authentication code</h2>
              <Form onSubmit={onSubmitForm}>
                <Form.Item {...formItemLayout} style={{ marginBottom: 10 }}>
                  {getFieldDecorator('code', {
                    rules: [
                      {
                        required: true,
                        message: 'Code is required',
                      },
                    ],
                  })(<Input prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />} disabled={props.loading} type="text" placeholder={`7-digit code`} />)}
                </Form.Item>
                <Form.Item style={{ marginBottom: 0 }}>
                  {getFieldDecorator('authy_user_id', {
                    initialValue: authy?.authy_user_id,
                    rules: [
                      {
                        required: true,
                      },
                    ],
                  })(<Input type="hidden" />)}
                </Form.Item>
                <div style={{ marginBottom: 15 }}>
                  <Alert
                    type="info"
                    icon="phone"
                    message="We just sent you a message via SMS with your authentication code. Enter
                  the code in the form above to verify your identity."
                  />
                </div>
                <AuthyStyles.Errors show={(props.errors && props.errors.length > 0) || props.errors?.message} count={props.errors && props.errors.length}>
                  {renderAuthyError(props.errors, props.external_error_code)}
                </AuthyStyles.Errors>
                <Row gutter={[16, 16]}>
                  <Col span={24}>
                    <div style={{ textAlign: `center` }}>
                      <AuthyStyles.Button disabled={props.loading} htmlType={'submit'} block>
                        {props.loading ? '...' : 'Verify'}
                      </AuthyStyles.Button>
                    </div>
                  </Col>
                </Row>
                <hr />
              </Form>
              <Row gutter={[16, 16]}>
                <Col span={24}>
                  <div style={{ textAlign: `center` }}>
                    <span style={{ paddingRight: `1em` }}>Didn&#39;t get your code?</span>
                    <AuthyStyles.Button disabled={props.loading} onClick={handleResend} block>
                      {props.loading ? '...' : 'Resend'}
                    </AuthyStyles.Button>
                  </div>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </AuthyStyles.Card>
    </AuthyStyles>
  );
};

// Property Types
Authy.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  handleResend: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  errors: PropTypes.object,
  external_error_code: PropTypes.string,
  data: PropTypes.object,
  form: PropTypes.object,
  token: PropTypes.string,
  validator: PropTypes.string,
  authy: PropTypes.any,
};

// Export
export default Form.create({ name: 'authy' })(Authy);
