// IMPORTS
// --------------------------------------------------------
import React from 'react';
import PropTypes from 'prop-types';
import DOMPurify from 'dompurify';
import { PageHeader, Spin, Divider } from 'antd';

// Styled Components
import { LessonStyles } from './styles.js';

// Presentation Components

// MAIN PRESENTATION COMPONENT
// --------------------------------------------------------
/**
 * Lesson
 * @param {Object} props component props
 * @returns {JSX} React compoennt
 */
const Lesson = props => {
  // State / Props
  const { data, loading, title, subTitle, sectionTitle, sectionSubTitle, subSectionTitle, subSectionSubTitle } = props;

  // Functions

  // Hooks

  // Render
  return loading ? (
    <Spin />
  ) : (
    <LessonStyles>
      <PageHeader title={title ?? ''} />
      <h2>{subTitle}</h2>
      <h3>{sectionTitle}</h3>
      <h4>{sectionSubTitle}</h4>
      <h5>{subSectionTitle}</h5>
      <h6>{subSectionSubTitle}</h6>
      {(subTitle || sectionTitle || sectionSubTitle || subSectionTitle || subSectionSubTitle) && <Divider />}
      <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(data, { ADD_ATTR: ['target'] }) }} />
    </LessonStyles>
  );
};

// PROP TYPES
// --------------------------------------------------------
Lesson.propTypes = {
  data: PropTypes.any,
  title: PropTypes.string,
  subTitle: PropTypes.string,
  sectionTitle: PropTypes.string,
  loading: PropTypes.bool,
  sectionSubTitle: PropTypes.string,
  subSectionTitle: PropTypes.string,
  subSectionSubTitle: PropTypes.string,
};

// EXPORTS
// --------------------------------------------------------
export default Lesson;
