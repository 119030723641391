// Export Types
export const CLEAR_LMSCOURSE = 'CLEAR_LMSCOURSE';
export const GET_LMSCOURSE_REQUEST = 'GET_LMSCOURSE_REQUEST';
export const GET_LMSCOURSE_SUCCESS = 'GET_LMSCOURSE_SUCCESS';
export const GET_LMSCOURSE_FAILURE = 'GET_LMSCOURSE_FAILURE';

export const GET_GUEST_LMSCOURSE_REQUEST = 'GET_GUEST_LMSCOURSE_REQUEST';
export const GET_GUEST_LMSCOURSE_SUCCESS = 'GET_GUEST_LMSCOURSE_SUCCESS';
export const GET_GUEST_LMSCOURSE_FAILURE = 'GET_GUEST_LMSCOURSE_FAILURE';

export const GET_GUEST_LMSCOURSES_REQUEST = 'GET_GUEST_LMSCOURSES_REQUEST';
export const GET_GUEST_LMSCOURSES_SUCCESS = 'GET_GUEST_LMSCOURSES_SUCCESS';
export const GET_GUEST_LMSCOURSES_FAILURE = 'GET_GUEST_LMSCOURSES_FAILURE';
