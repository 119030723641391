// IMPORTS
// --------------------------------------------------------
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Redirect } from 'react-router-dom';

// Actions
import { doReadScriptAttemptsRequest, doReadScriptAttemptsReset } from '../../redux/modules/ScriptAttempts/read/actions';
import { doCreateScriptAttemptsRequest, doCreateScriptAttemptsReset } from '../../redux/modules/ScriptAttempts/create/actions';
import { doUpdateScriptAttemptsRequest, doUpdateScriptAttemptsReset } from '../../redux/modules/ScriptAttempts/update/actions';
import { doQuestionScriptAttemptsReset } from '../../redux/modules/ScriptAttempts/question/actions';

// Presentation Components
import ScriptAttemptResults from '../../components/ScriptAttemptResults';

// Container Components

// MAIN CONTAINER COMPONENT
// --------------------------------------------------------
/**
 * ScriptAttemptResultsContainer
 * @param {Object} props component props
 * @returns {JSX} React compoennt
 */
const ScriptAttemptResultsContainer = props => {
  // State / Props
  const {
    token,
    match,
    history,
    doCreateScriptAttemptsRequest: createScriptAttemptsRequest,
    doCreateScriptAttemptsReset: createScriptAttemptsReset,
    doReadScriptAttemptsRequest: readScriptAttemptsRequest,
    doReadScriptAttemptsReset: readScriptAttemptsReset,
    doUpdateScriptAttemptsRequest: updateScriptAttemptsRequest,
    doUpdateScriptAttemptsReset: updateScriptAttemptsReset,
    doQuestionScriptAttemptsReset: questionScriptAttemptsReset,
    createScriptAttempt,
    readScriptAttempt,
    updateScriptAttempt,
  } = props;
  const { scriptAttemptId, enrollmentId, courseId, lmsScriptId } = match.params;
  const [allowReview, setAllowReview] = useState(false);

  // Functions
  /**
   * When the button is clicked
   * @returns {void}
   */
  const handleRetry = () => {
    questionScriptAttemptsReset();
    createScriptAttemptsRequest({ token, enrollmentId, lmsScriptId });
  };

  /**
   * When handle review button is clicked
   * @returns {void}
   */
  const handleReview = () => {
    const lastSectionOrderIndex = readScriptAttempt?.data?.lms_script?.script?.sections?.reduce((a, b) => Math.max(a?.order_index ?? 0, b?.order_index ?? 0));
    const section = readScriptAttempt?.data?.lms_script?.script?.sections?.find(i => i.order_index === lastSectionOrderIndex);
    setAllowReview(true);
    updateScriptAttemptsRequest({
      token,
      id: scriptAttemptId,
      payload: {
        section_id: section.id,
        position: 0,
      },
    });
  };

  // Hooks
  // Step 1
  /**
   * On load / clean up
   */
  useEffect(() => {
    if (token && lmsScriptId && enrollmentId) {
      readScriptAttemptsRequest({ token, id: scriptAttemptId, enrollmentId });
    }
    return () => {
      readScriptAttemptsReset();
      updateScriptAttemptsReset();
      createScriptAttemptsReset();
    };
  }, [token, scriptAttemptId, enrollmentId]);

  // Render
  // No Data / Not Complete - Redirect
  if (readScriptAttempt?.loading === false && readScriptAttempt?.data && readScriptAttempt?.data?.complete === null) {
    return <Redirect to={`/dashboard/enrollments/${enrollmentId}/courses/${courseId}/lmslessons/${lmsScriptId}`} />;
  }

  // Creating New Attempt - Redirect
  if (createScriptAttempt?.loading === false && createScriptAttempt?.data) {
    return <Redirect to={`/dashboard/enrollments/${enrollmentId}/courses/${courseId}/lmslessons/${lmsScriptId}/attempts/${createScriptAttempt?.data?.id}`} />;
  }

  // Updating Script Attempt - Redirect
  if (updateScriptAttempt?.loading === false && updateScriptAttempt?.data && allowReview) {
    return <Redirect to={`/dashboard/enrollments/${enrollmentId}/courses/${courseId}/lmslessons/${lmsScriptId}/attempts/${scriptAttemptId}`} />;
  }
  return (
    <ScriptAttemptResults
      handleRetry={handleRetry}
      handleReview={handleReview}
      errors={readScriptAttempt?.errors}
      data={readScriptAttempt?.data}
      loading={readScriptAttempt?.loading}
      match={match}
      history={history}
    />
  );
};

// REDUX
// --------------------------------------------------------
/**
 * Main redux state
 * @param {*} state object
 * @returns {Object} state objects
 */
const mapStateToProps = state => ({
  token: state.loginReducer.token,
  readScriptAttempt: state.ReadScriptAttemptsReducer,
  createScriptAttempt: state.CreateScriptAttemptsReducer,
  updateScriptAttempt: state.UpdateScriptAttemptsReducer,
});

/**
 * Main redux dispatch functions
 * @param {*} dispatch redux dispatch
 * @returns {bindActionCreators} state objects
 */
const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      doCreateScriptAttemptsRequest,
      doCreateScriptAttemptsReset,
      doReadScriptAttemptsRequest,
      doReadScriptAttemptsReset,
      doUpdateScriptAttemptsRequest,
      doUpdateScriptAttemptsReset,
      doQuestionScriptAttemptsReset,
    },
    dispatch,
  );

// PROP TYPES
// --------------------------------------------------------
ScriptAttemptResultsContainer.propTypes = {
  token: PropTypes.string,
  match: PropTypes.any,
  history: PropTypes.any,
  doCreateScriptAttemptsRequest: PropTypes.func,
  doCreateScriptAttemptsReset: PropTypes.func,
  doReadScriptAttemptsRequest: PropTypes.func,
  doReadScriptAttemptsReset: PropTypes.func,
  doUpdateScriptAttemptsRequest: PropTypes.func,
  doUpdateScriptAttemptsReset: PropTypes.func,
  doQuestionScriptAttemptsReset: PropTypes.func,
  createScriptAttempt: PropTypes.any,
  readScriptAttempt: PropTypes.any,
  updateScriptAttempt: PropTypes.any,
};

// EXPORTS
// --------------------------------------------------------
export default connect(mapStateToProps, mapDispatchToProps)(ScriptAttemptResultsContainer);
