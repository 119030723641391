// IMPORTS
// --------------------------------------------------------
import React, { useEffect, useState } from 'react';
import { useStripe, CardElement, useElements } from '@stripe/react-stripe-js';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Purchase from '../../components/Purchase';
// Operations
import {
  doUpdateStudentInformation,
  doCreateAmazonPaymentIntent,
  doCreateCartEnrollment,
  doCreateCartCourse,
  doResetStripeData,
  doCreateStripePaymentIntent,
  doProcessAmazonPaymentIntent,
  doProcessStripePaymentIntent,
  doResetCartData,
  doSetStripeStatus,
  doGetCart,
} from './operations';
import { doGetMe } from '../Login/operations';
import { doSendFeedback } from '../Feedback/operations';
import Bugsnag from '@bugsnag/js';

// Purchase CONTAINER
// --------------------------------------------------------
/**
 * PurchaseContainer
 * @param {Object} props component props
 * @returns {JSX.Element} React component
 */
const PurchaseContainer = props => {
  const {
    token,
    doGetCart: getCart,
    doCreateCartCourse: createCartCourse,
    doCreateCartEnrollment: createCartEnrollment,
    doUpdateStudentInformation: updateStudentInformation,
    doCreateAmazonPaymentIntent: createAmazonPaymentIntent,
    doProcessAmazonPaymentIntent: processAmazonPaymentIntent,
    doResetCartData: resetCartData,
    doSendFeedback: sendFeedback,
    doResetStripeData: resetStripeData,
    doSetStripeStatus: setStripeStatus,
    doCreateStripePaymentIntent: createStripePaymentIntent,
    doProcessStripePaymentIntent: processStripePaymentIntent,
    doGetMe: getMe,
    mefetch,
    user,
    userErrors,
    cartData,
    studentData,
    stripeData,
    amazonData,
    match,
    location,
    history,
  } = props;

  const amazonCheckoutSessionId = new URLSearchParams(location?.search).get('amazonCheckoutSessionId');
  const { enrollmentId, cartId, lmsCourseId } = match?.params;

  // STATE / PROPS
  const [enrollment, setEnrollment] = useState(null);
  const [step, setStep] = useState(0);

  const stripeUserErrors = [
    'account_number_invalid',
    'acss_debit_session_incomplete',
    'balance_insufficient',
    'card_declined',
    'email_invalid',
    'expired_card',
    'incorrect_address',
    'incorrect_cvc',
    'incorrect_number',
    'incorrect_zip',
    'invalid_card_type',
    'invalid_characters',
    'invalid_cvc',
    'invalid_expiry_month',
    'invalid_expiry_year',
    'invalid_number',
    'invalid_cvc',
    'incomplete_expiry',
    'incomplete_number',
    'incomplete_zip',
    'payment_intent_authentication_failure',
    'payment_intent_payment_attempt_failed',
    'payment_method_currency_mismatch',
    'payment_method_provider_decline',
    'payment_method_unactivated',
    'postal_code_invalid',
    'processing_error',
  ];

  useEffect(() => {
    if (user?.student?.enrollments) {
      setEnrollment(user.student.enrollments.find(e => e.id === parseInt(enrollmentId)));
    }
  }, [user]);

  useEffect(() => {
    if (enrollment?.guest) {
      setStep(0);
    } else if (user?.student?.address === '') {
      setStep(1);
    } else if (enrollment && !enrollment.guest && step !== 2) {
      getMe({ token });
      setStep(2);
    }
  }, [enrollment, user]);

  // STEP UTILITY
  const handleCheckoutStep = () => {
    setStep(0);
  };
  const handleStudentInformationStep = () => {
    if (user.full_user) {
      getMe({ token });
      setStep(2);
    } else {
      setStep(1);
    }
  };

  const handleConfirmationStep = () => {
    getMe({ token });
    setStep(2);
  };

  //PAYMENT CHANGE EVENT
  const onPaymentTypeChange = event => {};

  useEffect(() => {
    if (amazonData && amazonData?.data?.complete) {
      handleStudentInformationStep();
    }
  }, [token, amazonCheckoutSessionId, cartData, amazonData]);

  //Get amazon checkout session if return
  useEffect(() => {
    if (amazonCheckoutSessionId && amazonData && amazonData?.data?.transaction_ref && cartData && cartData?.data?.id) {
      let cartId = cartData?.data?.id;
      let transactionRef = amazonData?.data?.transaction_ref;
      const queryParams = new URLSearchParams(location?.search);
      if (queryParams.has('amazonCheckoutSessionId')) {
        queryParams.delete('amazonCheckoutSessionId');
        history.replace({
          search: queryParams.toString(),
        });
      }
      processAmazonPaymentIntent({ token, amazonCheckoutSessionId, cartId, transactionRef });
    }
  }, [token, amazonCheckoutSessionId, cartData, amazonData]);

  //CREATE CART WITH CART ITEMS OF ENROLLMENT IF IT DOES NOT EXIST
  useEffect(() => {
    if (!cartData?.fetching && !cartData?.errors) {
      if (enrollmentId && !cartId && !lmsCourseId) {
        if (token && enrollmentId && cartData && !cartData?.data && user) {
          getMe({ token });
          createCartEnrollment({ token, enrollmentId });
        }
      } else if (cartId && !enrollmentId && !lmsCourseId && cartData && !cartData?.data) {
        getCart({ token, cartId });
      } else if (lmsCourseId && !cartId && !enrollmentId && cartData && !cartData?.data) {
        createCartCourse({ token, lmsCourseId });
      }
    }
  }, [token, user, enrollmentId, cartId, setStep, cartData, getMe, createCartEnrollment, createCartCourse, getCart]);

  useEffect(() => {
    if (stripeData.errors) {
      if (stripeData.errors.code && stripeUserErrors.includes(stripeData.errors.code)) {
        let $message = `
          <p>Message: ${stripeData.errors.message}</p>
          <p>Error Type: ${stripeData.errors.type}</p>
          <p>Error Code: <a href="${stripeData.errors.doc_url}" target="_blank">${stripeData.errors.code}</a></p>`;
        $message += stripeData.errors.param ? `<p>Param: ${stripeData.errors.param}</p>` : '';
        $message += stripeData.errors.decline_code ? `<p>Decline Code: ${stripeData.errors.decline_code}</p>` : '';
        $message += `<p>Cart Total: $${cartData.data.cart_total}</p>`;
        $message += `<p>Had Previous Errors: ${stripeData.errorCount > 1 ? `Yes (${stripeData.errorCount - 1})` : 'No'}</p>`;

        sendFeedback({ token, feedbackType: 'Declined Purchase', feedback: $message, enrollmentId, alertUser: false });
      } else {
        Bugsnag.notify(stripeData.errors);
      }
    }
  }, [stripeData]);

  //Spawn bugsnag for cart request errors
  useEffect(() => {
    if (!cartData?.fetching && cartData?.errors) {
      Bugsnag.notify(cartData.errors);
    }
  }, [cartData]);

  //Spawn bugsnags for amazon request errors
  useEffect(() => {
    if (!amazonData?.fetching && amazonData?.errors) {
      Bugsnag.notify(amazonData.errors);
    }
  }, [amazonData]);

  // RENDER
  return (
    <Purchase
      token={token}
      lmsCourseId={lmsCourseId}
      enrollmentId={enrollmentId}
      cartId={cartId}
      amazonCheckoutSessionId={amazonCheckoutSessionId}
      updateStudentInformation={updateStudentInformation}
      createStripePaymentIntent={createStripePaymentIntent}
      processStripePaymentIntent={processStripePaymentIntent}
      setStripeStatus={setStripeStatus}
      resetStripeData={resetStripeData}
      createAmazonPaymentIntent={createAmazonPaymentIntent}
      processAmazonPaymentIntent={processAmazonPaymentIntent}
      getMe={getMe}
      userErrors={userErrors}
      mefetch={mefetch}
      user={user}
      cartData={cartData}
      studentData={studentData}
      stripeData={stripeData}
      amazonData={amazonData}
      step={step}
      //STEP 1
      handleStudentInformationStep={handleStudentInformationStep}
      //STEP 2
      handleCheckoutStep={handleCheckoutStep}
      //STEP 3
      handleConfirmationStep={handleConfirmationStep}
      //PAYMENT CHANGE EVENT
      onPaymentTypeChange={onPaymentTypeChange}
    />
  );
};

// REDUX
// --------------------------------------------------------
/**
 * Maps Redux State To Component
 * @param {Object} state main redux state
 * @returns {Object} Redux Reducer States
 */
const mapStateToProps = state => ({
  token: state.loginReducer.token,
  user: state.loginReducer.user,
  mefetch: state.loginReducer.fetching,
  userErrors: state.loginReducer.errors,
  cartData: state.paymentReducer.cartData,
  studentData: state.paymentReducer.studentData,
  stripeData: state.paymentReducer.stripeData,
  amazonData: state.paymentReducer.amazonData,
});

/**
 * Maps Redux Actions To Component
 * @param {*} dispatch main redux dispatch event
 * @returns {{}} Redux Dispatch
 */
const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      doResetCartData,
      doGetMe,
      doGetCart,
      doSetStripeStatus,
      doResetStripeData,
      doUpdateStudentInformation,
      doCreateCartEnrollment,
      doCreateCartCourse,
      doCreateStripePaymentIntent,
      doProcessStripePaymentIntent,
      doCreateAmazonPaymentIntent,
      doProcessAmazonPaymentIntent,
      doSendFeedback,
    },
    dispatch,
  );

// PROPS TYPES
// --------------------------------------------------------
PurchaseContainer.propTypes = {
  token: PropTypes.string,
  doGetMe: PropTypes.func,
  doResetCartData: PropTypes.func,
  doGetCart: PropTypes.func,
  doUpdateStudentInformation: PropTypes.func,
  doCreateCartEnrollment: PropTypes.func,
  doCreateCartCourse: PropTypes.func,
  doCreateStripePaymentIntent: PropTypes.func,
  doResetStripeData: PropTypes.func,
  doSetStripeStatus: PropTypes.func,
  doSendFeedback: PropTypes.func,
  doProcessStripePaymentIntent: PropTypes.func,
  doCreateAmazonPaymentIntent: PropTypes.func,
  doProcessAmazonPaymentIntent: PropTypes.func,
  cartData: PropTypes.object,
  studentData: PropTypes.object,
  amazonData: PropTypes.object,
  stripeData: PropTypes.object,
  user: PropTypes.object,
  match: PropTypes.object,
  location: PropTypes.object,
  history: PropTypes.object,
  mefetch: PropTypes.bool,
  userErrors: PropTypes.any,
};

// EXPORTS
// --------------------------------------------------------
export default connect(mapStateToProps, mapDispatchToProps)(PurchaseContainer);
