// Imports
import styled from 'styled-components';

// Main
/**
 * Main container style
 */
const AccountStyles = styled.div`
  padding: 20px;
  .ant-card {
    margin-bottom: 20px;
  }
`;

// Export
export default AccountStyles;
