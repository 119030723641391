// Imports
import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

// Operations
import { doPasswordReset } from './operations';

// Presentation Components
import PasswordReset from '../../components/PasswordReset';

// Main Component
/**
 * Main Container
 * @param {*} props any
 * @returns {JSX.Element} Main JSX Routes
 */
const PasswordResetContainer = props => {
  // Props
  const { doPasswordReset: passwordReset, errors, fetching, data, history, match } = props;

  const { token, validator } = match.params;

  /**
   * Main function that handle form submit
   *
   * @param {event} event browser event
   * @param {form} form antd form object
   *
   * @returns {dispatch} Dispatches PasswordReset function
   */
  const handleSubmit = ({ password, password_confirmation, token, validator }) => {
    passwordReset({ password, password_confirmation, token, validator });
  };

  // /**
  //  * Redirect after password change success
  //  */
  useEffect(() => {
    if (!fetching && data != null && errors === null) {
      setTimeout(function() {
        history.push(`/login`);
      }, 2000);
    }
  }, [data, fetching, errors, history]);

  // Render
  return <PasswordReset data={data} token={token} validator={validator} loading={props.fetching} handleSubmit={handleSubmit} errors={props.errors || []} />;
};

// Mapping State / Dispatch To Props
/**
 * Main redux state
 * @param {*} state object
 * @returns {Object} state objects
 */
const mapStateToProps = state => ({
  fetching: state.passwordResetReducer.fetching,
  data: state.passwordResetReducer.data,
  errors: state.passwordResetReducer.errors,
});

/**
 * Main redux dispatch functions
 * @param {*} dispatch redux dispatch
 * @returns {bindActionCreators} state objects
 */
const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      doPasswordReset,
    },
    dispatch,
  );

// Property Types
PasswordResetContainer.propTypes = {
  doPasswordReset: PropTypes.func,
  data: PropTypes.object,
  match: PropTypes.object,
  history: PropTypes.object,
  fetching: PropTypes.bool,
  token: PropTypes.string,
  validator: PropTypes.string,
  errors: PropTypes.array,
};

// Export
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PasswordResetContainer));
