// IMPORTS
// --------------------------------------------------------
import axios from 'axios';

// CONFIG
// --------------------------------------------------------
const apiUri = window.API_URL;

// MAIN REQUESTS
// --------------------------------------------------------
/**
 * List all ScriptAttempts
 * @returns {Promise} Axios Promise
 */
export const listScriptAttempts = ({ token }) => axios({ method: 'get', url: `${apiUri}/scriptattempts`, headers: { Authorization: `Bearer ${token}` } });

/**
 * Create ScriptAttempts
 * @returns {Promise} Axios Promise
 */
export const createScriptAttempts = ({ token, enrollmentId, lmsScriptId, sectionId }) =>
  axios({ method: 'post', url: `${apiUri}/scriptattempts`, data: { lms_script_id: lmsScriptId, enrollment_id: enrollmentId, section_id: sectionId }, headers: { Authorization: `Bearer ${token}` } });

/**
 * Read individual ScriptAttempts
 * @returns {Promise} Axios Promise
 */
export const readScriptAttempts = ({ token, id, enrollmentId, include }) => {
  const defaultWith = include
    ? include
    : 'lmsScript.lmsCourse.lmsCourseMembers,section.sectionslides,section.sectiontype,section.videoAsset,section.videoCaptionAsset,section.videoStatus,section.sectionslides.question.questionoptions,lmsScript.script.sections,lmsScript.script.sections.sectiontype,lmsScript.script.sections.sectionslides,scriptAttemptAnswers,scriptAttemptAnswers.question,scriptAttemptAnswers.questionoption';
  return axios({ method: 'get', url: `${apiUri}/scriptattempts/${id}?with=${defaultWith}&${enrollmentId ? `enrollment_id=${enrollmentId}` : ''}`, headers: { Authorization: `Bearer ${token}` } });
};

/**
 * Update individual ScriptAttempts
 * @returns {Promise} Axios Promise
 */
export const updateScriptAttempts = ({ token, id, payload }) => axios({ method: 'put', url: `${apiUri}/scriptattempts/${id}`, data: payload, headers: { Authorization: `Bearer ${token}` } });

/**
 * Delete individual ScriptAttempts
 * @returns {Promise} Axios Promise
 */
export const deleteScriptAttempts = ({ token, id }) => axios({ method: 'delete', url: `${apiUri}/scriptattempts/${id}`, headers: { Authorization: `Bearer ${token}` } });

/**
 * Creates new watched video for specific script attempt
 * @param {Object} param0 { token, id, payload }
 * @returns {Promise} Axios Promise
 */
export const watchedVideosScriptAttempts = ({ token, id, payload }) =>
  axios({ method: 'post', url: `${apiUri}/scriptattempts/${id}/watchedvideos`, data: payload, headers: { Authorization: `Bearer ${token}` } });

/**
 * Creates new script attempt answer to question
 * @param {Object} param0 { token, id, questionId, questionOptionId }
 * @returns {Promise} Axios Promise
 */
export const answersScriptAttempts = ({ token, id, questionId, questionOptionId }) =>
  axios({
    method: 'post',
    url: `${apiUri}/scriptattemptanswers`,
    data: { script_attempt_id: id, question_id: questionId, question_option_id: questionOptionId },
    headers: { Authorization: `Bearer ${token}` },
  });

/**
 * Retrieves answers to specific question associated to script attempt
 * @param {*} param0 ({ token, id, questionId }
 * @returns {Promise} Axios Promise
 */
export const questionScriptAttempts = ({ token, id, questionId }) =>
  axios({ method: 'get', url: `${apiUri}/scriptattempts/${id}/questions/${questionId}`, headers: { Authorization: `Bearer ${token}` } });

/**
 * Get all tracking associated to a script attempt
 * @returns {Promise} Axios Promise
 */
export const trackingScriptAttempts = ({ token, id }) => axios({ method: 'get', url: `${apiUri}/scriptattempts/${id}/tracking`, headers: { Authorization: `Bearer ${token}` } });
