// IMPORTS
// --------------------------------------------------------
import React from 'react';
import PropTypes from 'prop-types';
import { PageHeader, Alert, Table, Steps, Skeleton } from 'antd';
import PurchaseStyles from './styles';
import StudentInformation from './Steps/StudentInformation';
import PaymentProcessor from './Steps/PaymentProcessor';
import Confirmation from './Steps/Confirmation';
import { withRouter } from 'react-router-dom';

// Purchase COMPONENT
// --------------------------------------------------------

/**
 * Purchase
 * @param {Object} props component props
 * @returns {JSX} React component
 */
const Purchase = props => {
  // PROPS / STATE
  const {
    token,
    enrollmentId,
    lmsCourseId,
    cartId,
    amazonCheckoutSessionId,
    history,
    updateStudentInformation,
    createAmazonPaymentIntent,
    resetStripeData,
    createStripePaymentIntent,
    processStripePaymentIntent,
    generateAmazonButtonSignature,
    processAmazonPaymentIntent,
    getMe,
    mefetch,
    onPaymentTypeChange,
    handleStudentInformationStep,
    handleCheckoutStep,
    handleConfirmationStep,
    step,
    cartData,
    studentData,
    stripeData,
    amazonData,
    setStripeStatus,
    user,
    userErrors,
  } = props;

  return (
    <PurchaseStyles>
      {!lmsCourseId && !cartId && step < 1 ? (
        <>
          <PageHeader title={'GUEST ACCESS EXPIRED'} ghost={false} />
          <div style={{ marginBottom: 15, fontSize: 32 }}>
            <Alert
              style={{ fontSize: 18 }}
              message={`Congratulations, you're ${window.GUEST_PERCENT_COMPLETE}% of the way through your course. Your trial ends here but pick up where you left off and purchase your course today.`}
              type="warning"
            />
          </div>
        </>
      ) : (
        ''
      )}
      <PurchaseStyles.Card style={{ padding: 0 }}>
        {cartData?.fetching || !cartData?.data ? (
          <div style={{ padding: 15, position: `relative` }}>
            <div style={{ position: `absolute`, top: 55, left: 20 }}>
              <h3 style={{ fontSize: 50 }}>Loading...</h3>
            </div>
            <Skeleton active />
          </div>
        ) : (
          <div>
            <div style={{ marginBottom: 15 }}>
              <Table
                loading={cartData?.fetching}
                pagination={false}
                columns={[
                  {
                    title: 'Product',
                    dataIndex: 'name',
                    render: (_text, record) => {
                      return record.name;
                    },
                  },
                  {
                    title: 'Price',
                    dataIndex: 'price',
                    align: 'right',
                  },
                ]}
                dataSource={cartData?.data?.cart_items.map(item => {
                  return { key: 1, name: item.cart_course.name, price: '$' + item?.item_total };
                })}
              />
            </div>
            <div>
              <div style={{ padding: 15, borderBottom: '1px solid #e8e8e8' }}>
                <Steps current={step} size={`default`} style={{ marginBottom: 15 }}>
                  <Steps.Step title="Payment" />
                  <Steps.Step title="Information" />
                  <Steps.Step title="Confirmation" />
                </Steps>
              </div>
              <div className="steps-content" style={{ padding: 15 }}>
                {
                  {
                    0: (
                      <PaymentProcessor
                        token={token}
                        enrollmentId={enrollmentId}
                        amazonCheckoutSessionId={amazonCheckoutSessionId}
                        cartData={cartData}
                        amazonData={amazonData}
                        stripeData={stripeData}
                        user={user}
                        onPaymentTypeChange={onPaymentTypeChange}
                        handleConfirmationStep={handleConfirmationStep}
                        handleStudentInformationStep={handleStudentInformationStep}
                        resetStripeData={resetStripeData}
                        getMe={getMe}
                        createStripePaymentIntent={createStripePaymentIntent}
                        processStripePaymentIntent={processStripePaymentIntent}
                        createAmazonPaymentIntent={createAmazonPaymentIntent}
                        processAmazonPaymentIntent={processAmazonPaymentIntent}
                        setStripeStatus={setStripeStatus}
                      />
                    ),
                    1: (
                      <StudentInformation
                        token={token}
                        mefetch={mefetch}
                        getMe={getMe}
                        studentData={studentData}
                        user={user}
                        updateStudentInformation={updateStudentInformation}
                        handleConfirmationStep={handleConfirmationStep}
                        handleCheckoutStep={handleCheckoutStep}
                      />
                    ),
                    2: <Confirmation user={user} histor={history} cartData={cartData} enrollmentId={enrollmentId} lmsCourseId={lmsCourseId} cartId={cartId} />,
                  }[step]
                }
              </div>
            </div>
          </div>
        )}
      </PurchaseStyles.Card>
      <div>
        <PurchaseStyles.Errors show={cartData.errors && cartData.errors.length > 0} count={cartData.errors && cartData.errors.length}>
          {cartData.errors && cartData.errors.length >= 1 && cartData.errors.map((val, key) => <PurchaseStyles.Error key={key.toString()}>{val}</PurchaseStyles.Error>)}
        </PurchaseStyles.Errors>
        <PurchaseStyles.Errors show={studentData.errors && studentData.errors.length > 0} count={studentData.errors && studentData.errors.length}>
          {studentData.errors && studentData.errors.length >= 1 && studentData.errors.map((val, key) => <PurchaseStyles.Error key={key.toString()}>{val}</PurchaseStyles.Error>)}
        </PurchaseStyles.Errors>
        <PurchaseStyles.Errors show={amazonData.errors && amazonData.errors.length > 0} count={amazonData.errors && amazonData.errors.length}>
          {amazonData.errors && amazonData.errors.length >= 1 && amazonData.errors.map((val, key) => <PurchaseStyles.Error key={key.toString()}>{val}</PurchaseStyles.Error>)}
        </PurchaseStyles.Errors>
        <PurchaseStyles.Errors show={stripeData.errors} count={stripeData.errors && stripeData.errors.length ? stripeData.errors.length : 1}>
          {stripeData.errors && stripeData.errors.message ? (
            <PurchaseStyles.Error>Payment failed: {stripeData.errors.message}</PurchaseStyles.Error>
          ) : (
            stripeData.errors && stripeData.errors.length >= 1 && stripeData.errors.map((val, key) => <PurchaseStyles.Error key={key.toString()}>{val}</PurchaseStyles.Error>)
          )}
        </PurchaseStyles.Errors>
        <PurchaseStyles.Errors show={userErrors && userErrors.length > 0} count={userErrors && userErrors.length}>
          {userErrors && userErrors.length >= 1 && userErrors.map((val, key) => <PurchaseStyles.Error key={key.toString()}>{val}</PurchaseStyles.Error>)}
        </PurchaseStyles.Errors>
      </div>
    </PurchaseStyles>
  );
};

// PROPS TYPES
// --------------------------------------------------------
Purchase.propTypes = {
  token: PropTypes.string,
  enrollmentId: PropTypes.string,
  lmsCourseId: PropTypes.number,
  cartId: PropTypes.number,
  amazonCheckoutSessionId: PropTypes.string,
  step: PropTypes.number,
  history: PropTypes.object,
  user: PropTypes.object,
  cartData: PropTypes.object,
  studentData: PropTypes.object,
  stripeData: PropTypes.object,
  amazonData: PropTypes.object,
  handleStudentInformationStep: PropTypes.func,
  handleCheckoutStep: PropTypes.func,
  handleConfirmationStep: PropTypes.func,
  getMe: PropTypes.func,
  resetStripeData: PropTypes.func,
  updateStudentInformation: PropTypes.func,
  generateAmazonButtonSignature: PropTypes.func,
  createAmazonPaymentIntent: PropTypes.func,
  processAmazonPaymentIntent: PropTypes.func,
  processStripePaymentIntent: PropTypes.func,
  createStripePaymentIntent: PropTypes.func,
  onPaymentTypeChange: PropTypes.func,
  mefetch: PropTypes.bool,
  userErrors: PropTypes.any,
  setStripeStatus: PropTypes.func,
};

// EXPORTS
// --------------------------------------------------------
export default withRouter(Purchase);
