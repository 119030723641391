// Imports
import axios from 'axios';

// Config
const apiUri = window.API_URL;

// Main Actions
/**
 * doGetLessonStatus Action
 * @param {*} Object { token, courseItemId, enrollmentId }
 * @returns {promise} axios promise
 */
export const doGetLessonStatus = ({ token, scriptId, enrollmentId }) => {
  return axios({
    method: 'get',
    url: `${apiUri}/lmsscripts/${scriptId}?enrollment_id=${enrollmentId}&with=script.courseitems,scriptAttempts.scriptAttemptAnswers`,
    headers: { Authorization: `Bearer ${token}` },
  });
};
