// IMPORTS
import axios from 'axios';
import { push } from 'connected-react-router';

export default {
  setupInterceptors: ({ store, history }) => {
    axios.interceptors.response.use(
      res => res,
      error => {
        const loginReducer = store().getState().loginReducer;
        if (error.response.status === 401 && history.location.pathname !== '/login' && !loginReducer.fetching) {
          store().dispatch(push('/logout'));
        }

        return Promise.reject(error);
      },
    );
  },
};
