// Imports
import React from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';

// Main Component
/**
 * Private routes
 * @param {*} param0 component, redirect and additional props
 * @returns {JSX.Element} Main JSX Routes
 */
const PrivateRoute = ({ component: Component, redirect, ...props }) => (
  <Route
    {...props}
    render={renderProps => {
      return !redirect ? <Component {...renderProps} /> : <Redirect to={{ pathname: '/login', state: { from: renderProps.location } }} />;
    }}
  />
);

// Prop Types
PrivateRoute.propTypes = {
  component: PropTypes.any,
  redirect: PropTypes.bool,
};

// Exports
export default PrivateRoute;
