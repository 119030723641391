// IMPORTS
import { createBrowserHistory } from 'history';
import { applyMiddleware, compose, createStore } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import { routerMiddleware } from 'connected-react-router';
import createSagaMiddleware from 'redux-saga';
import storage from 'redux-persist/lib/storage';

// MAIN REDUX REDUCER + SAGA
import createRootReducer from './reducers';
import rootSaga from './sagas';

// Configure Persistor
const persistConfig = {
  key: 'aypo',
  whitelist: ['loginReducer'],
  storage,
};

// Configure History
export const history = createBrowserHistory();

// Configure Enhancers
const composeEnhancers = (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({ trace: true })) || compose;

// MAIN ROOT STORE
/**
 * Main redux store
 * @param {*} preloadedState Object state
 * @returns {createStore} redux createStore
 */
const configureStore = (preloadedState = {}) => {
  const sagaMiddleware = createSagaMiddleware();

  const store = createStore(
    persistReducer(persistConfig, createRootReducer(history)),
    preloadedState,
    composeEnhancers(
      applyMiddleware(
        routerMiddleware(history),
        sagaMiddleware,
        // ... other middlewares ...
      ),
    ),
  );

  sagaMiddleware.run(rootSaga);

  store.persistor = persistStore(store);

  return store;
};

// EXPORTS
export default configureStore;
