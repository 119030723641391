// IMPORTS
// --------------------------------------------------------
import styled from 'styled-components';
import Colors from '../../styles/Colors';
import { Button } from 'antd';
import Card from 'antd/es/card';

// MAIN STYLED COMPONENTS
// --------------------------------------------------------
/**
 * Parent styles for component
 * @constant
 */

const PurchaseStyles = styled.div`
  position: relative;
  padding: 20px;

  .level-2 {
    padding-left: 10px;
  }

  .level-3 {
    padding-left: 20px;
  }

  .level-4 {
    padding-left: 30px;
  }

  .level-5 {
    padding-left: 40px;
  }

  .level-6 {
    padding-left: 50px;
  }

  header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    > div {
      width: 50%;
      display: flex;

      .ant-progress {
        padding-right: 1rem;
      }
    }
  }

  .ant-card-body {
    .ant-spin {
      display: block;
      margin: 200px auto;
    }
  }

  footer {
    display: flex;
    justify-content: space-between;

    button {
      span {
        font-weight: bold;
        text-transform: capitalize;
      }
    }
  }
`;
PurchaseStyles.Button = styled(Button)`
  margin: auto;
  width: 329px;
  height: 61px;
  background-color: #ff8a00;
  font-size: 24px;
  text-transform: uppercase;
  color: #ffffff;
  cursor: pointer;
  border: 0;
  :hover {
    background-color: #0098e4;
    color: #fff;
    box-shadow: 0px 0px 9px 0px rgb(47 143 205 / 25%);
  }
`;

PurchaseStyles.Card = styled(Card)`
  .ant-card-body {
    padding: 0px;
  }
  .ant-tabs-tab-prev {
    background: aliceblue;
  }
  .ant-tabs-tab-next {
    background: aliceblue;
  }
`;

PurchaseStyles.Errors = styled.ul`
  background: ${Colors.pink};
  display: block;
  list-style: none;
  margin: ${props => (props.show ? '20px 0 20px 0' : '0')};
  padding: 0;
  border-radius: 4px;
  height: ${props => (props.show ? `${48 * props.count}px` : '0px')};
  overflow: hidden;
  transition: all 250ms ease-in-out 0s;
`;

PurchaseStyles.Error = styled.li`
  color: ${Colors.red};
  padding: 15px 20px;
`;
export default PurchaseStyles;
