// IMPORTS
// --------------------------------------------------------
// Redux
import { put, takeLatest, call } from 'redux-saga/effects';

// Type
import { ANSWERS_SCRIPTATTEMPTS_REQUEST } from './types';

// Actions
import { doAnswersScriptAttemptsFailure, doAnswersScriptAttemptsSuccess } from './actions';

// Requests
import { answersScriptAttempts } from '../requests';

// MAIN SAGAS
// --------------------------------------------------------
/**
 * Worker for handling answersScriptAttempts
 * @return {void}
 */
function* workerAnswersScriptAttempts({ token, id, questionId, questionOptionId }) {
  try {
    const response = yield call(answersScriptAttempts, { token, id, questionId, questionOptionId });

    if (!response?.data?.data) {
      throw new Error('workerAnswersScriptAttempts - Response Error');
    }

    const { data } = response.data;

    yield put(doAnswersScriptAttemptsSuccess({ data }));
  } catch (error) {
    const errors = error?.message ?? 'Something went wrong';
    yield put(doAnswersScriptAttemptsFailure({ errors }));
  }
}

/**
 * Watches for Dispatch ANSWERS_SCRIPTATTEMPTS_REQUEST
 * @return {void}
 */
export function* watcherAnswersScriptAttempts() {
  yield takeLatest(ANSWERS_SCRIPTATTEMPTS_REQUEST, workerAnswersScriptAttempts);
}
