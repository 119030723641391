// Imports
import styled from 'styled-components';
import { Table } from 'antd';
import Screens from '../../styles/Screens';
import Colors from '../../styles/Colors';

// Constants
import CONSTANTS from '../../utils/Constants';

// Main Component
const CourseOutlineStyles = styled.div`
  padding: 20px;
`;

CourseOutlineStyles.Table = styled(Table)`
  margin-bottom: 1rem;

  tr {
    cursor: pointer;
  }
  .${CONSTANTS.STATUS.COMPLETE} {
    td {
      background-color: ${Colors.green};
    }
  }
  .${CONSTANTS.STATUS.STARTED}, .${CONSTANTS.STATUS.NOT_STARTED} {
    font-weight: 700;
    td {
      background-color: ${Colors.blueLight};
    }
  }
  .${CONSTANTS.STATUS.RESTART} {
    font-weight: 700;
    td {
      background-color: ${Colors.redLight};
    }
  }
  .${CONSTANTS.STATUS.LOCKED} {
    cursor: default;
    td {
      background-color: ${Colors.greyf5};
    }
  }
`;

CourseOutlineStyles.StatusText = styled.span`
  min-width: 100px;
  text-align: left;
  display: none;
  @media (min-width: ${Screens.small}) {
    display: inline-block;
  }
`;

// Export
export default CourseOutlineStyles;
