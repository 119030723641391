// Imports
import axios from 'axios';

// Config
const apiUri = window.API_URL;

// Export actions
/**
 * Creates new quiz attempt answer
 * @param {*} param0 { token, enrollmentId, lmsQuizId }
 * @returns {AxiosPromise} object
 */
export const createQuizAttemptAnswer = ({ token, quizAttemptId, questionId, questionOptionId }) => {
  return axios({
    method: 'post',
    url: `${apiUri}/quizattemptanswers`,
    data: { quiz_attempt_id: quizAttemptId, question_id: questionId, question_option_id: questionOptionId },
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

/**
 * Update existing quiz attempt answer
 * @param {*} param0 { token, enrollmentId, lmsQuizId }
 * @returns {AxiosPromise} object
 */
export const updateQuizAttemptAnswer = ({ token, quizAttemptId, questionId, questionOptionId }) => {
  return axios({
    method: 'put',
    url: `${apiUri}/quizattemptanswers/${quizAttemptId}`,
    data: { question_id: questionId, question_option_id: questionOptionId },
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};
