// Imports
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Spin, Button, Icon } from 'antd';

// Styles
import QuestionStyles from './styles';

// Main Component
/**
 * Main Container
 * @param {*} props any
 * @returns {JSX.Element} Main JSX Routes
 */
const Question = props => {
  // State / Props
  const [options, setOptions] = useState([]);
  const [answers, setAnswers] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(null);
  const { data, loading, submitting: submittingParent, handleSubmit, attempt, index, complete, settings, answer } = props;
  const [submitting, setSubmitting] = useState(loading);

  // Functions
  /**
   * When the input radio is selected
   * @param {object} option input object
   * @returns {void}
   */
  const onClickButton = option => event => {
    if (handleSubmit) {
      handleSubmit(option);
    }
  };

  const randomizeArray = oldArray => {
    if (!oldArray || oldArray.length === 0) return [];
    const array = [...oldArray];
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  };

  const getOptionStatus = id => {
    if (attempt?.question?.correct_question_options?.filter(q => q.id === id).length > 0) {
      return 'correct';
    } else if (id === attempt?.question_option_id && attempt?.question?.correct_question_options?.filter(q => q.id === id).length === 0) {
      return 'incorrect';
    }
    return false;
  };
  // Hooks
  /**
   * On load set the question options
   */
  useEffect(() => {
    if (data?.questionoptions?.length > 0) {
      setSubmitting(false);
      if (
        !options ||
        options.length === 0 ||
        currentIndex === null ||
        currentIndex !== index ||
        (options.length > 0 && data?.questionoptions?.length > 0 && options[0]?.question_id !== data?.questionoptions[0]?.question_id)
      ) {
        const randomizedOptions = (data?.questionoptions?.length > 0 && randomizeArray(data?.questionoptions.map(i => ({ ...{ status: getOptionStatus(i.id), disabled: false }, ...i })) ?? [])) || [];

        // Set you local state
        setOptions(randomizedOptions);
        setAnswers(randomizedOptions);
        setCurrentIndex(index);
      } else if (attempt?.question?.correct_question_options?.length) {
        const updatedAnswers = answers.map(i => {
          return { ...i, status: getOptionStatus(i.id), disabled: true };
        });
        setAnswers(updatedAnswers);
      }
    }
  }, [data]);

  /**
   * WHen index changes, begin loading animation
   */
  useEffect(() => {
    setSubmitting(true);
  }, [index]);

  useEffect(() => {
    setSubmitting(!!loading);
  }, [loading]);

  const getSelectedAnswer = option => {
    return answer && answer.question_option_id === option.id;
  };

  // Render
  return (
    <QuestionStyles>
      {!submittingParent && loading && !data ? (
        ''
      ) : (
        <>
          <h1>{data?.question ?? ''}</h1>
          {options?.length > 0 && answer && (
            <>
              <ul>
                {/*<p>*/}
                {/*  {(submitting || submittingParent) && (*/}
                {/*    // <span style={{ position: 'relative' }}>*/}
                {/*    //   <Spin spinning={true} style={{ display: 'inline-block', left: 5, position: 'absolute' }} />*/}
                {/*    // </span>*/}
                {/*  )}*/}
                {/*</p>*/}
                {options.map((i, k) => {
                  let buttonClass = '';
                  if (answers[k]?.status && complete) {
                    buttonClass = answers[k]?.status;
                  } else if (getSelectedAnswer(answers[k])) {
                    buttonClass = 'selected';
                  }
                  return (
                    <li key={`question-${data?.id ?? ''}-option-${k}`}>
                      <Button disabled={loading || answers[k]?.disabled || complete} type="secondary" onClick={onClickButton(i)} className={buttonClass}>
                        {i?.option ?? ''}
                        {answers[k]?.status && answers[k]?.status === 'correct' && complete && <Icon type="check-circle" />}
                        {answers[k]?.status && answers[k]?.status === 'incorrect' && complete && <Icon type="close-circle" />}
                        {(!answers[k]?.status || !complete) && <Icon type="right" />}
                      </Button>
                    </li>
                  );
                })}
              </ul>
            </>
          )}
        </>
      )}
    </QuestionStyles>
  );
};

// Prop Types
Question.propTypes = {
  data: PropTypes.object,
  loading: PropTypes.bool,
  params: PropTypes.object,
  history: PropTypes.object,
  handleBegin: PropTypes.func,
  handleSubmit: PropTypes.func,
  index: PropTypes.number,
  attempt: PropTypes.object,
  complete: PropTypes.bool,
  settings: PropTypes.object,
  answer: PropTypes.any,
  submitting: PropTypes.any,
};

// Exports
export default Question;
