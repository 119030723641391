// Imports
import { takeLatest, put, call } from 'redux-saga/effects';

// Types
import {
  GET_LMSCOURSE_REQUEST,
  GET_LMSCOURSE_SUCCESS,
  GET_LMSCOURSE_FAILURE,
  GET_GUEST_LMSCOURSE_SUCCESS,
  GET_GUEST_LMSCOURSE_FAILURE,
  GET_GUEST_LMSCOURSE_REQUEST,
  GET_GUEST_LMSCOURSES_REQUEST,
  GET_GUEST_LMSCOURSES_FAILURE,
  GET_GUEST_LMSCOURSES_SUCCESS,
} from './types';

// Actions
import { getGuestLmsCourse, getGuestLmsCourses, getLmsCourse } from './actions';

// Main Sagas
/**
 * Retrieve LMS COURSE by id
 * @param {*} param0 { token, lmsCourseId, enrollmentId}
 * @returns {void}
 */
function* workerGetLmsCourse({ token, lmsCourseId, enrollmentId }) {
  try {
    const response = yield call(getLmsCourse, { token, lmsCourseId, enrollmentId });

    // Catch For No Response
    if (!response?.data?.data) {
      throw new Error('workerGetLmsCourse - Response Error');
    }

    const { data } = response.data;

    yield put({
      type: GET_LMSCOURSE_SUCCESS,
      data,
    });
  } catch (error) {
    const message = error?.message ?? 'Something went wrong';
    yield put({ type: GET_LMSCOURSE_FAILURE, errors: message });
  }
}

/**
 * Watches for request
 * @return {void}
 */
export function* watcherGetLmsCourse() {
  yield takeLatest(GET_LMSCOURSE_REQUEST, workerGetLmsCourse);
}

/**
 * Retrieve LMS COURSE by id
 * @param {*} param0 { token, lmsCourseId, enrollmentId}
 * @returns {void}
 */
function* workerGetGuestLmsCourse({ lmsCourseId }) {
  try {
    const response = yield call(getGuestLmsCourse, { lmsCourseId });

    // Catch For No Response
    if (!response?.data?.data) {
      throw new Error('workerGetGuestLmsCourse - Response Error');
    }

    const { data } = response.data;

    yield put({
      type: GET_GUEST_LMSCOURSE_SUCCESS,
      data,
    });
  } catch (error) {
    const message = error.response?.data?.error ?? ['Something went wrong'];
    yield put({ type: GET_GUEST_LMSCOURSE_FAILURE, errors: message });
  }
}

/**
 * Watches for request
 * @return {void}
 */
export function* watcherGetGuestLmsCourse() {
  yield takeLatest(GET_GUEST_LMSCOURSE_REQUEST, workerGetGuestLmsCourse);
}

/**
 * Retrieve LMS COURSE by id
 * @param {*} param0 { token, lmsCourseId, enrollmentId}
 * @returns {void}
 */
function* workerGetGuestLmsCourses() {
  try {
    const response = yield call(getGuestLmsCourses, {});

    // Catch For No Response
    if (!response?.data?.data) {
      throw new Error('workerGetGuestLmsCourses - Response Error');
    }

    const { data } = response.data;

    yield put({
      type: GET_GUEST_LMSCOURSES_SUCCESS,
      data,
    });
  } catch (error) {
    const message = error.response?.data?.error ?? ['Something went wrong'];
    yield put({ type: GET_GUEST_LMSCOURSES_FAILURE, errors: message });
  }
}

/**
 * Watches for request
 * @return {void}
 */
export function* watcherGetGuestLmsCourses() {
  yield takeLatest(GET_GUEST_LMSCOURSES_REQUEST, workerGetGuestLmsCourses);
}
