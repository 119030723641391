// Import types
import {
  PROCESS_AMAZON_PAYMENT_REQUEST,
  PROCESS_STRIPE_PAYMENT_REQUEST,
  CREATE_AMAZON_PAYMENT_REQUEST,
  CREATE_CART_ENROLLMENT_REQUEST,
  RESET_STRIPE_DATA,
  RESET_CART_DATA,
  SET_STRIPE_STATUS,
  CREATE_STRIPE_PAYMENT_REQUEST,
  UPDATE_STUDENT_INFORMATION_REQUEST,
  READ_CART_REQUEST,
  CREATE_CART_COURSE_REQUEST,
} from './types';

// Export operations
export const doGetCart = ({ token, cartId }) => ({
  type: READ_CART_REQUEST,
  token,
  cartId,
});

export const doUpdateStudentInformation = ({ token, studentId, firstName, lastName, zipCode, address, city, state, email, phone, password, confirmPassword }) => ({
  type: UPDATE_STUDENT_INFORMATION_REQUEST,
  token,
  studentId,
  firstName,
  lastName,
  zipCode,
  address,
  city,
  state,
  email,
  phone,
  password,
  confirmPassword,
});

export const doCreateCartEnrollment = ({ token, enrollmentId }) => ({
  type: CREATE_CART_ENROLLMENT_REQUEST,
  token,
  enrollmentId,
});

export const doCreateCartCourse = ({ token, lmsCourseId }) => ({
  type: CREATE_CART_COURSE_REQUEST,
  token,
  lmsCourseId,
});

export const doResetStripeData = () => ({
  type: RESET_STRIPE_DATA,
});

export const doResetCartData = () => ({
  type: RESET_CART_DATA,
});

export const doSetStripeStatus = ({ fetching, error }) => ({
  type: SET_STRIPE_STATUS,
  error: error,
  fetching: fetching,
});

export const doCreateStripePaymentIntent = ({ token, cartId }) => ({
  type: CREATE_STRIPE_PAYMENT_REQUEST,
  token,
  cartId,
});

export const doProcessStripePaymentIntent = ({ token, cartId, transactionRef }) => ({
  type: PROCESS_STRIPE_PAYMENT_REQUEST,
  token,
  cartId,
  transactionRef,
});

export const doCreateAmazonPaymentIntent = ({ token, enrollmentId, cartId }) => ({
  type: CREATE_AMAZON_PAYMENT_REQUEST,
  token,
  enrollmentId,
  cartId,
});

export const doProcessAmazonPaymentIntent = ({ token, amazonCheckoutSessionId, cartId, transactionRef }) => ({
  type: PROCESS_AMAZON_PAYMENT_REQUEST,
  token,
  amazonCheckoutSessionId,
  cartId,
  transactionRef,
});
