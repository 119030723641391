// Imports
import { takeLatest, put, call } from 'redux-saga/effects';

// Types
import { GET_COURSE_OUTLINE_REQUEST, GET_COURSE_OUTLINE_SUCCESS, GET_COURSE_OUTLINE_FAILURE, GET_COURSE_REVIEW_REQUEST, GET_COURSE_REVIEW_SUCCESS, GET_COURSE_REVIEW_FAILURE } from './types';

// Actions
import { doGetCourseOutline, doGetCourseReview } from './actions';

// Main Sagas
/**
 *
 * @param {*} param0 Authentication token
 * @returns {void}
 */
function* workerGetCourseOutline({ token, courseId, enrollmentId }) {
  try {
    const response = yield call(doGetCourseOutline, { token, courseId, enrollmentId });

    // Catch For No Response
    if (!response?.data?.data) {
      throw new Error('workerGetCourseOutline - Response Error');
    }

    const { data } = response.data;

    yield put({
      type: GET_COURSE_OUTLINE_SUCCESS,
      data,
    });
  } catch (error) {
    const message = error?.message ?? 'Something went wrong';
    yield put({ type: GET_COURSE_OUTLINE_FAILURE, errors: message });
  }
}

/**
 * Watches for request
 * @return {void}
 */
export function* watcherGetCourseOutline() {
  yield takeLatest(GET_COURSE_OUTLINE_REQUEST, workerGetCourseOutline);
}

/**
 *
 * @param {*} param0 Authentication token
 * @returns {void}
 */
function* workerGetCourseReview({ token, courseId, enrollmentId }) {
  try {
    const response = yield call(doGetCourseReview, { token, courseId, enrollmentId });

    // Catch For No Response
    if (!response?.data?.data) {
      throw new Error('workerGetCourseReview - Response Error');
    }

    const { data } = response.data;

    yield put({
      type: GET_COURSE_REVIEW_SUCCESS,
      data,
    });
  } catch (error) {
    const message = error?.message ?? 'Something went wrong';
    yield put({ type: GET_COURSE_REVIEW_FAILURE, errors: message });
  }
}

/**
 * Watches for request
 * @return {void}
 */
export function* watcherGetCourseReview() {
  yield takeLatest(GET_COURSE_REVIEW_REQUEST, workerGetCourseReview);
}
