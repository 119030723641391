// action types
import {
  GET_SCRIPT_ATTEMPTS_REQUEST,
  GET_SCRIPT_ATTEMPTS_SUCCESS,
  GET_SCRIPT_ATTEMPTS_FAILURE,
  GET_LESSON_TRACKER_REQUEST,
  GET_LESSON_TRACKER_SUCCESS,
  GET_LESSON_TRACKER_FAILURE,
  CREATE_SCRIPT_ATTEMPTS_REQUEST,
  CREATE_SCRIPT_ATTEMPTS_SUCCESS,
  CREATE_SCRIPT_ATTEMPTS_FAILURE,
  UPDATE_SCRIPT_ATTEMPTS_REQUEST,
  UPDATE_SCRIPT_ATTEMPTS_SUCCESS,
  UPDATE_SCRIPT_ATTEMPTS_FAILURE,
  CREATE_SCRIPT_ATTEMPT_WATCHED_REQUEST,
  CREATE_SCRIPT_ATTEMPT_WATCHED_SUCCESS,
  CREATE_SCRIPT_ATTEMPT_WATCHED_FAILURE,
} from './types';

// reducer with initial state
const initialState = {
  fetching: false,
  data: null,
  errors: null,
  scriptAttempts: {
    fetching: false,
    data: null,
    errors: null,
  },
  lessonTracker: {
    fetching: false,
    data: null,
    errors: null,
  },
  scriptAttemptWatchedVideo: {
    fetching: false,
    data: null,
    errors: null,
  },
};

const lessonsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_SCRIPT_ATTEMPTS_REQUEST:
      return {
        ...state,
        fetching: true,
        errors: null,
        data: null,
      };
    case GET_SCRIPT_ATTEMPTS_SUCCESS:
      return {
        ...state,
        fetching: false,
        errors: null,
        data: action.response.data.data,
      };
    case GET_SCRIPT_ATTEMPTS_FAILURE:
      return {
        ...state,
        fetching: false,
        errors: action.errors,
      };
    case GET_LESSON_TRACKER_REQUEST:
      return {
        ...state,
        lessonTracker: {
          fetching: true,
          errors: null,
        },
      };
    case GET_LESSON_TRACKER_SUCCESS:
      return {
        ...state,
        lessonTracker: {
          fetching: false,
          errors: null,
          data: action.response.data.data,
        },
      };
    case GET_LESSON_TRACKER_FAILURE:
      return {
        ...state,
        lessonTracker: {
          fetching: false,
          errors: action.errors,
        },
      };
    case CREATE_SCRIPT_ATTEMPTS_REQUEST:
    case UPDATE_SCRIPT_ATTEMPTS_REQUEST:
      return {
        ...state,
        scriptAttempts: {
          fetching: true,
          errors: null,
        },
      };
    case CREATE_SCRIPT_ATTEMPTS_SUCCESS:
    case UPDATE_SCRIPT_ATTEMPTS_SUCCESS:
      return {
        ...state,
        scriptAttempts: {
          fetching: false,
          errors: null,
          data: action.response.data.data,
        },
      };
    case CREATE_SCRIPT_ATTEMPTS_FAILURE:
    case UPDATE_SCRIPT_ATTEMPTS_FAILURE:
      return {
        ...state,
        scriptAttempts: {
          fetching: false,
          errors: action.error,
        },
      };
    case CREATE_SCRIPT_ATTEMPT_WATCHED_REQUEST:
      return {
        ...state,
        scriptAttemptWatchedVideo: {
          ...state.scriptAttemptWatchedVideo,
          fetching: true,
          errors: null,
        },
      };
    case CREATE_SCRIPT_ATTEMPT_WATCHED_SUCCESS:
      return {
        ...state,
        scriptAttemptWatchedVideo: {
          ...state.scriptAttemptWatchedVideo,
          fetching: false,
          data: null,
        },
      };
    case CREATE_SCRIPT_ATTEMPT_WATCHED_FAILURE:
      return {
        ...state,
        scriptAttemptWatchedVideo: {
          ...state.scriptAttemptWatchedVideo,
          fetching: false,
          errors: action.error,
        },
      };
    default:
      return state;
  }
};

export default lessonsReducer;
