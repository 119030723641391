/* eslint-disable react/jsx-key */
// Import
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import CourseOutlineStyles from './styles';
import { Alert, Icon, Divider, PageHeader, Card, Spin } from 'antd';

// Constants
import CONSTANTS from '../../utils/Constants';
import { parseJsonConfigFileContent } from 'typescript';

// Main Component
/**
 * Main Component
 * @param {*} props any
 * @return {JSX.Element} JSX Element
 */
const CourseOutline = props => {
  // Props
  const { data, loading, history, token, params, courseReview, user, outlineErrors } = props;
  const [downloading, setDownloading] = useState({});
  const [authLinkError, setAuthLinkError] = useState('');

  // Functions
  /*
   * Determine link based on objective type
   */
  const getObjectiveLink = courseItem => {
    let objectiveLink;
    if (courseItem.script_id) {
      objectiveLink = `/dashboard/enrollments/${params.enrollmentId}/courses/${params.courseId}/lmslessons/${courseItem?.script?.lms_scripts[0]?.id ?? ''}`;
    } else if (courseItem.quiz_id) {
      objectiveLink = `/dashboard/enrollments/${params.enrollmentId}/courses/${params.courseId}/lmsquizzes/${courseItem?.quiz?.lms_quizzes[0]?.id ?? ''}`;
    }
    return objectiveLink;
  };

  const getLatestByCreatedAt = array => {
    if (array && array.length > 0) {
      const sortedArray = array.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
      return sortedArray[0];
    } else {
      return null;
    }
  };

  /*
   * Determine text and icon depending on status
   */
  const getStatusValues = status => {
    const statusValues = { statusText: '', icon: '' };

    switch (status) {
      case CONSTANTS.STATUS.LOCKED:
        statusValues.statusText = 'Locked';
        statusValues.icon = 'lock';
        break;
      case CONSTANTS.STATUS.NOT_STARTED:
        statusValues.statusText = 'Start Now';
        statusValues.icon = 'right';
        break;
      case CONSTANTS.STATUS.STARTED:
        statusValues.statusText = 'Continue';
        statusValues.icon = 'right-circle';
        break;
      case CONSTANTS.STATUS.RESTART:
        statusValues.statusText = 'Restart';
        statusValues.icon = 'reload';
        break;
      case CONSTANTS.STATUS.COMPLETE:
        statusValues.statusText = 'Complete';
        statusValues.icon = 'check';
        break;
      case CONSTANTS.STATUS.AVAILABLE:
        statusValues.statusText = 'Available';
        statusValues.icon = 'download';
        break;
      default:
        statusValues.statusText = 'Incomplete';
        statusValues.icon = 'right';
        break;
    }
    return statusValues;
  };

  const getCourseMemberPrevStatus = (courseMember, index) => {
    return unlockProgress() ? false : courseMember[index - 1] && getCourseMemberStatus(courseMember[index - 1]);
  };

  const getCourseItemPrevStatus = (courseMember, index) => {
    return unlockProgress() ? false : courseMember.courseitems[index - 1] && getCourseItemStatus(courseMember.courseitems[index - 1]);
  };

  const getCourseMemberStatus = courseMember => {
    for (let i = 0; i < courseMember.courseitems.length; i++) {
      const status = getCourseItemStatus(courseMember.courseitems[i]);
      if (status !== CONSTANTS.STATUS.COMPLETE) {
        return status;
      }
    }
  };

  const getEnrollment = () => {
    return data?.enrollments?.find(i => i.lms_course_id === data?.id);
  };

  const parseErrors = errorResponse => {
    return (
      errorResponse && (
        <span style={{ color: '#f5222d' }}>
          <p>An error has occurred</p>
          {Object.values(errorResponse).map(error => (Array.isArray(error) ? error.map(suberror => <p key={`error`}>{suberror}</p>) : <p>{error}</p>))}
        </span>
      )
    );
  };

  const downloadWithAuth = async record => {
    const issue = getLatestByCreatedAt(record.certificate_issues);
    if (issue?.link && issue?.link?.current) {
      issue.link.current.click();
      return;
    }

    const unlock_override = unlockProgress();
    let url = null;
    let filename = null;
    let preview = false;
    if (issue?.asset) {
      url = `${window.API_URL}/certificateissues/${issue.id}/signed`;
      filename = issue.asset.original_filename;
    } else if (unlock_override && record?.settings) {
      preview = true;
      url = `${window.API_URL}/certificateissues/${record.settings.id}/signedPreview`;
      filename = `certificate-preview-${record.settings.id}.pdf`;
    } else {
      return;
    }

    await fetch(url, {
      headers: { Authorization: `Bearer ${token}` },
    }).then(async function handleLinkedResource(result) {
      let response = await result.json();
      if (result.status !== 200 || !response.success) {
        setAuthLinkError(parseErrors());
        return;
      }

      let href;
      if (preview) {
        href = `${window.API_URL}/certificateissues/${record.settings.id}/downloadPreviewSigned?token=` + response.token;
      } else {
        href = `${window.API_URL}/signedassets?token=` + response.token;
      }

      var link = document.createElement('a');
      link.href = href;
      link.download = filename;
      link.style.visibility = 'hidden';
      document.body.appendChild(link);

      if (issue) {
        issue.link = link;
      }

      link.click();
    });
  };

  const unlockProgress = () => {
    return user?.role?.permissions?.filter(p => p.name === `custom.unlock_progress`).length >= 1;
  };

  /*
   * Determine status of course item
   */
  const getCourseItemStatus = courseitem => {
    let status;
    const unlock_override = unlockProgress();
    if (courseitem) {
      if (courseitem.script_id) {
        const lms_script = courseitem?.script?.lms_scripts?.[0];
        const attempts = lms_script?.script_attempts;
        const last_attempt = attempts?.[attempts.length - 1];
        const started = attempts?.length >= 1;
        const complete = attempts?.filter(attempt => attempt.complete === 1).length >= 1;
        const last_complete = last_attempt?.complete === 1;
        const required_grade_achieved = attempts?.filter(q => q.score >= lms_script?.settings?.req_grade).length >= 1;
        const scriptConditions = {
          exists: unlock_override || !!attempts,
          empty: attempts?.length === 0 && !unlock_override,
          started: unlock_override || started,
          complete: complete && required_grade_achieved,
          failed: last_complete && !required_grade_achieved,
        };
        if (!scriptConditions.exists || scriptConditions.empty) {
          status = CONSTANTS.STATUS.NOT_STARTED;
        } else if (scriptConditions.complete) {
          status = CONSTANTS.STATUS.COMPLETE;
        } else if (scriptConditions.failed) {
          status = CONSTANTS.STATUS.RESTART;
        } else if (scriptConditions.started) {
          status = CONSTANTS.STATUS.STARTED;
        }
      } else if (courseitem.quiz_id) {
        // Is quiz

        const lms_quiz = courseitem?.quiz?.lms_quizzes?.[0];
        const attempts = lms_quiz?.quiz_attempts;
        const last_attempt = attempts?.[attempts.length - 1];
        const started = attempts?.length >= 1;
        const complete = attempts?.filter(attempt => attempt.complete === 1).length >= 1;
        const last_complete = last_attempt?.complete === 1;
        const required_grade_achieved = attempts?.filter(q => q.score >= lms_quiz?.settings?.req_grade).length >= 1;

        const quizConditions = {
          exists: unlock_override || !!attempts,
          empty: attempts?.length === 0 && !unlock_override,
          started: unlock_override || started,
          complete: complete && required_grade_achieved,
          failed: last_complete && !required_grade_achieved,
        };

        if (!quizConditions.exists || quizConditions.empty) {
          status = CONSTANTS.STATUS.NOT_STARTED;
        } else if (quizConditions.complete) {
          status = CONSTANTS.STATUS.COMPLETE;
        } else if (quizConditions.failed) {
          status = CONSTANTS.STATUS.RESTART;
        } else if (quizConditions.started) {
          status = CONSTANTS.STATUS.STARTED;
        }
      }
    }

    return status;
  };

  const getReportingStatus = () => {
    const unlock_override = unlockProgress();
    const lastItemStatus = getCourseItemStatus(
      data?.lms_course_members[data.lms_course_members.length - 1]?.courseitems[data?.lms_course_members[data.lms_course_members?.length - 1]?.courseitems?.length - 1],
    );
    if (data?.reporting_queue) {
      return CONSTANTS.STATUS.COMPLETE;
    } else if (getEnrollment()?.certificate_locked || (!unlock_override && lastItemStatus && lastItemStatus !== CONSTANTS.STATUS.COMPLETE)) {
      return CONSTANTS.STATUS.LOCKED;
    } else {
      return CONSTANTS.STATUS.NOT_STARTED;
    }
  };

  const getReviewStatus = () => {
    const unlock_override = unlockProgress();
    if (!unlock_override && !data?.reporting_queue) {
      return CONSTANTS.STATUS.LOCKED;
    } else if (courseReview) {
      return CONSTANTS.STATUS.COMPLETE;
    } else {
      return CONSTANTS.STATUS.NOT_STARTED;
    }
  };

  const getCertificateStatus = () => {
    const unlock_override = unlockProgress();
    if (
      getEnrollment()?.certificate_locked ||
      (!unlock_override &&
        (!courseReview ||
          !data?.reporting_queue ||
          !data?.certificates?.[0]?.certificate_issues ||
          data?.certificates?.[0]?.certificate_issues.length === 0 ||
          !getLatestByCreatedAt(data?.certificates?.[0]?.certificate_issues)?.asset))
    ) {
      return CONSTANTS.STATUS.LOCKED;
    } else if (data?.certificates?.[0]?.certificate_issues?.length && getEnrollment()?.certificate_downloaded_at !== null) {
      return CONSTANTS.STATUS.COMPLETE;
    } else {
      return CONSTANTS.STATUS.NOT_STARTED;
    }
  };

  const courseCompletionRows = () => {
    return [
      {
        id: 'reportingForm',
        text: 'Reporting Form',
        status: getReportingStatus(),
        onClickStatus: () => {
          if (getReportingStatus() !== CONSTANTS.STATUS.LOCKED) {
            history.push(`/dashboard/enrollments/${params.enrollmentId}/courses/${params.courseId}/reporting`);
          }
        },
      },
      {
        id: 'review',
        text: 'Course Review',
        status: getReviewStatus(),
        onClickStatus: () => {
          if (getReviewStatus() !== CONSTANTS.STATUS.LOCKED) {
            history.push(`/dashboard/enrollments/${params.enrollmentId}/courses/${params.courseId}/review`);
          }
        },
      },
      {
        id: 'certificate',
        text: 'Certificate of Completion',
        status: getCertificateStatus(),
        onClickStatus: () => {
          if (getCertificateStatus() !== CONSTANTS.STATUS.LOCKED) {
            downloadWithAuth(data?.certificates?.[0]);
          }
        },
      },
    ];
  };

  const downloadCoursePdfWithAuth = async course => {
    setDownloading({
      ...downloading,
      [course.id]: true,
    });
    // if (course.pdflink) {
    //   course.pdflink.current.click();
    //   return;
    // }

    let url = null;
    let filename = null;
    if (getEnrollment()?.pdf_download_purchased || unlockProgress()) {
      url = `${window.API_URL}/courses/${course.id}/signedCoursePdf/${params.enrollmentId}`;
      filename = course.name + '.pdf';
    } else {
      setDownloading(false);
      return;
    }

    await fetch(url, {
      headers: { Authorization: `Bearer ${token}` },
    }).then(async function handleLinkedResource(result) {
      let response = await result.json();
      if (result.status !== 200 || !response.success) {
        setAuthLinkError(parseErrors(response));
        return;
      }

      const href = `${window.API_URL}/signedassets?token=` + response.token;

      var link = document.createElement('a');
      link.href = href;
      link.download = filename;
      link.style.visibility = 'hidden';
      document.body.appendChild(link);

      if (course) {
        course.link = link;
      }

      link.click();
      setDownloading({
        ...downloading,
        [course.id]: false,
      });
    });
  };

  return (
    <CourseOutlineStyles>
      <PageHeader title={data?.name} onBack={() => history.push(`/dashboard`)} />
      {outlineErrors ? (
        <Alert message={outlineErrors} type="error" />
      ) : (
        <Card loading={!data}>
          {authLinkError}
          {data && (!data.lms_course_members || data.lms_course_members.length === 0) ? (
            <Alert message="Course content not found." type="error" />
          ) : (
            data?.lms_course_members?.map((courseMember, memberIndex) => (
              <div key={courseMember.id}>
                <h3>{courseMember.course.name}</h3>
                {courseMember.course.coursepdf_drive_id && (getEnrollment()?.pdf_download_purchased || unlockProgress()) ? (
                  <CourseOutlineStyles.Table
                    loading={loading}
                    rowKey={`id`}
                    columns={[
                      {
                        key: 'coursePdf',
                        render: () => 'Course PDF',
                      },
                      {
                        key: 'downloadingStatus',
                        width: '33%',
                        render: () => {
                          return <>{downloading[courseMember.course.id] ? <Spin /> : ''}</>;
                        },
                      },
                      {
                        key: 'pdfStatus',
                        width: 150,
                        align: 'right',
                        render: () => <Icon type={'download'} />,
                      },
                    ]}
                    dataSource={[courseMember]}
                    pagination={false}
                    rowClassName={CONSTANTS.STATUS.COMPLETE}
                    onRow={courseMember => {
                      return {
                        onClick: () => {
                          if (downloading[courseMember.course.id]) {
                            return;
                          }
                          downloadCoursePdfWithAuth(courseMember.course);
                        },
                      };
                    }}
                    showHeader={false}
                  />
                ) : (
                  ''
                )}

                <CourseOutlineStyles.Table
                  loading={loading}
                  rowKey={'id'}
                  columns={[
                    {
                      dataIndex: 'name',
                      key: 'name',
                    },
                    {
                      key: 'status',
                      width: 150,
                      align: 'right',
                      render: (text, record, index) => {
                        let status;
                        const prevMemberStatus = getCourseMemberPrevStatus(data?.lms_course_members, memberIndex);
                        if (prevMemberStatus && prevMemberStatus !== CONSTANTS.STATUS.COMPLETE) {
                          status = CONSTANTS.STATUS.LOCKED;
                        } else {
                          const prevStatus = getCourseItemPrevStatus(courseMember, index);
                          if (prevStatus && prevStatus !== CONSTANTS.STATUS.COMPLETE) {
                            status = CONSTANTS.STATUS.LOCKED;
                          } else {
                            status = getCourseItemStatus(record);
                          }
                        }

                        const { statusText, icon } = getStatusValues(status);

                        return (
                          <>
                            <CourseOutlineStyles.StatusText>{statusText}</CourseOutlineStyles.StatusText> <Icon type={icon} />
                          </>
                        );
                      },
                    },
                  ]}
                  dataSource={courseMember.courseitems}
                  pagination={false}
                  rowClassName={(record, index) => {
                    let status;
                    const prevMemberStatus = getCourseMemberPrevStatus(data?.lms_course_members, memberIndex);
                    if (prevMemberStatus && prevMemberStatus !== CONSTANTS.STATUS.COMPLETE) {
                      status = CONSTANTS.STATUS.LOCKED;
                    } else {
                      const prevStatus = getCourseItemPrevStatus(courseMember, index);
                      if (prevStatus && prevStatus !== CONSTANTS.STATUS.COMPLETE) {
                        status = CONSTANTS.STATUS.LOCKED;
                      } else {
                        status = getCourseItemStatus(record);
                      }
                    }
                    return status;
                  }}
                  onRow={(record, index) => {
                    let status;
                    const prevMemberStatus = getCourseMemberPrevStatus(data?.lms_course_members, memberIndex);
                    if (prevMemberStatus && prevMemberStatus !== CONSTANTS.STATUS.COMPLETE) {
                      status = CONSTANTS.STATUS.LOCKED;
                    } else {
                      const prevStatus = getCourseItemPrevStatus(courseMember, index);
                      if (prevStatus && prevStatus !== CONSTANTS.STATUS.COMPLETE) {
                        status = CONSTANTS.STATUS.LOCKED;
                      } else {
                        status = getCourseItemStatus(record);
                      }
                    }
                    return {
                      onClick: () => {
                        if (status !== CONSTANTS.STATUS.LOCKED) {
                          history.push(getObjectiveLink(record));
                        }
                      },
                    };
                  }}
                  showHeader={false}
                />
              </div>
            ))
          )}

          <Divider />

          <h3>Course Completion</h3>
          <CourseOutlineStyles.Table
            loading={loading}
            rowKey={'id'}
            columns={[
              {
                key: 'text',
                render: (text, record) => {
                  return record.text;
                },
              },
              {
                key: 'status',
                width: 150,
                align: 'right',
                render: (status, record) => {
                  const { statusText, icon } = getStatusValues(record.status);
                  return (
                    <>
                      <CourseOutlineStyles.StatusText>{statusText}</CourseOutlineStyles.StatusText> <Icon type={icon} />
                    </>
                  );
                },
              },
            ]}
            dataSource={courseCompletionRows()}
            pagination={false}
            rowClassName={data => data.status}
            onRow={data => {
              return {
                onClick: data.onClickStatus,
              };
            }}
            showHeader={false}
          />
        </Card>
      )}
    </CourseOutlineStyles>
  );
};

// Property Types
CourseOutline.propTypes = {
  data: PropTypes.object,
  history: PropTypes.object,
  loading: PropTypes.bool,
  token: PropTypes.string,
  params: PropTypes.object,
  courseReview: PropTypes.any,
  user: PropTypes.object,
  outlineErrors: PropTypes.string,
};

// Export
export default CourseOutline;
