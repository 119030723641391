// Types
import { GET_COURSE_ITEM_REQUEST, GET_COURSE_ITEM_SUCCESS, GET_COURSE_ITEM_FAILURE } from './types';

// Initial State
const initialState = {
  fetching: false,
  data: null,
  errors: null,
};

// Main Reducer
/**
 * courseItemReducer Reducer
 * @param {Object} state current state of redux
 * @param {Object} action action state object
 * @returns {state} Redux State
 */
const courseItemReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_COURSE_ITEM_REQUEST:
      return {
        ...state,
        loading: true,
        errors: null,
      };
    case GET_COURSE_ITEM_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.data,
      };
    case GET_COURSE_ITEM_FAILURE:
      return {
        ...state,
        loading: false,
        errors: action.errors,
      };
    default:
      return state;
  }
};

export default courseItemReducer;
