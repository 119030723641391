// Export types
export const READ_CART_REQUEST = 'READ_CART_REQUEST';
export const READ_CART_SUCCESS = 'READ_CART_SUCCESS';
export const READ_CART_FAILURE = 'READ_CART_FAILURE';

export const UPDATE_STUDENT_INFORMATION_REQUEST = 'UPDATE_STUDENT_INFORMATION_REQUEST';
export const UPDATE_STUDENT_INFORMATION_SUCCESS = 'UPDATE_STUDENT_INFORMATION_SUCCESS';
export const UPDATE_STUDENT_INFORMATION_FAILURE = 'UPDATE_STUDENT_INFORMATION_FAILURE';

export const CREATE_CART_ENROLLMENT_REQUEST = 'CREATE_CART_ENROLLMENT_REQUEST';
export const CREATE_CART_ENROLLMENT_SUCCESS = 'CREATE_CART_ENROLLMENT_SUCCESS';
export const CREATE_CART_ENROLLMENT_FAILURE = 'CREATE_CART_ENROLLMENT_FAILURE';

export const CREATE_CART_COURSE_REQUEST = 'CREATE_CART_COURSE_REQUEST';
export const CREATE_CART_COURSE_SUCCESS = 'CREATE_CART_COURSE_SUCCESS';
export const CREATE_CART_COURSE_FAILURE = 'CREATE_CART_COURSE_FAILURE';

export const CREATE_STRIPE_PAYMENT_REQUEST = 'CREATE_STRIPE_PAYMENT_REQUEST';
export const CREATE_STRIPE_PAYMENT_SUCCESS = 'CREATE_STRIPE_PAYMENT_SUCCESS';
export const CREATE_STRIPE_PAYMENT_FAILURE = 'CREATE_STRIPE_PAYMENT_FAILURE';

export const PROCESS_STRIPE_PAYMENT_REQUEST = 'PROCESS_STRIPE_PAYMENT_REQUEST';
export const PROCESS_STRIPE_PAYMENT_SUCCESS = 'PROCESS_STRIPE_PAYMENT_SUCCESS';
export const PROCESS_STRIPE_PAYMENT_FAILURE = 'PROCESS_STRIPE_PAYMENT_FAILURE';

export const CREATE_AMAZON_PAYMENT_REQUEST = 'CREATE_AMAZON_PAYMENT_REQUEST';
export const CREATE_AMAZON_PAYMENT_SUCCESS = 'CREATE_AMAZON_PAYMENT_SUCCESS';
export const CREATE_AMAZON_PAYMENT_FAILURE = 'CREATE_AMAZON_PAYMENT_FAILURE';

export const PROCESS_AMAZON_PAYMENT_REQUEST = 'PROCESS_AMAZON_PAYMENT_REQUEST';
export const PROCESS_AMAZON_PAYMENT_SUCCESS = 'PROCESS_AMAZON_PAYMENT_SUCCESS';
export const PROCESS_AMAZON_PAYMENT_FAILURE = 'PROCESS_AMAZON_PAYMENT_FAILURE';

export const RESET_CART_DATA = 'RESET_CART_DATA';
export const RESET_STRIPE_DATA = 'RESET_STRIPE_DATA';
export const SET_STRIPE_STATUS = 'SET_STRIPE_STATUS';
