// Types
import { CLEAR_REFERRAL, POST_REFERRAL_REQUEST, POST_REFERRAL_SUCCESS, POST_REFERRAL_FAILURE } from './types';

// Initial State
const initialState = {
  referral: {
    fetching: false,
    success: false,
    errors: null,
  },
};

// Main Reducer
/**
 * referralReducer Reducer
 * @param {Object} state current state of redux
 * @param {Object} action action state object
 * @returns {state} Redux State
 */
const referralReducer = (state = initialState, action) => {
  switch (action.type) {
    case CLEAR_REFERRAL:
      return initialState;
    case POST_REFERRAL_REQUEST:
      return {
        ...state,
        referral: {
          fetching: true,
          errors: null,
          success: false,
        },
      };
    case POST_REFERRAL_SUCCESS:
      return {
        ...state,
        referral: {
          fetching: false,
          errors: null,
          success: true,
        },
      };

    case POST_REFERRAL_FAILURE:
      return {
        ...state,
        referral: {
          fetching: false,
          errors: action.errors,
        },
      };
    default:
      return state;
  }
};

export default referralReducer;
