// IMPORTS
export default {
  getState: (z, ab = false) => {
    z = parseInt(z, 10); // removes leading '0'
    if (z < 1001 || z > 99950) return null;
    let i = 69,
      next,
      zs = [
        [1001, 2791, 'MA', 'Massachusetts'],
        [2801, 2940, 'RI', 'Rhode Island'],
        [3031, 3897, 'NH', 'New Hampshire'],
        [3901, 4992, 'ME', 'Maine'],
        [5001, 5495, 'VT', 'Vermont'],
        [5501, 5544, 'MA', 'Massachusetts'],
        [5601, 5907, 'VT', 'Vermont'],
        [6001, 6389, 'CT', 'Connecticut'],
        [6390, 6390, 'NY', 'New York'],
        [6401, 6928, 'CT', 'Connecticut'],
        [7001, 8989, 'NJ', 'New Jersey'],
        [10001, 14975, 'NY', 'New York'],
        [15001, 19640, 'PA', 'Pennsylvania'],
        [19701, 19980, 'DE', 'Delaware'],
        [20001, 20039, 'DC', 'Dist. of Columbia'],
        [20040, 20167, 'VA', 'Virginia'],
        [20042, 20599, 'DC', 'Dist. of Columbia'],
        [20331, 20331, 'MD', 'Maryland'],
        [20335, 20797, 'MD', 'Maryland'],
        [20799, 20799, 'DC', 'Dist. of Columbia'],
        [20812, 21930, 'MD', 'Maryland'],
        [22001, 24658, 'VA', 'Virginia'],
        [24701, 26886, 'WV', 'West Virginia'],
        [27006, 28909, 'NC', 'North Carolina'],
        [29001, 29948, 'SC', 'South Carolina'],
        [30001, 31999, 'GA', 'Georgia'],
        [32004, 34997, 'FL', 'Florida'],
        [35004, 36925, 'AL', 'Alabama'],
        [37010, 38589, 'TN', 'Tennessee'],
        [38601, 39776, 'MS', 'Mississippi'],
        [39901, 39901, 'GA', 'Georgia'],
        [40003, 42788, 'KY', 'Kentucky'],
        [43001, 45999, 'OH', 'Ohio'],
        [46001, 47997, 'IN', 'Indiana'],
        [48001, 49971, 'MI', 'Michigan'],
        [50001, 52809, 'IO', 'Iowa'],
        [53001, 54990, 'WI', 'Wisconsin'],
        [55001, 56763, 'MN', 'Minnesota'],
        [57001, 57799, 'SD', 'South Dakota'],
        [58001, 58856, 'ND', 'North Dakota'],
        [59001, 59937, 'MT', 'Montana'],
        [60001, 62999, 'IL', 'Illinois'],
        [63001, 65899, 'MO', 'Missouri'],
        [66002, 67954, 'KS', 'Kansas'],
        [68001, 68118, 'NB', 'Nebraska'],
        [68119, 68120, 'IO', 'Iowa'],
        [68122, 69367, 'NB', 'Nebraska'],
        [70001, 71232, 'LA', 'Louisiana'],
        [71233, 71233, 'MS', 'Mississippi'],
        [71234, 71497, 'LA', 'Louisiana'],
        [73001, 73199, 'OK', 'Oklahoma'],
        [73301, 73301, 'TX', 'Texas'],
        [73401, 74966, 'OK', 'Oklahoma'],
        [75001, 75501, 'TX', 'Texas'],
        [75502, 75502, 'AK', 'Arkansas'],
        [75503, 79999, 'TX', 'Texas'],
        [80001, 81658, 'CO', 'Colorado'],
        [82001, 83128, 'WY', 'Wyoming'],
        [83201, 83876, 'ID', 'Idaho'],
        [84001, 84784, 'UT', 'Utah'],
        [85001, 86556, 'AZ', 'Arizona'],
        [87001, 88441, 'NM', 'New Mexico'],
        [88510, 88589, 'TX', 'Texas'],
        [88901, 89883, 'NV', 'Nevada'],
        [90001, 96162, 'CA', 'California'],
        [96701, 96898, 'HI', 'Hawaii'],
        [97001, 97920, 'OR', 'Oregon'],
        [98001, 99403, 'WA', 'Washington'],
        [99501, 99950, 'AK', 'Alaska'],
      ];

    while (i) {
      next = zs[--i];
      if (z > next[0] && z < next[1]) return ab ? next[2] : next[3];
    }
    return null;
  },
};
