// Import
import React from 'react';
import PropTypes from 'prop-types';
import LoginStyles from './styles';
import { Icon, Row, Col, Form, Input, Button } from 'antd';
import { Link } from 'react-router-dom';
import Constants from '../../utils/Constants';

// Main Component
/**
 * Main Component
 * @param {*} props any
 * @return {JSX.Element} JSX Element
 */
const Login = props => {
  // Props
  const { form, handleSubmit } = props;
  const { getFieldDecorator } = form;

  // Functions
  const onSubmitForm = event => {
    event.preventDefault();
    form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        handleSubmit(values);
      }
    });
  };

  // Display of form fields
  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 24 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 24 },
    },
  };

  return (
    <LoginStyles>
      <LoginStyles.Card>
        <Row gutter={[0, 0]} style={{ width: '100%' }}>
          <Col span={24}>
            <div style={{ padding: 20 }}>
              <div style={{ textAlign: `center`, padding: `15px 0 30px 0` }}>
                <img style={{ width: '100%', maxWidth: 350 }} src={'/images/logos/' + Constants.WEBSITE_NAME + '/logo-on-white.png'} />
              </div>
              <Form onSubmit={onSubmitForm}>
                <Form.Item {...formItemLayout}>
                  {getFieldDecorator('email', {
                    rules: [
                      {
                        type: 'email',
                        message: 'Not a valid email',
                      },
                      {
                        required: true,
                        message: 'Email is required',
                      },
                    ],
                  })(<Input prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />} required={true} disabled={props.loading} type="email" placeholder={`Email address`} />)}
                </Form.Item>

                <Form.Item {...formItemLayout}>
                  {getFieldDecorator('password', {
                    rules: [
                      {
                        required: true,
                        message: 'Password is required',
                      },
                    ],
                  })(<Input prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />} disabled={props.loading} type="password" placeholder={`Password`} />)}
                </Form.Item>

                <LoginStyles.Errors show={props.errors && props.errors.length > 0} count={props.errors && props.errors.length}>
                  {props.errors && props.errors.length >= 1 && props.errors.map((val, key) => <LoginStyles.Error key={key.toString()}>{val}</LoginStyles.Error>)}
                </LoginStyles.Errors>
                <Row gutter={[16, 16]}>
                  <Col span={12}>
                    <div style={{ textAlign: `center` }}>
                      <LoginStyles.Button disabled={props.loading} htmlType={'submit'} block>
                        {props.loading ? '...' : 'Login'}
                      </LoginStyles.Button>
                    </div>
                  </Col>
                  <Col span={12}>
                    <div style={{ padding: 10, textAlign: `center` }}>
                      <Link to={`forgotpassword`}>Forgot Password?</Link>
                    </div>
                  </Col>
                </Row>
              </Form>
            </div>
          </Col>
        </Row>
      </LoginStyles.Card>
    </LoginStyles>
  );
};

// Property Types
Login.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  errors: PropTypes.array,
  form: PropTypes.object,
};

// Export
export default Form.create({ name: 'login' })(Login);
