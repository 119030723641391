// Imports
import axios from 'axios';

// Config
const apiUri = window.API_URL;

// Export actions
export function forgotPassword({ email }) {
  return axios({
    method: 'post',
    url: `${apiUri}/auth/forgotpassword`,
    data: { email },
  });
}
