// Imports
import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

// Operations

// Presentation Components
// eslint-disable-next-line import/no-named-as-default,import/no-named-as-default-member
import Authy from '../../components/Authy';
import { doAuthyReset, doAuthyVerify, doAuthyRequest, doGetMe } from '../Login/operations';

// Main Component
/**
 * Main Container
 * @param {*} props any
 * @returns {JSX.Element} Main JSX Routes
 */
const AuthyContainer = props => {
  // Props
  const { doGetMe: getMe, doAuthyReset: authyReset, doAuthyVerify: authyVerify, doAuthyRequest: authyRequest, authy, fetching, token, user, history, errors, external_error_code } = props;

  /**
   * Main function that handle form submit
   *
   *
   * @returns {dispatch} Dispatches Authy function
   * @param code
   * @param authy_user_id
   */
  // eslint-disable-next-line camelcase
  const handleSubmit = values => {
    authyVerify(values);
  };

  const handleResend = () => {
    authyRequest({ authy_user_id: authy?.id });
  };

  // /**
  //  * Redirect after authy success
  //  */
  useEffect(() => {
    if (!fetching && token != null && errors === null) {
      getMe({ token });
      authyReset();
    }
  }, [authyReset, getMe, token, fetching, errors]);

  useEffect(() => {
    if (user && authy == null) {
      history.push('/dashboard');
    }
    if (!token && !authy) {
      history.push('/login');
    }
  }, [authy, user]);

  // Render
  return <Authy authy={authy} loading={props.fetching} handleSubmit={handleSubmit} handleResend={handleResend} errors={props.errors || {}} external_error_code={props.external_error_code} />;
};

// Mapping State / Dispatch To Props
/**
 * Main redux state
 * @param {*} state object
 * @returns {Object} state objects
 */
const mapStateToProps = state => ({
  fetching: state.loginReducer.fetching,
  authy: state.loginReducer.authy,
  token: state.loginReducer.token,
  user: state.loginReducer.user,
  errors: state.loginReducer.errors,
  external_error_code: state.loginReducer.external_error_code,
});

/**
 * Main redux dispatch functions
 * @param {*} dispatch redux dispatch
 * @returns {{doAuthyVerify: (function({code: *}): {code: *, type: string}), doAuthyRequest: (function(): {type: string})}} state objects
 */
const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      doAuthyVerify,
      doAuthyRequest,
      doGetMe,
      doAuthyReset,
    },
    dispatch,
  );

// Property Types
AuthyContainer.propTypes = {
  doAuthyRequest: PropTypes.func,
  doAuthyVerify: PropTypes.func,
  doAuthyReset: PropTypes.func,
  doRedirectRefer: PropTypes.func,
  doGetMe: PropTypes.func,
  history: PropTypes.object,
  authy: PropTypes.object,
  user: PropTypes.object,
  fetching: PropTypes.bool,
  token: PropTypes.string,
  errors: PropTypes.object,
  external_error_code: PropTypes.string,
};

// Export
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AuthyContainer));
