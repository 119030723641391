// Imports
import styled from 'styled-components';
import Screens from '../../styles/Screens';
import Colors from '../../styles/Colors';
import { Button } from 'antd';

// Colors
const Color = require('color');

// Main Component
const LoginStyles = styled.div`
  display: flex;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  align-items: center;
`;

LoginStyles.Button = styled(Button)`
  -webkit-appearance: none;
  width: auto;
  min-width: 100px;
  border-radius: 24px;
  text-align: center;
  padding: 5px 40px;
  margin-top: 5px;
  background-color: #1f7ed6;
  color: #fff;
  font-size: 14px;
  margin-left: auto;
  font-weight: 500;
  box-shadow: 0px 2px 6px -1px rgba(0, 0, 0, 0.13);
  border: none;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  outline: 0;

  :hover,
  :focus {
    color: #fff;
    background-color: #2d9aff;
    border-color: #26b3f0;
  }
  :active,
  .active {
    text-decoration: none;
    background: #2d9aff;
  }
`;
LoginStyles.Card = styled.div`
  display: -webkit-box;
  display: flex;
  background: ${Colors.white};
  border-radius: 4px;
  width: calc(100% - 20px);
  padding: 0px;
  box-shadow: 0px 0px 5px 0px
    ${Color(Colors.black)
      .alpha(0.05)
      .string()};
  transition: all 250ms ease-in-out 0s;
  margin-left: 20px;
  margin-right: 20px;

  &:hover {
    box-shadow: 0px 0px 5px 0px
      ${Color(Colors.black)
        .alpha(0.2)
        .string()};
  }

  @media (min-width: ${Screens.small}) {
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    width: 400px;
  }
`;

LoginStyles.Errors = styled.ul`
  background: ${Colors.pink};
  display: block;
  list-style: none;
  margin: ${props => (props.show ? '0 0 20px 0' : '0')};
  padding: 0;
  border-radius: 4px;
  height: ${props => (props.show ? `${48 * props.count}px` : '0px')};
  overflow: hidden;
  transition: all 250ms ease-in-out 0s;
`;

LoginStyles.Error = styled.li`
  color: ${Colors.red};
  padding: 15px 20px;
`;

// Export
export default LoginStyles;
