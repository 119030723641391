// IMPORTS
// --------------------------------------------------------
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

// Actions
import { doReadLmsScriptRequest, doReadLmsScriptReset } from '../../redux/modules/LmsScripts/read/actions';
import { doReadScriptAttemptsRequest, doReadScriptAttemptsReset } from '../../redux/modules/ScriptAttempts/read/actions';
import { doUpdateScriptAttemptsRequest, doUpdateScriptAttemptsReset } from '../../redux/modules/ScriptAttempts/update/actions';
import { doQuestionScriptAttemptsRequest, doQuestionScriptAttemptsReset } from '../../redux/modules/ScriptAttempts/question/actions';
import { doAnswersScriptAttemptsRequest, doAnswersScriptAttemptsReset } from '../../redux/modules/ScriptAttempts/answers/actions';
import { doTrackingScriptAttemptsRequest, doTrackingScriptAttemptsReset } from '../../redux/modules/ScriptAttempts/tracking/actions';

// Presentation Component
import ScriptAttempt from '../../components/ScriptAttempt';

// Container Components
import VideoContainer from '../Videos';
import LmsScriptQuestionContainer from '../LmsScriptQuestion';
import LessonContainer from '../Lesson';
import { Alert, Card } from 'antd';

const queryString = require('query-string');

// MAIN COMPONENT
// --------------------------------------------------------
/**
 *
 * @param {*} props
 */
let intervalTimer = null;
let timer = 0;
/**
 * Main ScriptAttemptContianer
 * @param {Object} props component props
 * @returns {JSX} React compoennt
 */
const ScriptAttemptContainer = props => {
  // STATE / PROPS
  const {
    token,
    doReadLmsScriptRequest: readLmsScriptRequest,
    doReadScriptAttemptsRequest: readScriptAttemptsRequest,
    doUpdateScriptAttemptsRequest: updateScriptAttemptsRequest,
    doReadLmsScriptReset: readLmsScriptReset,
    doReadScriptAttemptsReset: readScriptAttemptsReset,
    doUpdateScriptAttemptsReset: updateScriptAttemptsReset,
    doAnswersScriptAttemptsRequest: answersScriptAttemptsRequest,
    doAnswersScriptAttemptsReset: answersScriptAttemptsReset,
    doQuestionScriptAttemptsRequest: questionScriptAttemptsRequest,
    doQuestionScriptAttemptsReset: questionScriptAttemptsReset,
    doTrackingScriptAttemptsRequest: trackingScriptAttemptsRequest,
    doTrackingScriptAttemptsReset: trackingScriptAttemptsReset,
    readScriptAttempt,
    updateScriptAttempt,
    answersScriptAttempt,
    questionScriptAttempt,
    trackingScriptAttempt,
    history,
    user,
    match,
  } = props;
  const { enrollmentId, courseId, lmsScriptId, scriptAttemptId } = match.params;
  const query = queryString.parse(window.location.search);
  const { sectionId, position } = query;
  // Used exclusively for the progress bar UI
  const [slideIndex, setSlideIndex] = useState(null);
  const [slides, setSlides] = useState([]);
  const [allowReload, setAllowReload] = useState(true); // allows for scenarios where only position needs to be updated (ex: allowReload = false)
  const [canProceed, setCanProceed] = useState(user?.student?.enrollments.some(e => parseInt(enrollmentId) === parseInt(e.id) && e.guest));
  const [buttonPrevLabel, setButtonPrevLabel] = useState(null);
  const [buttonNextLabel, setButtonNextLabel] = useState(null);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [totalSlides, setTotalSlides] = useState(null);
  const [redirectToResults, setRedirecToResults] = useState(false);
  const [videoUrl, setVideoUrl] = useState(null);
  const [useTranscript, setUseTranscript] = useState(false);
  const [useVideoFallback, setUseVideoFallback] = useState(false);
  const [transcriptLoaded, setTranscriptLoaded] = useState(false);
  const [watched, setWatched] = useState(false);
  const [scrollTop, setScrollTop] = useState(false);

  /**
   * Handles reseting the labels for the next and prev buttons
   * @param {Number} newIndex new index for slide position
   * @returns {void}
   */
  const resetButtonLabels = (newIndex = null) => {
    // get number of slides
    const currentSectionOrderIndex = readScriptAttempt?.data?.section?.order_index;
    const currentSlides = readScriptAttempt?.data?.section?.sectionslides?.filter(i => i.content);
    const currentIndex = newIndex !== null ? newIndex : 0;
    const prevSection = readScriptAttempt?.data?.lms_script?.script?.sections?.find(i => i.order_index === currentSectionOrderIndex - 1);
    const nextSection = readScriptAttempt?.data?.lms_script?.script?.sections?.find(i => i.order_index === currentSectionOrderIndex + 1);

    // Beginning
    if (currentIndex === 0) {
      // Previous Button
      if (currentSectionOrderIndex === 1) {
        setButtonPrevLabel(
          <>
            Previous
            <span className="desktop-text">
              &nbsp;|&nbsp;<span>Attempts</span>
            </span>
          </>,
        );
      } else {
        setButtonPrevLabel(
          <>
            Previous
            <span className="desktop-text">
              &nbsp;|&nbsp;<span>{prevSection?.sectiontype?.type}</span>
            </span>
          </>,
        );
      }
      // Next Button
      if (currentIndex === currentSlides?.length - 1) {
        setButtonNextLabel(
          nextSection ? (
            <>
              Next
              <span className="desktop-text">
                &nbsp;|&nbsp;<span>{nextSection?.sectiontype?.type}</span>
              </span>
            </>
          ) : (
            'Finish'
          ),
        );
      } else {
        setButtonNextLabel('Next');
      }
    } else if (currentIndex + 1 === currentSlides?.length) {
      // End
      // Previous
      setButtonPrevLabel('Previous');
      // Next Button
      if (nextSection) {
        setButtonNextLabel(
          <>
            Next
            <span className="desktop-text">
              &nbsp;|&nbsp;<span>{nextSection?.sectiontype?.type}</span>
            </span>
          </>,
        );
      } else {
        setButtonNextLabel('Finish');
      }
    } else {
      setButtonPrevLabel('Previous');
      setButtonNextLabel('Next');
    }
  };

  /**
   * Handle on click button Previous
   * @returns {void}
   */
  const handlePrev = () => {
    clearInterval(intervalTimer);
    setUseTranscript(false);
    const currentSectionOrderIndex = readScriptAttempt?.data?.section?.order_index;
    if (slideIndex > 0) {
      setSlideIndex(slideIndex - 1);
      resetButtonLabels(slideIndex - 1);
      updateScriptAttemptsRequest({
        token,
        id: scriptAttemptId,
        payload: {
          section_id: readScriptAttempt?.data?.section?.id,
          position: slideIndex - 1,
        },
      });
    } else {
      resetButtonLabels(0);
      if (currentSectionOrderIndex === 1) {
        history.push(`/dashboard/enrollments/${enrollmentId}/courses/${courseId}/lmslessons/${lmsScriptId}`);
      } else {
        setAllowReload(true);
        const currentSectionOrderIndex = readScriptAttempt?.data?.section?.order_index;
        const prevSection = readScriptAttempt?.data?.lms_script?.script?.sections?.find(i => i.order_index === currentSectionOrderIndex - 1);
        if (prevSection) {
          updateScriptAttemptsRequest({
            token,
            id: scriptAttemptId,
            payload: {
              section_id: prevSection.id,
              position: 0,
            },
          });
        }
      }
    }
  };

  /**
   * When next button is clicked
   * @returns {void}
   */
  const handleNext = () => {
    clearInterval(intervalTimer);
    setCanProceed(user?.student?.enrollments.some(e => parseInt(enrollmentId) === parseInt(e.id) && e.guest));

    // Skip sections that do not have section content
    const sectionSlides = readScriptAttempt?.data?.section?.sectionslides?.filter(i => i.content).length;

    if (slideIndex < sectionSlides - 1) {
      updateScriptAttemptsRequest({
        token,
        id: scriptAttemptId,
        payload: {
          section_id: readScriptAttempt?.data?.section?.id,
          position: slideIndex + 1,
        },
      });
      loadContent(slideIndex + 1);
      setSlideIndex(slideIndex + 1);
      setScrollTop(true);
    } else {
      setAllowReload(true);
      // get the current order_index
      const currentSectionOrderIndex = readScriptAttempt?.data?.section?.order_index;
      const nextSection = readScriptAttempt?.data?.lms_script?.script?.sections?.find(i => i.order_index === currentSectionOrderIndex + 1);

      if (nextSection) {
        updateScriptAttemptsRequest({
          token,
          id: scriptAttemptId,
          payload: {
            section_id: nextSection.id,
            position: 0,
          },
        });
      } else {
        // setAllowReload(true);
        setRedirecToResults(true);
        updateScriptAttemptsRequest({
          token,
          id: scriptAttemptId,
          payload: {
            complete: 1,
            end_time: new Date(),
          },
        });
      }
    }
    trackingScriptAttemptsRequest({ token, id: scriptAttemptId });
  };

  /**
   * Allows skipping to specific sections
   * @param {Object} slide updated slide object
   * @param {Number} position updated position for the slide index
   * @returns {void}
   */
  const handleTocNav = (slide, position) => {
    // Catch if the section and the slide index / position are the same and do nothing;
    if (readScriptAttempt?.data?.section_id === slide.section_id && position === slideIndex) {
      return;
    } else if (readScriptAttempt?.data?.section_id === slide.section_id) {
      // If the section is the same just update the slideIndex and position
      loadContent(position);
    } else {
      // Update new section and position
      setAllowReload(true);
      updateScriptAttemptsRequest({
        token,
        id: scriptAttemptId,
        payload: {
          section_id: slide?.section_id,
          position,
        },
      });
    }
  };

  /**
   * Function that loads content on load and when next / prev is clicked
   * @param {Number} newIndex next index to load
   * @returns {void}
   */
  const loadContent = newIndex => {
    if (readScriptAttempt?.errors) return;
    const index = newIndex || readScriptAttempt?.data?.position || 0;
    setCanProceed(user?.student?.enrollments.some(e => parseInt(enrollmentId) === parseInt(e.id) && e.guest));

    setButtonLoading(!user?.student?.enrollments.some(e => parseInt(enrollmentId) === parseInt(e.id) && e.guest));
    setSlideIndex(index);
    setSlides(readScriptAttempt?.data?.section?.sectionslides[index] ?? {});
    const getTotalSlides = readScriptAttempt?.data?.lms_script?.script?.sections
      ?.map(i => {
        return i?.sectionslides?.filter(i => i.content)?.length;
      })
      ?.reduce((a, b) => a + b, 0);
    setTotalSlides(getTotalSlides);

    // LESSON
    if (readScriptAttempt?.data?.section?.sectiontype?.type === 'lesson' || (readScriptAttempt?.data?.section?.sectiontype?.type === 'video' && useTranscript)) {
      const filteredSlides = readScriptAttempt?.data?.section?.sectionslides.filter(i => i.content);
      const timeToWaitSeconds = filteredSlides[index]?.timer || 3;

      // Start lesson timer
      startTimer(timeToWaitSeconds, () => {
        setButtonLoading(false);
        setAllowReload(false);
        resetButtonLabels(index);

        if (index + 1 < filteredSlides.length) {
          updateScriptAttemptsRequest({
            token,
            id: scriptAttemptId,
            payload: {
              position: index + 1,
            },
          });
        } else {
          // get current section id
          const currentSectionOrderIndex = readScriptAttempt?.data?.section?.order_index;
          const nextSection = readScriptAttempt?.data?.lms_script?.script?.sections?.find(i => i.order_index === currentSectionOrderIndex + 1);
          // Update current position to update tracking
          updateScriptAttemptsRequest({
            token,
            id: scriptAttemptId,
            payload: {
              position: index + 1,
            },
          });

          if (nextSection) {
            updateScriptAttemptsRequest({
              token,
              id: scriptAttemptId,
              payload: {
                section_id: nextSection.id,
                position: 0,
              },
            });
            const data = readScriptAttempt?.data?.section?.sectionslides;
            const filteredUpToIndex = [...data.slice(0, data.indexOf(filteredSlides[index]) + 1)].reverse();
            if (filteredUpToIndex && filteredUpToIndex.length === 0) {
              readScriptAttemptsRequest({ token, id: scriptAttemptId, enrollmentId });
            }
          }
        }
      });
    } else if (readScriptAttempt?.data?.section?.sectiontype?.type === 'video' && readScriptAttempt?.data?.section?.video_status?.value === 'Published') {
      // VIDEO
      setCanProceed(user?.student?.enrollments.some(e => parseInt(enrollmentId) === parseInt(e.id) && e.guest));
      setButtonLoading(false);
    } else if (readScriptAttempt?.data?.section?.sectiontype?.type === 'question') {
      // QUESTION
      let tmpIndex = index;
      if (readScriptAttempt?.data?.section?.sectionslides?.length === 1) {
        tmpIndex = 0;
      }
      let questionId = readScriptAttempt?.data?.section?.sectionslides[tmpIndex]?.question?.id;
      if (questionId === undefined) {
        questionId = readScriptAttempt?.data?.section?.sectionslides[tmpIndex]?.question_id;
      }
      questionScriptAttemptsRequest({
        token,
        id: readScriptAttempt?.data?.id,
        questionId: questionId,
      });
      setButtonLoading(false);
    } else {
      // EVERYTHING ELSE
      setButtonLoading(false);
      setCanProceed(true);
    }

    // Initial load of button label
    resetButtonLabels(index);
    trackingScriptAttemptsRequest({ token, id: scriptAttemptId });
  };

  /**
   * Function used for timer on buttons
   * @param {Number} seconds seconds to finish timer
   * @param {Function} callback optional callback when timer done
   * @return {void}
   */
  const startTimer = (seconds, callback) => {
    timer = 0;
    clearInterval(intervalTimer);

    if (seconds === 0) {
      setCanProceed(true);
      callback();
    }

    const intervalFunction = () => {
      timer++;
      setButtonNextLabel(
        <>
          {seconds - timer} sec{seconds - timer !== 1 && 's'}
        </>,
      );
      if (timer >= seconds) {
        clearInterval(intervalTimer);
        setCanProceed(true);
        if (callback) {
          callback();
        }
      }
    };
    intervalTimer = setInterval(intervalFunction.bind(slideIndex), 1000);
  };

  /**
   * Returns current slide index in all of slides
   * @param {Number} index expects current index
   * @returns {Number} current slide index from total slides
   */
  const getTotalSlideIndex = index => {
    if (index !== null) {
      const currentSectionOrderIndex = readScriptAttempt?.data?.section?.order_index;
      const currentSectionSlide = readScriptAttempt?.data?.lms_script?.script?.sections
        ?.map(i => {
          if (i.order_index === currentSectionOrderIndex) {
            return i?.sectionslides?.filter(i => i.content).filter((_i, k) => k <= index)?.length;
          } else if (i.order_index < currentSectionOrderIndex) {
            return i?.sectionslides?.filter(i => i.content)?.length;
          }
          return 0;
        })
        ?.reduce((a, b) => a + b, 0);
      return currentSectionSlide;
    }
    return 0;
  };

  /* Video Specific Functions
  -------------------------------------------------------- */
  const handleSetUseTranscript = () => {
    setUseTranscript(true);
  };

  const handleSetVideoFallback = () => {
    setUseVideoFallback(true);
  };

  /* Question Specific Functions
  -------------------------------------------------------- */
  /**
   * When a question is answered
   * @param {Object} data Question data
   * @returns {void}
   */
  const handleSubmitQuestionAnswer = data => {
    answersScriptAttemptsRequest({ token, id: scriptAttemptId, questionId: data.question_id, questionOptionId: data.id });
  };

  /**
   * Callback when question has been answered or met its attempt limit or video has finished being watched
   * @returns {void}
   */
  const handleSetCanProceed = () => {
    setCanProceed(true);
  };

  const handleSetPosition = position => {
    updateScriptAttempt({ position: position });
  };

  const handleVideoDone = () => {
    setWatched(true);
  };
  // HOOKS
  /* Hooks - Scripts
  -------------------------------------------------------- */
  // Step 1 - Get Script Attempt
  useEffect(() => {
    let enrollment = user?.student?.enrollments.find(e => parseInt(enrollmentId) === parseInt(e.id));
    if (enrollment && !enrollment.guest && !user.full_user) {
      clearInterval(intervalTimer);
      history.push(`/dashboard/enrollments/${enrollmentId}/purchase`);
    }
    setCanProceed(enrollment && enrollment.guest);
    readScriptAttemptsRequest({ token, id: scriptAttemptId, enrollmentId });
    trackingScriptAttemptsRequest({ token, id: scriptAttemptId });
  }, [scriptAttemptId, enrollmentId, user]);

  useEffect(() => {
    if (readScriptAttempt?.errors) {
      if (readScriptAttempt?.errors['GUEST_EXPIRED']) {
        clearInterval(intervalTimer);
        history.push(`/dashboard/enrollments/${enrollmentId}/purchase`);
      }
    }
    if (questionScriptAttempt?.errors) {
      if (questionScriptAttempt?.errors['GUEST_EXPIRED']) {
        clearInterval(intervalTimer);
        history.push(`/dashboard/enrollments/${enrollmentId}/purchase`);
      }
    }
    if (trackingScriptAttempt?.errors) {
      if (trackingScriptAttempt?.errors['GUEST_EXPIRED']) {
        clearInterval(intervalTimer);
        history.push(`/dashboard/enrollments/${enrollmentId}/purchase`);
      }
    }
    if (updateScriptAttempt?.errors) {
      if (updateScriptAttempt?.errors['GUEST_EXPIRED']) {
        clearInterval(intervalTimer);
        history.push(`/dashboard/enrollments/${enrollmentId}/purchase`);
      }
    }
  }, [readScriptAttempt, questionScriptAttempt, trackingScriptAttempt, updateScriptAttempt]);

  // Step 2 - Verify Script Attempt Has A Section
  useEffect(() => {
    if (!readScriptAttempt?.errors && !readScriptAttempt?.data?.section && readScriptAttempt?.data?.lms_script?.script?.sections?.length > 0) {
      // If not section define, get the first section and set if for the script attempt section
      updateScriptAttemptsRequest({
        token,
        id: scriptAttemptId,
        payload: {
          section_id: readScriptAttempt.data.lms_script.script.sections[0]?.id,
        },
      });
    }
  }, [scriptAttemptId, readScriptAttempt?.data?.section]);

  // Step 2A (Optional) - If Done Updating Reload Script Attempt OR update tracking
  useEffect(() => {
    if (updateScriptAttempt?.errors) {
      return;
    } else if (updateScriptAttempt?.data?.complete === 1 && updateScriptAttempt?.loading === false && redirectToResults) {
      readScriptAttemptsReset();
      history.push(`/dashboard/enrollments/${enrollmentId}/courses/${courseId}/lmslessons/${lmsScriptId}/attempts/${scriptAttemptId}/results`);
    } else if (updateScriptAttempt?.data && updateScriptAttempt?.loading === false && allowReload && !redirectToResults) {
      if (updateScriptAttempt?.data?.section_id !== readScriptAttempt?.data?.section_id || readScriptAttempt?.data?.section?.sectiontype?.type !== 'video') {
        // If the update is completed loading and in the case that there is no first section defined
        readScriptAttemptsRequest({ token, id: scriptAttemptId, enrollmentId });
      }
      setAllowReload(false);
    } else if (updateScriptAttempt?.data && !allowReload && !redirectToResults) {
      doTrackingScriptAttemptsRequest({ token, id: scriptAttemptId });
    }
  }, [updateScriptAttempt?.data]);

  // Step 3 - Set Data
  useEffect(() => {
    if (readScriptAttempt?.data?.section) {
      loadContent();
    }
  }, [readScriptAttempt?.data?.section, useTranscript]);

  // Validate if the user has already visited this section / slide
  useEffect(() => {
    if (trackingScriptAttempt?.data && readScriptAttempt?.data && slideIndex !== null) {
      const currentSectionOrderIndex = readScriptAttempt?.data?.section?.order_index;
      const currentSection = readScriptAttempt?.data?.lms_script?.script?.sections?.find(i => i?.order_index === currentSectionOrderIndex);
      const reachedSection = readScriptAttempt?.data?.lms_script?.script?.sections?.find(i => i?.id === trackingScriptAttempt?.data?.section_id);

      if (currentSection?.order_index < reachedSection?.order_index || (currentSection?.order_index === reachedSection?.order_index && slideIndex < trackingScriptAttempt?.data?.position)) {
        setButtonLoading(false);
        setCanProceed(true);
        resetButtonLabels(readScriptAttempt.data.position);
        clearInterval(intervalTimer);
        timer = 0;
      }
    }
  }, [readScriptAttempt?.data, trackingScriptAttempt?.data, slideIndex]);

  useEffect(() => {
    if (readScriptAttempt?.data?.section?.sectiontype?.type === 'video') {
      const url =
        readScriptAttempt?.data?.section?.youtube_link && !useVideoFallback
          ? readScriptAttempt?.data?.section?.youtube_link
          : `${window.AWS_BUCKET_PUBLIC}${readScriptAttempt?.data?.section?.video_asset?.filename}`;
      setVideoUrl(url);

      // set watched
      const isWatched = readScriptAttempt?.data?.watched_videos?.find(i => i.section_id === readScriptAttempt?.data?.section_id) !== undefined || false;
      setWatched(isWatched);
    }
  }, [readScriptAttempt, useVideoFallback]);

  useEffect(() => {
    if (sectionId) {
      let goToPosition;
      if (position > 0) {
        goToPosition = position;
      } else {
        goToPosition = 0;
      }

      updateScriptAttemptsRequest({
        token,
        id: scriptAttemptId,
        payload: {
          section_id: sectionId,
          position: goToPosition,
        },
      });
    }
  }, [sectionId, position]);

  // If video has been watched handle can proceed
  useEffect(() => {
    if (watched) {
      handleSetCanProceed();
    }
  }, [watched]);

  /**
   * Clean up
   * clears up redux state when off from this component
   */
  useEffect(() => {
    return () => {
      readLmsScriptReset();
      readScriptAttemptsReset();
      updateScriptAttemptsReset();
      answersScriptAttemptsReset();
      questionScriptAttemptsReset();
      trackingScriptAttemptsReset();
    };
  }, []);

  useEffect(() => {
    if (scrollTop) {
      window.scrollTo(0, 0);
      setScrollTop(false);
    }
  }, [scrollTop]);

  /* Hooks - Questions
  -------------------------------------------------------- */
  /**
   * After answer submitted
   * Retrieve latest answers
   */
  useEffect(() => {
    if (answersScriptAttempt?.data) {
      questionScriptAttemptsRequest({ token, id: scriptAttemptId, questionId: answersScriptAttempt?.data?.question_id });
      answersScriptAttemptsReset();
    }
  }, [answersScriptAttempt?.data, scriptAttemptId]);
  // RENDER
  return (
    <>
      {readScriptAttempt?.errors && Object.keys(readScriptAttempt?.errors).length >= 1 ? (
        Object.keys(readScriptAttempt?.errors)?.map(error => {
          return (
            // eslint-disable-next-line react/jsx-key
            <Card>
              <Alert message={error} type="error" />
            </Card>
          );
        })
      ) : (
        <>
          <ScriptAttempt
            canProceed={canProceed}
            handlePrev={handlePrev}
            handleNext={handleNext}
            handleTocNav={handleTocNav}
            buttonPrev={buttonPrevLabel}
            buttonNext={buttonNextLabel}
            buttonNextLoading={readScriptAttempt.loading || updateScriptAttempt.loading || buttonLoading}
            history={history}
            match={match}
            title={'LMS Lesson'}
            subTitle={readScriptAttempt?.data?.lms_script?.name}
            progress={(getTotalSlideIndex(slideIndex) / totalSlides) * 100}
            slideIndex={getTotalSlideIndex(slideIndex)}
            totalSlides={totalSlides}
            complete={readScriptAttempt?.data?.complete === 1}
            position={slideIndex}
            sectionId={readScriptAttempt?.data?.section_id}
            sectionType={readScriptAttempt?.data?.section?.sectiontype?.type}
            slides={readScriptAttempt?.data?.lms_script?.script?.sections}
            reached={trackingScriptAttempt?.data}
          >
            <>
              {/* Section VIDEO 
          -------------------------------------------------------- */}
              {readScriptAttempt?.data?.section?.sectiontype?.type === 'video' && readScriptAttempt?.data?.section?.video_status?.value === 'Published' && !useTranscript && videoUrl && (
                <VideoContainer
                  title={slides[slideIndex - 1]?.title}
                  watched={watched}
                  position={readScriptAttempt?.data?.position}
                  videoUrl={videoUrl}
                  captionUrl={readScriptAttempt?.data?.section?.video_caption_asset ? `${window.AWS_BUCKET_PUBLIC}${readScriptAttempt?.data?.section?.video_caption_asset?.filename}` : null}
                  scriptAttemptId={readScriptAttempt?.data?.id}
                  sectionId={readScriptAttempt?.data?.section_id}
                  handleVideoDone={handleVideoDone}
                  handleVideoUpdatePosition={handleSetPosition}
                  handleSetUseTranscript={handleSetUseTranscript}
                  handleSetVideoFallback={handleSetVideoFallback}
                />
              )}

              {/* Section QUESTION 
          -------------------------------------------------------- */}
              {readScriptAttempt?.data?.section?.sectiontype?.type === 'question' && (
                <LmsScriptQuestionContainer
                  index={slideIndex}
                  loading={questionScriptAttempt?.loading || questionScriptAttempt?.loading}
                  attempts={questionScriptAttempt?.data?.length > 0 ? questionScriptAttempt?.data?.map(i => ({ ...i })) : []}
                  complete={!!readScriptAttempt?.data?.complete}
                  questionId={readScriptAttempt?.data?.section?.sectionslides[0]?.question?.id}
                  allowRetry={!!readScriptAttempt?.data?.lms_script?.settings?.question_retry}
                  handleSubmitOption={handleSubmitQuestionAnswer}
                  handleQuestionDone={handleSetCanProceed}
                />
              )}

              {/* Section LESSON
          -------------------------------------------------------- */}
              {(readScriptAttempt?.data?.section?.sectiontype?.type === 'lesson' ||
                (readScriptAttempt?.data?.section?.sectiontype?.type === 'video' && readScriptAttempt?.data?.section?.video_status?.value !== 'Published') ||
                (readScriptAttempt?.data?.section?.sectiontype?.type === 'video' && useTranscript)) && <LessonContainer data={readScriptAttempt?.data?.section?.sectionslides} index={slideIndex} />}
            </>
          </ScriptAttempt>
        </>
      )}
    </>
  );
};

// REDUX
// --------------------------------------------------------
/**
 * Maps Redux State To Component
 * @param {Object} state main redux state
 * @returns {Object} Redux Reducer States
 */
const mapStateToProps = state => ({
  token: state.loginReducer.token,
  user: state.loginReducer.user,
  readScriptAttempt: state.ReadScriptAttemptsReducer,
  updateScriptAttempt: state.UpdateScriptAttemptsReducer,
  answersScriptAttempt: state.AnswersScriptAttemptsReducer,
  questionScriptAttempt: state.QuestionScriptAttemptsReducer,
  watchedVideosScriptAttempt: state.watchedVideosScriptAttemptReducer,
  trackingScriptAttempt: state.TrackingScriptAttemptsReducer,
});

/**
 * Maps Redux Actions To Component
 * @param {*} dispatch main redux dispatch event
 * @returns {Dispatch} Redux Dispatch
 */
const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      doReadLmsScriptRequest,
      doReadScriptAttemptsRequest,
      doUpdateScriptAttemptsRequest,
      doReadLmsScriptReset,
      doReadScriptAttemptsReset,
      doUpdateScriptAttemptsReset,
      doQuestionScriptAttemptsRequest,
      doQuestionScriptAttemptsReset,
      doAnswersScriptAttemptsRequest,
      doAnswersScriptAttemptsReset,
      doTrackingScriptAttemptsRequest,
      doTrackingScriptAttemptsReset,
    },
    dispatch,
  );

// PROPS TYPES
// --------------------------------------------------------
ScriptAttemptContainer.propTypes = {
  token: PropTypes.string,
  match: PropTypes.object,
  user: PropTypes.object,
  doReadLmsScriptRequest: PropTypes.func,
  doReadScriptAttemptsRequest: PropTypes.func,
  doUpdateScriptAttemptsRequest: PropTypes.func,
  doReadLmsScriptReset: PropTypes.func,
  doReadScriptAttemptsReset: PropTypes.func,
  doUpdateScriptAttemptsReset: PropTypes.func,
  doAnswersScriptAttemptsRequest: PropTypes.func,
  doAnswersScriptAttemptsReset: PropTypes.func,
  doQuestionScriptAttemptsRequest: PropTypes.func,
  doQuestionScriptAttemptsReset: PropTypes.func,
  doTrackingScriptAttemptsRequest: PropTypes.func,
  doTrackingScriptAttemptsReset: PropTypes.func,
  readLmsScript: PropTypes.any,
  readScriptAttempt: PropTypes.any,
  updateScriptAttempt: PropTypes.any,
  answersScriptAttempt: PropTypes.any,
  questionScriptAttempt: PropTypes.any,
  trackingScriptAttempt: PropTypes.any,
  history: PropTypes.any,
};

// EXPORTS
// --------------------------------------------------------
export default connect(mapStateToProps, mapDispatchToProps)(ScriptAttemptContainer);
