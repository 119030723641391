// Imports
import { takeLatest, put, call } from 'redux-saga/effects';

// Types
import { POST_REVIEW_REQUEST, POST_REVIEW_SUCCESS, POST_REVIEW_FAILURE, GET_STUDENT_REVIEW_REQUEST, GET_STUDENT_REVIEW_SUCCESS, GET_STUDENT_REVIEW_FAILURE } from './types';

// Actions
import { postReview, getStudentReview } from './actions';

// Main Sagas
/**
 *
 * @param {token} token Authentication token
 * @param {courseId} enrollment ID of Enrollment
 * @param {rating} rating 1-5 int rating
 * @param {review} review (Optional) string review
 * @returns {void}
 */
function* workerPostReview({ token, enrollmentId, rating, review }) {
  try {
    const response = yield call(postReview, { token, enrollmentId, rating, review });

    yield put({
      type: POST_REVIEW_SUCCESS,
      response,
    });
  } catch (error) {
    yield put({ type: POST_REVIEW_FAILURE, ...error.response });
  }
}

/**
 * Watches for request
 * @return {void}
 */
export function* watcherPostReview() {
  yield takeLatest(POST_REVIEW_REQUEST, workerPostReview);
}

/**
 *
 * @param {token} token Authentication token
 * @param {courseId} courseId ID of LMS Course
 * @param {rating} rating 1-5 int rating
 * @param {review} review (Optional) string review
 * @returns {void}
 */
function* workerGetStudentReview({ token, courseId, enrollmentId }) {
  try {
    const response = yield call(getStudentReview, { token, courseId, enrollmentId });

    yield put({
      type: GET_STUDENT_REVIEW_SUCCESS,
      response,
    });
  } catch (error) {
    yield put({ type: GET_STUDENT_REVIEW_FAILURE, ...error.response });
  }
}

/**
 * Watches for request
 * @return {void}
 */
export function* watcherGetStudentReview() {
  yield takeLatest(GET_STUDENT_REVIEW_REQUEST, workerGetStudentReview);
}
