// IMPORTS
// --------------------------------------------------------
// Types
import { LIST_SCRIPTATTEMPTS_RESET, LIST_SCRIPTATTEMPTS_REQUEST, LIST_SCRIPTATTEMPTS_FAILURE, LIST_SCRIPTATTEMPTS_SUCCESS } from './types';

// MAIN ACTIONS
// --------------------------------------------------------
/**
 * Resets main reducer to initialState
 * @returns {Dispatch} Redux Dispatch
 */
export const doListScriptAttemptsReset = () => ({
  type: LIST_SCRIPTATTEMPTS_RESET,
});

/**
 * Main request
 * @returns {Dispatch} Redux Dispatch
 */
export const doListScriptAttemptsRequest = () => ({
  type: LIST_SCRIPTATTEMPTS_REQUEST,
});

/**
 * Handling request failure
 * @returns {Dispatch} Redux Dispatch
 */
export const doListScriptAttemptsFailure = ({ errors }) => ({
  type: LIST_SCRIPTATTEMPTS_FAILURE,
  errors,
});

/**
 * Handling request success
 * @returns {Dispatch} Redux Dispatch
 */
export const doListScriptAttemptsSuccess = ({ data }) => ({
  type: LIST_SCRIPTATTEMPTS_SUCCESS,
  data,
});
