import { Select, Col, Form, Icon, Input, Row, Tag } from 'antd';
import { PasswordInput } from 'antd-password-input-strength';
import PurchaseStyles from '../../styles';
import { UserOutlined } from '@ant-design/icons';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import AYPOUtil from '../../../../utils/AYPOUtil';

const StudentInformation = props => {
  const { token, user, studentData, mefetch, getMe, updateStudentInformation, handleCheckoutStep, handleConfirmationStep } = props;

  const [firstName, setFirstName] = useState(user?.firstname === 'Demo' ? '' : user?.firstname);
  const [lastName, setLastName] = useState(user?.lastname === 'User' ? '' : user?.lastname);
  const [zipCode, setZipCode] = useState(user?.student?.zip);
  const [address, setAddress] = useState(user?.student?.address);
  const [city, setCity] = useState(user?.student?.city);
  const [state, setState] = useState(user?.student?.state);
  const [phone, setPhone] = useState(user?.student?.phone);
  const [email, setEmail] = useState(user?.email);
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  //Request Triggers
  const [updateStudentInformationRequest, setUpdateStudentInformationRequest] = useState(false);
  const [meRequest, setMeRequest] = useState(false);

  //Form Events
  /**
   * Student First Name Input Field Change Event
   * @param {*} event Event
   * @returns {void}
   */
  const handleFirstNameChange = event => {
    const { value } = event.target;
    setFirstName(value);
  };

  /**
   * Student Last Name Input Field Change Event
   * @param {*} event Event
   * @returns {void}
   */
  const handleLastNameChange = event => {
    const { value } = event.target;
    setLastName(value);
  };

  /**
   * Student Zip Code Input Field Change Event
   * @param {*} event Event
   * @returns {void}
   */
  const handleZipCodeChange = event => {
    const { value } = event.target;
    setZipCode(value);
    setState(AYPOUtil.getState(value, true));
  };

  /**
   * Student Address Input Field Change Event
   * @param {*} event Event
   * @returns {void}
   */
  const handleAddressChange = event => {
    const { value } = event.target;
    setAddress(value);
  };

  /**
   * Student City Input Field Change Event
   * @param {*} event Event
   * @returns {void}
   */
  const handleCityChange = event => {
    const { value } = event.target;
    setCity(value);
  };

  /**
   * Student State Input Field Change Event
   * @param {*} event Event
   * @returns {void}
   */
  const handleStateChange = event => {
    const { value } = event.target;
    setState(value);
  };

  /**
   * Student Email Input Field Change Event
   * @param {*} event Event
   * @returns {void}
   */
  const handleEmailChange = event => {
    const { value } = event.target;
    setEmail(value);
  };

  /**
   * Student Phone Input Field Change Event
   * @param {*} event Event
   * @returns {void}
   */
  const handlePhoneChange = event => {
    const { value } = event.target;
    setPhone(value);
  };

  /**
   * Student Password Input Field Change Event
   * @param {*} event Event
   * @returns {void}
   */
  const handlePasswordChange = event => {
    const { value } = event.target;
    setPassword(value);
  };

  /**
   * Student Password Confirm Input Field Change Event
   * @param {*} event Event
   * @returns {void}
   */
  const handlePasswordConfirmChange = event => {
    const { value } = event.target;
    setConfirmPassword(value);
  };

  //Form Submission
  const handleStudentInformationUpdate = () => {
    setUpdateStudentInformationRequest(true);
  };

  // Form Validation
  const [formValidation, setFormValidation] = useState({
    fields: {
      firstName: {
        validated: false,
        valid: true,
        errors: [],
      },
      lastName: {
        validated: false,
        valid: true,
        errors: [],
      },
      zipCode: {
        validated: false,
        valid: true,
        errors: [],
      },
      address: {
        validated: false,
        valid: true,
        errors: [],
      },
      city: {
        validated: false,
        valid: true,
        errors: [],
      },
      state: {
        validated: false,
        valid: true,
        errors: [],
      },
      email: {
        validated: false,
        valid: true,
        errors: [],
      },
      phone: {
        validated: false,
        valid: true,
        errors: [],
      },
      password: {
        validated: false,
        valid: true,
        errors: [],
      },
      confirmPassword: {
        validated: false,
        valid: true,
        errors: [],
      },
    },
  });

  // Form Utility Functions

  // This clears the error validation on forms
  const clearFormValidation = formValidation => {
    Object.keys(formValidation.fields).forEach(function(key) {
      formValidation.fields[key].errors = [];
    });
  };

  // This will validate the form to ensure that all the fields are valid
  const validateForm = formValidation => {
    let isValid = true;
    Object.keys(formValidation.fields).forEach(function(key) {
      formValidation.fields[key].validated = true;
      formValidation.fields[key].valid = !formValidation.fields[key].errors.length;
      if (!formValidation.fields[key].valid) {
        isValid = false;
      }
    });
    return isValid;
  };

  //Effects
  useEffect(() => {
    if (updateStudentInformationRequest) {
      clearFormValidation(formValidation);
      if (firstName === '') {
        formValidation.fields.firstName.errors.push('First Name cannot be blank');
      }
      if (firstName.length < 3) {
        formValidation.fields.firstName.errors.push('First Name requires at least 3 characters');
      }
      if (lastName === '') {
        formValidation.fields.lastName.errors.push('Last Name cannot be blank');
      }
      if (lastName.length < 3) {
        formValidation.fields.lastName.errors.push('Last Name requires at least 3 characters');
      }
      if (zipCode === '') {
        formValidation.fields.zipCode.errors.push('ZipCode cannot be blank');
      }
      if (zipCode.length < 5) {
        formValidation.fields.zipCode.errors.push('Zip Code requires at least 5 characters');
      }
      if (address === '') {
        formValidation.fields.address.errors.push('Address cannot be blank');
      }
      if (address.length < 3) {
        formValidation.fields.address.errors.push('Address is not valid');
      }
      if (city === '') {
        formValidation.fields.city.errors.push('City cannot be blank');
      }
      if (city.length < 3) {
        formValidation.fields.city.errors.push('City cannot be less than 3 characters');
      }
      // if (city.match(/^[a-zA-Z]+(?:[\s-][a-zA-Z]\s+)*$/)) {
      //   formValidation.fields.city.errors.push('City is not valid');
      // }
      if (state === '') {
        formValidation.fields.state.errors.push('State cannot be blank');
      }
      if (email === '') {
        formValidation.fields.firstName.errors.push('Last Name cannot be blank');
      }
      if (!email.match(/^.+@.+$/)) {
        formValidation.fields.email.errors.push('Email is not valid');
      }
      if (phone === '') {
        formValidation.fields.phone.errors.push('Phone cannot be blank');
      }
      if (!phone.match(/^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/)) {
        formValidation.fields.phone.errors.push('Phone is not valid');
      }
      if (password === '') {
        formValidation.fields.password.errors.push('Password cannot be blank');
      }
      if (confirmPassword === '') {
        formValidation.fields.confirmPassword.errors.push('Confirm Password cannot be blank');
      }

      if (password.length < 8) {
        formValidation.fields.password.errors.push('Password cannot be less than 8 characters');
      }
      if (confirmPassword.length < 8) {
        formValidation.fields.confirmPassword.errors.push('Confirm Password cannot be less than 8 characters');
      }
      if (password !== confirmPassword) {
        formValidation.fields.password.errors.push('Passwords do not match');
        formValidation.fields.confirmPassword.errors.push('Passwords do not match');
      }
      // if (!password.match(/^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/)) {
      //   formValidation.fields.password.errors.push('Minimum of 8 characters, at least one letter and one number.');
      // }
      // if (!confirmPassword.match(/^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/)) {
      //   formValidation.fields.confirmPassword.errors.push('Minimum of 8 characters, at least one letter and one number.');
      // }
      if (validateForm(formValidation)) {
        let studentId = user?.student?.id;
        updateStudentInformation({ token, studentId, firstName, lastName, zipCode, address, city, state, email, phone, password, confirmPassword });
        setMeRequest(true);
      } else {
        setFormValidation(formValidation);
      }
      setUpdateStudentInformationRequest(false);
    }
  }, [token, user, firstName, lastName, zipCode, address, city, state, email, phone, password, confirmPassword, updateStudentInformationRequest]);

  const renderHelp = formValidation => {
    return formValidation.errors.length
      ? formValidation.errors.map(item => (
          <Tag key={`error`} color="red">
            {item}
          </Tag>
        ))
      : '';
  };

  const renderValidateStatus = formValidation => {
    return formValidation.errors.length ? 'error' : formValidation.validated && formValidation.valid ? 'success' : '';
  };

  useEffect(() => {
    if (meRequest && token && studentData && !studentData?.fetching && studentData?.data) {
      setMeRequest(false);
      getMe({ token });
      handleConfirmationStep();
    }
  }, [studentData, token, getMe, meRequest]);

  return (
    <div>
      <legend style={{ fontSize: 22, marginBottom: 10 }}>Student Information</legend>
      <Form layout="vertical">
        <Row gutter={[{ xs: 8, sm: 16, md: 24, lg: 32 }, 20]}>
          <Col xs={24} sm={24} md={12} span={12}>
            <Form.Item
              validateStatus={renderValidateStatus(formValidation?.fields?.firstName)}
              help={renderHelp(formValidation?.fields?.firstName)}
              label="First Name"
              required
              tooltip="This is a required field"
              style={{ marginBottom: -15 }}
            >
              <Input value={firstName} onChange={handleFirstNameChange} placeholder="Student first name" />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={12} span={12}>
            <Form.Item
              validateStatus={renderValidateStatus(formValidation?.fields?.lastName)}
              help={renderHelp(formValidation?.fields?.lastName)}
              label="Last Name"
              required
              tooltip="This is a required field"
              style={{ marginBottom: 0 }}
            >
              <Input value={lastName} onChange={handleLastNameChange} placeholder="Student last name" />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={12} span={12}>
            <Form.Item
              validateStatus={renderValidateStatus(formValidation?.fields?.zipCode)}
              help={renderHelp(formValidation?.fields?.zipCode)}
              label="Zip Code"
              required
              tooltip="This is a required field"
              style={{ marginBottom: 0 }}
            >
              <Input value={zipCode} onChange={handleZipCodeChange} placeholder="Student zip code" />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={12} span={12}>
            <Form.Item
              validateStatus={renderValidateStatus(formValidation?.fields?.address)}
              help={renderHelp(formValidation?.fields?.address)}
              label="Address"
              required
              tooltip="This is a required field"
              style={{ marginBottom: 0 }}
            >
              <Input value={address} onChange={handleAddressChange} placeholder="Student address" />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={12} span={12}>
            <Form.Item
              validateStatus={renderValidateStatus(formValidation?.fields?.state)}
              help={renderHelp(formValidation?.fields?.state)}
              label="State"
              required
              tooltip="This is a required field"
              style={{ marginBottom: 0 }}
            >
              <Select defaultValue={state} value={state} onChange={handleStateChange}>
                <Select.Option value={'AL'}>Alabama</Select.Option>
                <Select.Option value={'AK'}>Alaska</Select.Option>
                <Select.Option value={'AZ'}>Arizona</Select.Option>
                <Select.Option value={'AR'}>Arkansas</Select.Option>
                <Select.Option value={'CA'}>California</Select.Option>
                <Select.Option value={'CO'}>Colorado</Select.Option>
                <Select.Option value={'CT'}>Connecticut</Select.Option>
                <Select.Option value={'DE'}>Delaware</Select.Option>
                <Select.Option value={'DC'}>District Of Columbia</Select.Option>
                <Select.Option value={'FL'}>Florida</Select.Option>
                <Select.Option value={'GA'}>Georgia</Select.Option>
                <Select.Option value={'HI'}>Hawaii</Select.Option>
                <Select.Option value={'ID'}>Idaho</Select.Option>
                <Select.Option value={'IL'}>Illinois</Select.Option>
                <Select.Option value={'IN'}>Indiana</Select.Option>
                <Select.Option value={'IA'}>Iowa</Select.Option>
                <Select.Option value={'KS'}>Kansas</Select.Option>
                <Select.Option value={'KY'}>Kentucky</Select.Option>
                <Select.Option value={'LA'}>Louisiana</Select.Option>
                <Select.Option value={'ME'}>Maine</Select.Option>
                <Select.Option value={'MD'}>Maryland</Select.Option>
                <Select.Option value={'MA'}>Massachusetts</Select.Option>
                <Select.Option value={'MI'}>Michigan</Select.Option>
                <Select.Option value={'MN'}>Minnesota</Select.Option>
                <Select.Option value={'MS'}>Mississippi</Select.Option>
                <Select.Option value={'MO'}>Missouri</Select.Option>
                <Select.Option value={'MT'}>Montana</Select.Option>
                <Select.Option value={'NE'}>Nebraska</Select.Option>
                <Select.Option value={'NV'}>Nevada</Select.Option>
                <Select.Option value={'NH'}>New Hampshire</Select.Option>
                <Select.Option value={'NJ'}>New Jersey</Select.Option>
                <Select.Option value={'NM'}>New Mexico</Select.Option>
                <Select.Option value={'NY'}>New York</Select.Option>
                <Select.Option value={'NC'}>North Carolina</Select.Option>
                <Select.Option value={'ND'}>North Dakota</Select.Option>
                <Select.Option value={'OH'}>Ohio</Select.Option>
                <Select.Option value={'OK'}>Oklahoma</Select.Option>
                <Select.Option value={'OR'}>Oregon</Select.Option>
                <Select.Option value={'PA'}>Pennsylvania</Select.Option>
                <Select.Option value={'RI'}>Rhode Island</Select.Option>
                <Select.Option value={'SC'}>South Carolina</Select.Option>
                <Select.Option value={'SD'}>South Dakota</Select.Option>
                <Select.Option value={'TN'}>Tennessee</Select.Option>
                <Select.Option value={'TX'}>Texas</Select.Option>
                <Select.Option value={'UT'}>Utah</Select.Option>
                <Select.Option value={'VT'}>Vermont</Select.Option>
                <Select.Option value={'VA'}>Virginia</Select.Option>
                <Select.Option value={'WA'}>Washington</Select.Option>
                <Select.Option value={'WV'}>West Virginia</Select.Option>
                <Select.Option value={'WI'}>Wisconsin</Select.Option>
                <Select.Option value={'WY'}>Wyoming</Select.Option>
              </Select>
              {/*<Input value={state} onChange={handleStateChange} placeholder="Student state" />*/}
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={12} span={12}>
            <Form.Item
              validateStatus={renderValidateStatus(formValidation?.fields?.city)}
              help={renderHelp(formValidation?.fields?.city)}
              label="City"
              required
              tooltip="This is a required field"
              style={{ marginBottom: 0 }}
            >
              <Input value={city} onChange={handleCityChange} placeholder="Student city" />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={24} span={24}>
            <Form.Item
              validateStatus={renderValidateStatus(formValidation?.fields?.phone)}
              help={renderHelp(formValidation?.fields?.phone)}
              label="Phone"
              required
              tooltip="This is a required field"
              style={{ marginBottom: 0 }}
            >
              <Input value={phone} onChange={handlePhoneChange} placeholder="Student phone" />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={12} span={12}>
            <Form.Item
              validateStatus={renderValidateStatus(formValidation?.fields?.password)}
              help={renderHelp(formValidation?.fields?.password)}
              label="Password (Must be at least 8 characters)"
              required
              tooltip="This is a required field"
              style={{ marginBottom: 0 }}
            >
              <PasswordInput value={password} onChange={handlePasswordChange} required={true} placeholder="Password" />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={12} span={12}>
            <Form.Item
              validateStatus={renderValidateStatus(formValidation?.fields?.confirmPassword)}
              help={renderHelp(formValidation?.fields?.confirmPassword)}
              label="Confirm Password (Must be at least 8 characters)"
              required
              tooltip="This is a required field"
              style={{ marginBottom: 0 }}
            >
              <PasswordInput value={confirmPassword} onChange={handlePasswordConfirmChange} required={true} placeholder="Confirm Password" />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={24} span={24}>
            <div style={{ textAlign: 'center' }}>
              <Form.Item>
                <PurchaseStyles.Button type="primary" onClick={handleStudentInformationUpdate}>
                  <UserOutlined />
                  {studentData.fetching || mefetch ? <Icon type="loading" /> : 'Update Info'}
                </PurchaseStyles.Button>
              </Form.Item>
            </div>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

// PROPS TYPES
// --------------------------------------------------------
StudentInformation.propTypes = {
  token: PropTypes.string,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  city: PropTypes.string,
  state: PropTypes.string,
  address: PropTypes.string,
  zipCode: PropTypes.string,
  email: PropTypes.string,
  phone: PropTypes.string,
  password: PropTypes.string,
  confirmPassword: PropTypes.string,

  mefetch: PropTypes.bool,
  studentData: PropTypes.object,
  user: PropTypes.object,

  handleFirstNameChange: PropTypes.func,
  handleLastNameChange: PropTypes.func,
  handleZipCodeChange: PropTypes.func,
  handleStateChange: PropTypes.func,
  handleAddressChange: PropTypes.func,
  handleCityChange: PropTypes.func,
  handleEmailChange: PropTypes.func,
  handlePhoneChange: PropTypes.func,
  handlePasswordChange: PropTypes.func,
  handlePasswordConfirmChange: PropTypes.func,
  handleStudentInformationUpdate: PropTypes.func,

  renderValidateStatus: PropTypes.func,
  renderHelp: PropTypes.func,
  formValidation: PropTypes.func,

  getMe: PropTypes.func,
  updateStudentInformation: PropTypes.func,
  handleCheckoutStep: PropTypes.func,
  handleConfirmationStep: PropTypes.func,
};

export default StudentInformation;
