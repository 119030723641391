// Imports
import styled from 'styled-components';
import { Card, Table } from 'antd';
import Colors from '../../styles/Colors';

// Constants
import CONSTANTS from '../../utils/Constants';

// Main Component
const CourseCompleteStyles = styled(Card)``;

CourseCompleteStyles.Table = styled(Table)`
  max-width: 300px;
  margin: 0 auto;
  .${CONSTANTS.STATUS.COMPLETE} {
    td {
      background-color: ${Colors.green};
    }
  }
  .${CONSTANTS.STATUS.STARTED}, .${CONSTANTS.STATUS.NOT_STARTED} {
    font-weight: 700;
    td {
      background-color: ${Colors.blueLight};
    }
  }
  .${CONSTANTS.STATUS.FAILED} {
    font-weight: 700;
    td {
      background-color: ${Colors.redLight};
    }
  }
  .${CONSTANTS.STATUS.LOCKED} {
    td {
      background-color: ${Colors.greyf5};
    }
  }
`;

CourseCompleteStyles.ResultSummary = styled.div`
  text-align: center;
`;

CourseCompleteStyles.ScoreDisplay = styled.div`
  margin-top: 30px;
  font-size: 1.1rem;
  line-height: 1;
`;

CourseCompleteStyles.Actions = styled.div`
  margin-top: 30px;
  text-align: center;
  display: flex;
  justify-content: space-between;
`;

// Export
export default CourseCompleteStyles;
