// Import types
import { GET_REPORTING_QUESTIONS_REQUEST, POST_REPORTING_ANSWER_REQUEST, GET_REPORTING_ANSWERS_REQUEST } from './types';

// Export operations
export const doGetReportingQuestions = ({ token, courseId }) => ({
  type: GET_REPORTING_QUESTIONS_REQUEST,
  token,
  courseId,
});

export const doGetReportingAnswers = ({ token, enrollmentId }) => ({
  type: GET_REPORTING_ANSWERS_REQUEST,
  token,
  enrollmentId,
});

export const doPostReportingAnswers = ({ token, enrollmentId, answerData, licenseTypeId }) => ({
  type: POST_REPORTING_ANSWER_REQUEST,
  token,
  enrollmentId,
  licenseTypeId,
  answerData,
});
