// IMPORTS
// --------------------------------------------------------
// Redux
import { put, takeLatest, call } from 'redux-saga/effects';

// Type
import { TRACKING_SCRIPTATTEMPTS_REQUEST } from './types';

// Actions
import { doTrackingScriptAttemptsFailure, doTrackingScriptAttemptsSuccess } from './actions';

// Requests
import { trackingScriptAttempts } from '../requests';

// MAIN SAGAS
// --------------------------------------------------------
/**
 * Worker for handling trackingScriptAttempts
 * @return {void}
 */
function* workerTrackingScriptAttempts({ token, id }) {
  try {
    const response = yield call(trackingScriptAttempts, { token, id });

    if (!response?.data?.data) {
      throw new Error('workerTrackingScriptAttempts - Response Error');
    }

    const { data } = response.data;

    yield put(doTrackingScriptAttemptsSuccess({ data }));
  } catch (error) {
    const errors = error?.message ?? 'Something went wrong';
    yield put(doTrackingScriptAttemptsFailure({ errors }));
  }
}

/**
 * Watches for Dispatch TRACKING_SCRIPTATTEMPTS_REQUEST
 * @return {void}
 */
export function* watcherTrackingScriptAttempts() {
  yield takeLatest(TRACKING_SCRIPTATTEMPTS_REQUEST, workerTrackingScriptAttempts);
}
