// Types
import { CREATE_QUIZ_ATTEMPT_ANSWER_REQUEST, UPDATE_QUIZ_ATTEMPT_ANSWER_REQUEST } from './types';

// Main Operations
/**
 * Create New Quiz Attempt Answer
 * @param {*} Object { token, quizAttemptId, questionId, questionOptionId }
 * @returns {Dispatch} for redux
 */
export const doCreateQuizAttemptAnswer = ({ token, quizAttemptId, questionId, questionOptionId }) => ({
  type: CREATE_QUIZ_ATTEMPT_ANSWER_REQUEST,
  token,
  quizAttemptId,
  questionId,
  questionOptionId,
});

/**
 * Update Existing Quiz Attempt Answer
 * @param {*} Object { token, quizAttemptId, questionId, questionOptionId }
 * @returns {Dispatch} for redux
 */
export const doUpdateQuizAttemptAnswer = ({ token, quizAttemptId, questionId, questionOptionId }) => ({
  type: UPDATE_QUIZ_ATTEMPT_ANSWER_REQUEST,
  token,
  quizAttemptId,
  questionId,
  questionOptionId,
});
