// IMPORTS
// --------------------------------------------------------
// Types
import { WATCHEDVIDEOS_SCRIPTATTEMPTS_RESET, WATCHEDVIDEOS_SCRIPTATTEMPTS_REQUEST, WATCHEDVIDEOS_SCRIPTATTEMPTS_FAILURE, WATCHEDVIDEOS_SCRIPTATTEMPTS_SUCCESS } from './types';

// MAIN ACTIONS
// --------------------------------------------------------
/**
 * Resets main reducer to initialState
 * @returns {Dispatch} Redux Dispatch
 */
export const doWatchedVideosScriptAttemptsReset = () => ({
  type: WATCHEDVIDEOS_SCRIPTATTEMPTS_RESET,
});

/**
 * Main request
 * @returns {Dispatch} Redux Dispatch
 */
export const doWatchedVideosScriptAttemptsRequest = ({ token, id, payload }) => ({
  type: WATCHEDVIDEOS_SCRIPTATTEMPTS_REQUEST,
  token,
  id,
  payload,
});

/**
 * Handling request failure
 * @returns {Dispatch} Redux Dispatch
 */
export const doWatchedVideosScriptAttemptsFailure = ({ errors }) => ({
  type: WATCHEDVIDEOS_SCRIPTATTEMPTS_FAILURE,
  errors,
});

/**
 * Handling request success
 * @returns {Dispatch} Redux Dispatch
 */
export const doWatchedVideosScriptAttemptsSuccess = ({ data }) => ({
  type: WATCHEDVIDEOS_SCRIPTATTEMPTS_SUCCESS,
  data,
});
