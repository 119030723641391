// Imports
import axios from 'axios';

// Config
const apiUri = window.API_URL;

// Export actions
export const getLmsCourse = ({ token, lmsCourseId }) => {
  return axios({
    method: 'get',
    url: `${apiUri}/lmscourses/${lmsCourseId}?with=licenseTypes`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const getGuestLmsCourse = ({ lmsCourseId }) => {
  return axios({
    method: 'get',
    url: `${apiUri}/guest/lmscourses/${lmsCourseId}`,
  });
};

export const getGuestLmsCourses = () => {
  return axios({
    method: 'get',
    url: `${apiUri}/guest/lmscourses`,
  });
};
