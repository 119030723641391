// Imports
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

// Operations
import { doGetReportingQuestions, doGetReportingAnswers, doPostReportingAnswers } from './operations';
import { doGetLmsCourse, doClearLmsCourse } from '../LmsCourse/operations';

// Presentation Components
import Reporting from '../../components/Reporting';

// Main Component
const ReportingContainer = props => {
  /**
   *
   */
  const {
    token,
    fetching,
    doGetReportingQuestions: getReportingQuestions,
    doGetReportingAnswers: getReportingAnswers,
    doPostReportingAnswers: postReportingAnswers,
    doGetLmsCourse: getLmsCourse,
    doClearLmsCourse: clearLmsCourse,
    match,
    answered,
    history,
  } = props;
  const { enrollmentId, courseId } = match.params;

  const handleSubmit = (answerData, licenseTypeId) => {
    postReportingAnswers({ token, enrollmentId, answerData, licenseTypeId });
  };

  // Refresh data when an attempt is made
  useEffect(() => {
    if (answered.data) {
      // Navigate to next action (Awaiting backend pulse for implementation)
      history.push(`/dashboard/enrollments/${enrollmentId}/courses/${courseId}/review`);
    }
  }, [answered]);

  // Get initial data
  useEffect(() => {
    if (token) {
      getReportingQuestions({ token, courseId });
      getReportingAnswers({ token, enrollmentId });
      getLmsCourse({ token, lmsCourseId: courseId });
    }
  }, [getReportingQuestions, getReportingAnswers, getLmsCourse, token, enrollmentId, courseId]);

  // When existing component
  useEffect(() => {
    return () => {
      clearLmsCourse();
    };
  }, [clearLmsCourse]);

  return <Reporting {...props} loading={fetching} handleSubmit={handleSubmit} params={match.params}></Reporting>;
};

// Mapping State / Dispatch To Props
const mapStateToProps = state => {
  return {
    token: state.loginReducer.token,
    questions: {
      fetching: state.reportingReducer.questions.fetching,
      data: state.reportingReducer.questions.data,
      errors: state.reportingReducer.questions.errors,
    },
    answers: {
      fetching: state.reportingReducer.answers.fetching,
      data: state.reportingReducer.answers.data,
      errors: state.reportingReducer.answers.errors,
    },
    answered: {
      fetching: state.reportingReducer.answered.fetching,
      data: state.reportingReducer.answered.data,
      errors: state.reportingReducer.answered.errors,
    },
    lmsCourse: {
      fetching: state.lmsCourseReducer.fetching,
      data: state.lmsCourseReducer.data,
      error: state.lmsCourseReducer.errors,
    },
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      doGetReportingQuestions,
      doGetReportingAnswers,
      doPostReportingAnswers,
      doGetLmsCourse,
      doClearLmsCourse,
    },
    dispatch,
  );

// Property Types
ReportingContainer.propTypes = {
  token: PropTypes.string,
  doGetReportingQuestions: PropTypes.func,
  doGetReportingAnswers: PropTypes.func,
  doPostReportingAnswers: PropTypes.func,
  doGetLmsCourse: PropTypes.func,
  doClearLmsCourse: PropTypes.func,
  match: PropTypes.object,
  fetching: PropTypes.bool,
  questions: PropTypes.object,
  answers: PropTypes.object,
  answered: PropTypes.object,
  history: PropTypes.object,
  scriptId: PropTypes.string,
};

// Export
export default connect(mapStateToProps, mapDispatchToProps)(ReportingContainer);
