// IMPORTS
// --------------------------------------------------------
import axios from 'axios';

// CONFIG
// --------------------------------------------------------
const apiUri = window.API_URL;

// MAIN REQUESTS
// --------------------------------------------------------
/**
 * List all LmsScripts
 * @returns {Promise} Axios Promise
 */
export const listLmsScripts = ({ token }) => axios({ method: 'get', url: `${apiUri}/lmsscripts`, headers: { Authorization: `Bearer ${token}` } });

/**
 * Create LmsScripts
 * @returns {Promise} Axios Promise
 */
export const createLmsScripts = ({ token }) => axios({ method: 'post', url: `${apiUri}/lmsscripts`, headers: { Authorization: `Bearer ${token}` } });

/**
 * Read individual LmsScripts
 * @returns {Promise} Axios Promise
 */
export const readLmsScripts = ({ token, id, enrollmentId, include }) => {
  const defaultWith = include ? include : 'scriptAttempts';
  return axios({ method: 'get', url: `${apiUri}/lmsscripts/${id}?with=${defaultWith}&${enrollmentId ? `enrollment_id=${enrollmentId}` : ''}`, headers: { Authorization: `Bearer ${token}` } });
};

/**
 * Update individual LmsScripts
 * @returns {Promise} Axios Promise
 */
export const updateLmsScripts = ({ token, id }) => axios({ method: 'put', url: `${apiUri}/lmsscripts/${id}`, headers: { Authorization: `Bearer ${token}` } });

/**
 * Delete individual LmsScripts
 * @returns {Promise} Axios Promise
 */
export const deleteLmsScripts = ({ token, id }) => axios({ method: 'delete', url: `${apiUri}/lmsscripts/${id}`, headers: { Authorization: `Bearer ${token}` } });

/**
 * Get Lms Script Lesson Tracker
 * @param {Object} param0 { token, id, enrollmentId }
 * @returns {Promise} Axios Promise
 */
export const lessonTrackerLmsScripts = ({ token, id, enrollmentId }) =>
  axios({ method: 'get', url: `${apiUri}/lmsscripts/${id}/lessontracker?${enrollmentId ? `enrollment_id=${enrollmentId}` : ''}`, headers: { Authorization: `Bearer ${token}` } });

/**
 * Retrieves all script attempts associated to an lmsscript
 * @param {Object} param0 { token, id, enrollmentId, include }
 * @returns {Promise} Axios Promise
 */
export const scriptAttemptsLmsScripts = ({ token, id, enrollmentId, include }) => {
  const defaultWith = include
    ? include
    : 'lmsScript.lmsCourse.lmsCourseMembers,section.sectionslides,section.sectiontype,section.videoAsset,section.videoCaptionAsset,section.videoStatus,section.sectionslides.question.questionoptions,lmsScript.script.sections,lmsScript.script.sections.sectiontype,scriptAttemptAnswers,scriptAttemptAnswers.question.questionOptions';
  return axios({
    method: 'get',
    url: `${apiUri}/lmsscripts/${id}/scriptattempts?with=${defaultWith}&${enrollmentId ? `enrollment_id=${enrollmentId}` : ''}`,
    headers: { Authorization: `Bearer ${token}` },
  });
};
