// IMPORTS
// --------------------------------------------------------
// Types
import { READ_SCRIPTATTEMPTS_RESET, READ_SCRIPTATTEMPTS_REQUEST, READ_SCRIPTATTEMPTS_SUCCESS, READ_SCRIPTATTEMPTS_FAILURE, SET_CURRENT_POSITION } from './types';

// INITIAL STATE
// --------------------------------------------------------
/**
 * @constant
 */
const initialState = {
  loading: false,
  data: null,
  errors: null,
};

// MAIN REDUCER
// --------------------------------------------------------
/**
 * ReadScriptAttempts Reducer
 * @param {object} state initialState
 * @param {object} action any
 * @returns {object} Redux state
 */
const ReadScriptAttemptsReducer = (state = initialState, action) => {
  switch (action.type) {
    case READ_SCRIPTATTEMPTS_RESET:
      return initialState;
    case READ_SCRIPTATTEMPTS_REQUEST:
      return {
        ...state,
        loading: true,
        errors: null,
      };
    case READ_SCRIPTATTEMPTS_FAILURE:
      return {
        ...state,
        loading: false,
        errors: action.data.error,
      };
    case READ_SCRIPTATTEMPTS_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.data,
      };
    case SET_CURRENT_POSITION:
      return {
        ...state,
        current_section_id: action.data.section_id,
        current_position: action.data.position,
      };
    default:
      return state;
  }
};

// EXPORTS
// --------------------------------------------------------
export default ReadScriptAttemptsReducer;
