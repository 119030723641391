// Imports
import React from 'react';
import { Card, PageHeader, Divider, Progress, Spin, Tag } from 'antd';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

// Styles
import DashboardStyles from './styles';

// Main Component
/**
 * Dashboard Container
 * @param {*} props any
 * @returns {JSX.Element} Main JSX Routes
 */
const Dashboard = props => {
  // State / Props
  const { user, fetching, history } = props;

  // Functions
  const onClickCard = url => event => {
    history.push(url);
    event.preventDefault();
  };

  // Hooks

  // Render
  return (
    <DashboardStyles>
      <PageHeader title="My Courses" />
      {fetching ? (
        <Spin />
      ) : (
        <>
          <Card>
            <h3>Active Classes</h3>
            <Divider />
            <DashboardStyles.Active>
              {user?.student?.enrollments?.length > 0 && user.student.enrollments.filter(i => i.completed === 0).length > 0 ? (
                <>
                  {user.student.enrollments
                    .filter(i => i.completed === 0)
                    .sort(function(a, b) {
                      return a.lms_course?.name > b.lms_course?.name ? 1 : b.lms_course?.name > a.lms_course?.name ? -1 : 0;
                    })
                    .map((i, k) => (
                      <Card
                        key={`enrollment-course-${k}`}
                        hoverable
                        title={i?.lms_course?.name ?? 'Unknown'}
                        onClick={onClickCard(`/dashboard/enrollments/${i.id}/courses/${i?.lms_course_id}/outline`)}
                      >
                        <Progress type="circle" percent={i?.percent_complete > 100 ? 100 : parseInt(i?.percent_complete, 0)} />
                        {i.guest ? (
                          <div>
                            <Tag color="cyan">Guest</Tag>
                          </div>
                        ) : (
                          ''
                        )}
                      </Card>
                    ))}
                </>
              ) : (
                <p>No Active Courses.</p>
              )}
            </DashboardStyles.Active>
          </Card>
          <Card>
            <h3>Completed Classes</h3>
            <Divider />
            <DashboardStyles.Completed>
              {user?.student?.enrollments?.length > 0 && user.student.enrollments.filter(i => i.completed === 1).length > 0 ? (
                <>
                  {user.student.enrollments
                    .filter(i => i.completed === 1)
                    .map((i, k) => (
                      <Card
                        key={`enrollment-course-${k}`}
                        hoverable
                        title={i?.lms_course?.name ?? 'Unknown'}
                        onClick={onClickCard(`/dashboard/enrollments/${i.id}/courses/${i?.lms_course_id}/outline`)}
                      >
                        <Progress type="circle" percent={i?.percent_complete > 100 ? 100 : parseInt(i?.percent_complete, 0)} />
                        {i.guest ? (
                          <div>
                            <Tag color="cyan">Guest</Tag>
                          </div>
                        ) : (
                          ''
                        )}
                      </Card>
                    ))}
                </>
              ) : (
                <p>No Completed Courses Yet.</p>
              )}
            </DashboardStyles.Completed>
          </Card>
        </>
      )}
    </DashboardStyles>
  );
};

// Prop Types
Dashboard.propTypes = {
  user: PropTypes.object,
  history: PropTypes.object,
  fetching: PropTypes.bool,
};

// Exports
export default withRouter(Dashboard);
