const Colors = {
  grey: '#efefef',
  grey7d: '#7d7d7d',
  greyf5: '#f5f5f5',
  greye8: '#e8e8e8',
  white: '#ffffff',
  black: '#000000',
  blue: '#0098e4',
  blueLight: '#aed0e9',
  green: '#c8e9ae',
  greenDark: '#42a94f',
  yellow: '#ffedab',
  lightyellow: '#ffff66',
  red: '#a94442',
  redLight: '#ff8f80',
  redBright: '#ee151f',
  pink: '#f2dede',
};

export default Colors;
