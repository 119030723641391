// Imports
import axios from 'axios';

// Config
const apiUri = window.API_URL;

// Export actions
export const postAttemptAnswer = ({ scriptAttemptId, questionId, questionOptionId, token }) => {
  return axios({
    method: 'post',
    url: `${apiUri}/scriptattemptanswers/`,
    data: { script_attempt_id: scriptAttemptId, question_id: questionId, question_option_id: questionOptionId },
    headers: { Authorization: `Bearer ${token}` },
  });
};

export const getQuestionAttempts = ({ scriptAttemptId, questionId, token }) => {
  return axios({
    method: 'get',
    url: `${apiUri}/scriptattempts/${scriptAttemptId}/questions/${questionId}`,
    headers: { Authorization: `Bearer ${token}` },
  });
};
