// Imports
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Spin, Button, Card, PageHeader, Divider, Alert, Icon } from 'antd';
import { Link } from 'react-router-dom';

// Styles
import LmsScriptStyles from './styles';

// Main Component
/**
 * Main Container
 * @param {*} props any
 * @returns {JSX.Element} Main JSX Routes
 */
const LmsScript = props => {
  // State / Props
  const { data, loading, errors, params, history, handleBegin } = props;
  const { enrollmentId, courseId, lmsScriptId } = params;
  const [completed, setCompleted] = useState([]);
  const [incompleted, setIncompleted] = useState([]);

  // Functions
  /**
   * When the begin button is clicked
   * @param {Event} event Event click listener
   * @returns {void}
   */
  const onClickBegin = event => {
    if (handleBegin && !loading) {
      handleBegin();
    }
    event.preventDefault();
  };

  // Hooks
  /**
   * On load to set completed / incompleted
   */
  useEffect(() => {
    if (data?.script_attempts?.length > 0) {
      const allAttempts = data?.script_attempts?.map((i, k) => ({ ...i, index: k + 1 }));
      setCompleted(allAttempts.filter(i => i.complete === 1).map(i => ({ ...i, ...{ pass: i?.score >= data?.settings?.req_grade } })));
      setIncompleted(allAttempts.filter(i => i.complete !== 1));
    } else if (data?.script_attempts?.length === 0 && handleBegin && !loading) {
      handleBegin();
    }
  }, [data, handleBegin, loading]);

  // Render
  return (
    <LmsScriptStyles>
      <PageHeader
        title={'LMS Lesson'}
        subTitle={data?.name ?? ''}
        ghost={false}
        onBack={() => {
          history.push(`/dashboard/enrollments/${enrollmentId}/courses/${courseId}/outline`);
        }}
        extra={
          <Button
            type="primary"
            style={{ backgroundColor: '#5e8dbd', borderColor: 'rgb(97 121 146)' }}
            onClick={() => {
              history.push(`/dashboard/enrollments/${enrollmentId}/courses/${courseId}/outline`);
            }}
          >
            <Icon type="book" /> Course Outline
          </Button>
        }
      />
      {errors?.error ? (
        errors?.error?.map(error => {
          // eslint-disable-next-line react/jsx-key
          return <Alert message={error} type="error" />;
        })
      ) : (
        <>
          <Card>
            {loading && <Spin spinning={true} />}
            {!loading && errors && <Alert message={errors} type="error" />}
            {!loading && !errors && (
              <>
                <PageHeader title={data?.name ?? ''} />

                <main>
                  {incompleted?.length > 0 && (
                    <>
                      <h4>Continue Existing Attempt{incompleted.length > 1 && 's'}</h4>
                      <p>
                        <small>Total: {incompleted?.length}</small>
                      </p>
                      <section>
                        {incompleted.map((i, k) => (
                          <p key={`incompleted-${k}`}>
                            <Link to={`/dashboard/enrollments/${enrollmentId}/courses/${courseId}/lmslessons/${lmsScriptId}/attempts/${i.id}`}>
                              <Button title={i?.start_time} type="primary">
                                <span>
                                  <strong>Continue</strong> Attempt #{i.index}
                                </span>
                              </Button>
                            </Link>
                          </p>
                        ))}
                      </section>
                      <Divider />
                    </>
                  )}
                  {completed?.length > 0 && (
                    <>
                      <h4>Review Past Attempts</h4>
                      <p>
                        <small>Total: {completed?.length}</small>
                      </p>
                      <section>
                        {completed.map((i, k) => (
                          <p key={`completed-${k}`}>
                            <Link to={`/dashboard/enrollments/${enrollmentId}/courses/${courseId}/lmslessons/${lmsScriptId}/attempts/${i.id}/results`}>
                              <Button title={i?.start_time} className={i?.pass ? 'passed' : ''}>
                                <span>
                                  <strong>Review</strong> Attempt #{i.index}
                                </span>
                                <strong>({i?.score}%)</strong>
                              </Button>
                            </Link>
                          </p>
                        ))}
                      </section>
                      <Divider />
                    </>
                  )}
                </main>

                <Button disabled={loading} loading={loading} onClick={onClickBegin} type={`primary`} ghost={`true`}>
                  New Attempt <Icon type="plus" />
                </Button>
              </>
            )}
          </Card>
        </>
      )}
    </LmsScriptStyles>
  );
};

// Prop Types
LmsScript.propTypes = {
  data: PropTypes.object,
  loading: PropTypes.bool,
  submitting: PropTypes.bool,
  params: PropTypes.object,
  history: PropTypes.object,
  handleBegin: PropTypes.func,
  errors: PropTypes.any,
};

// Exports
export default LmsScript;
