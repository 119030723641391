// Imports
import styled from 'styled-components';
import Colors from '../../styles/Colors';

// Main
/**
 * Main container style
 */
const QuizResultsStyles = styled.div`
  padding: 20px;

  .questionAnswer {
    color: #333;
    font-weight: bold;
  }

  .userQuestionAnswer {
    color: #333;
  }

  .questionHint {
    a {
      color: #333;
      font-style: italic;
      text-decoration: underline;
      &:hover {
        color: ${Colors.white};
      }
    }
  }

  .review {
    background: ${Colors.blueLight};

    &:hover {
      background: ${Colors.white};
    }
  }

  .retry {
    background: ${Colors.yellow};

    &:hover {
      background: ${Colors.white};
    }
  }

  ul {
    margin: 0;
    padding: 0;
    list-style: none;
    li {
      button {
        padding: 1rem 2rem;
        width: 100%;
        height: auto;
        margin: 0.25rem 0;
        font-weight: 800;

        i {
          position: absolute;
          right: 0.5rem;
          top: calc(50% - 7px);
        }
      }
      .correct {
        background-color: ${Colors.green};
        color: ${Colors.greenDark};
      }
      .incorrect {
        background: ${Colors.redLight};
        color: ${Colors.red};
      }
      .skipped {
        background: #eaca55;
        color: #ffc107;
      }
    }
  }

  footer {
    display: flex;
    justify-content: space-between;
  }
`;

QuizResultsStyles.Controls = styled.div`
  display: flex;
  justify-content: space-between;
  button {
    margin-right: 10px;
  }
`;

// Export
export default QuizResultsStyles;
