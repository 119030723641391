// Imports
import styled from 'styled-components';
import { Card } from 'antd';

// Main Component
const ReviewStyles = styled(Card)``;

ReviewStyles.Actions = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;
`;

// Export
export default ReviewStyles;
