// Imports
import axios from 'axios';

// Config
const apiUri = window.API_URL;

// Export actions
export const getLmsQuiz = ({ token, lmsQuizId, enrollmentId }) => {
  return axios({
    method: 'get',
    url: `${apiUri}/lmsquizzes/${lmsQuizId}?with=quiz.courseitems,quizAttempts&section_review=true&${enrollmentId ? `enrollment_id=${enrollmentId}` : ''}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const resetLmsCourse = ({ token, enrollmentId, lmsCourseMemberId }) => {
  return axios({
    method: 'put',
    url: `${apiUri}/enrollments/${enrollmentId}/lmscoursemembers/${lmsCourseMemberId}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};
