// Imports
import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import moment from 'moment';

// Presentations
import QuizAttempt from '../../components/QuizAttempt';

// Operations
import { doGetQuizAttempt, doUpdateQuizAttempt, doClearQuizAttempt, doSetCurrentQuizAttemptAnswer } from './operations';
import { doCreateQuizAttemptAnswer, doUpdateQuizAttemptAnswer } from '../QuizAttemptAnswer/operations';

const queryString = require('query-string');

// Main Component
/**
 * Main Container
 * @param {*} props any
 * @returns {JSX.Element} Main JSX Routes
 */
const QuizAttemptContainer = props => {
  //   // State / Props
  const {
    doGetQuizAttempt: getQuizAttempt,
    doUpdateQuizAttempt: updateQuizAttempt,
    doUpdateQuizAttemptAnswer: updateQuizAttemptAnswer,
    doClearQuizAttempt: clearQuizAttempt,
    doSetCurrentQuizAttemptAnswer: setCurrentQuizAttemptAnswer,
    submitting,
    submittingQuizAttemptAnswer,
    token,
    data,
    loading,
    match,
    history,
    errors,
  } = props;
  const { enrollmentId, courseId, quizAttemptId, lmsQuizId } = match.params;
  const query = queryString.parse(window.location.search);
  const { attemptAnswerId } = query;
  const [remainingTime, setRemainingTime] = useState(null);
  const quizTimer = useRef(null);

  // Functions
  /**
   * When submitting an single answer
   * @param {*} option Object selected option
   * @returns {void}
   */
  const handleSubmitAnswer = option => {
    updateQuizAttemptAnswer({ token, quizAttemptId, questionId: option.question_id, questionOptionId: option.id });
  };

  /**
   * When doing a final submit
   * @return {void}
   */
  const handleSubmitDone = () => {
    if (data?.complete === 1) {
      history.push(`/dashboard/enrollments/${enrollmentId}/courses/${courseId}/lmsquizzes/${lmsQuizId}/attempts/${quizAttemptId}/results`);
    } else {
      updateQuizAttempt({ token, quizAttemptId, complete: 1 });
    }
  };

  /**
   * handle a timer tick
   * @return {void}
   */
  const tickTimer = () => {
    let endTime = moment
      .utc(data.created_at)
      .local()
      .add(data.lms_quiz.settings.timer, 'minutes');
    let now = moment().local();
    if (endTime.isAfter(now)) {
      let diff = moment.utc(endTime.diff(now));
      setRemainingTime(diff.format('HH:mm:ss'));
    } else {
      setRemainingTime('00:00:00');
      clearInterval(quizTimer.current);
      handleSubmitDone();
    }
  };

  /**
   * When clicking back arrow
   * @return {void}
   */
  const handleBack = () => {
    history.push(`/dashboard/enrollments/${enrollmentId}/courses/${courseId}/lmsquizzes/${lmsQuizId}`);
  };

  // Hooks
  /**
   * Onload get the quiz attempt details
   */
  useEffect(() => {
    getQuizAttempt({ token, quizAttemptId, enrollmentId });
  }, [getQuizAttempt, quizAttemptId, enrollmentId, token]);

  /**
   * When finally submitting
   */
  useEffect(() => {
    return () => {
      clearQuizAttempt();
    };
  }, [clearQuizAttempt]);

  /**
   * start the quiz timer
   * @return {void}
   */
  useEffect(() => {
    if (!quizTimer.current && data?.complete !== 1 && data?.lms_quiz?.settings?.timer > 0) {
      quizTimer.current = setInterval(tickTimer, 1000);
    }
  }, [data]);
  // Render
  return (
    <QuizAttempt
      handleDone={handleSubmitDone}
      data={data}
      attemptAnswerId={attemptAnswerId}
      remainingTime={remainingTime}
      submitting={submitting || submittingQuizAttemptAnswer}
      loading={loading}
      params={match.params}
      history={history}
      match={match}
      errors={errors}
      handleSubmit={handleSubmitAnswer}
      handleBack={handleBack}
      handleSetCurrentAnswer={setCurrentQuizAttemptAnswer}
    />
  );
};

// Map State / Dispatch To Props
/**
 * Main redux state
 * @param {*} state object
 * @returns {Object} state objects
 */
const mapStateToProps = state => ({
  token: state.loginReducer.token,
  loading: state.quizAttemptReducer.fetching,
  data: state.quizAttemptReducer.data,
  errors: state.quizAttemptReducer.errors,
  submitting: state.quizAttemptReducer.submitting,
  submittingQuizAttemptAnswer: state.quizAttemptAnswerReducer.submitting,
});

/**
 * Main redux dispatch functions
 * @param {*} dispatch redux dispatch
 * @returns {bindActionCreators} state objects
 */
const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      doGetQuizAttempt,
      doCreateQuizAttemptAnswer,
      doUpdateQuizAttemptAnswer,
      doUpdateQuizAttempt,
      doClearQuizAttempt,
      doSetCurrentQuizAttemptAnswer,
    },
    dispatch,
  );

// Prop Types
QuizAttemptContainer.propTypes = {
  match: PropTypes.object,
  doGetQuizAttempt: PropTypes.func,
  doGetQuizAttemptQuestionns: PropTypes.func,
  token: PropTypes.string,
  data: PropTypes.any,
  errors: PropTypes.any,
  loading: PropTypes.bool,
  submitting: PropTypes.bool,
  submittingQuizAttemptAnswer: PropTypes.bool,
  enrollmentId: PropTypes.number,
  courseId: PropTypes.number,
  history: PropTypes.object,
  doGetQuizAttemptQuestions: PropTypes.func,
  doCreateQuizAttemptAnswer: PropTypes.func,
  doClearQuizAttempt: PropTypes.func,
  doUpdateQuizAttempt: PropTypes.func,
  doUpdateQuizAttemptAnswer: PropTypes.func,
  doSetCurrentQuizAttemptAnswer: PropTypes.func,
};

// Exports
export default connect(mapStateToProps, mapDispatchToProps)(QuizAttemptContainer);
