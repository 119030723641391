// Import types
import {
  LOGIN_ERRORS_SET,
  LEAVEIMPERSONATION_REQUEST,
  LOGIN_REQUEST,
  LOGOUT_REQUEST,
  LOGOUT_IN_PLACE_REQUEST,
  REFRESH_REQUEST,
  AUTH_RESET,
  REDIRECT_RESET,
  AUTH_ME_REQUEST,
  SETUSER,
  NEWRELIC_FAILURE,
  REDIRECT_REFER,
  AUTHY_RESET,
  AUTHY_VERIFY_REQUEST,
  AUTHY_REQUEST_REQUEST,
  AUTHY_SETUP_REQUEST,
  CREATE_GUEST_ENROLLMENT_REQUEST,
  CLEAR_GUEST_ENROLLMENT,
  CLEAR_FETCHING,
} from './types';

// Export operations
export const doAuthReset = () => ({
  type: AUTH_RESET,
});

export const doSetErrors = error => ({
  type: LOGIN_ERRORS_SET,
  error,
});

export const doLogin = ({ email, password }) => ({
  type: LOGIN_REQUEST,
  email,
  password,
});

export const doLeaveImpersonation = ({ token }) => ({
  type: LEAVEIMPERSONATION_REQUEST,
  token,
});

export const doLogout = ({ token, redirect }) => ({ type: LOGOUT_REQUEST, token, redirect });
export const doLogoutLogin = ({ token, temptoken }) => ({ type: LOGOUT_IN_PLACE_REQUEST, token, temptoken }); //Logout without redirect or page refresh

export const doRefresh = ({ token, refresh }) => ({ type: REFRESH_REQUEST, token, refresh });

export const doRedirectReset = () => ({ type: REDIRECT_RESET });

export const doRedirectRefer = redirect => ({ type: REDIRECT_REFER, redirect });

export const doSetUser = ({ token, user }) => ({ type: SETUSER, token, user });

export const doNewRelicFailure = () => ({ type: NEWRELIC_FAILURE });

export const doGetMe = ({ token }) => ({ type: AUTH_ME_REQUEST, token });

export const doAuthyReset = () => ({
  type: AUTHY_RESET,
});

export const doAuthyVerify = ({ authy_user_id, code }) => ({
  type: AUTHY_VERIFY_REQUEST,
  authy_user_id,
  code,
});

export const doAuthySetup = ({ user_id, password, cellphone }) => ({
  type: AUTHY_SETUP_REQUEST,
  user_id,
  password,
  cellphone,
});

export const doAuthyRequest = ({ authy_user_id }) => ({
  type: AUTHY_REQUEST_REQUEST,
  authy_user_id,
});

export const doCreateGuestEnrollment = ({ lmsCourseId, token, email, firstName }) => ({
  type: CREATE_GUEST_ENROLLMENT_REQUEST,
  lmsCourseId,
  token,
  email,
  firstName,
});

export const doClearGuestEnrollment = () => ({
  type: CLEAR_GUEST_ENROLLMENT,
});

export const doClearFetching = () => ({
  type: CLEAR_FETCHING,
});
