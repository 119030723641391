// Imports
import { takeLatest, put, call } from 'redux-saga/effects';

// Types
import { GET_QUESTION_REQUEST, GET_QUESTION_SUCCESS, GET_QUESTION_FAILURE } from './types';

// Actions
import { getQuestion } from './actions';

// Main Sagas
/**
 *
 * @param {*} param0 Authentication token
 * @returns {void}
 */
function* workerGetQuestion({ token, questionId }) {
  try {
    const response = yield call(getQuestion, { token, questionId });

    // Catch For No Response
    if (!response?.data?.data) {
      throw new Error('workerGetQuestion - Response Error');
    }

    const { data } = response.data;

    yield put({
      type: GET_QUESTION_SUCCESS,
      data,
    });
  } catch (error) {
    const message = error?.message ?? 'Something went wrong';
    yield put({ type: GET_QUESTION_FAILURE, errors: message });
  }
}

/**
 * Watches for request
 * @return {void}
 */
export function* watcherGetQuestion() {
  yield takeLatest(GET_QUESTION_REQUEST, workerGetQuestion);
}
