// Import types
import { POST_REVIEW_REQUEST, GET_STUDENT_REVIEW_REQUEST } from './types';

// Export operations
export const doPostReview = ({ token, enrollmentId, rating, review }) => ({
  type: POST_REVIEW_REQUEST,
  token,
  enrollmentId,
  rating,
  review,
});

export const doGetStudentReview = ({ token, courseId, enrollmentId }) => ({
  type: GET_STUDENT_REVIEW_REQUEST,
  token,
  courseId,
  enrollmentId,
});
