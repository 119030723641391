// IMPORTS
import { all, fork } from 'redux-saga/effects';

// SAGAS

import {
  watcherLogin,
  watcherLogout,
  watcherLogoutInPlace,
  watcherRefresh,
  watcherLeaveImpersonation,
  watcherMe,
  watcherAuthyVerify,
  watcherAuthySetup,
  watcherAuthyRequest,
  watcherCreateGuestEnrollment,
} from '../containers/Login/sagas';
import { watcherForgotPassword } from '../containers/ForgotPassword/sagas';
import { watcherPasswordReset } from '../containers/PasswordReset/sagas';

import { watcherGetStudentContact, watcherCreateStudent, watcherUpdateStudentContact } from '../containers/Account/sagas';
import { watcherSendFeedback } from '../containers/Feedback/sagas';
import { watcherSendReferral } from '../containers/Referral/sagas';
import { watcherGetCourseOutline, watcherGetCourseReview } from '../containers/CourseOutline/sagas';
import { watcherGetCourseItems } from '../containers/CourseItems/sagas';
import { watcherGetCourseItem } from '../containers/CourseItem/sagas';
import { watcherGetQuiz } from '../containers/Quiz/sagas';
import { watcherGetQuizQuestions } from '../containers/QuizQuestions/sagas';
import { watcherGetLmsQuiz, watcherResetLmsCourse } from '../containers/LmsQuiz/sagas';
// import { watcherGetLmsScripts } from './modules/LmsScripts/sagas';
import { watcherGetLmsCourse, watcherGetGuestLmsCourse, watcherGetGuestLmsCourses } from '../containers/LmsCourse/sagas';
import { watcherCreateQuizAttempt, watcherGetQuizAttempt, watcherUpdateQuizAttempt } from '../containers/QuizAttempt/sagas';
// import { watcherGetLmsScriptAttempts } from './modules/ScriptAttempts/sagas';
import { watcherGetQuestion } from '../containers/Question/sagas';
import { watcherCreateQuizAttemptAnswer, watcherUpdateQuizAttemptAnswer } from '../containers/QuizAttemptAnswer/sagas';
// import { watcherGetLessonTracker, watcherGetScriptAttempts, watcherCreateScriptAttempts, watcherUpdateScriptAttempts, watcherCreateScriptAttemptWatchedVideo } from '../containers/Lessons/sagas';
import { watcherGetLessonTracker, watcherGetScriptAttempts, watcherCreateScriptAttemptWatchedVideo } from '../containers/Lessons/sagas';
import { watcherGetReportingQuestions, watcherGetReportingAnswers, watcherPostReportingAnswer } from '../containers/Reporting/sagas';
import { watcherPostAttemptAnswer, watcherGetQuestionAttempts } from '../containers/Questions/sagas';
import { watcherGetLessonStatus } from '../containers/CourseComplete/sagas';
import { watcherPostReview, watcherGetStudentReview } from '../containers/Review/sagas';
import { watcherPutSection } from '../containers/Sections/sagas';
//
import { watcherListScriptAttempts } from './modules/ScriptAttempts/list/sagas';
import { watcherCreateScriptAttempts } from './modules/ScriptAttempts/create/sagas';
import { watcherReadScriptAttempts } from './modules/ScriptAttempts/read/sagas';
import { watcherUpdateScriptAttempts } from './modules/ScriptAttempts/update/sagas';
import { watcherWatchedVideosScriptsAttempts } from './modules/ScriptAttempts/watchedvideos/sagas';
import { watcherAnswersScriptAttempts } from './modules/ScriptAttempts/answers/sagas';
import { watcherQuestionScriptAttempts } from './modules/ScriptAttempts/question/sagas';
import { watcherTrackingScriptAttempts } from './modules/ScriptAttempts/tracking/sagas';
import { watcherReadLmsScripts } from './modules/LmsScripts/read/sagas';
import { watcherLessonTrackerLmsScripts } from './modules/LmsScripts/lessontracker/sagas';
import { watcherScriptAttemptsLmsScripts } from './modules/LmsScripts/scriptattempts/sagas';
import { watcherReadQuestions } from './modules/Questions/read/sagas';

import {
  watcherUpdateStudentInformation,
  watcherCreateCartEnrollment,
  watcherCreateCartCourse,
  watcherCreateStripePaymentIntent,
  watcherProcessStripePaymentIntent,
  watcherCreateAmazonPaymentIntent,
  watcherProcessAmazonPaymentIntent,
  watcherGetCart,
} from '../containers/Purchase/sagas';

// MAIN ROOT SAGA
/**
 * Main root saga
 * @returns {void}
 */
function* rootSaga() {
  yield all(
    [
      watcherLogin,
      watcherLogout,
      watcherLogoutInPlace,
      watcherRefresh,
      watcherMe,
      watcherAuthyVerify,
      watcherAuthySetup,
      watcherForgotPassword,
      watcherPasswordReset,
      watcherAuthyRequest,
      watcherGetStudentContact,
      watcherUpdateStudentContact,
      watcherGetCourseItems,
      watcherGetCourseItem,
      watcherGetQuiz,
      watcherGetQuizQuestions,
      watcherGetLmsQuiz,
      watcherGetLmsCourse,
      watcherGetGuestLmsCourse,
      watcherGetGuestLmsCourses,
      watcherCreateQuizAttempt,
      watcherGetQuizAttempt,
      watcherUpdateQuizAttempt,
      watcherGetQuestion,
      watcherCreateQuizAttemptAnswer,
      watcherUpdateQuizAttemptAnswer,
      watcherSendFeedback,
      watcherSendReferral,
      watcherGetCourseOutline,
      watcherGetCourseReview,
      watcherGetLessonTracker,
      // watcherCreateScriptAttempts,
      watcherCreateScriptAttemptWatchedVideo,
      watcherGetReportingQuestions,
      watcherGetReportingAnswers,
      watcherPostReportingAnswer,
      watcherPostAttemptAnswer,
      watcherGetQuestionAttempts,
      watcherGetLessonStatus,
      watcherPostReview,
      watcherGetStudentReview,
      watcherPutSection,
      // watcherGetLmsScripts,
      watcherListScriptAttempts,
      watcherCreateScriptAttempts,
      watcherReadScriptAttempts,
      watcherUpdateScriptAttempts,
      watcherWatchedVideosScriptsAttempts,
      watcherAnswersScriptAttempts,
      watcherQuestionScriptAttempts,
      watcherTrackingScriptAttempts,
      watcherReadLmsScripts,
      watcherLessonTrackerLmsScripts,
      watcherScriptAttemptsLmsScripts,
      watcherReadQuestions,
      watcherResetLmsCourse,
      watcherCreateStudent,
      watcherLeaveImpersonation,
      watcherUpdateStudentInformation,
      watcherCreateCartEnrollment,
      watcherCreateStripePaymentIntent,
      watcherProcessStripePaymentIntent,
      watcherCreateAmazonPaymentIntent,
      watcherProcessAmazonPaymentIntent,
      watcherCreateGuestEnrollment,
      watcherCreateCartCourse,
      watcherGetCart,
    ].map(fork),
  );
}

// EXPORTS
export default rootSaga;
