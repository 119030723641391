// Imports
import React, { useState, useEffect } from 'react';
import { withRouter, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

// Operations
import { doLogout } from '../Login/operations';

// Main Component
/**
 * Main Container
 * @param {*} props any
 * @returns {JSX.Element} Main JSX Routes
 */
const LogoutContainer = props => {
  // Props
  const { token, doLogout: logout } = props;
  const [loggedOut, setLoggedOut] = useState(false);

  /**
   * This will load the function once
   * equivalent to 'componentDidMount'
   */
  useEffect(() => {
    if (!loggedOut) {
      logout({ token });
      setLoggedOut(true);
    }
  }, [token, logout, loggedOut]);

  // Render
  return token ? <>Logging Out</> : <Redirect to="/login" />;
};

// Mapping State / Dispatch To Props
/**
 * Main redux state
 * @param {*} state object
 * @returns {Object} state objects
 */
const mapStateToProps = state => ({
  token: state.loginReducer.token,
});

/**
 * Main redux dispatch functions
 * @param {*} dispatch redux dispatch
 * @returns {bindActionCreators} state objects
 */
const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      doLogout,
    },
    dispatch,
  );

// Property Types
LogoutContainer.propTypes = {
  doLogout: PropTypes.func,
  token: PropTypes.string,
};

// Export
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(LogoutContainer));
