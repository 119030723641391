// Imports
import React from 'react';
import { Card, PageHeader, Form, Input, Button, Col, Row, Select, Skeleton } from 'antd';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

// Styles
import AccountStyles from './styles';

// Main Component
/**
 * Account Container
 * @param {*} props any
 * @returns {JSX.Element} Main JSX Routes
 */
const Account = props => {
  // State / Props
  const { contact, request, form, submitForm, errors } = props;
  const { getFieldDecorator } = form;

  // Render
  return (
    <AccountStyles>
      <PageHeader title="Account" />
      <Card>
        {contact && contact.fetching ? (
          <Skeleton active />
        ) : (
          <Form onSubmit={submitForm}>
            <legend>General Information</legend>
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  label="First Name"
                  validateStatus={errors && errors.errors && errors.errors.firstname && 'error'}
                  help={errors && errors.errors && errors.errors.firstname && errors.errors.firstname[0]}
                >
                  {getFieldDecorator('firstname', {
                    rules: [
                      {
                        required: true,
                        message: 'First Name is required',
                      },
                    ],
                  })(<Input type="text" placeholder="First Name" />)}
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="Last Name"
                  validateStatus={errors && errors.errors && errors.errors.lastname && 'error'}
                  help={errors && errors.errors && errors.errors.lastname && errors.errors.lastname[0]}
                >
                  {getFieldDecorator('lastname', {
                    rules: [
                      {
                        required: true,
                        message: 'Last Name is required',
                      },
                    ],
                  })(<Input type="text" placeholder="Last Name" />)}
                </Form.Item>
              </Col>
            </Row>
            <Form.Item label="Phone">
              {getFieldDecorator('phone', {
                rules: [
                  {
                    required: true,
                    message: 'Phone is required',
                  },
                ],
              })(<Input type="text" placeholder="5555555555" />)}
            </Form.Item>
            <Form.Item label="Email Address" validateStatus={errors && errors.errors && errors.errors.email && 'error'} help={errors && errors.errors && errors.errors.email && errors.errors.email[0]}>
              {getFieldDecorator('email', {
                rules: [
                  {
                    type: 'email',
                    message: 'Not a valid email',
                  },
                  {
                    required: true,
                    message: 'Email is required',
                  },
                ],
              })(<Input type="email" placeholder="email@address.com" />)}
            </Form.Item>
            <legend>Mailing Address</legend>
            <Form.Item label="Address" validateStatus={errors && errors.errors && errors.errors.address && 'error'} help={errors && errors.errors && errors.errors.address && errors.errors.address[0]}>
              {getFieldDecorator('address', {
                rules: [
                  {
                    required: true,
                    message: 'Address is required',
                  },
                ],
              })(<Input type="text" placeholder="Address" />)}
            </Form.Item>
            <Row gutter={16}>
              <Col span={8}>
                <Form.Item label="City" validateStatus={errors && errors.errors && errors.errors.city && 'error'} help={errors && errors.errors && errors.errors.city && errors.errors.city[0]}>
                  {getFieldDecorator('city', {
                    rules: [
                      {
                        required: true,
                        message: 'City is required',
                      },
                    ],
                  })(<Input type="text" placeholder="City" />)}
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label="State" validateStatus={errors && errors.errors && errors.errors.state && 'error'} help={errors && errors.errors && errors.errors.state && errors.errors.state[0]}>
                  {getFieldDecorator('state', {
                    rules: [
                      {
                        required: true,
                        message: 'State is required',
                      },
                    ],
                  })(
                    <Select>
                      <Select.Option value={'AL'}>Alabama</Select.Option>
                      <Select.Option value={'AK'}>Alaska</Select.Option>
                      <Select.Option value={'AZ'}>Arizona</Select.Option>
                      <Select.Option value={'AR'}>Arkansas</Select.Option>
                      <Select.Option value={'CA'}>California</Select.Option>
                      <Select.Option value={'CO'}>Colorado</Select.Option>
                      <Select.Option value={'CT'}>Connecticut</Select.Option>
                      <Select.Option value={'DE'}>Delaware</Select.Option>
                      <Select.Option value={'DC'}>District Of Columbia</Select.Option>
                      <Select.Option value={'FL'}>Florida</Select.Option>
                      <Select.Option value={'GA'}>Georgia</Select.Option>
                      <Select.Option value={'HI'}>Hawaii</Select.Option>
                      <Select.Option value={'ID'}>Idaho</Select.Option>
                      <Select.Option value={'IL'}>Illinois</Select.Option>
                      <Select.Option value={'IN'}>Indiana</Select.Option>
                      <Select.Option value={'IA'}>Iowa</Select.Option>
                      <Select.Option value={'KS'}>Kansas</Select.Option>
                      <Select.Option value={'KY'}>Kentucky</Select.Option>
                      <Select.Option value={'LA'}>Louisiana</Select.Option>
                      <Select.Option value={'ME'}>Maine</Select.Option>
                      <Select.Option value={'MD'}>Maryland</Select.Option>
                      <Select.Option value={'MA'}>Massachusetts</Select.Option>
                      <Select.Option value={'MI'}>Michigan</Select.Option>
                      <Select.Option value={'MN'}>Minnesota</Select.Option>
                      <Select.Option value={'MS'}>Mississippi</Select.Option>
                      <Select.Option value={'MO'}>Missouri</Select.Option>
                      <Select.Option value={'MT'}>Montana</Select.Option>
                      <Select.Option value={'NE'}>Nebraska</Select.Option>
                      <Select.Option value={'NV'}>Nevada</Select.Option>
                      <Select.Option value={'NH'}>New Hampshire</Select.Option>
                      <Select.Option value={'NJ'}>New Jersey</Select.Option>
                      <Select.Option value={'NM'}>New Mexico</Select.Option>
                      <Select.Option value={'NY'}>New York</Select.Option>
                      <Select.Option value={'NC'}>North Carolina</Select.Option>
                      <Select.Option value={'ND'}>North Dakota</Select.Option>
                      <Select.Option value={'OH'}>Ohio</Select.Option>
                      <Select.Option value={'OK'}>Oklahoma</Select.Option>
                      <Select.Option value={'OR'}>Oregon</Select.Option>
                      <Select.Option value={'PA'}>Pennsylvania</Select.Option>
                      <Select.Option value={'RI'}>Rhode Island</Select.Option>
                      <Select.Option value={'SC'}>South Carolina</Select.Option>
                      <Select.Option value={'SD'}>South Dakota</Select.Option>
                      <Select.Option value={'TN'}>Tennessee</Select.Option>
                      <Select.Option value={'TX'}>Texas</Select.Option>
                      <Select.Option value={'UT'}>Utah</Select.Option>
                      <Select.Option value={'VT'}>Vermont</Select.Option>
                      <Select.Option value={'VA'}>Virginia</Select.Option>
                      <Select.Option value={'WA'}>Washington</Select.Option>
                      <Select.Option value={'WV'}>West Virginia</Select.Option>
                      <Select.Option value={'WI'}>Wisconsin</Select.Option>
                      <Select.Option value={'WY'}>Wyoming</Select.Option>
                    </Select>,
                  )}
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label="Zip" validateStatus={errors && errors.errors && errors.errors.zip && 'error'} help={errors && errors.errors && errors.errors.zip && errors.errors.zip[0]}>
                  {getFieldDecorator('zip', {
                    rules: [
                      {
                        required: true,
                        message: 'Zip is required',
                      },
                    ],
                  })(<Input type="text" placeholder="Zip" />)}
                </Form.Item>
              </Col>
            </Row>
            <legend>Change Password</legend>
            <Form.Item
              label="Password"
              validateStatus={errors && errors.errors && errors.errors.password && 'error'}
              help={errors && errors.errors && errors.errors.password && errors.errors.password[0]}
            >
              {getFieldDecorator('password', {
                rules: [
                  {
                    required: false,
                    message: 'Password is required',
                  },
                  {
                    min: 8,
                    message: 'Password requires at least 8 characters',
                  },
                ],
              })(<Input type="password" placeholder="Password" />)}
            </Form.Item>
            <Form.Item label="Confirm Password">
              {getFieldDecorator('confirmpassword', {
                rules: [
                  {
                    required: false,
                    message: 'Confirm Password is required',
                  },
                  {
                    min: 8,
                    message: 'Confirm Password requires at least 8 characters',
                  },
                ],
              })(<Input type="password" placeholder="Retype Password" />)}
            </Form.Item>
            <Button type="primary" loading={request && request.fetching} htmlType="submit">
              {request && request.fetching ? `Saving` : `Save`}
            </Button>
          </Form>
        )}
      </Card>
    </AccountStyles>
  );
};

// Prop Types
Account.propTypes = {
  form: PropTypes.object,
  contact: PropTypes.any,
  request: PropTypes.any,
  submitForm: PropTypes.func,
  errors: PropTypes.any,
};

// Exports
export default withRouter(Account);
