// Imports
import styled from 'styled-components';
import Colors from '../../styles/Colors';
import Screens from '../../styles/Screens';

// Main
/**
 * Main container style
 */
const LmsScriptStyles = styled.div`
  padding: 20px;

  main {
    .ant-btn {
      min-width: 100%;
      text-align: left;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    a {
      display: block;
    }

    section {
      display: block;
      max-height: 200px;
      overflow: auto;
      border: 1px solid ${Colors.greyf5};
      padding: 20px;
    }
  }

  .passed {
    background-color: ${Colors.green};
  }

  @media (min-width: ${Screens.small}) {
    main {
      .ant-btn {
        min-width: 200px;
      }

      a {
        max-width: 202px;
      }
    }
  }
`;

// Export
export default LmsScriptStyles;
