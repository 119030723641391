// Types
import { GET_LMSQUIZ_REQUEST, GET_LMSQUIZ_SUCCESS, GET_LMSQUIZ_FAILURE, RESET_LMSCOURSEMEMBER_REQUEST, RESET_LMSCOURSEMEMBER_FAILURE, RESET_LMSCOURSEMEMBER_SUCCESS } from './types';

// Initial State
const initialState = {
  loading: false,
  data: null,
  errors: null,
  lmsCoursemember: {
    loading: false,
    data: null,
    errors: null,
  },
};

// Main Reducer
/**
 * lmsQuizReducer Reducer
 * @param {Object} state current state of redux
 * @param {Object} action action state object
 * @returns {state} Redux State
 */
const lmsQuizReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_LMSQUIZ_REQUEST:
      return {
        ...state,
        loading: true,
        errors: null,
      };
    case GET_LMSQUIZ_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.data,
      };
    case GET_LMSQUIZ_FAILURE:
      return {
        ...state,
        loading: false,
        errors: action.errors,
      };
    case RESET_LMSCOURSEMEMBER_REQUEST:
      return {
        ...state,
        lmsCoursemember: {
          loading: true,
          errors: null,
        },
      };
    case RESET_LMSCOURSEMEMBER_SUCCESS:
      return {
        ...state,
        lmsCoursemember: {
          loading: false,
          data: action.data,
        },
      };
    case RESET_LMSCOURSEMEMBER_FAILURE:
      return {
        ...state,
        lmsCoursemember: {
          loading: false,
          errors: action.errors,
        },
      };
    default:
      return state;
  }
};

export default lmsQuizReducer;
