import React, { useEffect, useState } from 'react';
import PropType from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { withRouter } from 'react-router-dom';
import jwtDecode from 'jwt-decode';

// PRESENTATION COMPONENTS
import App from '../../components/App';
import { doLeaveImpersonation, doRefresh, doRedirectRefer, doSetUser, doLogoutLogin } from '../Login/operations';

// MAIN COMPONENT
/**
 * Main app component
 * @param {*} props default component props
 * @returns {JSX} react component
 */
const AppContainer = props => {
  const { children, user, loginFetching, token, doRedirectRefer: redirectRefer } = props;
  const params = new URLSearchParams(props.location.search);

  const [leaveImpersonationRequest, setLeaveImpersonationRequest] = useState(false);
  const [refreshRequest, setRefreshRequest] = useState(false);

  const removeTokenQuery = () => {
    params.delete('token');
    props.history.replace({
      search: params.toString(),
    });
  };

  /**
   * When leave impersonation (return to normal login) is clicked
   * @param {*} event Event
   * @returns {void}
   */
  const handleLeaveImpersonation = event => {
    event.preventDefault();
    props.doLeaveImpersonation({ token: props.token });
    setLeaveImpersonationRequest(true);
  };

  /**
   * Update Student Contact Information Hook
   */
  useEffect(() => {
    if (!loginFetching && !user?.impersonator?.id && leaveImpersonationRequest) {
      setLeaveImpersonationRequest(false);
    }
  }, [user, leaveImpersonationRequest]);

  /**
   * Redirect Hook
   */
  useEffect(() => {
    if (props.location.pathname) {
      redirectRefer(props.location.pathname);
    }
  }, [user]);

  /**
   * refresh token hook
   */
  useEffect(() => {
    if (!loginFetching && refreshRequest) {
      setRefreshRequest(false);
    }
  }, [user, refreshRequest]);

  var handleTempToken = tempToken => {
    if (tempToken) {
      try {
        var decodedToken = jwtDecode(tempToken, { header: true }); //Checks for invalid token
      } catch (error) {
        removeTokenQuery();
      }
      if (!refreshRequest) {
        const tempuser = jwtDecode(tempToken).user;
        props.doSetUser({ token: tempToken, user: tempuser });
        props.doRefresh({ token: tempToken });
        setRefreshRequest(true);
        removeTokenQuery();
      }
    }
  };

  handleTempToken(params.get('token'));

  return (
    <App user={user} handleLeaveImpersonation={handleLeaveImpersonation}>
      {children}
    </App>
  );
};

//REDUX
/**
 * Maps redux state to local component state
 * @param {*} state incoming redux state
 * @return {Object} redux state
 */
const mapStateToProps = state => ({
  token: state.loginReducer.token,
  loginFetching: state.loginReducer.fetching,
  user: state.loginReducer.user,
  redirect: state.loginReducer.redirect,
});

/**
 * Maps redux actions to local component state
 * @param {*} dispatch incoming redux dispatch
 * @return {Object} redux actions
 */
const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      doLeaveImpersonation,
      doRefresh,
      doRedirectRefer,
      doSetUser,
      doLogoutLogin,
    },
    dispatch,
  );

// PROP TYPES
AppContainer.propTypes = {
  location: PropType.object,
  history: PropType.object,
  children: PropType.any,
  loginFetching: PropType.bool,
  token: PropType.string,
  user: PropType.object,
  doLeaveImpersonation: PropType.func,
  doRedirectRefer: PropType.func,
  redirectRefer: PropType.func,
  doRefresh: PropType.func,
  doSetUser: PropType.func,
  doLogoutLogin: PropType.func,
};

//EXPORTS
export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(AppContainer);
