// MAIN TYPES
// --------------------------------------------------------
/**
 * @constant
 */
export const WATCHEDVIDEOS_SCRIPTATTEMPTS_RESET = 'WATCHEDVIDEOS_SCRIPTATTEMPTS_RESET';

/**
 * @constant
 */
export const WATCHEDVIDEOS_SCRIPTATTEMPTS_REQUEST = 'WATCHEDVIDEOS_SCRIPTATTEMPTS_REQUEST';

/**
 * @constant
 */
export const WATCHEDVIDEOS_SCRIPTATTEMPTS_SUCCESS = 'WATCHEDVIDEOS_SCRIPTATTEMPTS_SUCCESS';

/**
 * @constant
 */
export const WATCHEDVIDEOS_SCRIPTATTEMPTS_FAILURE = 'WATCHEDVIDEOS_SCRIPTATTEMPTS_FAILURE';
