// IMPORTS
// --------------------------------------------------------
import styled from 'styled-components';
import Screens from '../../styles/Screens';

// MAIN STYLED COMPONENTS
// --------------------------------------------------------
/**
 * Parent styles for component
 * @constant
 */
export const ScriptAttemptStyles = styled.div`
  // .scrollbar-track::-webkit-scrollbar-track {
  //   -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  //   border-radius: 10px;
  //   background-color: #f5f5f5;
  // }
  //
  // .scrollbar-track::-webkit-scrollbar {
  //   width: 12px;
  //   background-color: #f5f5f5;
  // }
  //
  // .scrollbar-track::-webkit-scrollbar-thumb {
  //   border-radius: 10px;
  //   -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  //   background-color: #bfbfbf;
  // }
  //
  // .scroll-responsive {
  //   height: 500px;
  //   width: 100%;
  //   position: relative;
  //   justify-content: center;
  //   align-items: center;
  //   overflow-y: scroll;
  // }

  position: relative;
  padding: 20px;

  .level-2 {
    padding-left: 10px;
  }

  .level-3 {
    padding-left: 20px;
  }

  .level-4 {
    padding-left: 30px;
  }

  .level-5 {
    padding-left: 40px;
  }

  .level-6 {
    padding-left: 50px;
  }

  header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    > div {
      width: 50%;
      display: flex;

      .ant-progress {
        padding-right: 1rem;
      }
    }
  }

  .ant-card-body {
    .ant-spin {
      display: block;
      margin: 200px auto;
    }
  }

  footer {
    display: flex;
    justify-content: space-between;

    button {
      span span {
        font-weight: bold;
        text-transform: capitalize;
      }
    }
  }
  @media screen and (max-width: 450px) {
    footer button .desktop-text {
      display: none;
    }
  }
`;
