// Imports
import storage from 'redux-persist/lib/storage';
import { persistReducer } from 'redux-persist';
import jwtDecode from 'jwt-decode';

// action types
import {
  LOGIN_FAILURE,
  LOGIN_SUCCESS,
  LOGIN_REQUEST,
  AUTH_RESET,
  LOGOUT_FAILURE,
  LOGOUT_SUCCESS,
  LOGOUT_REQUEST,
  LOGOUT_IN_PLACE_REQUEST,
  LOGOUT_IN_PLACE_SUCCESS,
  LOGOUT_IN_PLACE_FAILURE,
  REFRESH_SUCCESS,
  REFRESH_REQUEST,
  REFRESH_FAILURE,
  LOGIN_ERRORS_SET,
  REDIRECT_RESET,
  REDIRECT_REFER,
  AUTH_ME_REQUEST,
  AUTH_ME_SUCCESS,
  AUTH_ME_FAILURE,
  LEAVEIMPERSONATION_REQUEST,
  LEAVEIMPERSONATION_SUCCESS,
  LEAVEIMPERSONATION_FAILURE,
  SETUSER,
  NEWRELIC_FAILURE,
  AUTHY_CHECKPOINT,
  AUTHY_RESET,
  AUTHY_VERIFY_FAILURE,
  AUTHY_VERIFY_REQUEST,
  AUTHY_VERIFY_SUCCESS,
  AUTHY_REQUEST_FAILURE,
  AUTHY_REQUEST_REQUEST,
  AUTHY_REQUEST_SUCCESS,
  AUTHY_SETUP_SUCCESS,
  AUTHY_SETUP_FAILURE,
  AUTHY_SETUP_REQUEST,
  CREATE_GUEST_ENROLLMENT_FAILURE,
  CREATE_GUEST_ENROLLMENT_REQUEST,
  CREATE_GUEST_ENROLLMENT_SUCCESS,
  CLEAR_GUEST_ENROLLMENT,
  CLEAR_FETCHING,
} from './types';

// reducer with initial state
const initialState = {
  fetching: false,
  errors: null,
  token: null,
  refresh: null,
  exp: null,
  user: null,
  redirect: null,
  authy: null,
  authySetup: false,
  enrollment: null,
};

const persistConfig = {
  key: 'auth',
  storage,
  whitelist: ['token', 'refresh', 'user', 'redirect'],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case AUTH_RESET:
      return { ...initialState };
    case LOGIN_ERRORS_SET:
      return {
        ...state,
        errors: action.error,
      };
    case LOGIN_REQUEST:
      return {
        ...state,
        fetching: true,
        errors: null,
        refresh: null,
        token: null,
        exp: null,
        user: null,
      };
    case LOGIN_FAILURE:
      return {
        ...state,
        fetching: false,
        errors: action.error,
        refresh: null,
        token: null,
        exp: null,
        user: null,
      };
    case LOGIN_SUCCESS:
      return {
        ...state,
        fetching: false,
        errors: null,
        refresh: action.refresh,
        token: action.token,
        exp: action.exp,
        user: action.user,
      };
    case CREATE_GUEST_ENROLLMENT_REQUEST:
      return {
        ...state,
        fetching: true,
        errors: null,
        data: null,
        enrollment: null,
      };
    case CREATE_GUEST_ENROLLMENT_FAILURE:
      return {
        ...state,
        fetching: false,
        errors: action.error ? action.error : action.errors,
        data: null,
        enrollment: null,
      };
    case CLEAR_GUEST_ENROLLMENT:
      return {
        ...state,
        enrollment: null,
      };
    case CREATE_GUEST_ENROLLMENT_SUCCESS:
      return {
        ...state,
        fetching: false,
        errors: null,
        enrollment: action.data.data,
      };
    case AUTHY_CHECKPOINT:
      return {
        ...state,
        fetching: false,
        errors: null,
        external_error_code: null,
        authy: action.authy,
        authySetup: !!action.authySetup,
      };
    case AUTHY_RESET:
      return {
        ...state,
        authy: null,
      };
    case AUTHY_VERIFY_REQUEST:
      return {
        ...state,
        fetching: true,
        errors: null,
        external_error_code: null,
      };
    case AUTHY_VERIFY_FAILURE:
      return {
        ...state,
        fetching: false,
        errors: action.errors ? action.errors : action.error,
        external_error_code: action.external_error_code,
      };
    case AUTHY_VERIFY_SUCCESS:
      return {
        ...state,
        fetching: false,
        errors: null,
        external_error_code: null,
        refresh: action.refresh,
        token: action.token,
        exp: action.exp,
      };
    case AUTHY_SETUP_REQUEST:
      return {
        ...state,
        fetching: true,
        errors: null,
        user: null,
      };
    case AUTHY_SETUP_FAILURE:
      return {
        ...state,
        fetching: false,
        errors: action.errors,
        user: null,
      };
    case AUTHY_SETUP_SUCCESS:
      return {
        ...state,
        fetching: false,
        errors: null,
        user: null,
        authySetup: false,
      };
    case AUTHY_REQUEST_REQUEST:
      return {
        ...state,
        fetching: true,
        errors: null,
        external_error_code: null,
      };
    case AUTHY_REQUEST_FAILURE:
      return {
        ...state,
        fetching: false,
        errors: action.errors ? action.errors : action.error,
        external_error_code: action.external_error_code,
      };
    case AUTHY_REQUEST_SUCCESS:
      return {
        ...state,
        fetching: false,
        errors: null,
        external_error_code: null,
        authy: action.data,
      };
    case LEAVEIMPERSONATION_REQUEST:
      return {
        ...state,
        fetching: true,
        errors: null,
      };
    case LEAVEIMPERSONATION_SUCCESS:
      return {
        ...state,
        fetching: false,
        errors: null,
        user: action.data,
      };
    case LEAVEIMPERSONATION_FAILURE:
      return {
        ...state,
        fetching: false,
        errors: action.error,
      };
    case REFRESH_REQUEST:
      return {
        ...state,
        fetching: true,
        errors: null,
      };
    case REFRESH_FAILURE:
      return {
        ...state,
        fetching: false,
        errors: null,
        refresh: action.refresh,
        token: action.token,
      };
    case REFRESH_SUCCESS:
      return {
        ...state,
        fetching: false,
        errors: null,
        refresh: action.refresh,
        token: action.token,
        user: action.user,
      };
    case LOGOUT_REQUEST:
      return {
        ...state,
        fetching: true,
        errors: null,
        refresh: null,
        token: null,
        exp: null,
        user: null,
        redirect: action.redirect,
      };
    case LOGOUT_SUCCESS:
      return {
        ...state,
        fetching: false,
        errors: null,
        refresh: null,
        token: null,
        redirect: action.redirect,
      };
    case LOGOUT_FAILURE:
      return {
        ...state,
        fetching: false,
        errors: null,
        refresh: null,
        token: null,
      };
    case LOGOUT_IN_PLACE_REQUEST:
      return {
        ...state,
        fetching: true,
        errors: null,
        refresh: null,
        token: null,
        exp: null,
        user: null,
      };
    case LOGOUT_IN_PLACE_SUCCESS:
      return {
        ...state,
        fetching: true,
        errors: null,
        refresh: null,
        token: action?.temptoken,
        exp: null,
        user: action?.temptoken ? jwtDecode(action.temptoken)?.user : null,
      };
    case LOGOUT_IN_PLACE_FAILURE:
      return {
        ...state,
        fetching: false,
        errors: null,
        refresh: null,
        token: null,
      };

    case REDIRECT_RESET:
      return {
        ...state,
        redirect: null,
      };
    case REDIRECT_REFER:
      return {
        ...state,
        redirect: action.redirect,
      };
    case AUTH_ME_REQUEST:
      return {
        ...state,
        fetching: true,
      };
    case AUTH_ME_SUCCESS:
      return {
        ...state,
        fetching: false,
        user: action.user,
      };
    case AUTH_ME_FAILURE:
      return {
        ...state,
        fetching: false,
        errors: action.error,
      };
    case SETUSER:
      return {
        ...state,
        token: action.token,
        user: action.user,
      };
    case NEWRELIC_FAILURE:
      let adjustedCount = state.newRelicFailureCount + 1;
      if (new Date() - state.lastNewRelicFailure > 1000 * 60 * 60 * 24) {
        adjustedCount = 1;
      }
      return {
        ...state,
        newRelicFailureCount: adjustedCount,
        lastNewRelicFailure: new Date(),
      };
    case CLEAR_FETCHING:
      return {
        ...state,
        fetching: false,
      };
    default:
      return state;
  }
};

const loginReducer = persistReducer(persistConfig, reducer);

export default loginReducer;
