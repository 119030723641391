// Imports
import axios from 'axios';

// Config
const apiUri = window.API_URL;

// Export actions
export const getCourseItem = ({ token, courseItemId, enrollmentId = null }) => {
  return axios({
    method: 'get',
    url: `${apiUri}/courseitems/${courseItemId}?${enrollmentId ? `enrollment_id=${enrollmentId}` : ''}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};
