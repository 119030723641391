// Imports
import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

// Operations
import { doSetErrors, doLogin, doAuthReset } from './operations';

// Presentation Components
import Login from '../../components/Login';

// Main Component
/**
 * Main Container
 * @param {*} props any
 * @returns {JSX.Element} Main JSX Routes
 */
const LoginContainer = props => {
  // Props
  const { doLogin: login, doAuthReset: resetAuth, authySetup, authy, fetching, errors, history } = props;

  /**
   * Main function that handle form submit
   *
   * @param {event} event browser event
   * @param {form} form antd form object
   *
   * @returns {dispatch} Dispatches login function
   */
  const handleSubmit = ({ email, password }) => {
    login({ email, password });
  };

  // /**
  //  * Redirect after authy checkpoint success
  //  */
  useEffect(() => {
    if (!fetching && authy != null && errors === null && authySetup === false) {
      history.push(`/two-factor`);
    }
  }, [authy, authySetup, fetching, errors]);

  // /**
  //  * Redirect after authy setup requirement
  //  */
  useEffect(() => {
    if (!fetching && authySetup === true && errors === null && authy != null) {
      history.push(`/two-factor-setup`);
    }
  }, [authy, authySetup, fetching, errors]);

  /**
   * This will load the function once
   * equivalent to 'componentDidMount'
   */
  useEffect(() => {
    resetAuth();
  }, [resetAuth]);

  // Render
  return <Login loading={props.fetching} handleSubmit={handleSubmit} errors={props.errors || []} />;
};

// Mapping State / Dispatch To Props
/**
 * Main redux state
 * @param {*} state object
 * @returns {Object} state objects
 */
const mapStateToProps = state => ({
  fetching: state.loginReducer.fetching,
  errors: state.loginReducer.errors,
  token: state.loginReducer.token,
  authy: state.loginReducer.authy,
  authySetup: state.loginReducer.authySetup,
  refresh: state.loginReducer.refresh,
});

/**
 * Main redux dispatch functions
 * @param {*} dispatch redux dispatch
 * @returns {bindActionCreators} state objects
 */
const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      doSetErrors,
      doLogin,
      doAuthReset,
    },
    dispatch,
  );

// Property Types
LoginContainer.propTypes = {
  doSetErrors: PropTypes.func,
  doLogin: PropTypes.func,
  doAuthReset: PropTypes.func,
  fetching: PropTypes.bool,
  authySetup: PropTypes.bool,
  history: PropTypes.object,
  authy: PropTypes.object,
  token: PropTypes.string,
  errors: PropTypes.array,
};

// Export
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(LoginContainer));
