// Imports
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Popconfirm, Row, Col, Spin, Icon, Button, Card, PageHeader, Divider, Menu, Dropdown, Alert } from 'antd';
import { MenuOutlined, QuestionCircleOutlined } from '@ant-design/icons';

// Import Containers
import QuestionContainer from '../../containers/Question';

// Styles
import QuizAttemptStyles from './styles';
import { setIn } from 'immutable';

// Main Component
/**
 * Main Container
 * @param {*} props any
 * @returns {JSX.Element} Main JSX Routes
 */
const QuizAttempt = props => {
  // State / Props
  const { data, history, match, attemptAnswerId, remainingTime, loading, submitting, handleSubmit, handleDone, handleBack, handleSetCurrentAnswer, errors } = props;
  const { enrollmentId, courseId } = match.params;
  const [index, setIndex] = useState(0);
  const [questions, setQuestions] = useState([]);
  const [attempt, setAttempt] = useState({});
  const [answer, setAnswer] = useState(null);
  const [skippedQuestions, setSkippedQuestions] = useState([]);
  const [secondSkippedQuestions, setSecondSkippedQuestions] = useState([]);
  const [answeredQuestions, setAnsweredQuestions] = useState([]);
  const [finished, setFinished] = useState(false);
  const [nextInit, setNextInit] = useState(false);

  // Functions
  /**
   * Prev button controls
   * @param {*} event Click event listener
   * @returns {void}
   */
  const onClickPrevious = event => {
    event.preventDefault();
    if (index === 0) {
      handleBack();
    } else {
      setQuestionIndex(index - 1);
    }
  };

  const setQuestionIndex = index => {
    setIndex(index);
    let currentAnswer = data && data?.quiz_attempt_answers && data?.quiz_attempt_answers[index] ? data?.quiz_attempt_answers[index] : [];
    handleSetCurrentAnswer(currentAnswer);
  };

  /**
   * Next button controls
   * @param {*} event Click event listener
   * @return {void}
   */
  const onClickNext = event => {
    setNextInit(true);
    if (index === questions.length - 1) {
      // If next button clicked on last question without an answer submitted
      if (data?.quiz_attempt_answers?.find(i => i.order_index === index + 1 && i.question_option_id === null)) {
        setSkippedQuestions(oldArray => [...oldArray, index % questions.length]);
      }
      setFinished(true);
      if (skippedQuestions.length) {
        setQuestionIndex(skippedQuestions[0]);
      } else if (handleDone || secondSkippedQuestions.length) {
        handleDone();
      }
    } else {
      if (!finished && !data?.quiz_attempt_answers?.find(i => i.order_index === index + 1)?.length) {
        setQuestionIndex(index + 1);
      } else {
        if (finished && skippedQuestions.length && secondSkippedQuestions.filter(i => i === index).length === 0) {
          setQuestionIndex(skippedQuestions[0]);
        } else {
          if (
            (finished && data && data?.quiz_attempt_answers?.filter(i => i.question_option_id != null)?.length === data?.lms_quiz?.quiz?.total_questions) ||
            (finished && skippedQuestions.length === 0) ||
            data?.quiz_attempt_answers?.filter(i => i.question_option_id != null)?.length + secondSkippedQuestions?.length === data?.lms_quiz?.quiz?.total_questions
          ) {
            handleDone();
          } else {
            if (data?.quiz_attempt_answers?.find(i => i.question_option_id === null && i.order_index >= index) == null) {
              setQuestionIndex(index + 1);
            } else {
              if (secondSkippedQuestions.length) {
                const lastSkippedIndex = secondSkippedQuestions[secondSkippedQuestions.length - 1];
                const tmpIndex = parseInt(data?.quiz_attempt_answers?.find(i => i.question_option_id === null && i.order_index >= index && i.order_index > lastSkippedIndex + 1)?.order_index - 1, 10);
                if (isNaN(tmpIndex)) {
                  setQuestionIndex(index + 1);
                } else {
                  setQuestionIndex(tmpIndex);
                }
              } else {
                const tmpIndex = parseInt(data?.quiz_attempt_answers?.find(i => i.question_option_id === null && i.order_index >= index)?.order_index - 1, 10);
                setQuestionIndex(tmpIndex);
              }
            }
          }
        }
      }
    }
    event.preventDefault();
  };

  /**
   * Handles submitting individual answer
   * @param {*} data Object
   * @return {void}
   */
  const handleSubmitAnswer = data => {
    setAnswer(data);
    if (handleSubmit) {
      if (skippedQuestions.filter(item => item === index).length) {
        setSkippedQuestions(skippedQuestions.filter(item => item !== index));
      }
      if (secondSkippedQuestions.filter(item => item === index).length) {
        setSecondSkippedQuestions(secondSkippedQuestions.filter(item => item !== index));
      }
      setAnsweredQuestions(oldArray => [...oldArray, index % questions.length]);
      handleSubmit(data);
    }
  };

  /**
   * Handles menu click to switch question being viewed
   * @param {Number} goToIndex index for current question
   * @returns {void}
   */
  const onClickMenuQuestion = goToIndex => () => {
    setQuestionIndex(goToIndex);
  };

  /**
   * Handles skipping question
   * @returns {void}
   */
  const onClickSkip = () => {
    setSkippedQuestions(oldArray => [...oldArray, index % questions.length]);
    if (index === questions.length - 1) {
      setFinished(true);
      if (skippedQuestions.length) {
        setQuestionIndex(skippedQuestions[0]);
      } else if (handleDone || secondSkippedQuestions.length) {
        handleDone();
      }
    } else {
      setQuestionIndex((index + 1) % questions.length);
    }
  };

  /**
   * Handles second skipping question
   * @returns {void}
   */
  const onClickSecondSkip = () => {
    if (skippedQuestions.filter(item => item === index).length) {
      setSkippedQuestions(skippedQuestions.filter(item => item !== index));
    }
    setSecondSkippedQuestions(oldArray => [...oldArray, index % questions.length]);
    if (index === questions.length - 1) {
      setFinished(true);
      if (handleDone) {
        handleDone();
      }
    } else if (skippedQuestions.filter(item => item !== index)?.length) {
      setQuestionIndex(skippedQuestions.filter(item => item !== index)[0]);
    }
  };

  // Hooks
  /**
   *
   */
  useEffect(() => {
    const ans = data?.quiz_attempt_answers?.find(i => i.question_id === data?.quiz_attempt_answers[index]?.question_id) ?? {};
    setAttempt(ans);
    setAnswer(ans?.question_option_id || null);
    setQuestions(data?.quiz_attempt_answers ?? []);
  }, [data, index]);

  useEffect(() => {
    if (data?.quiz_attempt_answers && attemptAnswerId) {
      let requestedIndex = data.quiz_attempt_answers.findIndex(answer => answer.id === parseInt(attemptAnswerId));
      setQuestionIndex(requestedIndex);
    }
  }, [data, attemptAnswerId]);

  /**
   * If refreshed and all questions have been answered it will handle it like the quiz is done.
   */
  useEffect(() => {
    //If a specific answer is request, don't redirect to latest.
    if (attemptAnswerId) {
      return;
    }
    if (
      (data && data?.quiz_attempt_answers?.filter(i => i.question_option_id != null)?.length === data?.lms_quiz?.quiz?.total_questions) ||
      data?.quiz_attempt_answers?.filter(i => i.question_option_id != null)?.length + secondSkippedQuestions?.length === data?.lms_quiz?.quiz?.total_questions
    ) {
      setFinished(true);
    } else {
      if (data && data?.quiz_attempt_answers?.filter(i => i.question_option_id == null)?.length >= 1 && skippedQuestions.length === 0 && !nextInit) {
        setNextInit(true);
        setQuestionIndex(data?.quiz_attempt_answers?.filter(i => i.question_option_id == null)[0]?.order_index - 1);
      }
    }
  }, [data, skippedQuestions]);

  /**
   * Handles the second round of skips. If there are no more skipped questions but there are
   * secondSkippedQuestions then we've completed our attempt and want to handleDone
   */
  useEffect(() => {
    if (secondSkippedQuestions?.length && skippedQuestions?.length === 0) {
      if (handleDone) {
        handleDone();
      }
    }
  }, [skippedQuestions, secondSkippedQuestions, finished, handleDone]);

  // Render
  const menu = (
    <Menu style={{ maxHeight: '200px', overflowY: 'scroll' }}>
      {questions.map((i, k) => (
        <Menu.Item
          style={{ backgroundColor: index === k ? '#e6f7ff' : i.question_option_id !== null ? '#ffedab' : '', color: index === k ? '#1890ff' : '' }}
          onClick={onClickMenuQuestion(k)}
          key={`menu-question-${k}`}
        >
          Question {k + 1}
        </Menu.Item>
      ))}
    </Menu>
  );

  return (
    <QuizAttemptStyles>
      <PageHeader
        title={'Quiz Attempt'}
        ghost={false}
        onBack={() => {
          handleBack();
        }}
        extra={
          <Button
            type="primary"
            style={{ backgroundColor: '#5e8dbd', borderColor: 'rgb(97 121 146)' }}
            onClick={() => {
              history.push(`/dashboard/enrollments/${enrollmentId}/courses/${courseId}/outline`);
            }}
          >
            <Icon type="book" /> Course Outline
          </Button>
        }
      />
      {errors ? (
        errors?.map(error => {
          // eslint-disable-next-line react/jsx-key
          return <Alert message={error} type="error" />;
        })
      ) : (
        <>
          <Card>
            {loading && (
              <div style={{ position: 'relative' }}>
                <Spin style={{ position: 'absolute', left: 115 }} spinning={true} />
              </div>
            )}
            <>
              {questions && questions.length > 0 && (
                <>
                  <header>
                    <Row>
                      <Col span={11}>
                        <p>{questions && `Question ${index + 1} of ${questions.length}`}</p>
                      </Col>
                      <Col span={12}>
                        {remainingTime ? (
                          <p>
                            <Icon type="clock-circle" />
                            <span style={{ marginLeft: 5 }}>{remainingTime}</span>
                          </p>
                        ) : null}
                      </Col>
                      <Col span={1}>
                        <Dropdown overlay={menu} placement="bottomRight" trigger={['click']}>
                          <Button>
                            <MenuOutlined />
                          </Button>
                        </Dropdown>
                      </Col>
                    </Row>
                  </header>

                  <Divider />
                </>
              )}

              <QuestionContainer
                index={index}
                attempt={attempt}
                loading={submitting || loading}
                submitting={submitting}
                handleSubmit={handleSubmitAnswer}
                question={questions[index] || null}
                answer={data && data?.quiz_attempt_answers && data?.quiz_attempt_answers[index] ? data?.quiz_attempt_answers[index] : []}
                complete={data?.complete || false}
                settings={data?.lms_quiz?.settings || {}}
              />
              <Divider />
              <QuizAttemptStyles.Controls>
                <Button disabled={submitting} onClick={onClickPrevious} type="secondary">
                  Previous
                </Button>
                {skippedQuestions.filter(item => item === index % questions.length).length ? (
                  <Popconfirm
                    disabled={data?.quiz_attempt_answers?.find(i => i.question_option_id !== null && i.order_index - 1 === index % questions.length)}
                    title="If you skip this question again you will get 0 credit on it. Are you sure you want to skip this question?"
                    onConfirm={onClickSecondSkip}
                    okText={`Yes`}
                    cancelText={`No`}
                    icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
                  >
                    <Button>Skip</Button>
                  </Popconfirm>
                ) : (
                  <Button onClick={onClickSkip} disabled={data?.complete || data?.quiz_attempt_answers?.find(i => i.question_option_id !== null && i.order_index - 1 === index % questions.length)}>
                    Skip
                  </Button>
                )}

                <Button
                  disabled={
                    (nextInit && (submitting || (!answer && !data?.complete)) && index !== questions.length - 1) ||
                    (index === data?.lms_quiz?.quiz?.total_questions - 1 &&
                      data?.quiz_attempt_answers?.filter(i => i.question_option_id === null && i.order_index === data?.lms_quiz?.quiz?.total_questions).length === 1 &&
                      secondSkippedQuestions?.filter(i => i === data?.lms_quiz?.quiz?.total_questions - 1)?.length === 0)
                  }
                  onClick={onClickNext}
                  type="primary"
                >
                  {index === questions.length - 1
                    ? 'Finish'
                    : (finished && data && data?.quiz_attempt_answers?.filter(i => i.question_option_id != null)?.length === data?.lms_quiz?.quiz?.total_questions) ||
                      data?.quiz_attempt_answers?.filter(i => i.question_option_id != null)?.length + secondSkippedQuestions?.length === data?.lms_quiz?.quiz?.total_questions
                    ? 'Finish'
                    : 'Next'}
                </Button>
              </QuizAttemptStyles.Controls>
            </>
          </Card>
        </>
      )}
    </QuizAttemptStyles>
  );
};

// Prop Types
QuizAttempt.propTypes = {
  data: PropTypes.object,
  attemptAnswerId: PropTypes.number,
  history: PropTypes.any,
  match: PropTypes.any,
  errors: PropTypes.object,
  remainingTime: PropTypes.string,
  loading: PropTypes.bool,
  params: PropTypes.object,
  history: PropTypes.object,
  submitting: PropTypes.bool,
  handleSubmit: PropTypes.func,
  handleBack: PropTypes.func,
  handleDone: PropTypes.func,
  handleSetCurrentAnswer: PropTypes.func,
};

// Exports
export default QuizAttempt;
