// Import types
import { PASSWORD_RESET_REQUEST } from './types';

// Export operations
export const doPasswordReset = ({ password, password_confirmation, token, validator }) => ({
  type: PASSWORD_RESET_REQUEST,
  password,
  password_confirmation,
  token,
  validator,
});
