// MAIN TYPES
// --------------------------------------------------------
/**
 * @constant
 */
export const ANSWERS_SCRIPTATTEMPTS_RESET = 'ANSWERS_SCRIPTATTEMPTS_RESET';

/**
 * @constant
 */
export const ANSWERS_SCRIPTATTEMPTS_REQUEST = 'ANSWERS_SCRIPTATTEMPTS_REQUEST';

/**
 * @constant
 */
export const ANSWERS_SCRIPTATTEMPTS_SUCCESS = 'ANSWERS_SCRIPTATTEMPTS_SUCCESS';

/**
 * @constant
 */
export const ANSWERS_SCRIPTATTEMPTS_FAILURE = 'ANSWERS_SCRIPTATTEMPTS_FAILURE';
