// IMPORTS
// --------------------------------------------------------
// Redux
import { put, takeLatest, call } from 'redux-saga/effects';

// Type
import { READ_SCRIPTATTEMPTS_FAILURE, READ_SCRIPTATTEMPTS_REQUEST } from './types';

// Actions
import { doReadScriptAttemptsFailure, doReadScriptAttemptsSuccess, doSetCurrentPosition } from './actions';

// Requests
import { readScriptAttempts } from '../requests';
import { CREATE_SCRIPT_ATTEMPTS_FAILURE } from '../../../../containers/Lessons/types';

// MAIN SAGAS
// --------------------------------------------------------
/**
 * Worker for handling readScriptAttempts
 * @return {void}
 */
function* workerReadScriptAttempts({ token, id, enrollmentId, include }) {
  try {
    const response = yield call(readScriptAttempts, { token, id, enrollmentId, include });

    if (!response?.data?.data) {
      throw new Error('workerReadScriptAttempt - Response Error');
    }

    const { data } = response.data;

    yield put(doReadScriptAttemptsSuccess({ data }));
    yield put(doSetCurrentPosition({ data }));
  } catch (error) {
    yield put({ type: READ_SCRIPTATTEMPTS_FAILURE, ...error?.response });
  }
}

/**
 * Watches for Dispatch READ_SCRIPTATTEMPTS_REQUEST
 * @return {void}
 */
export function* watcherReadScriptAttempts() {
  yield takeLatest(READ_SCRIPTATTEMPTS_REQUEST, workerReadScriptAttempts);
}
