// Imports
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Spin, Button, Card, PageHeader, Divider, Alert, Row, Col, Icon } from 'antd';
import { Link } from 'react-router-dom';
import QuizLessonsReview from '../QuizLessonsReview';
import CONSTANTS from '../../utils/Constants';

// Styles
import LmsQuizStyles from './styles';
import DOMPurify from 'dompurify';
// Main Component
/**
 * Main Container
 * @param {*} props any
 * @returns {JSX.Element} Main JSX Routes
 */
const LmsQuiz = props => {
  // State / Props
  const { data, loading, errors, submitting, params, history, handleBegin, handleReset, user } = props;
  const { enrollmentId, courseId, lmsQuizId } = params;

  const [timeRemaining, setTimeRemaining] = useState(0);
  const [endTime, setEndTime] = useState(0);
  const [relatedSections, setRelatedSections] = useState([]);

  useEffect(() => {
    if (data && data.settings && data.settings?.attempt_delay && data?.quiz_attempts && data?.quiz_attempts.length >= 1) {
      let end = new Date(data?.quiz_attempts[data?.quiz_attempts.length - 1].end_time + ' GMT').getTime() + data?.settings?.attempt_delay * 1000;
      setEndTime(end);
      setTimeRemaining(end - Date.now());
    }
  }, [data]);

  useEffect(() => {
    if (timeRemaining > 0) {
      setTimeout(() => setTimeRemaining(endTime - Date.now()), 1000);
    }
  }, [timeRemaining]);

  useEffect(() => {
    setRelatedSections([]);
    if (data && data?.quiz_attempts && data?.quiz_attempts?.length) {
      data.quiz_attempts.forEach(attempt => {
        if (attempt.complete && attempt.related_sections) {
          setRelatedSections(attempt.related_sections);
        }
      });
    }
  }, [data]);

  const toHHMMSS = function(val) {
    val = Math.floor(val / 1000);
    var sec_num = parseInt(val, 10); // don't forget the second param
    var hours = Math.floor(sec_num / 3600);
    var minutes = Math.floor((sec_num - hours * 3600) / 60);
    var seconds = sec_num - hours * 3600 - minutes * 60;

    if (hours < 10) {
      hours = '0' + hours;
    }
    if (minutes < 10) {
      minutes = '0' + minutes;
    }
    if (seconds < 10) {
      seconds = '0' + seconds;
    }
    return hours + ':' + minutes + ':' + seconds;
  };

  useEffect(() => {
    if (data && data.lms_course && data.quiz_id) {
      let courseMember = data?.lms_course?.lms_course_members?.filter(c => c.id === data?.lms_course_member?.id)?.[0];
      let courseItem = courseMember?.courseitems?.filter(c => {
        return c.quiz_id === data?.quiz_id;
      })?.[0];

      if (user?.role?.permissions?.filter(p => p.name === `custom.unlock_progress`).length == 0) {
        if (courseItem) {
          let prevCourseItem = courseMember?.courseitems?.filter(c => {
            return c.order_index === courseItem.order_index - 1;
          })?.[0];
          if (prevCourseItem) {
            let prevStatus = getCourseItemStatus(prevCourseItem);
            if (prevStatus !== CONSTANTS.STATUS.COMPLETE) {
              // User shouldn't be here, redirect back to the outline
              history.push(`/dashboard/enrollments/${enrollmentId}/courses/${courseId}/outline`);
            }
          }
        }
      }
    }
  }, [data]);

  /*
   * Determine status of course item
   */
  const getCourseItemStatus = courseitem => {
    let status;
    const unlock_override = user?.role?.permissions?.filter(p => p.name === `custom.unlock_progress`).length >= 1;
    if (courseitem) {
      if (courseitem.script_id) {
        const lms_script = courseitem?.script?.lms_scripts?.[0];
        const attempts = lms_script?.script_attempts;
        const last_attempt = attempts?.[attempts.length - 1];
        const started = attempts?.length >= 1;
        const complete = attempts?.filter(attempt => attempt.complete === 1).length >= 1;
        const last_complete = last_attempt?.complete === 1;
        const required_grade_achieved = attempts?.filter(q => q.score >= lms_script?.settings?.req_grade).length >= 1;
        const scriptConditions = {
          exists: unlock_override || !!attempts,
          empty: attempts?.length === 0 && !unlock_override,
          started: unlock_override || started,
          complete: complete && required_grade_achieved,
          failed: last_complete && !required_grade_achieved,
        };
        if (!scriptConditions.exists || scriptConditions.empty) {
          status = CONSTANTS.STATUS.NOT_STARTED;
        } else if (scriptConditions.complete) {
          status = CONSTANTS.STATUS.COMPLETE;
        } else if (scriptConditions.failed) {
          status = CONSTANTS.STATUS.RESTART;
        } else if (scriptConditions.started) {
          status = CONSTANTS.STATUS.STARTED;
        }
      } else if (courseitem.quiz_id) {
        // Is quiz

        const lms_quiz = courseitem?.quiz?.lms_quizzes?.[0];
        const attempts = lms_quiz?.quiz_attempts;
        const last_attempt = attempts?.[attempts.length - 1];
        const started = attempts?.length >= 1;
        const complete = attempts?.filter(attempt => attempt.complete === 1).length >= 1;
        const last_complete = last_attempt?.complete === 1;
        const required_grade_achieved = attempts?.filter(q => q.score >= lms_quiz?.settings?.req_grade).length >= 1;

        const quizConditions = {
          exists: unlock_override || !!attempts,
          empty: attempts?.length === 0 && !unlock_override,
          started: unlock_override || started,
          complete: complete && required_grade_achieved,
          failed: last_complete && !required_grade_achieved,
        };

        if (!quizConditions.exists || quizConditions.empty) {
          status = CONSTANTS.STATUS.NOT_STARTED;
        } else if (quizConditions.complete) {
          status = CONSTANTS.STATUS.COMPLETE;
        } else if (quizConditions.failed) {
          status = CONSTANTS.STATUS.RESTART;
        } else if (quizConditions.started) {
          status = CONSTANTS.STATUS.STARTED;
        }
      }
    }

    return status;
  };

  // Functions
  /**
   * When the begin button is clicked
   * @param {Event} event Event click listener
   * @returns {void}
   */
  const onClickBegin = event => {
    if (handleBegin) {
      handleBegin();
    }
    event.preventDefault();
  };
  // Render
  return (
    <LmsQuizStyles>
      <PageHeader
        title={'LMS Quiz'}
        subTitle={data?.name ?? ''}
        ghost={false}
        onBack={() => {
          history.push(`/dashboard/enrollments/${enrollmentId}/courses/${courseId}/outline`);
        }}
        extra={
          <Button
            type="primary"
            style={{ backgroundColor: '#5e8dbd', borderColor: 'rgb(97 121 146)' }}
            onClick={() => {
              history.push(`/dashboard/enrollments/${enrollmentId}/courses/${courseId}/outline`);
            }}
          >
            <Icon type="book" /> Course Outline
          </Button>
        }
      />
      <Card>
        {loading && <Spin spinning={true} />}
        {!loading && errors && <Alert message={errors} type="error" />}
        {!loading && !errors && (
          <>
            <Row>
              <Col span={24}>
                <h1>
                  {data?.name ?? ''}{' '}
                  <div style={{ float: 'right' }}>
                    <p>The required grade to pass is {data?.settings?.req_grade ?? 0}%</p>
                  </div>
                </h1>
                <Divider />
                {data?.settings?.description ? <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(data?.settings?.description, { ADD_ATTR: ['target'] }) }} /> : ''}
                <p>
                  There&nbsp;
                  {data?.quiz?.questions_asked === 1 ? 'is' : 'are'}
                  &nbsp;
                  {data?.quiz?.questions_asked ?? 0} Question{data?.quiz?.questions_asked !== 1 && 's'} in this quiz.
                </p>
                {data?.settings?.timer ? (
                  <p>
                    You will have
                    {data?.settings?.timer > 1 ? ' ' + data?.settings?.timer + ' minutes ' : ' 1 minute '}
                    to complete this quiz.
                  </p>
                ) : null}

                <p>
                  You have&nbsp;
                  {!data?.settings?.attempts ? 'unlimited' : data?.settings?.attempts && (data?.settings?.attempts ?? 0) - (data?.quiz_attempts?.length ?? 0)} attempt(s) left to pass this quiz.
                </p>
                {(data?.quiz_attempts?.length ?? 0) !== (data?.settings?.attempts ?? 0) && <p>This is attempt #{data?.quiz_attempts?.length > 0 ? data?.quiz_attempts?.length + 1 : 1}</p>}
              </Col>
              <Col span={12}>
                <QuizLessonsReview sections={relatedSections} enrollmentId={enrollmentId} courseId={courseId} loading={loading} />
              </Col>
            </Row>
            <Divider />
            {data?.quiz_attempts?.length > 0 && data?.quiz_attempts.filter(i => i.complete === 1)?.length > 0 && (
              <>
                <h4>Review Past Attempt(s)</h4>
                {data?.quiz_attempts
                  .filter(i => i.complete === 1)
                  .map((i, k) => (
                    <p key={`quiz-attempts-existing-${k}`}>
                      <Link to={`/dashboard/enrollments/${enrollmentId}/courses/${courseId}/lmsquizzes/${lmsQuizId}/attempts/${i.id}/results`}>
                        <Button type="secondary">
                          {i?.start_time ?? ''} - {i?.score ?? 0}%&nbsp;►
                        </Button>
                      </Link>
                    </p>
                  ))}
                <Divider />
              </>
            )}
            {data?.quiz_attempts?.length > 0 && data?.quiz_attempts.filter(i => i.complete === 0)?.length > 0 && (
              <>
                <h4>Continue Existing Attempt(s)</h4>
                {data?.quiz_attempts
                  .filter(i => i.complete === 0)
                  .map((i, k) => (
                    <p key={`quiz-attempts-existing-${k}`}>
                      <Link to={`/dashboard/enrollments/${enrollmentId}/courses/${courseId}/lmsquizzes/${lmsQuizId}/attempts/${i.id}`}>
                        <Button type="secondary">{i?.start_time ?? ''} ►</Button>
                      </Link>
                    </p>
                  ))}
                <Divider />
              </>
            )}
            {data?.settings?.attempt_delay > 0 && data?.quiz_attempts && data?.quiz_attempts.length >= 1 && data?.quiz_attempts?.length < data?.settings.attempts && timeRemaining > 0 ? (
              <Button disabled={timeRemaining > 0} type="primary">
                Next Quiz Attempt Available In {toHHMMSS(timeRemaining)}
              </Button>
            ) : data?.settings.attempts && (data?.quiz_attempts.length ?? 0) >= (data?.settings.attempts ?? 0) && data?.settings?.reset_course ? (
              <Button onClick={handleReset} type="primary">
                Reset Course
              </Button>
            ) : (
              <Button disabled={submitting || (data?.settings.attempts && (data?.quiz_attempts.length ?? 0) >= (data?.settings.attempts ?? 0))} onClick={onClickBegin} type="primary">
                {data?.settings.attempts && (data?.quiz_attempts.length ?? 0) >= (data?.settings.attempts ?? 0) ? 'Max Attempts Reached' : 'Begin'}
              </Button>
            )}
          </>
        )}
      </Card>
    </LmsQuizStyles>
  );
};

// Prop Types
LmsQuiz.propTypes = {
  data: PropTypes.object,
  loading: PropTypes.bool,
  submitting: PropTypes.bool,
  params: PropTypes.object,
  history: PropTypes.object,
  handleBegin: PropTypes.func,
  handleReset: PropTypes.func,
  errors: PropTypes.any,
  lmsCoursemember: PropTypes.any,
  user: PropTypes.object,
};

// Exports
export default LmsQuiz;
