// Types
import { GET_COURSE_OUTLINE_REQUEST, CLEAR_COURSE_OUTLINE, GET_COURSE_REVIEW_REQUEST } from './types';

// Main Operations

/**
 * GetCourseOutline Operation
 * @param {*} Object { token, id }
 * @returns {Dispatch} for redux
 */
export const doGetCourseOutline = ({ token, courseId, enrollmentId }) => ({
  type: GET_COURSE_OUTLINE_REQUEST,
  token,
  courseId,
  enrollmentId,
});

/**
 * GetCourseReview Operation
 * @param {*} Object { token, id }
 * @returns {Dispatch} for redux
 */
export const doGetCourseReview = ({ token, courseId, enrollmentId }) => ({
  type: GET_COURSE_REVIEW_REQUEST,
  token,
  courseId,
  enrollmentId,
});

/**
 * ClearStatus Operation
 * @returns {Dispatch} for redux
 */
export const doClearOutline = () => ({
  type: CLEAR_COURSE_OUTLINE,
});
