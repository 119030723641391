// MAIN TYPES
// --------------------------------------------------------
/**
 * @constant
 */
export const QUESTION_SCRIPTATTEMPTS_RESET = 'QUESTION_SCRIPTATTEMPTS_RESET';

/**
 * @constant
 */
export const QUESTION_SCRIPTATTEMPTS_REQUEST = 'QUESTION_SCRIPTATTEMPTS_REQUEST';

/**
 * @constant
 */
export const QUESTION_SCRIPTATTEMPTS_SUCCESS = 'QUESTION_SCRIPTATTEMPTS_SUCCESS';

/**
 * @constant
 */
export const QUESTION_SCRIPTATTEMPTS_FAILURE = 'QUESTION_SCRIPTATTEMPTS_FAILURE';
