// Types
import { GET_LMSQUIZ_REQUEST, RESET_LMSCOURSEMEMBER_REQUEST } from './types';

// Main Operations

/**
 * Get Lms Quiz Id
 * @param {*} Object { token, lmsQuizId, enrollmentId }
 * @returns {Dispatch} for redux
 */
export const doGetLmsQuiz = ({ token, lmsQuizId, enrollmentId = null }) => ({
  type: GET_LMSQUIZ_REQUEST,
  token,
  lmsQuizId,
  enrollmentId,
});

/**
 * Reset course member
 * @param {*} Object { token, enrollmentId, lmsCourseMemberId }
 * @returns {Dispatch} for redux
 */
export const doResetLmsCourse = ({ token, enrollmentId, lmsCourseMemberId }) => ({
  type: RESET_LMSCOURSEMEMBER_REQUEST,
  token,
  enrollmentId,
  lmsCourseMemberId,
});
