// MAIN TYPES
// --------------------------------------------------------
/**
 * @constant
 */
export const LIST_SCRIPTATTEMPTS_RESET = 'LIST_SCRIPTATTEMPTS_RESET';

/**
 * @constant
 */
export const LIST_SCRIPTATTEMPTS_REQUEST = 'LIST_SCRIPTATTEMPTS_REQUEST';

/**
 * @constant
 */
export const LIST_SCRIPTATTEMPTS_SUCCESS = 'LIST_SCRIPTATTEMPTS_SUCCESS';

/**
 * @constant
 */
export const LIST_SCRIPTATTEMPTS_FAILURE = 'LIST_SCRIPTATTEMPTS_FAILURE';
