// Export types
export const GET_SCRIPT_ATTEMPTS_REQUEST = 'GET_SCRIPT_ATTEMPTS_REQUEST';
export const GET_SCRIPT_ATTEMPTS_SUCCESS = 'GET_SCRIPT_ATTEMPTS_SUCCESS';
export const GET_SCRIPT_ATTEMPTS_FAILURE = 'GET_SCRIPT_ATTEMPTS_FAILURE';

export const GET_LESSON_TRACKER_REQUEST = 'GET_LESSON_TRACKER_REQUEST';
export const GET_LESSON_TRACKER_SUCCESS = 'GET_LESSON_TRACKER_SUCCESS';
export const GET_LESSON_TRACKER_FAILURE = 'GET_LESSON_TRACKER_FAILURE';

export const CREATE_SCRIPT_ATTEMPTS_REQUEST = 'CREATE_SCRIPT_ATTEMPTS_REQUEST';
export const CREATE_SCRIPT_ATTEMPTS_SUCCESS = 'CREATE_SCRIPT_ATTEMPTS_SUCCESS';
export const CREATE_SCRIPT_ATTEMPTS_FAILURE = 'CREATE_SCRIPT_ATTEMPTS_FAILURE';

export const UPDATE_SCRIPT_ATTEMPTS_REQUEST = 'UPDATE_SCRIPT_ATTEMPTS_REQUEST';
export const UPDATE_SCRIPT_ATTEMPTS_SUCCESS = 'UPDATE_SCRIPT_ATTEMPTS_SUCCESS';
export const UPDATE_SCRIPT_ATTEMPTS_FAILURE = 'UPDATE_SCRIPT_ATTEMPTS_FAILURE';

export const CREATE_SCRIPT_ATTEMPT_WATCHED_REQUEST = 'CREATE_SCRIPT_ATTEMPT_WATCHED_REQUEST';
export const CREATE_SCRIPT_ATTEMPT_WATCHED_SUCCESS = 'CREATE_SCRIPT_ATTEMPT_WATCHED_SUCCESS';
export const CREATE_SCRIPT_ATTEMPT_WATCHED_FAILURE = 'CREATE_SCRIPT_ATTEMPT_WATCHED_FAILURE';
