// Export types
export const LOGIN_ERRORS_SET = 'LOGIN_ERRORS_SET';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGOUT_FAILURE = 'LOGOUT_FAILURE';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const LOGOUT_REQUEST = 'LOGOUT_REQUEST';
export const LOGOUT_IN_PLACE_REQUEST = 'LOGOUT_IN_PLACE_REQUEST';
export const LOGOUT_IN_PLACE_SUCCESS = 'LOGOUT_IN_PLACE_SUCCESS';
export const LOGOUT_IN_PLACE_FAILURE = 'LOGOUT_IN_PLACE_FAILURE';
export const AUTH_RESET = 'AUTH_RESET';
export const REFRESH_FAILURE = 'REFRESH_FAILURE';
export const REFRESH_SUCCESS = 'REFRESH_SUCCESS';
export const REFRESH_REQUEST = 'REFRESH_REQUEST';
export const REDIRECT_RESET = 'REDIRECT_RESET';
export const REDIRECT_REFER = 'REDIRECT_REFER';
export const AUTH_ME_REQUEST = 'AUTH_ME_REQUEST';
export const AUTH_ME_SUCCESS = 'AUTH_ME_SUCCESS';
export const AUTH_ME_FAILURE = 'AUTH_ME_FAILURE';
export const LEAVEIMPERSONATION_FAILURE = 'LEAVEIMPERSONATION_FAILURE';
export const LEAVEIMPERSONATION_SUCCESS = 'LEAVEIMPERSONATION_SUCCESS';
export const LEAVEIMPERSONATION_REQUEST = 'LEAVEIMPERSONATION_REQUEST';
export const SETUSER = 'SETUSER';
export const NEWRELIC_FAILURE = 'NEWRELIC_FAILURE';
export const AUTHY_CHECKPOINT = 'AUTHY_CHECKPOINT';
export const AUTHY_VERIFY_REQUEST = 'AUTHY_VERIFY_REQUEST';
export const AUTHY_VERIFY_SUCCESS = 'AUTHY_VERIFY_SUCCESS';
export const AUTHY_VERIFY_FAILURE = 'AUTHY_VERIFY_FAILURE';
export const AUTHY_SETUP_REQUEST = 'AUTHY_SETUP_REQUEST';
export const AUTHY_SETUP_FAILURE = 'AUTHY_SETUP_FAILURE';
export const AUTHY_SETUP_SUCCESS = 'AUTHY_SETUP_SUCCESS';
export const AUTHY_REQUEST_REQUEST = 'AUTHY_REQUEST_REQUEST';
export const AUTHY_REQUEST_SUCCESS = 'AUTHY_REQUEST_SUCCESS';
export const AUTHY_REQUEST_FAILURE = 'AUTHY_REQUEST_FAILURE';
export const AUTHY_RESET = 'AUTHY_RESET';

export const CREATE_GUEST_ENROLLMENT_REQUEST = 'CREATE_GUEST_ENROLLMENT_REQUEST';
export const CREATE_GUEST_ENROLLMENT_FAILURE = 'CREATE_GUEST_ENROLLMENT_FAILURE';
export const CREATE_GUEST_ENROLLMENT_SUCCESS = 'CREATE_GUEST_ENROLLMENT_SUCCESS';
export const CLEAR_GUEST_ENROLLMENT = 'CLEAR_GUEST_ENROLLMENT';
export const CLEAR_FETCHING = 'CLEAR_FETCHING';
