// Imports
import { takeLatest, put, call } from 'redux-saga/effects';

//Types
import {
  UPDATE_STUDENT_CONTACT_REQUEST,
  UPDATE_STUDENT_CONTACT_SUCCESS,
  UPDATE_STUDENT_CONTACT_FAILURE,
  GET_STUDENT_CONTACT_REQUEST,
  GET_STUDENT_CONTACT_SUCCESS,
  GET_STUDENT_CONTACT_FAILURE,
  CREATE_STUDENT_REQUEST,
  CREATE_STUDENT_SUCCESS,
  CREATE_STUDENT_FAILURE,
} from './types';

//Actions
import { getStudentContact, createStudent, updateStudentContact } from './actions';

/**
 * Worker function
 * @param {*} Object { token, id }
 * @returns {void}
 */
export function* workerGetStudentContact({ token, id }) {
  try {
    // Request
    const response = yield call(getStudentContact, { token, id });

    // Catch For No Response
    if (!response || !response.data || !response.data.success || !response.data.data) {
      throw new Error('workerGetStudentContact - No Data Returned');
    }

    // Dispatch
    yield put({
      type: GET_STUDENT_CONTACT_SUCCESS,
      data: response.data.data,
    });
  } catch (error) {
    const errors = error.response?.data || error.message;
    yield put({ type: GET_STUDENT_CONTACT_FAILURE, ...{ errors } });
  }
}

/**
 * Watcher function
 * @returns {void}
 */
export function* watcherGetStudentContact() {
  yield takeLatest(GET_STUDENT_CONTACT_REQUEST, workerGetStudentContact);
}

/**
 * Worker function
 * @param {*} Object { token, user_id }
 * @returns {void}
 */
export function* workerCreateStudent({ token, user_id }) {
  try {
    // Request
    const response = yield call(createStudent, { token, user_id });

    // Catch For No Response
    if (!response || !response.data || !response.data.success || !response.data.data) {
      throw new Error('workerCreateStudent - No Data Returned');
    }

    // Dispatch
    yield put({
      type: CREATE_STUDENT_SUCCESS,
      data: response.data.data,
    });
  } catch (error) {
    const errors = error.response?.data || error.message;
    yield put({ type: CREATE_STUDENT_FAILURE, ...{ errors } });
  }
}

/**
 * Watcher function
 * @returns {void}
 */
export function* watcherCreateStudent() {
  yield takeLatest(CREATE_STUDENT_REQUEST, workerCreateStudent);
}

/**
 * Worker function
 * @param {*} Object { token, id, firstname, lastname, email, phone, address, city, state, zip, password }
 * @returns {void}
 */
export function* workerUpdateStudentContact({ token, id, firstname, lastname, email, phone, address, city, state, zip, password }) {
  try {
    // Request
    const response = yield call(updateStudentContact, {
      token,
      id,
      firstname,
      lastname,
      email,
      phone,
      address,
      city,
      state,
      zip,
      password,
    });
    // Catch For No Response
    if (!response || !response.data || !response.data.success || !response.data.data) {
      throw new Error('workerUpdateStudentContact - No Data Returned');
    }

    // Dispatch
    yield put({
      type: UPDATE_STUDENT_CONTACT_SUCCESS,
      data: response.data.data,
    });
  } catch (error) {
    const errors = error.response?.data || error.message;
    yield put({ type: UPDATE_STUDENT_CONTACT_FAILURE, ...{ errors } });
  }
}

/**
 * Watcher function
 * @returns {void}
 */
export function* watcherUpdateStudentContact() {
  yield takeLatest(UPDATE_STUDENT_CONTACT_REQUEST, workerUpdateStudentContact);
}
