// IMPORTS
import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

// REDUCERS
import loginReducer from '../containers/Login/reducers';
import forgotPasswordReducer from '../containers/ForgotPassword/reducers';
import passwordResetReducer from '../containers/PasswordReset/reducers';
import accountReducer from '../containers/Account/reducers';
import feedbackReducer from '../containers/Feedback/reducers';
import referralReducer from '../containers/Referral/reducers';
import courseOutlineReducer from '../containers/CourseOutline/reducers';
import courseItemsReducer from '../containers/CourseItems/reducers';
import courseItemReducer from '../containers/CourseItem/reducers';
import quizReducer from '../containers/Quiz/reducers';
import quizQuestionsReducer from '../containers/QuizQuestions/reducers';
import lmsQuizReducer from '../containers/LmsQuiz/reducers';
//
import lmsCourseReducer from '../containers/LmsCourse/reducers';
import quizAttemptReducer from '../containers/QuizAttempt/reducers';
import questionReducer from '../containers/Question/reducers';
import quizAttemptAnswerReducer from '../containers/QuizAttemptAnswer/reducers';
import lessonsReducer from '../containers/Lessons/reducers';
import reportingReducer from '../containers/Reporting/reducers';
import questionsReducer from '../containers/Questions/reducers';
import courseCompleteReducer from '../containers/CourseComplete/reducers';
import reviewReducer from '../containers/Review/reducers';
import sectionReducer from '../containers/Sections/reducers';
import paymentReducer from '../containers/Purchase/reducers';
import guestAccessReducer from '../containers/GuestAccess/reducers';
//
import ListScriptAttemptsReducer from './modules/ScriptAttempts/list/reducers';
import CreateScriptAttemptsReducer from './modules/ScriptAttempts/create/reducers';
import ReadScriptAttemptsReducer from './modules/ScriptAttempts/read/reducers';
import UpdateScriptAttemptsReducer from './modules/ScriptAttempts/update/reducers';
import WatchedVideosScriptAttemptsReducer from './modules/ScriptAttempts/watchedvideos/reducers';
import AnswersScriptAttemptsReducer from './modules/ScriptAttempts/answers/reducers';
import QuestionScriptAttemptsReducer from './modules/ScriptAttempts/question/reducers';
import TrackingScriptAttemptsReducer from './modules/ScriptAttempts/tracking/reducers';

import ReadLmsScriptsReducer from './modules/LmsScripts/read/reducers';
import LessonTrackerLmsScriptsReducer from './modules/LmsScripts/lessontracker/reducers';
import ScriptAttemptsLmsScriptsReducer from './modules/LmsScripts/scriptattempts/reducers';
import ReadQuestionsReducer from './modules/Questions/read/reducers';

// MAIN ROOT REDUCER
/**
 * Main root reducer
 * @param {Object} history redux history
 * @returns {combineReducers} all reducers in one main root reducer
 */
const rootReducer = history =>
  combineReducers({
    router: connectRouter(history),
    loginReducer,
    forgotPasswordReducer,
    passwordResetReducer,
    accountReducer,
    feedbackReducer,
    referralReducer,
    courseOutlineReducer,
    courseItemsReducer,
    courseItemReducer,
    quizReducer,
    quizQuestionsReducer,
    lmsQuizReducer,
    lmsCourseReducer,
    quizAttemptReducer,
    questionReducer,
    quizAttemptAnswerReducer,
    lessonsReducer,
    reportingReducer,
    questionsReducer,
    courseCompleteReducer,
    reviewReducer,
    sectionReducer,
    ListScriptAttemptsReducer,
    CreateScriptAttemptsReducer,
    ReadScriptAttemptsReducer,
    UpdateScriptAttemptsReducer,
    WatchedVideosScriptAttemptsReducer,
    AnswersScriptAttemptsReducer,
    QuestionScriptAttemptsReducer,
    TrackingScriptAttemptsReducer,
    ReadLmsScriptsReducer,
    LessonTrackerLmsScriptsReducer,
    ScriptAttemptsLmsScriptsReducer,
    ReadQuestionsReducer,
    paymentReducer,
    guestAccessReducer,
  });

// EXPORTS
export default rootReducer;
