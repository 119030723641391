// IMPORTS
// --------------------------------------------------------
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { PageHeader, Spin, Card, Icon, Button, Progress, Divider, Dropdown, Menu } from 'antd';
import { MenuOutlined, LockOutlined, CheckCircleFilled } from '@ant-design/icons';
import Colors from '../../styles/Colors';

// Style Components
import { ScriptAttemptStyles } from './styles';

// Container Components

// MAIN COMPONENT
// --------------------------------------------------------
/**
 * Main ScriptAttempt Component
 * @param {Object} props component props
 * @returns {JSX} React compoennt
 */
const ScriptAttempt = props => {
  // PROPS / STATE
  const {
    children,
    title,
    subTitle,
    loading,
    progress,
    slideIndex,
    totalSlides,
    history,
    canProceed,
    handlePrev,
    handleNext,
    buttonNext,
    handleTocNav,
    buttonNextLoading,
    buttonPrev,
    buttonPrevLoading,
    reached,
    complete,
    sectionType,
    position,
    slides,
  } = props;
  const { enrollmentId, courseId, lmsScriptId, scriptAttemptId } = props.match.params;

  // FUNCTIONS
  // Main Controls
  // ----------------------------------------------
  /**
   * When prev button is clicked
   * @returns {void}
   */
  const onClickPrev = () => {
    if (handlePrev) {
      handlePrev();
    }
  };

  /**
   * When next button is clicked
   * @returns {void}
   */
  const onClickNext = () => {
    if (handleNext && canProceed) {
      handleNext();
    }
  };

  /**
   * When Toc Menu Item Is Clicked
   * @param {Object} section Section object
   * @param {Number} position slides index
   * @return {void}
   */
  const onClickToc = (section, position) => {
    if (handleTocNav) {
      handleTocNav(section, position);
    }
  };

  let foundLast = false;
  let questionCount = 0;
  // RENDER

  const menu = (
    <Menu style={{ maxHeight: '200px', overflow: 'scroll' }}>
      {slides?.map((section, sectionkey) => {
        if (reached === undefined || reached === null) {
          foundLast = true;
        }
        let type = section?.sectiontype?.type;
        switch (type) {
          case 'question':
            if (reached?.section_id === section.id) {
              foundLast = true;
            }
            questionCount++;
            break;
          case 'video':
            if (reached?.section_id === section.id) {
              foundLast = true;
            }
            break;
          case 'lesson':
            break;
        }
        let op = 0;
        return section?.sectionslides?.map((slide, slidekey) => {
          const slidesWithContent = section?.sectionslides.filter(section => section.content);
          let active = true;
          if (type === 'lesson') {
            if (slide.word_count >= 1) {
              op++;
            }
          }

          if (foundLast) {
            active = false;
          }
          if (reached?.section_id === slide.section_id) {
            if (reached?.position === 0) {
              foundLast = true;
            } else if (op >= reached?.position) {
              foundLast = true;
            }
          }
          return (
            <Menu.Item
              key={`toc-${sectionkey}-${slidekey}`}
              style={{ paddingLeft: `${slide.level * 10}px`, cursor: active ? 'pointer' : 'not-allowed' }}
              onClick={() => {
                // If they did not reach this section
                if (!active) return;
                let nextSlidePosition = slidesWithContent?.findIndex(section => section.id === slide.id);
                if (nextSlidePosition === -1) {
                  const slicedSlides = section?.sectionslides.slice(slidekey + 1).find(section => section.content !== null);
                  nextSlidePosition = slidesWithContent?.findIndex(section => section.id === slicedSlides.id);
                }
                onClickToc(slidesWithContent[nextSlidePosition], nextSlidePosition);
              }}
            >
              {active ? <CheckCircleFilled style={{ color: Colors.greenDark }} /> : <LockOutlined />}
              {section?.sectiontype?.type === 'question' ? 'Question ' + questionCount : slide.title}
            </Menu.Item>
          );
        });
      })}

      {complete && (
        <Menu.Item
          style={{ borderTop: `1px solid ${Colors.greye8}` }}
          onClick={() => {
            history.push(`/dashboard/enrollments/${enrollmentId}/courses/${courseId}/lmslessons/${lmsScriptId}/attempts/${scriptAttemptId}/results`);
          }}
        >
          Results
        </Menu.Item>
      )}
    </Menu>
  );
  return (
    <ScriptAttemptStyles>
      <PageHeader
        title={title}
        subTitle={subTitle}
        ghost={false}
        extra={
          <Button
            type="primary"
            style={{ backgroundColor: '#5e8dbd', borderColor: 'rgb(97 121 146)' }}
            onClick={() => {
              history.push(`/dashboard/enrollments/${enrollmentId}/courses/${courseId}/outline`);
            }}
          >
            <Icon type="book" /> Course Outline
          </Button>
        }
        onBack={() => {
          history.push(`/dashboard/enrollments/${enrollmentId}/courses/${courseId}/lmslessons/${lmsScriptId}`);
        }}
      />
      <Card>
        {loading || totalSlides === null ? (
          <Spin spinning={true} />
        ) : (
          <>
            {totalSlides !== null && totalSlides > -1 && (
              <>
                <header>
                  <div>
                    <Progress percent={progress || 0} showInfo={false} />
                    <div>
                      {slideIndex}/{totalSlides}
                    </div>
                  </div>
                  <Dropdown overlay={menu} placement="bottomRight" trigger={['click']}>
                    <Button>
                      <MenuOutlined />
                    </Button>
                  </Dropdown>
                </header>

                <Divider />
              </>
            )}
            <main>
              <div style={{ width: '100%' }}>{children}</div>
            </main>
            {totalSlides !== null && totalSlides > -1 && (
              <>
                <Divider />

                <footer>
                  <Button type="primary" onClick={onClickPrev}>
                    {!buttonPrevLoading && <Icon type="left" />} {buttonPrev || 'Previous'}
                  </Button>

                  <Button type="primary" disabled={buttonNextLoading || !canProceed} onClick={onClickNext} loading={buttonNextLoading}>
                    {' '}
                    {(!canProceed && buttonNext) || 'Next'} {!buttonNextLoading && <Icon type="right" />}
                  </Button>
                </footer>
              </>
            )}
          </>
        )}
      </Card>
    </ScriptAttemptStyles>
  );
};

// PROPS TYPES
// --------------------------------------------------------
ScriptAttempt.propTypes = {
  token: PropTypes.string,
  data: PropTypes.any,
  slides: PropTypes.any,
  videoWatched: PropTypes.bool,
  handleVideoWatched: PropTypes.func,
  match: PropTypes.object,
  children: PropTypes.any,
  title: PropTypes.string,
  subTitle: PropTypes.string,
  sectionType: PropTypes.string,
  loading: PropTypes.bool,
  progress: PropTypes.any,
  slideIndex: PropTypes.number,
  totalSlides: PropTypes.number,
  history: PropTypes.any,
  canProceed: PropTypes.bool,
  questionType: PropTypes.bool,
  handlePrev: PropTypes.func,
  handleNext: PropTypes.func,
  buttonPrev: PropTypes.any,
  buttonNext: PropTypes.any,
  buttonNextLoading: PropTypes.bool,
  buttonPrevLoading: PropTypes.bool,
  lessonTracker: PropTypes.any,
  handleVideoUpdatePosition: PropTypes.func,
  handleTocNav: PropTypes.func,
  reached: PropTypes.object,
  complete: PropTypes.bool,
  position: PropTypes.number,
};

// EXPORTS
// --------------------------------------------------------
export default ScriptAttempt;
