// action types
import { PUT_SECTION_REQUEST, PUT_SECTION_SUCCESS, PUT_SECTION_FAILURE, PUT_SECTION_RESET } from './types';

// reducer with initial state
const initialState = {
  fetching: false,
  data: null,
  errors: null,
};

const sectionReducer = (state = initialState, action) => {
  switch (action.type) {
    case PUT_SECTION_REQUEST:
      return {
        ...state,
        fetching: true,
        errors: null,
        data: null,
      };
    case PUT_SECTION_SUCCESS:
      return {
        ...state,
        fetching: false,
        errors: null,
        data: action.response.data.data,
      };
    case PUT_SECTION_FAILURE:
      return {
        ...state,
        fetching: false,
        errors: action.data,
      };
    case PUT_SECTION_RESET:
      return initialState;
    default:
      return state;
  }
};

export default sectionReducer;
