// Imports
import { takeLatest, put, call } from 'redux-saga/effects';

// Types
import {
  CREATE_QUIZ_ATTEMPT_ANSWER_REQUEST,
  CREATE_QUIZ_ATTEMPT_ANSWER_SUCCESS,
  CREATE_QUIZ_ATTEMPT_ANSWER_FAILURE,
  UPDATE_QUIZ_ATTEMPT_ANSWER_REQUEST,
  UPDATE_QUIZ_ATTEMPT_ANSWER_SUCCESS,
  UPDATE_QUIZ_ATTEMPT_ANSWER_FAILURE,
} from './types';

import { GET_QUIZ_ATTEMPT_REQUEST } from '../QuizAttempt/types';

import { MODIFY_QUIZ_ATTEMPT_QUIZ_ATTEMPT_ANSWER } from '../QuizAttempt/types';

// Actions
import { createQuizAttemptAnswer, updateQuizAttemptAnswer } from './actions';

// Main Sagas
/**
 *
 * @param {*} param0 Authentication token
 * @returns {void}
 */
function* workerCreateQuizAttemptAnswer({ token, quizAttemptId, questionId, questionOptionId }) {
  try {
    const response = yield call(createQuizAttemptAnswer, { token, quizAttemptId, questionId, questionOptionId });

    // Catch For No Response
    if (!response?.data?.data) {
      throw new Error('workerCreateQuizAttemptAnswer - Response Error');
    }

    const { data } = response.data;

    yield put({
      type: CREATE_QUIZ_ATTEMPT_ANSWER_SUCCESS,
      data,
    });
  } catch (error) {
    const message = error?.message ?? 'Something went wrong';
    yield put({ type: CREATE_QUIZ_ATTEMPT_ANSWER_FAILURE, errors: message });
  }
}

/**
 * Watches for request
 * @return {void}
 */
export function* watcherCreateQuizAttemptAnswer() {
  yield takeLatest(CREATE_QUIZ_ATTEMPT_ANSWER_REQUEST, workerCreateQuizAttemptAnswer);
}

/**
 *
 * @param {*} param0 Authentication token
 * @returns {void}
 */
function* workerUpdateQuizAttemptAnswer({ token, quizAttemptId, questionId, questionOptionId }) {
  try {
    const response = yield call(updateQuizAttemptAnswer, { token, quizAttemptId, questionId, questionOptionId });

    // Catch For No Response
    if (!response?.data?.data) {
      throw new Error('workerUpdateQuizAttemptAnswer - Response Error');
    }

    const { data } = response.data;

    // Update quiz attempt
    yield put({
      type: MODIFY_QUIZ_ATTEMPT_QUIZ_ATTEMPT_ANSWER,
      data,
    });

    yield put({
      type: UPDATE_QUIZ_ATTEMPT_ANSWER_SUCCESS,
      data,
    });

    yield put({
      type: GET_QUIZ_ATTEMPT_REQUEST,
      token,
      quizAttemptId,
    });
  } catch (error) {
    const message = error?.message ?? 'Something went wrong';
    yield put({ type: UPDATE_QUIZ_ATTEMPT_ANSWER_FAILURE, errors: message });
  }
}

/**
 * Watches for request
 * @return {void}
 */
export function* watcherUpdateQuizAttemptAnswer() {
  yield takeLatest(UPDATE_QUIZ_ATTEMPT_ANSWER_REQUEST, workerUpdateQuizAttemptAnswer);
}
