// Imports
import styled from 'styled-components';
import { Button, Icon } from 'antd';
import Colors from '../../styles/Colors';

// Main Component
const ReportingStyles = styled.div`
  padding: 20px;
  .ant-form-item-label label:after {
    content: '';
  }
`;

ReportingStyles.Question = styled.p`
  font-size: 1.2rem;
  text-align: center;
`;

ReportingStyles.Answer = styled(Button)`
  padding: 1rem 2rem;
  margin: 0.25rem 0;
  height: auto;
  font-weight: 800;
  white-space: normal;
  background-color: ${Colors.greyf5};
  &.incorrect {
    background-color: ${Colors.redLight};
    color: ${Colors.red};
    &:hover {
      background-color: ${Colors.redLight};
      color: ${Colors.red};
    }
  }
  &.correct {
    background-color: ${Colors.green};
    color: ${Colors.greenDark};
    &:hover {
      background-color: ${Colors.green};
      color: ${Colors.greenDark};
    }
  }
`;

ReportingStyles.ResultIcon = styled(Icon)`
  position: absolute;
  right: 0.5rem;
  top: calc(50% - 7px);
`;

ReportingStyles.Actions = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;
`;

// Export
export default ReportingStyles;
