// MAIN TYPES
// --------------------------------------------------------
/**
 * @constant
 */
export const LESSONTRACKER_LMSSCRIPTS_RESET = 'LESSONTRACKER_LMSSCRIPTS_RESET';

/**
 * @constant
 */
export const LESSONTRACKER_LMSSCRIPTS_REQUEST = 'LESSONTRACKER_LMSSCRIPTS_REQUEST';

/**
 * @constant
 */
export const LESSONTRACKER_LMSSCRIPTS_SUCCESS = 'LESSONTRACKER_LMSSCRIPTS_SUCCESS';

/**
 * @constant
 */
export const LESSONTRACKER_LMSSCRIPTS_FAILURE = 'LESSONTRACKER_LMSSCRIPTS_FAILURE';
