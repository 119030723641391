// Import
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ReportingStyles from './styles';
import { Alert, PageHeader, Button, Icon, Skeleton, Form, Input, Card, Select, Modal, Divider, DatePicker } from 'antd';
import { Link } from 'react-router-dom';
import moment from 'moment';

// Main Component
/**
 * Main Component
 * @param {*} props any
 * @return {JSX.Element} JSX Element
 */
const Reporting = props => {
  const { form, handleSubmit, questions, answers, answered, lmsCourse, history, params } = props;

  const { enrollmentId, courseId } = params;
  const { getFieldDecorator } = form;
  const [modalVisible, setModalVisible] = useState(false);
  const dateFormat = 'MM/DD/YYYY';
  const onSubmitForm = event => {
    event.preventDefault();
    form.validateFields((err, values) => {
      if (!err) {
        setModalVisible(true);
      }
    });
  };

  const onClickOkModal = () => {
    const answers = form.getFieldsValue(questions?.data?.map(i => `question_id_${i.id}`) ?? []);
    const answerData = Object.keys(answers).map(i => ({
      reporting_question_id: parseInt(i.replace('question_id_', ''), 0),
      answer: moment.isMoment(answers[i]) ? formatMomentDate(answers[i], dateFormat) : answers[i],
    }));

    const licenseTypeId = parseInt(form.getFieldValue('license_type_id'), 0);
    handleSubmit(answerData, licenseTypeId);
    setModalVisible(false);
  };

  const onClickCancelModal = () => {
    setModalVisible(false);
  };

  const getReportingQuestionElement = (question, existingAnswer) => {
    switch (question.validation) {
      case 'date':
        return <DatePicker format={dateFormat} disabled={!!existingAnswer || lmsCourse.fetching || answered.fetching || answers?.data?.[0]?.enrollment?.reporting_queue} />;
      default:
        return <Input name={`question_id_${question.id}`} disabled={!!existingAnswer || lmsCourse.fetching || answered.fetching || answers?.data?.[0]?.enrollment?.reporting_queue} />;
    }
  };

  const formatReportingAnswer = (value, validation) => {
    switch (validation) {
      case 'date':
        return formatMomentDate(value, dateFormat);
      default:
        return value;
    }
  };

  const formatExistingAnswer = (value, validation) => {
    switch (validation) {
      case 'date':
        return value ? moment(value) : null;
      default:
        return value;
    }
  };

  const formatMomentDate = (date, format) => {
    return moment.isMoment(date) ? date?.format(format) : null;
  };

  return (
    <ReportingStyles>
      <PageHeader
        title={'Course Completion Reporting Form'}
        ghost={false}
        onBack={() => {
          history.push(`/dashboard/enrollments/${enrollmentId}/courses/${courseId}/outline`);
        }}
        extra={
          <Button
            type="primary"
            style={{ backgroundColor: '#5e8dbd', borderColor: 'rgb(97 121 146)' }}
            onClick={() => {
              history.push(`/dashboard/enrollments/${enrollmentId}/courses/${courseId}/outline`);
            }}
          >
            <Icon type="book" /> Course Outline
          </Button>
        }
      />
      {answers?.errors ? (
        answers?.errors.map(error => {
          // eslint-disable-next-line react/jsx-key
          return <Alert message={error} type="error" />;
        })
      ) : (
        <>
          <Card>
            {!answered.fetching && answered.data?.length > 0 && <Alert message="Successully Submitted" type="success" />}
            {!answered.fetching && (answered.errors?.length || answered.errors === true) > 0 && <Alert message="Submission Failed" type="error" />}
            <Skeleton active loading={!questions?.data?.length > 0 || !answers?.data}>
              {questions?.data?.length > 0 && answers?.data && (
                <Form onSubmit={onSubmitForm}>
                  <Form.Item label={'License Type'}>
                    {getFieldDecorator('license_type_id', {
                      rules: [
                        {
                          required: true,
                          message: 'Required',
                        },
                      ],
                      initialValue: (lmsCourse?.data?.license_types?.length === 1 && lmsCourse?.data?.license_types[0].id) || answers?.data[0]?.enrollment?.student_license?.license_type_id || null,
                    })(
                      <Select
                        placeholder="Choose License Type"
                        disabled={
                          lmsCourse?.data?.license_types?.length === 1 || (answers?.data[0]?.enrollment?.student_license?.license_type_id ?? null) !== null || lmsCourse.fetching || answered.fetching
                        }
                      >
                        {lmsCourse?.data?.license_types?.map((i, k) => (
                          <Select.Option key={`license-type-${k}`} value={i.id}>
                            {i.name}
                          </Select.Option>
                        ))}
                      </Select>,
                    )}
                  </Form.Item>

                  {questions?.data?.map(question => {
                    const existingAnswer = answers?.data?.find(answer => answer.reporting_question_id === question.id)?.answer;
                    const element = getReportingQuestionElement(question, existingAnswer);
                    return (
                      <Form.Item label={question.question} key={question.id}>
                        {getFieldDecorator(`question_id_${question.id}`, {
                          rules: [
                            {
                              required: true,
                              message: 'Required',
                            },
                          ],
                          initialValue: formatExistingAnswer(existingAnswer, question.validation),
                        })(element)}
                      </Form.Item>
                    );
                  })}
                  <ReportingStyles.Actions>
                    {questions?.data?.length === answers?.data?.length || answers?.data?.[0]?.enrollment?.reporting_queue ? (
                      <>
                        <Button onClick={() => history.push(`/dashboard/enrollments/${enrollmentId}/courses/${courseId}/outline`)}>
                          <Icon type="arrow-left" />
                          Back to outline
                        </Button>
                        <Link to={`/dashboard/enrollments/${enrollmentId}/courses/${courseId}/review`}>
                          <Button type="primary">
                            Next <Icon type="right" />
                          </Button>
                        </Link>
                      </>
                    ) : (
                      <Button type="primary" htmlType="submit" loading={answered.fetching || lmsCourse.fetching || answered.fetching}>
                        Submit
                        <Icon type="arrow-right" />
                      </Button>
                    )}
                  </ReportingStyles.Actions>
                </Form>
              )}
            </Skeleton>
          </Card>
          <Modal title={'Please Confirm Information'} visible={modalVisible} onOk={onClickOkModal} onCancel={onClickCancelModal}>
            <p>
              You can <strong>NOT</strong> change this information once it has been submitted.
            </p>
            <p>Please make sure to verify that this information is correct.</p>

            <Divider />

            <p>
              <strong>License Type:</strong>
              <br />
              {lmsCourse?.data?.license_types?.find(i => i.id === parseInt(form.getFieldValue('license_type_id'), 0))?.name ?? 'Unknown'}
            </p>

            {questions?.data?.map((i, k) => {
              let rawAnswer = form.getFieldValue(`question_id_${i.id}`);
              return (
                <p key={`modal-question-${k}`}>
                  <strong>{i.question}</strong>
                  <br />
                  {formatReportingAnswer(rawAnswer, i.validation)}
                </p>
              );
            })}
          </Modal>
        </>
      )}
    </ReportingStyles>
  );
};

// Property Types
Reporting.propTypes = {
  handleSubmit: PropTypes.func,
  form: PropTypes.object,
  history: PropTypes.object,
  params: PropTypes.object,
  questions: PropTypes.object,
  answers: PropTypes.object,
  lmsCourse: PropTypes.object,
  answered: PropTypes.object,
};

// Export
export default Form.create()(Reporting);
