// IMPORTS
// --------------------------------------------------------
// Types
import { READ_QUESTIONS_RESET, READ_QUESTIONS_REQUEST, READ_QUESTIONS_FAILURE, READ_QUESTIONS_SUCCESS } from './types';

// MAIN ACTIONS
// --------------------------------------------------------
/**
 * Resets main reducer to initialState
 * @returns {Dispatch} Redux Dispatch
 */
export const doReadQuestionsReset = () => ({
  type: READ_QUESTIONS_RESET,
});

/**
 * Main request
 * @returns {Dispatch} Redux Dispatch
 */
export const doReadQuestionsRequest = ({ token, id }) => ({
  type: READ_QUESTIONS_REQUEST,
  token,
  id,
});

/**
 * Handling request failure
 * @returns {Dispatch} Redux Dispatch
 */
export const doReadQuestionsFailure = ({ errors }) => ({
  type: READ_QUESTIONS_FAILURE,
  errors,
});

/**
 * Handling request success
 * @returns {Dispatch} Redux Dispatch
 */
export const doReadQuestionsSuccess = ({ data }) => ({
  type: READ_QUESTIONS_SUCCESS,
  data,
});
