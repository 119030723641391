// Imports
import { takeLatest, put, call } from 'redux-saga/effects';

// Types
import { GET_LESSON_STATUS_REQUEST, GET_LESSON_STATUS_SUCCESS, GET_LESSON_STATUS_FAILURE } from './types';

// Actions
import { doGetLessonStatus } from './actions';

// Main Sagas
/**
 *
 * @param {*} param0 Authentication token
 * @returns {void}
 */
function* workerGetLessonStatus({ token, scriptId, enrollmentId }) {
  try {
    const response = yield call(doGetLessonStatus, { token, scriptId, enrollmentId });

    // Catch For No Response
    if (!response?.data?.data) {
      throw new Error('workerGetLessonStatus - Response Error');
    }

    const { data } = response.data;

    yield put({
      type: GET_LESSON_STATUS_SUCCESS,
      data,
    });
  } catch (error) {
    const message = error?.message ?? 'Something went wrong';
    yield put({ type: GET_LESSON_STATUS_FAILURE, errors: message });
  }
}

/**
 * Watches for request
 * @return {void}
 */
export function* watcherGetLessonStatus() {
  yield takeLatest(GET_LESSON_STATUS_REQUEST, workerGetLessonStatus);
}
