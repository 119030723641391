// Imports
import { takeLatest, put, call } from 'redux-saga/effects';

// Types
import { PUT_SECTION_REQUEST, PUT_SECTION_SUCCESS, PUT_SECTION_FAILURE } from './types';

// Actions
import { putSection } from './actions';

// Main Sagas
/**
 *
 * @param {token} token Authentication token
 * @param {courseId} courseId ID of LMS Course
 * @param {rating} rating 1-5 int rating
 * @param {review} review (Optional) string review
 * @returns {void}
 */
function* workerPutSection({ token, sectionId, videoWatched }) {
  try {
    const response = yield call(putSection, { token, sectionId, videoWatched });

    yield put({
      type: PUT_SECTION_SUCCESS,
      response,
    });
  } catch (error) {
    yield put({ type: PUT_SECTION_FAILURE, ...error.response });
  }
}

/**
 * Watches for request
 * @return {void}
 */
export function* watcherPutSection() {
  yield takeLatest(PUT_SECTION_REQUEST, workerPutSection);
}
