// IMPORTS
// --------------------------------------------------------
// Types
import { READ_LMSSCRIPTS_RESET, READ_LMSSCRIPTS_REQUEST, READ_LMSSCRIPTS_FAILURE, READ_LMSSCRIPTS_SUCCESS } from './types';

// MAIN ACTIONS
// --------------------------------------------------------
/**
 * Resets main reducer to initialState
 * @returns {Dispatch} Redux Dispatch
 */
export const doReadLmsScriptReset = () => ({
  type: READ_LMSSCRIPTS_RESET,
});

/**
 * Main request
 * @returns {Dispatch} Redux Dispatch
 */
export const doReadLmsScriptRequest = ({ token, id, enrollmentId, include }) => ({
  type: READ_LMSSCRIPTS_REQUEST,
  token,
  id,
  enrollmentId,
  include,
});

/**
 * Handling request failure
 * @returns {Dispatch} Redux Dispatch
 */
export const doReadLmsScriptFailure = ({ errors }) => ({
  type: READ_LMSSCRIPTS_FAILURE,
  errors,
});

/**
 * Handling request success
 * @returns {Dispatch} Redux Dispatch
 */
export const doReadLmsScriptSuccess = ({ data }) => ({
  type: READ_LMSSCRIPTS_SUCCESS,
  data,
});
