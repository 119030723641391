// Types
import { CLEAR_FEEDBACK, POST_FEEDBACK_REQUEST, POST_FEEDBACK_SUCCESS, POST_FEEDBACK_FAILURE } from './types';

// Initial State
const initialState = {
  feedback: {
    fetching: false,
    success: false,
    errors: null,
  },
};

// Main Reducer
/**
 * feedbackReducer Reducer
 * @param {Object} state current state of redux
 * @param {Object} action action state object
 * @returns {state} Redux State
 */
const feedbackReducer = (state = initialState, action) => {
  switch (action.type) {
    case CLEAR_FEEDBACK:
      return initialState;
    case POST_FEEDBACK_REQUEST:
      return {
        ...state,
        feedback: {
          fetching: true,
          errors: null,
          success: false,
        },
      };
    case POST_FEEDBACK_SUCCESS:
      return {
        ...state,
        feedback: {
          fetching: false,
          errors: null,
          success: true,
          alertUser: action.alertUser,
        },
      };

    case POST_FEEDBACK_FAILURE:
      return {
        ...state,
        feedback: {
          fetching: false,
          errors: action.errors,
          alertUser: action.alertUser,
        },
      };
    default:
      return state;
  }
};

export default feedbackReducer;
