// Import
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import QuizLessonsReviewStyles from './styles';
import { Card, Spin } from 'antd';
import { Link } from 'react-router-dom';

// Main Component
/**
 * Main Component
 * @param {*} props any
 * @return {JSX.Element} JSX Element
 */
const QuizLessonsReview = props => {
  // State / Props
  const { sections, enrollmentId, courseId, loading } = props;

  const getLinkToSection = section => {
    if (section?.lms_script) {
      return <Link to={`/dashboard/enrollments/${enrollmentId}/courses/${courseId}/lmslessons/${section.lms_script.id}`}>{section?.sectiontype?.title}</Link>;
    } else {
      return section?.sectiontype?.title;
    }
  };

  // Functions
  // Render
  return sections && sections.length ? (
    <QuizLessonsReviewStyles>
      <Card className="quiz-lessons-review" title="Lessons To Review">
        {sections.map(section => {
          if (section.question_count > 0) {
            return (
              <>
                <p key={section.id}>
                  {getLinkToSection(section)}
                  {' - '}
                  {Math.round(section.percent_correct)}% Correct ({section.correct_count} out of {section.question_count})
                </p>
              </>
            );
          } else {
            return '';
          }
        })}
      </Card>
    </QuizLessonsReviewStyles>
  ) : (
    ''
  );
};

// Property Types
QuizLessonsReview.propTypes = {
  sections: PropTypes.any,
  enrollmentId: PropTypes.string,
  courseId: PropTypes.string,
  loading: PropTypes.bool,
};

// Export
export default QuizLessonsReview;
