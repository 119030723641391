// Imports
import axios from 'axios';

// Config
const apiUri = window.API_URL;

// Export actions
export const getQuiz = ({ token, courseId, enrollmentId }) => {
  return axios({
    method: 'get',
    url: `${apiUri}/courses/${courseId}/quiz?${enrollmentId ? `enrollment_id=${enrollmentId}` : ''}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};
