// Imports
import { takeLatest, put, call } from 'redux-saga/effects';

// Types
import { PASSWORD_RESET_FAILURE, PASSWORD_RESET_REQUEST, PASSWORD_RESET_SUCCESS } from './types';

// Actions
import { passwordReset } from './actions';

/**
 * Function that handles the forgot password http request
 *
 * @param {Object} deconstructed object
 * @returns {void}
 */
function* workerPasswordReset({ password, password_confirmation, token, validator }) {
  try {
    const response = yield call(passwordReset, { password, password_confirmation, token, validator });
    if (!response || !response.data || !response.data.data) {
      throw new Error('workerPasswordReset - Response Error');
    }

    yield put({
      type: PASSWORD_RESET_SUCCESS,
      data: response.data.data,
    });
  } catch (error) {
    const message = error.response && error.response.data ? error.response.data : null;
    yield put({ type: PASSWORD_RESET_FAILURE, ...message });
  }
}

// Exports
export function* watcherPasswordReset() {
  yield takeLatest(PASSWORD_RESET_REQUEST, workerPasswordReset);
}
