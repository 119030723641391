// Import
import React from 'react';
import PropTypes from 'prop-types';
import ReviewStyles from './styles';
import { PageHeader, Button, Icon, Form, Input, Rate, Alert } from 'antd';
import ReportingStyles from '../Reporting/styles';

const { TextArea } = Input;

// Main Component
/**
 * Review Component
 * @param {*} props any
 * @return {JSX.Element} JSX Element
 */
const Review = props => {
  const { fetching, errors, form, handleSubmit, review, enrollmentId, courseId, history } = props;

  const { getFieldDecorator } = form;

  const onSubmit = event => {
    event.preventDefault();
    form.validateFields((err, values) => {
      if (!err) {
        const { rating, review } = values;
        handleSubmit({ rating, review });
      }
    });
  };
  return (
    <ReviewStyles>
      <PageHeader
        title="Course Completion Review Form"
        extra={
          <Button
            type="primary"
            style={{ backgroundColor: '#5e8dbd', borderColor: 'rgb(97 121 146)' }}
            onClick={() => {
              history.push(`/dashboard/enrollments/${enrollmentId}/courses/${courseId}/outline`);
            }}
          >
            <Icon type="book" /> Course Outline
          </Button>
        }
        onBack={() => {
          history.push(`/dashboard/enrollments/${enrollmentId}/courses/${courseId}/outline`);
        }}
      />
      {errors?.error ? (
        errors?.error?.map(error => {
          // eslint-disable-next-line react/jsx-key
          return <Alert message={error} type="error" />;
        })
      ) : (
        <>
          <Form onSubmit={onSubmit}>
            <Form.Item label="How many stars would you rate this course?">
              {getFieldDecorator(`rating`, {
                initialValue: review && review.id ? review.rating : 5,
              })(<Rate disabled={review && review.id} allowClear={false} />)}
            </Form.Item>
            <Form.Item label="(Optional) Add a short course review to your rating.">
              {getFieldDecorator(`review`, {
                rules: [],
                initialValue: review && review.id ? review.review : '',
              })(<TextArea disabled={review && review.id} rows={4} />)}
            </Form.Item>
            {errors?.message && <Alert message={errors.message} type="error" />}
            <ReviewStyles.Actions>
              {review && review.id ? (
                <>
                  <Button onClick={() => history.push(`/dashboard/enrollments/${enrollmentId}/courses/${courseId}/outline`)}>
                    <Icon type="arrow-left" />
                    Back to outline
                  </Button>
                </>
              ) : (
                <Button type="primary" htmlType="submit" loading={fetching}>
                  Submit
                  <Icon type="arrow-right" />
                </Button>
              )}
            </ReviewStyles.Actions>
          </Form>
        </>
      )}
    </ReviewStyles>
  );
};

// Property Types
Review.propTypes = {
  handleSubmit: PropTypes.func,
  form: PropTypes.object,
  fetching: PropTypes.bool,
  errors: PropTypes.object,
  review: PropTypes.object,
  enrollmentId: PropTypes.string,
  courseId: PropTypes.string,
  history: PropTypes.object,
};

// Export
export default Review;
