// IMPORTS
// --------------------------------------------------------
// Types
import { LESSONTRACKER_LMSSCRIPTS_RESET, LESSONTRACKER_LMSSCRIPTS_REQUEST, LESSONTRACKER_LMSSCRIPTS_FAILURE, LESSONTRACKER_LMSSCRIPTS_SUCCESS } from './types';

// MAIN ACTIONS
// --------------------------------------------------------
/**
 * Resets main reducer to initialState
 * @returns {Dispatch} Redux Dispatch
 */
export const doLessonTrackerLmsScriptsReset = () => ({
  type: LESSONTRACKER_LMSSCRIPTS_RESET,
});

/**
 * Main request
 * @returns {Dispatch} Redux Dispatch
 */
export const doLessonTrackerLmsScriptsRequest = ({ token, id, enrollmentId }) => ({
  type: LESSONTRACKER_LMSSCRIPTS_REQUEST,
  token,
  id,
  enrollmentId,
});

/**
 * Handling request failure
 * @returns {Dispatch} Redux Dispatch
 */
export const doLessonTrackerLmsScriptsFailure = ({ errors }) => ({
  type: LESSONTRACKER_LMSSCRIPTS_FAILURE,
  errors,
});

/**
 * Handling request success
 * @returns {Dispatch} Redux Dispatch
 */
export const doLessonTrackerLmsScriptsSuccess = ({ data }) => ({
  type: LESSONTRACKER_LMSSCRIPTS_SUCCESS,
  data,
});
