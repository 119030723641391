import PropTypes from 'prop-types';
import { CardElement, Elements, useElements, useStripe } from '@stripe/react-stripe-js';
import React, { useEffect, useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import StripeForm from './StripeForm';

const StripePayment = props => {
  const { token, cartData, stripeData, resetStripeData, processStripePaymentIntent, handleConfirmationStep, handleStudentInformationStep, setStripeStatus, getMe } = props;

  const stripePromise = loadStripe(window.STRIPE_PUBLISH_KEY);

  return (
    <Elements stripe={stripePromise}>
      <StripeForm
        token={token}
        handleConfirmationStep={handleConfirmationStep}
        handleStudentInformationStep={handleStudentInformationStep}
        resetStripeData={resetStripeData}
        processStripePaymentIntent={processStripePaymentIntent}
        getMe={getMe}
        cartData={cartData}
        stripeData={stripeData}
        setStripeStatus={setStripeStatus}
      />
    </Elements>
  );
};
// PROPS TYPES
// --------------------------------------------------------
StripePayment.propTypes = {
  stripePromise: PropTypes.string,
  token: PropTypes.string,
  cartData: PropTypes.object,
  stripeData: PropTypes.object,
  resetStripeData: PropTypes.func,
  processStripePaymentIntent: PropTypes.func,
  getMe: PropTypes.func,
  handleStudentInformationStep: PropTypes.func,
  handleConfirmationStep: PropTypes.func,
  setStripeStatus: PropTypes.func,
};
export default StripePayment;
