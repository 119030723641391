//types
import {
  UPDATE_STUDENT_CONTACT_REQUEST,
  UPDATE_STUDENT_CONTACT_SUCCESS,
  UPDATE_STUDENT_CONTACT_FAILURE,
  GET_STUDENT_CONTACT_REQUEST,
  GET_STUDENT_CONTACT_SUCCESS,
  GET_STUDENT_CONTACT_FAILURE,
  CREATE_STUDENT_REQUEST,
  CREATE_STUDENT_FAILURE,
  CREATE_STUDENT_SUCCESS,
} from './types';

// reducer with initial state
const initialState = {
  request: {
    fetching: false,
    errors: null,
    type: null,
    success: false,
    failure: false,
  },
  contact: {
    fetching: false,
    errors: null,
    data: null,
  },
  student: {
    fetching: false,
    errors: null,
    data: null,
  },
};

// Main reducer
/**
 * Main student reducer
 * @param {Object} state initial state
 * @param {any} action Object of data
 * @returns {Object} reducer state
 */
const accountReducer = (state = initialState, action) => {
  switch (action.type) {
    case CREATE_STUDENT_SUCCESS:
      return {
        ...state,
        student: {
          fetching: false,
          errors: null,
          data: action.data,
        },
      };
    case GET_STUDENT_CONTACT_SUCCESS:
      return {
        ...state,
        contact: {
          fetching: false,
          errors: null,
          data: action.data,
        },
      };
    case GET_STUDENT_CONTACT_FAILURE:
      return {
        ...state,
        contact: {
          fetching: false,
          errors: action.error,
        },
      };
    case CREATE_STUDENT_FAILURE:
      return {
        ...state,
        student: {
          fetching: false,
          errors: action.errors,
        },
      };
    case GET_STUDENT_CONTACT_REQUEST:
      return {
        ...state,
        contact: {
          fetching: true,
          errors: null,
          data: null,
        },
      };
    case CREATE_STUDENT_REQUEST:
      return {
        ...state,
        student: {
          fetching: true,
          errors: null,
          data: null,
        },
      };
    case UPDATE_STUDENT_CONTACT_SUCCESS:
      return {
        ...state,
        request: {
          message: 'Student information has been updated',
          fetching: false,
          errors: null,
          success: true,
          failure: false,
        },
      };
    case UPDATE_STUDENT_CONTACT_FAILURE:
      return {
        ...state,
        request: {
          message: 'blank',
          fetching: false,
          errors: action.errors,
          success: false,
          failure: true,
        },
      };
    case UPDATE_STUDENT_CONTACT_REQUEST:
      return {
        ...state,
        request: {
          message: 'blank',
          fetching: true,
          errors: null,
          success: false,
          failure: false,
        },
      };
    default:
      return state;
  }
};

// Exports
export default accountReducer;
