// Import
import React from 'react';
import PropTypes from 'prop-types';
import ForgotPasswordStyles from './styles';
import { Alert, Icon, Row, Col, Form, Input } from 'antd';
import { Link } from 'react-router-dom';
import Constants from '../../utils/Constants';

// Main Component
/**
 * Main Component
 * @param {*} props any
 * @return {JSX.Element} JSX Element
 */
const ForgotPassword = props => {
  // Props
  const { form, handleSubmit, data } = props;
  const { getFieldDecorator } = form;

  // Functions
  const onSubmitForm = event => {
    event.preventDefault();
    form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        handleSubmit(values);
      }
    });
  };

  // Display of form fields
  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 24 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 24 },
    },
  };
  return (
    <ForgotPasswordStyles>
      <ForgotPasswordStyles.Card>
        <Row gutter={[0, 0]} style={{ width: '100%' }}>
          <Col span={24}>
            <div style={{ padding: 20 }}>
              {data ? <Alert message="Password reset email has been sent. Please check your email and click the link to change your password." type="success" /> : <></>}
              <div style={{ textAlign: `center`, padding: '15px 0 30px 0' }}>
                <img style={{ width: 350 }} src={'/images/logos/' + Constants.WEBSITE_NAME + '/logo-on-white.png'} alt={'logo'} />
              </div>
              <p>Enter the email address associated with your account, and we’ll email you a link to reset your password.</p>
              <Form onSubmit={onSubmitForm}>
                <Form.Item {...formItemLayout}>
                  {getFieldDecorator('email', {
                    rules: [
                      {
                        type: 'email',
                        message: 'Not a valid email',
                      },
                      {
                        required: true,
                        message: 'Email is required',
                      },
                    ],
                  })(<Input prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />} required={true} disabled={props.loading} type="email" placeholder={`Email address`} />)}
                </Form.Item>
                <ForgotPasswordStyles.Errors show={props.errors && props.errors.length > 0} count={props.errors && props.errors.length}>
                  {props.errors && props.errors.length >= 1 && props.errors.map((val, key) => <ForgotPasswordStyles.Error key={key.toString()}>{val}</ForgotPasswordStyles.Error>)}
                </ForgotPasswordStyles.Errors>
                <Row gutter={[16, 16]}>
                  <Col span={12}>
                    <div style={{ textAlign: `center` }}>
                      <ForgotPasswordStyles.Button disabled={data || props.loading} htmlType={'submit'} block>
                        {props.loading ? '...' : 'Send reset link'}
                      </ForgotPasswordStyles.Button>
                    </div>
                  </Col>
                  <Col span={12}>
                    <div style={{ padding: 10, textAlign: `center` }}>
                      <Link to={`login`}>
                        <Icon type="rollback" /> Go Back
                      </Link>
                    </div>
                  </Col>
                </Row>
              </Form>
            </div>
          </Col>
        </Row>
      </ForgotPasswordStyles.Card>
    </ForgotPasswordStyles>
  );
};

// Property Types
ForgotPassword.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  data: PropTypes.object,
  loading: PropTypes.bool,
  errors: PropTypes.array,
  form: PropTypes.object,
};

// Export
export default Form.create({ name: 'login' })(ForgotPassword);
