// Types
import {
  GET_COURSE_OUTLINE_REQUEST,
  GET_COURSE_OUTLINE_SUCCESS,
  GET_COURSE_OUTLINE_FAILURE,
  CLEAR_COURSE_OUTLINE,
  GET_COURSE_REVIEW_REQUEST,
  GET_COURSE_REVIEW_SUCCESS,
  GET_COURSE_REVIEW_FAILURE,
} from './types';

// Initial State
const initialState = {
  fetching: false,
  data: null,
  errors: null,
  review: {
    fetching: false,
    data: null,
    errors: null,
  },
};

// Main Reducer
/**
 * courseOutlineReducer Reducer
 * @param {Object} state current state of redux
 * @param {Object} action action state object
 * @returns {state} Redux State
 */
const courseOutlineReducer = (state = initialState, action) => {
  switch (action.type) {
    case CLEAR_COURSE_OUTLINE:
      return initialState;
    case GET_COURSE_OUTLINE_REQUEST:
      return {
        ...state,
        fetching: true,
        errors: null,
      };
    case GET_COURSE_OUTLINE_SUCCESS:
      return {
        ...state,
        fetching: false,
        errors: null,
        data: action.data,
      };

    case GET_COURSE_OUTLINE_FAILURE:
      return {
        ...state,
        fetching: false,
        errors: action.errors,
      };
    case GET_COURSE_REVIEW_REQUEST:
      return {
        ...state,
        review: {
          fetching: true,
          errors: null,
        },
      };
    case GET_COURSE_REVIEW_SUCCESS:
      return {
        ...state,
        review: {
          fetching: false,
          errors: null,
          data: action.data,
        },
      };

    case GET_COURSE_REVIEW_FAILURE:
      return {
        ...state,
        review: {
          fetching: false,
          errors: action.errors,
        },
      };
    default:
      return state;
  }
};

export default courseOutlineReducer;
