// Types
import {
  GET_LMSCOURSE_REQUEST,
  GET_LMSCOURSE_SUCCESS,
  GET_LMSCOURSE_FAILURE,
  CLEAR_LMSCOURSE,
  GET_GUEST_LMSCOURSE_REQUEST,
  GET_GUEST_LMSCOURSE_SUCCESS,
  GET_GUEST_LMSCOURSE_FAILURE,
  GET_GUEST_LMSCOURSES_REQUEST,
  GET_GUEST_LMSCOURSES_SUCCESS,
  GET_GUEST_LMSCOURSES_FAILURE,
} from './types';

// Initial State
const initialState = {
  fetching: false,
  data: null,
  errors: null,
};

// Main Reducer
/**
 * lmsCourseReducer Reducer
 * @param {Object} state current state of redux
 * @param {Object} action action state object
 * @returns {state} Redux State
 */
const lmsCourseReducer = (state = initialState, action) => {
  switch (action.type) {
    case CLEAR_LMSCOURSE:
      return initialState;
    case GET_LMSCOURSE_REQUEST:
      return {
        ...state,
        fetching: true,
        errors: null,
      };
    case GET_LMSCOURSE_SUCCESS:
      return {
        ...state,
        fetching: false,
        data: action.data,
      };
    case GET_LMSCOURSE_FAILURE:
      return {
        ...state,
        fetching: false,
        errors: action.errors,
      };
    case GET_GUEST_LMSCOURSE_REQUEST:
      return {
        ...state,
        fetching: true,
        errors: null,
      };
    case GET_GUEST_LMSCOURSE_SUCCESS:
      return {
        ...state,
        fetching: false,
        data: action.data,
      };
    case GET_GUEST_LMSCOURSE_FAILURE:
      return {
        ...state,
        fetching: false,
        errors: action.errors,
      };
    case GET_GUEST_LMSCOURSES_REQUEST:
      return {
        ...state,
        fetching: true,
        errors: null,
      };
    case GET_GUEST_LMSCOURSES_SUCCESS:
      return {
        ...state,
        fetching: false,
        data: action.data,
      };
    case GET_GUEST_LMSCOURSES_FAILURE:
      return {
        ...state,
        fetching: false,
        errors: action.errors,
      };
    default:
      return state;
  }
};

export default lmsCourseReducer;
