// Types
import { CREATE_STUDENT_REQUEST, GET_STUDENT_CONTACT_REQUEST, UPDATE_STUDENT_CONTACT_REQUEST } from './types';

/**
 * getStudentCourses Operation
 * @param {*} Object { token, id }
 * @returns {Dispatch} for redux
 */
export const doGetStudentContact = ({ token, id }) => ({
  type: GET_STUDENT_CONTACT_REQUEST,
  token,
  id,
});

/**
 * createStudent Operation
 * @param {*} Object { token, user_id }
 * @returns {Dispatch} for redux
 */
export const doCreateStudent = ({ token, user_id }) => ({
  type: CREATE_STUDENT_REQUEST,
  token,
  user_id,
});

/**
 * doUpdateStudentContact Operation
 * @param {*} Object { token, id, firstname, lastname, email, phone, address, city, state, zip, password }
 * @returns {Dispatch} for redux
 */
export const doUpdateStudentContact = ({ token, id, firstname, lastname, email, phone, address, city, state, zip, password }) => ({
  type: UPDATE_STUDENT_CONTACT_REQUEST,
  token,
  id,
  firstname,
  lastname,
  email,
  phone,
  address,
  city,
  state,
  zip,
  password,
});
