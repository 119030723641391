// Imports
import { takeLatest, put, call } from 'redux-saga/effects';

// Types
import { GET_COURSE_ITEMS_REQUEST, GET_COURSE_ITEMS_SUCCESS, GET_COURSE_ITEMS_FAILURE } from './types';

// Actions
import { getCourseItems } from './actions';

// Main Sagas
/**
 *
 * @param {*} param0 Authentication token
 * @returns {void}
 */
function* workerGetCourseItems({ token, enrollmentId }) {
  try {
    const response = yield call(getCourseItems, { token, enrollmentId });

    // Catch For No Response
    if (!response?.data?.data) {
      throw new Error('workerGetCourseItems - Response Error');
    }

    const { data } = response.data;

    yield put({
      type: GET_COURSE_ITEMS_SUCCESS,
      data,
    });
  } catch (error) {
    const message = error?.message ?? 'Something went wrong';
    yield put({ type: GET_COURSE_ITEMS_FAILURE, errors: message });
  }
}

/**
 * Watches for request
 * @return {void}
 */
export function* watcherGetCourseItems() {
  yield takeLatest(GET_COURSE_ITEMS_REQUEST, workerGetCourseItems);
}
