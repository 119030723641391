// Import
import React from 'react';
import PropTypes from 'prop-types';
import { Icon, Row, Col, Form, Input, Alert } from 'antd';
import AuthySetupStyles from './styles';
import LoginStyles from '../Login/styles';
import Constants from '../../utils/Constants';

// Main Component
/**
 * Main Component
 * @param {*} props any
 * @return {JSX.Element} JSX Element
 */
const AuthySetup = props => {
  // Props
  const { authy, form, errors, handleSubmit } = props;
  const { getFieldDecorator } = form;

  // Functions
  const onSubmitForm = event => {
    event.preventDefault();
    form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        handleSubmit(values);
      }
    });
  };

  // Display of form fields
  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 24 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 24 },
    },
  };
  return (
    <AuthySetupStyles>
      <AuthySetupStyles.Card>
        <Row gutter={[0, 0]} style={{ width: '100%' }}>
          <Col span={24}>
            <div style={{ padding: 20 }}>
              <div style={{ textAlign: `center`, padding: 30 }}>
                <img style={{ width: '100%', maxWidth: 350 }} src={'/images/logos/' + Constants.WEBSITE_NAME + '/logo-on-white.png'} />
              </div>
              <h2 style={{ textAlign: 'center' }}>2FA Setup</h2>
              <Form onSubmit={onSubmitForm}>
                <Form.Item {...formItemLayout} style={{ marginBottom: 10 }}>
                  {getFieldDecorator('cellphone', {
                    rules: [
                      {
                        required: true,
                        message: 'Phone is required',
                      },
                    ],
                  })(<Input prefix={<Icon type="phone" style={{ color: 'rgba(0,0,0,.25)' }} />} disabled={props.loading} type="text" placeholder={`555-555-5555`} />)}
                </Form.Item>
                <Form.Item {...formItemLayout}>
                  {getFieldDecorator('password', {
                    rules: [
                      {
                        required: true,
                        message: 'Password is required',
                      },
                    ],
                  })(<Input prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />} disabled={props.loading} type="password" placeholder={`Password`} />)}
                </Form.Item>
                <Form.Item style={{ marginBottom: 0 }}>
                  {getFieldDecorator('user_id', {
                    initialValue: authy?.id,
                    rules: [
                      {
                        required: true,
                      },
                    ],
                  })(<Input type="hidden" />)}
                </Form.Item>
                <div style={{ marginBottom: 15 }}>
                  <Alert type="warning" icon="phone" message="Your role in the system is either employee or admin. We require that you have 2fa authentication setup." />
                </div>
                <AuthySetupStyles.Errors show={props.errors && Object.keys(props.errors).length >= 1} count={props.errors && Object.keys(props.errors).length}>
                  {props.errors && Object.keys(props.errors).length && Object.keys(props.errors).map(key => <LoginStyles.Error key={key.toString()}>{props.errors[key][0]}</LoginStyles.Error>)}
                </AuthySetupStyles.Errors>
                <Row gutter={[16, 16]}>
                  <Col span={24}>
                    <div style={{ textAlign: `center` }}>
                      <AuthySetupStyles.Button disabled={props.loading} htmlType={'submit'} block>
                        {props.loading ? '...' : 'Create'}
                      </AuthySetupStyles.Button>
                    </div>
                  </Col>
                </Row>
              </Form>
            </div>
          </Col>
        </Row>
      </AuthySetupStyles.Card>
    </AuthySetupStyles>
  );
};

// Property Types
AuthySetup.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  handleResend: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  errors: PropTypes.any,
  data: PropTypes.object,
  form: PropTypes.object,
  authy: PropTypes.object,
  token: PropTypes.string,
  validator: PropTypes.string,
};

// Export
export default Form.create({ name: 'authy_setup' })(AuthySetup);
