// IMPORTS
// --------------------------------------------------------
import styled from 'styled-components';

// MAIN STYLED COMPONENTS
// --------------------------------------------------------
/**
 * Parent styles for component
 * @constant
 */

const ConfirmationStyles = styled.div``;
ConfirmationStyles.Button = styled.button`
  margin: auto;
  width: 329px;
  height: 61px;
  background-color: #ff8a00;
  font-size: 24px;
  text-transform: uppercase;
  color: #ffffff;
  cursor: pointer;
  border: 0;
  :hover {
    background-color: #0098e4;
    color: #fff;
    box-shadow: 0px 0px 9px 0px rgb(47 143 205 / 25%);
  }
`;
export default ConfirmationStyles;
