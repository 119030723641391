// Imports
import styled from 'styled-components';
import Screens from '../../styles/Screens';

// Main Component
const VideoStyles = styled.div`
  @media (min-width: ${Screens.xsmall}) {
    iframe {
      width: 100%;
    }
  }
  @media (min-width: ${Screens.small}) {
    iframe {
      width: 100%;
    }
  }
  @media (min-width: ${Screens.medium}) {
    #lesson-video-player {
      width: 65% !important;
      display: block;
      margin: 0 auto;
    }
    iframe {
      width: 100%;
      min-height: 1000;
    }
  }

  @media (min-width: ${Screens.large}) {
    iframe {
      width: 100vw;
      height: 30.25vw;
    }
  }
`;

// Export
export default VideoStyles;

// updateScriptAttempts
