// IMPORTS
// --------------------------------------------------------
import styled from 'styled-components';
import Colors from '../../styles/Colors';

// MAIN STYLES
// --------------------------------------------------------
export const LmsScriptQuestionStyles = styled.div`
  width: 100%;
  ul {
    margin: 0;
    padding: 0;
    list-style: none;
    li {
      border: none;

      button {
        display: block;
        width: 100%;
        padding: 1rem 2rem;
        margin: 0.25rem 0;
        height: auto;
        font-weight: 800;
        white-space: normal;
        background-color: ${Colors.greyf5};

        &.incorrect {
          background-color: ${Colors.redLight};
          color: ${Colors.red};
          &:hover {
            background-color: ${Colors.redLight};
            color: ${Colors.red};
          }
        }
        &.correct {
          background-color: ${Colors.green};
          color: ${Colors.greenDark};
          &:hover {
            background-color: ${Colors.green};
            color: ${Colors.greenDark};
          }
        }

        i {
          position: absolute;
          right: 0.5rem;
          top: calc(50% - 7px);
        }
      }

      label {
        input {
          margin-right: 10px;
        }
        span {
        }
      }
      label.correct {
        color: green;
      }
      label.incorrect {
        color: red;
        text-decoration: line-through;
      }
    }
  }
`;
