// action types
import {
  GET_REPORTING_QUESTIONS_REQUEST,
  GET_REPORTING_QUESTIONS_SUCCESS,
  GET_REPORTING_QUESTIONS_FAILURE,
  POST_REPORTING_ANSWER_REQUEST,
  POST_REPORTING_ANSWER_SUCCESS,
  POST_REPORTING_ANSWER_FAILURE,
  GET_REPORTING_ANSWERS_REQUEST,
  GET_REPORTING_ANSWERS_SUCCESS,
  GET_REPORTING_ANSWERS_FAILURE,
} from './types';

// reducer with initial state
const initialState = {
  questions: {
    fetching: false,
    data: null,
    errors: null,
  },
  answers: {
    fetching: false,
    data: null,
    errors: null,
  },
  answered: {
    fetching: false,
    data: null,
    errors: null,
  },
};

const reportingReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_REPORTING_QUESTIONS_REQUEST:
      return {
        ...state,
        questions: {
          fetching: true,
          errors: null,
        },
        answered: {},
      };
    case GET_REPORTING_QUESTIONS_SUCCESS:
      return {
        ...state,
        questions: {
          fetching: false,
          errors: null,
          data: action.response.data.data,
        },
      };
    case GET_REPORTING_QUESTIONS_FAILURE:
      return {
        ...state,
        questions: {
          fetching: false,
          errors: action.error,
        },
      };
    case GET_REPORTING_ANSWERS_REQUEST:
      return {
        ...state,
        answers: {
          fetching: true,
          errors: null,
        },
        attempt: {
          data: null,
        },
      };
    case GET_REPORTING_ANSWERS_SUCCESS:
      return {
        ...state,
        answers: {
          fetching: false,
          errors: null,
          data: action.response.data.data,
        },
      };
    case GET_REPORTING_ANSWERS_FAILURE:
      return {
        ...state,
        answers: {
          fetching: false,
          errors: action.data.error,
        },
      };
    case POST_REPORTING_ANSWER_REQUEST:
      return {
        ...state,
        answered: {
          fetching: true,
          errors: null,
          data: null,
        },
      };
    case POST_REPORTING_ANSWER_SUCCESS:
      return {
        ...state,
        answered: {
          fetching: false,
          errors: null,
          data: action.response.data.data,
        },
      };
    case POST_REPORTING_ANSWER_FAILURE:
      return {
        ...state,
        answered: {
          fetching: false,
          errors: action.error ? action.error : true,
          data: null,
        },
      };
    default:
      return state;
  }
};

export default reportingReducer;
