// IMPORTS
// --------------------------------------------------------
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import GuestAccessStyles from './styles';
import { withRouter } from 'react-router-dom';
import { Layout, Col, Form, Row, Tag, Icon, Alert, Select } from 'antd';
import { Base64 } from 'js-base64';
import { useMediaQuery } from 'react-responsive';
import Constants from '../../utils/Constants';

// GuestAccess COMPONENT
// --------------------------------------------------------

/**
 * GuestAccess
 * @param {Object} props component props
 * @returns {JSX} React component
 */
const GuestAccess = props => {
  // PROPS / STATE
  const { lmsCourseId, lmsCourseData, lmsCourseErrors, createGuestEnrollment, fetching, errors, meta, token, user } = props;

  const [firstName, setFirstName] = useState('');
  const [email, setEmail] = useState('');

  const [formLmsCourseId, setFormLmsCourseId] = useState(lmsCourseId);
  const [activeEnrollment, setActiveEnrollment] = useState(null);
  //Request Triggers
  const [createGuestRequest, setCreateGuestRequest] = useState(false);

  const [createPrompt, setCreatePrompt] = useState(false);

  const isMobile = useMediaQuery({ maxWidth: 768 });

  // Form Validation
  const [formValidation, setFormValidation] = useState({
    fields: {
      formLmsCourseId: {
        validated: false,
        valid: true,
        errors: [],
      },
      firstName: {
        validated: false,
        valid: true,
        errors: [],
      },
      email: {
        validated: false,
        valid: true,
        errors: [],
      },
    },
  });

  //Form Submission
  const handleGuestCreate = () => {
    let lmsCourseId = formLmsCourseId ? formLmsCourseId : lmsCourseData?.id;
    createGuestEnrollment({ lmsCourseId, token, firstName, email });
  };

  //Form Events
  /**
   * Course Select Field Change Event
   * @param {*} value int
   * @returns {void}
   */
  const handleFormLmsCourseChange = value => {
    setFormLmsCourseId(value);
  };

  /**
   * Student First Name Input Field Change Event
   * @param {*} event Event
   * @returns {void}
   */
  const handleFirstNameChange = event => {
    const { value } = event.target;
    setFirstName(value);
  };

  /**
   * Student Email Input Field Change Event
   * @param {*} event Event
   * @returns {void}
   */
  const handleEmailChange = event => {
    const { value } = event.target;
    setEmail(value);
  };

  // Effects
  useEffect(() => {
    if (meta && meta !== '' && meta !== 'course') {
      try {
        let metaData = Base64?.decode(meta);
        if (metaData !== undefined && metaData != null) {
          let metaSplit = metaData.split(':');
          setFirstName(metaSplit[0]);
          setEmail(metaSplit[1]);
          if (lmsCourseData) {
            let firstName = metaSplit[0];
            let email = metaSplit[1];
            createGuestEnrollment({ lmsCourseId, token, firstName, email });
          }
        }
      } catch (e) {}
    }
  }, [meta, lmsCourseData]);

  useEffect(() => {
    setFormLmsCourseId(lmsCourseId);
  }, [lmsCourseId]);

  useEffect(() => {
    if (user?.student?.enrollments && user?.student?.enrollments?.length >= 1) {
      let lastActive;
      let active_guest_lms_course = user?.student?.enrollments?.filter(enrollment => {
        if (enrollment?.guest && !enrollment?.guest_expired && (!lastActive?.enrollment_date || enrollment?.enrollment_date >= lastActive?.enrollment_date)) {
          lastActive = enrollment;
        }
        return lastActive;
      })?.[0];
      setActiveEnrollment(active_guest_lms_course);
    }
  }, [user]);

  const getRating = () => {
    if (lmsCourseData) {
      if (Array.isArray(lmsCourseData) && lmsCourseData.length >= 1) {
        return parseFloat(
          (
            lmsCourseData
              ?.map(item => item?.review_aggregates?.average_rating)
              .reduce(function(a, b) {
                return a + b;
              }, 0) / lmsCourseData.length
          ).toFixed(2),
        );
      } else {
        return lmsCourseData?.review_aggregates?.average_rating;
      }
    }
  };
  const getRatingCount = () => {
    if (lmsCourseData) {
      if (Array.isArray(lmsCourseData) && lmsCourseData.length >= 1) {
        return parseFloat(
          lmsCourseData
            ?.map(item => item?.review_aggregates?.count)
            .reduce(function(a, b) {
              return a + b;
            }, 0),
        );
      } else {
        return lmsCourseData?.review_aggregates?.count;
      }
    }
  };
  return (
    <GuestAccessStyles>
      <Layout style={{ background: `none` }}>
        {/*<Header>*/}
        {/*  <div className={`aypo-header-container`}>*/}
        {/*    <Row style={{ width: `100%` }}>*/}
        {/*      <Col xs={24} sm={24} md={24} lg={8}>*/}
        {/*        <div className={`aypo-header-goback-container`}>*/}
        {/*          <a href={`#`} style={{ color: '#fff' }}>*/}
        {/*            <a href={`/`}>*/}
        {/*              <RollbackOutlined style={{ fontSize: 30 }} /> Go Back To Cart*/}
        {/*            </a>*/}
        {/*          </a>*/}
        {/*        </div>*/}
        {/*      </Col>*/}
        {/*    </Row>*/}
        {/*  </div>*/}
        {/*</Header>*/}
        <GuestAccessStyles.Content>
          <div className={`aypo-layout-container`}>
            <div className={`aypo-content-header-container`}>
              <Row gutter={[16, 16]}>
                <Col xs={24} sm={24} md={24} lg={12}>
                  <div className={`aypo-logo-container`}>
                    <img className={`aypo-logo`} src={'/images/logos/' + Constants.WEBSITE_NAME + '/logo.png'} alt={`aypo continuing education guest access`} />
                  </div>
                </Col>
                <Col xs={24} sm={24} md={24} lg={12}>
                  <div className={`aypo-phone-conversion`}>
                    <div className={`aypo-phone-conversion-padding`}>
                      <div style={{ fontWeight: `bold`, fontSize: 22 }}>Call Us Toll-Free</div>
                      <div className={`aypo-phone-number`} style={{ color: `white`, fontSize: 38 }}>
                        <a href={isMobile ? `tel:8777246150` : `${window.CART_URL}contact/`}>(877) 724-6150</a>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
            <div className={`aypo-content-container`}>
              {lmsCourseId && meta ? (
                <Row gutter={[16, 16]}>
                  <Col xs={24} sm={24} md={24} lg={24}>
                    {errors || lmsCourseErrors ? (
                      ''
                    ) : (
                      <div style={{ padding: 25 }}>
                        <h1 style={{ fontSize: 34 }}>Loading Guest Access...</h1>
                        <GuestAccessStyles.Skeleton active />
                      </div>
                    )}
                  </Col>
                </Row>
              ) : lmsCourseData && lmsCourseData?.length === 0 ? (
                <Row gutter={[16, 16]}>
                  <Col xs={24} sm={24} md={24} lg={24}>
                    <div className={`aypo-content-widget-container aypo-title-container`}>
                      <h1
                        style={{
                          fontSize: 40,
                          marginBottom: 0,
                          color: `#fff`,
                          textAlign: `center`,
                        }}
                      >
                        Guest Access Not Available
                      </h1>
                    </div>
                  </Col>
                </Row>
              ) : (
                <Row gutter={[16, 16]}>
                  <Col xs={24} sm={24} md={24} lg={24}>
                    <div className={`aypo-content-widget-container aypo-title-container`}>
                      <h1
                        style={{
                          fontSize: 40,
                          marginBottom: 0,
                          color: `#fff`,
                          textAlign: `center`,
                        }}
                      >
                        {lmsCourseData?.name ? lmsCourseData?.name : `Try Before You Buy`} - Guest Access
                      </h1>
                    </div>
                  </Col>
                  <Col start="sm" xs={24} sm={24} md={24} lg={{ push: 14, span: 10 }}>
                    <div style={{ width: `100%`, height: `auto` }}>
                      <div style={{ position: `static`, height: 'auto', width: `100%`, minHeight: `auto` }}>
                        <div className={`aypo-form-container`} style={{ marginBottom: 15 }}>
                          <div style={{ padding: 20 }}>
                            <Form>
                              <Row>
                                <Col xs={24} sm={24} md={24} lg={24}>
                                  <h1 style={{ fontSize: 30, color: '#fff' }}>Try your course before you buy</h1>
                                </Col>
                                <Col xs={24} sm={24} md={24} span={24}>
                                  <p style={{ color: '#fff', fontSize: 20 }}>
                                    Let&apos;s get started! {token ? '' : 'Start by telling us your name and email and then we can get you right into your course.'}
                                  </p>
                                </Col>
                                {!lmsCourseId ? (
                                  <Col xs={24} sm={24} md={24} span={24}>
                                    <Form.Item
                                      validateStatus={errors && errors['lms_course_id'] ? 'error' : 'success'}
                                      help={errors && errors['lms_course_id'] ? errors['lms_course_id'] : null}
                                      label={activeEnrollment?.lms_course_id ? `Continue where you left off or try another course` : `What course do you want to try`}
                                      required
                                      tooltip="This is a required field"
                                      style={{ marginBottom: 15, color: `#fff` }}
                                    >
                                      <GuestAccessStyles.Select
                                        placeholder="Choose Course"
                                        loading={fetching}
                                        defaultValue={
                                          formLmsCourseId && lmsCourseData && lmsCourseData?.filter(item => item?.id == formLmsCourseId) ? formLmsCourseId : activeEnrollment?.lms_course_id
                                        }
                                        value={formLmsCourseId && lmsCourseData && lmsCourseData?.filter(item => item?.id == formLmsCourseId) ? formLmsCourseId : activeEnrollment?.lms_course_id}
                                        onChange={handleFormLmsCourseChange}
                                        disabled={false}
                                      >
                                        {lmsCourseData &&
                                          lmsCourseData?.length >= 1 &&
                                          lmsCourseData?.map((i, k) => {
                                            return (
                                              <Select.Option key={`${k}`} value={i.id}>
                                                {i.name} - ${i?.cart_course?.[0]?.price}
                                              </Select.Option>
                                            );
                                          })}
                                      </GuestAccessStyles.Select>
                                    </Form.Item>
                                  </Col>
                                ) : (
                                  ``
                                )}

                                {!token ? (
                                  <Col xs={24} sm={24} md={24} span={24}>
                                    <Form.Item
                                      validateStatus={errors && errors['firstname'] ? 'error' : 'success'}
                                      help={errors && errors['firstname'] ? errors['firstname'] : null}
                                      label="First Name"
                                      required
                                      tooltip="This is a required field"
                                      style={{ marginBottom: 15, color: `#fff` }}
                                    >
                                      <GuestAccessStyles.Input value={firstName} onChange={handleFirstNameChange} placeholder="Student first name" />
                                    </Form.Item>
                                  </Col>
                                ) : (
                                  ''
                                )}

                                {!token ? (
                                  <Col xs={24} sm={24} md={24} span={24}>
                                    <Form.Item
                                      validateStatus={errors && errors['email'] ? 'error' : 'success'}
                                      help={errors && errors['email'] ? errors['email'] : null}
                                      label="Email"
                                      required
                                      tooltip="This is a required field"
                                      style={{ marginBottom: 15, color: `#fff` }}
                                    >
                                      <GuestAccessStyles.Input value={email} onChange={handleEmailChange} placeholder="Student Email" />
                                    </Form.Item>
                                  </Col>
                                ) : (
                                  ''
                                )}

                                <Col xs={24} sm={24} md={24} span={24}>
                                  <div style={{ textAlign: 'center' }}>
                                    <GuestAccessStyles.Button onClick={handleGuestCreate} disabled={fetching}>
                                      {fetching ? (
                                        <Icon type="loading" />
                                      ) : (activeEnrollment?.lms_course_id && !formLmsCourseId) || (activeEnrollment?.lms_course_id && activeEnrollment?.lms_course_id === formLmsCourseId) ? (
                                        'Continue Trial'
                                      ) : (
                                        'Start Trial'
                                      )}
                                    </GuestAccessStyles.Button>
                                  </div>
                                </Col>
                              </Row>
                            </Form>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={{ span: 14, pull: 10 }}>
                    <Row
                      className={`aypo-widget-container`}
                      gutter={[
                        { xs: 10, sm: 12, md: 16, lg: 16 },
                        { xs: 10, sm: 12, md: 16, lg: 16 },
                      ]}
                    >
                      <Col xs={24} sm={24} md={24} lg={24}>
                        <div className={`aypo-content-widget-container`}>
                          <div style={{ textAlign: `center` }}>{lmsCourseData ? <GuestAccessStyles.Rate allowHalf defaultValue={lmsCourseData && getRating()} disabled /> : ''}</div>
                          <div style={{ color: `#fff`, fontSize: 25, textAlign: 'center' }}>
                            {lmsCourseData ? (
                              getRating().toLocaleString('en-US', {
                                maximumFractionDigits: 2,
                              })
                            ) : (
                              <Icon type="loading" />
                            )}{' '}
                            {lmsCourseId ? `Star` : 'Star Overall'} Rating ({lmsCourseData ? getRatingCount() : <Icon type="loading" />})
                          </div>
                        </div>
                      </Col>
                      <Col xs={24} sm={24} md={24} lg={12}>
                        <div className={`aypo-content-widget-container`}>
                          <div style={{ textAlign: `center` }}>
                            <img src={`/images/icon/icon_usa.svg`} width={lmsCourseId ? 80 : 135} />
                          </div>
                          <div style={{ color: `#fff`, fontSize: 18, textAlign: 'center' }}>State Approved</div>
                        </div>
                      </Col>

                      <Col xs={24} sm={24} md={24} lg={12}>
                        <div className={`aypo-content-widget-container`}>
                          <div style={{ textAlign: `center` }}>
                            <img src={`/images/icon/icon_online.svg`} width={lmsCourseId ? 80 : 135} />
                          </div>
                          <div style={{ color: `#fff`, fontSize: 18, textAlign: 'center' }}>100% Online</div>
                        </div>
                      </Col>
                      <Col xs={24} sm={24} md={24} lg={12}>
                        <div className={`aypo-content-widget-container`}>
                          <div style={{ textAlign: `center` }}>
                            <img src={`/images/icon/icon_phone.svg`} width={lmsCourseId ? 80 : 135} />
                          </div>
                          <div style={{ color: `#fff`, fontSize: 18, textAlign: 'center' }}>Mobile Friendly</div>
                        </div>
                      </Col>
                      <Col xs={24} sm={24} md={24} lg={12}>
                        <div className={`aypo-content-widget-container`}>
                          <div style={{ textAlign: `center` }}>
                            <img src={`/images/icon/icon_cert.svg`} width={lmsCourseId ? 80 : 135} />
                          </div>
                          <div style={{ color: `#fff`, fontSize: 18, textAlign: 'center' }}>Print Your Certificate</div>
                        </div>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              )}
              {errors && errors?.length >= 1 ? errors?.map((item, index) => <Alert message={item} key={`error-${index}`} type="error" style={{ marginBottom: 15 }} />) : ''}
              {lmsCourseErrors && lmsCourseErrors?.length >= 1
                ? lmsCourseErrors?.map((item, index) => <Alert message={item} key={`lms-course-error-${index}`} type="error" style={{ marginBottom: 15 }} />)
                : ''}
            </div>
          </div>
        </GuestAccessStyles.Content>
        {/*<GuestAccessStyles.Footer style={{ background: '#efefef', padding: 25 }}>*/}
        {/*  <div className={`aypo-header-container`}>*/}
        {/*    <Row>*/}
        {/*      <Col xs={24} sm={24} md={12} lg={12}>*/}
        {/*        <div style={{ textAlign: 'center' }}>*/}
        {/*          <ul className="list-inline">*/}
        {/*            <li>*/}
        {/*              <img src={`/images/footer/100-satisfaction-guaranteed.png`} alt={``} />*/}
        {/*            </li>*/}
        {/*            <li>*/}
        {/*              <img src={`/images/footer/godaddy-certified-atyourpaceonline.gif`} alt={``} />*/}
        {/*            </li>*/}
        {/*            <li>*/}
        {/*              <img src={`/images/footer/authorize-certified-atyourpaceonline.png`} alt={``} />*/}
        {/*            </li>*/}
        {/*            <li>*/}
        {/*              <img src={`/images/footer/arello-idecc-certified-atyourpaceonline.png`} alt={``} />*/}
        {/*            </li>*/}
        {/*            <li>*/}
        {/*              <img src={`/images/footer/facebook.png`} alt={``} />*/}
        {/*            </li>*/}
        {/*          </ul>*/}
        {/*        </div>*/}
        {/*      </Col>*/}
        {/*      <Col xs={24} sm={24} md={12} lg={12}>*/}
        {/*        <div style={{ fontSize: 18, position: `relative`, top: 15 }}>*/}
        {/*          <div style={{ textAlign: 'center', marginBottom: 10 }}>*/}
        {/*            <span onClick={handleRefundPolicy}>Refund Policy</span> <Divider type="vertical" /> <span onClick={handlePrivacyPolicy}>Privacy Policy</span>*/}
        {/*          </div>*/}
        {/*          <div style={{ textAlign: 'center' }}>© WIContractorTraining.com is an At Your Pace Online, LLC Property</div>*/}
        {/*        </div>*/}
        {/*      </Col>*/}
        {/*    </Row>*/}
        {/*  </div>*/}
        {/*</GuestAccessStyles.Footer>*/}
      </Layout>
    </GuestAccessStyles>
  );
};

// PROPS TYPES
// --------------------------------------------------------
GuestAccess.propTypes = {
  token: PropTypes.string,
  lmsCourseId: PropTypes.number,
  meta: PropTypes.array,
  fetching: PropTypes.bool,
  errors: PropTypes.object,
  lmsCourseData: PropTypes.any,
  lmsCourseErrors: PropTypes.any,
  createGuestEnrollment: PropTypes.func,
  user: PropTypes.any,
};

// EXPORTS
// --------------------------------------------------------
export default withRouter(GuestAccess);
